import React from "react";

export const RefundIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.303 28.072"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1664"
            width="32.303"
            height="28.072"
            fill="currentColor"
          ></rect>
        </clipPath>
      </defs>
      <g id="Group_166894" clipPath="url(#clip-path)">
        <path
          id="Path_13687"
          d="M26.552,54.293a10.577,10.577,0,0,0-3.8-.514c-.224.009-.247-.083-.245-.27.009-.829,0-1.659.006-2.488a.638.638,0,0,0-.313-.638.615.615,0,0,0-.7.106q-3.94,2.818-7.881,5.634a.589.589,0,0,0,0,1.075q3.938,2.819,7.88,5.634a.615.615,0,0,0,.7.1.641.641,0,0,0,.312-.64c-.006-.817,0-1.635-.007-2.452,0-.222.032-.313.288-.285a6.864,6.864,0,0,1,3.53,1.4.535.535,0,0,1,.23.48q-.01,3.516,0,7.031c0,.258-.069.31-.317.31q-12.963-.01-25.926,0c-.248,0-.317-.052-.316-.31Q.012,58.4,0,48.318c0-.24.04-.322.3-.321q5.174.015,10.349,0c.287,0,.325.089.322.341-.013,1.178-.007,2.356-.005,3.534,0,.56.193.749.762.749q1.569,0,3.137,0c.514,0,.716-.2.716-.723,0-1.2.008-2.4-.005-3.606,0-.239.065-.293.3-.293q5.192.011,10.385.006c.147,0,.3-.051.3.216-.012,2.007-.007,4.014-.007,6.073"
          transform="translate(0 -43.369)"
          fill="currentColor"
        ></path>
        <path
          id="Path_13688"
          d="M161.28,89.912c0,.766,0,1.487,0,2.208,0,.47.213.683.683.683a14.371,14.371,0,0,1,3.546.277,8.913,8.913,0,0,1,6.667,7.691,8.76,8.76,0,0,1-3.912,8.409c-.091.065-.172.176-.346.123.1-.126.192-.242.284-.356a7.788,7.788,0,0,0-4.666-12.55,7.923,7.923,0,0,0-1.614-.129.581.581,0,0,0-.641.651c-.006.732,0,1.464,0,2.193-.1.046-.142-.031-.194-.068q-3.036-2.165-6.07-4.331c-.158-.112-.266-.17-.029-.338q3.087-2.185,6.16-4.391c.027-.019.058-.032.132-.072"
          transform="translate(-139.922 -81.244)"
          fill="currentColor"
        ></path>
        <path
          id="Path_13689"
          d="M8.524,3.481c.962-.961,1.9-1.839,2.761-2.78A2.018,2.018,0,0,1,12.961,0c3.026.042,6.052.018,9.078.018h.352l.087.1a1.279,1.279,0,0,0-.263.159q-1.508,1.5-3.007,3a.557.557,0,0,1-.43.2c-3.386-.007-6.773-.005-10.254-.005"
          transform="translate(-7.702 0)"
          fill="currentColor"
        ></path>
        <path
          id="Path_13690"
          d="M184.841.17c-1.126,1.124-2.214,2.209-3.3,3.3a.444.444,0,0,1-.344.145q-5.1-.006-10.2,0c-.045-.116.061-.146.111-.2q1.537-1.545,3.078-3.086a.519.519,0,0,1,.4-.185q5.044.009,10.087.005c.033,0,.066.009.161.022"
          transform="translate(-154.508 -0.129)"
          fill="currentColor"
        ></path>
        <path
          id="Path_13691"
          d="M290.8,8.7V20.224c-.07.062-.127-.01-.182-.038-1.008-.5-2.012-1.01-3.024-1.5a.386.386,0,0,1-.247-.411q.012-2.936,0-5.872a.522.522,0,0,1,.166-.409c1.081-1.076,2.155-2.157,3.287-3.292"
          transform="translate(-259.645 -7.861)"
          fill="currentColor"
        ></path>
        <path
          id="Path_13692"
          d="M131.845.161c-1.218,1.37-2.379,2.682-3.549,3.986a.906.906,0,0,0-.26.668c.016,1.045,0,2.09.012,3.134,0,.231-.053.3-.292.3-.6-.018-1.2-.012-1.8,0-.168,0-.225-.039-.224-.217q.012-1.711,0-3.423a.4.4,0,0,1,.147-.307q2-2,4-4a.358.358,0,0,1,.273-.14c.537.007,1.075,0,1.693,0"
          transform="translate(-113.61 -0.143)"
          fill="currentColor"
        ></path>
        <path
          id="Path_13693"
          d="M15.405,216.776c-1.046,0-2.091-.008-3.137.006-.235,0-.3-.059-.3-.295.005-.865,0-.865.873-.865,1.911,0,3.822.006,5.733-.007.269,0,.338.062.332.332-.017.829,0,.829-.838.829Z"
          transform="translate(-10.819 -194.828)"
          fill="currentColor"
        ></path>
        <path
          id="Path_13694"
          d="M15.419,240.7c-1.046,0-2.091-.007-3.137.005-.236,0-.329-.032-.325-.3.012-.858,0-.858.845-.858,1.911,0,3.822.009,5.733-.009.313,0,.368.089.361.377-.02.785-.006.786-.809.786Z"
          transform="translate(-10.804 -216.449)"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
};
