import React, { Suspense, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import { Router } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HeaderTop from "../components/common/HeaderTop";
import Header from "../components/common/Header";
import { setLanguage } from "../redux/actions/globalAction";
import { getDateTimeFormat } from "../redux/actions/masterDataConfigurationAction";
import Footer from "../components/common/Footer";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageLoader from "../components/common/PageLoader";
import MobileHeader from "../components/common/MobileHeader";
import Logo from "../assets/images/gogo-motors.png";
import ScrollToTop from "./ScrollToTop";
import { languages } from "../utilities/constants/global.constants";

const history = createBrowserHistory();

const AppRoute = () => {
  const [, setLoadingApp] = useState(false);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthentic } = useSelector((state) => state.authReducer);
  const { isError } = useSelector((state) => state.authReducer);
  const step = sessionStorage.getItem("step");

  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  const onLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const loader = () => (
    <div className="h-screen flex items-center justify-center w-full">
      <div className="w-16 h-16 border-b-2 border-t-2 border-red-600 rounded-full animate-spin"></div>
    </div>
  );

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.title = i18n.t("GOGO_MOTOR");
    sessionStorage.removeItem("step");
  }, [i18n, i18n.language]);

  useEffect(() => {
    sessionStorage.removeItem("step");
  }, [step]);

  useEffect(() => {
    if (isAuthentic) {
      setLoadingApp(true);
    }
  }, [isAuthentic]);

  useEffect(() => {
    dispatch(getDateTimeFormat());
  }, [dispatch]);

  return (
    <>
      <Router history={history}>
        <ScrollToTop>
          <Suspense fallback={loader()}>
            <>
              <div className="hidden lg:block">
                {!isError ? (
                  <div className="w-full bg-white">
                    <div
                      className={`container flex justify-between gap-6 ${
                        selectedLanguage === languages.eng
                          ? "flex-row"
                          : "flex-row-reverse"
                      }`}
                    >
                      <div
                        className={
                          isAuthentic
                            ? "flex items-center justify-center cursor-pointer"
                            : "flex items-center justify-center cursor-pointer p-3"
                        }
                      >
                        <img
                          src={Logo}
                          alt="logo"
                          onClick={() => history.push("/")}
                        />
                      </div>
                      <div className="w-full">
                        <HeaderTop
                          onLanguageChange={(lang) => onLanguageChange(lang)}
                          height={40}
                          backgroundColor="white"
                        />
                        {isAuthentic && (
                          <Header height={50} backgroundColor="white" />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="block lg:hidden">
                <MobileHeader
                  onLanguageChange={(lang) => onLanguageChange(lang)}
                />
              </div>
              <div
                className={isAuthentic ? "app-container" : "app-conatiner flex"}
              >
                {isAuthentic ? <PrivateRoute /> : <PublicRoute />}
              </div>
              {!isError ? <Footer /> : ""}
            </>
          </Suspense>
        </ScrollToTop>
      </Router>
      <PageLoader />
      <div id="app-message-box" />
    </>
  );
};
export default connect((state) => state, {})(AppRoute);
