import {
  GET_SERVICES,
  GET_SERVICES_DETAIL,
  GET_SERVICE_PARAMETERS,
  GET_SERVICE_DETAILS_BY_ID,
  GET_SERVICE_CONFIG_DETAIL,
} from "../types";
const initialState = {
  services: [],
  serviceDetails: [],
  serviceParameters: [],
  serviceDataById: {},
  serviceConfigDetail: {},
  serviceConfigDataById: {},
};

export const packageServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case GET_SERVICES_DETAIL:
      return {
        ...state,
        serviceDetails: action.payload,
      };
    case GET_SERVICE_PARAMETERS:
      return {
        ...state,
        serviceParameters: action.payload,
      };

    case GET_SERVICE_DETAILS_BY_ID:
      return {
        ...state,
        serviceDataById: action.payload,
      };
    case GET_SERVICE_CONFIG_DETAIL:
      return {
        ...state,
        serviceConfigDataById: action.payload,
      };
    default:
      return state;
  }
};
