import {
  GET_PACKAGES,
  GET_B2C_PACKAGES,
  GET_PACKAGE_CONFIG_VALUE,
} from "../types";

const initialState = {
  packages: [],
  percentageValue: null,
  b2cPackages: [],
};

export const packageConfigurationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PACKAGES:
      state = {
        ...state,
        packages: payload,
      };
      break;
    case GET_PACKAGE_CONFIG_VALUE:
      state = {
        ...state,
        percentageValue: payload,
      };
      break;
    case GET_B2C_PACKAGES:
      state = {
        ...state,
        b2cPackages: payload,
      };
      break;
    default:
      return state;
  }
  return state;
};
