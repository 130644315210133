export const CarBootIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.991 54.933"
      className={className}
      fill="currentColor"
    >
      <g
        id="Group_167988"
        data-name="Group 167988"
        transform="translate(11187.52 -12006.77)"
      >
        <g
          id="Icon_feather-arrow-left"
          data-name="Icon feather-arrow-left"
          transform="translate(-11107.735 12007.516) rotate(90)"
        >
          <path
            id="Path_15365"
            data-name="Path 15365"
            d="M19.811,18H7.5"
            transform="translate(0 -6.877)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_15366"
            data-name="Path 15366"
            d="M11.123,14.745,7.5,11.123,11.123,7.5"
            transform="translate(0 0)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="Layer_x0020_1" transform="translate(-20287.52 6484.77)">
          <g id="_1466912779632" transform="translate(9100 5522)">
            <path
              id="Path_15420"
              data-name="Path 15420"
              d="M9131.816,5567.511a7.886,7.886,0,0,1,13.258-6.145,8.121,8.121,0,0,1,1.69,9.281,7.89,7.89,0,0,1-14.147-.051A6.683,6.683,0,0,1,9131.816,5567.511Zm16.375-5.374h14.9c0,2.415.492,5.236-1.208,5.236h-12.351A11.949,11.949,0,0,0,9148.191,5562.138Zm9.4-4.161a2.552,2.552,0,0,1,2.553-2.415h2.953v4.566h-5.506Zm-.938-29.263h2.208v-.015l2.963,0c2.263-.1,1.811-1.01,1.811-5.358,0-.873-.861-1.345-1.745-1.345h-5.905c-1.619,0-2.086.812-2.924,1.645a23.712,23.712,0,0,0-2.238,2.329c-1.232,1.593-3.3,3.035-4.506,4.618-1.217,1.6-3.206,2.989-4.484,4.643a62.445,62.445,0,0,1-4.644,4.623,25.2,25.2,0,0,0-1.507-2.253c-.279-.426-.508-.726-.777-1.106-1.491-2.1-7.443-11.123-8.834-12.239a4.98,4.98,0,0,0-2.842-1.05H9100.67c-.533,0-.67.522-.67.943,0,1.36,2.207.939,4.563.939,3.008,0,18.176-.177,19.189.142,1.828.579,2.716,2.558,4.035,4.42l10.307,14.665c.4.3,19.129,3.643,21.123,3.978.822.137,1.6.259,2.395.426,1.97.411,1.482,1.4,1.482,4.963-2.369,0-4.861-.375-6.332,1.451-1.34,1.66-1.187,2.867-1.187,4.993h-8.86c-.624,0-4.526-5.028-11.519-1.725-2.73,1.294-1.213,1.746-3.648,1.725H9104.7c-2.075,0-4.7-.538-4.7,1.071,0,1.263,1.715.939,2.953.939h28.188c-.2.853-1.34,2.248-1.34,5.236h-28.191c-1.187,0-1.609.259-1.609,1.076,0,1.253,1.553.938,2.816.938h27.254c.883,1.842.705,2.943,3.115,5.069a7.939,7.939,0,0,0,2.432,1.6,9.983,9.983,0,0,0,8.031.066,9.869,9.869,0,0,0,5.364-5.911,6.062,6.062,0,0,1,.254-.822c4.039,0,8.5.183,12.487,0a3.286,3.286,0,0,0,3.223-2.948c.045-.984-.006-2.1-.006-3.095v-8.186a48.025,48.025,0,0,0-.051-5.855c-.334-1.573-1.852-2.461-3.572-2.6v-.122l-2.014-.345v.066l-14.787-2.669a15.662,15.662,0,0,1-2.125-.421l12.381-12.717c.3-.3.6-.558.867-.883C9155.914,5529.322,9156.223,5528.713,9156.649,5528.713ZM9140,5542.81c-.985,0-1.218-.756-1.608-1.345l12.787-13.122c.705-.705,1.5-1.41,2.146-2.146.385-.436,1.7-2.182,2.248-2.182h6.175v2.821h-5.638c-.938,0-3.389,3.055-4.125,3.791l-3.024,3.019c-.411.406-.538.68-.934,1.076l-4.019,4.04A47.514,47.514,0,0,1,9140,5542.81Z"
              transform="translate(-9100 -5522)"
              fillRule="evenodd"
            />
            <path
              id="Path_15421"
              data-name="Path 15421"
              d="M9100,6739.939c0,1.6,2.507,1.076,4.563,1.076h16.512c1.2,2.263,7.535,10.89,8.59,12.889h-26.711c-1.882,0-2.953-.4-2.953,1.208,0,.431.487.669.939.669h30.739c.381,0,.807-.558.807-.938,0-.421-4.972-7.692-5.292-8.134-.979-1.345-1.781-2.613-2.71-4-.477-.715-.847-1.314-1.354-1.994-.467-.619-.873-1.71-1.787-1.71h-20.271C9100.451,6739,9100,6739.3,9100,6739.939Z"
              transform="translate(-9100 -6732.825)"
              fillRule="evenodd"
            />
            <path
              id="Path_15422"
              data-name="Path 15422"
              d="M15873.883,13365.232a3.656,3.656,0,0,1,1.365-2.658,4.911,4.911,0,0,1,.486-.32,3.412,3.412,0,0,1,4.994,2.979C15880.729,13369.926,15873.883,13369.576,15873.883,13365.232Zm-1.883-.27v.676c0,3.968,5.4,6.81,8.916,3.541l1.07-1.35a5.347,5.347,0,0,0-8.348-6.459A5.653,5.653,0,0,0,15872,13364.963Z"
              transform="translate(-15837.637 -13320.128)"
              fillRule="evenodd"
            />
            <path
              id="Path_15423"
              data-name="Path 15423"
              d="M11636.287,10603.34c0,1.04-.232,2.821.939,2.821,1.309,0,1.07-1.376,1.07-2.284h5.506c0,.792-.122,1.559.2,1.949a.967.967,0,0,0,1.68-.473c0-1.207.32-3.354-.8-3.354h-7.521C11636.557,10602,11636.287,10602.538,11636.287,10603.34Z"
              transform="translate(-11623.399 -10576.223)"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
