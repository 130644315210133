import React from "react";

export const ActivateUserIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 35.621 29.182"
      id=".5696407377400232"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        data-name="Icon feather-user-check"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <path
          data-name="Path 13669"
          d="M24 27.682v-3a6 6 0 0 0-6-6H7.5a6 6 0 0 0-6 6v3"
        />
        <path
          data-name="Path 13670"
          d="M18.5 7.5a6 6 0 1 1-6-6 6 6 0 0 1 6 6z"
        />
        <path data-name="Path 13671" d="M24.5 13.149l3 3 6-6" />
      </g>
    </svg>
  );
};
