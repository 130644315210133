import React from "react";

export const BookmarkIcon = ({ className = "" }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.5002 18.5601L9.75031 17.7838L9.72901 17.7618L9.70641 17.7411C6.9598 15.2262 4.76705 13.1898 3.25123 11.2806C1.74771 9.38688 1.00053 7.72733 1.00053 5.97767H1.00061L1.00044 5.96453C0.991777 5.30544 1.11085 4.65196 1.34983 4.04303C1.58879 3.43415 1.94232 2.88329 2.38784 2.4221C2.83329 1.96099 3.36153 1.59893 3.9404 1.35549C4.51917 1.11208 5.13793 0.991678 5.76046 1.00045L5.76322 1.00048C6.51456 1.00899 7.25667 1.18352 7.94016 1.51371C8.62377 1.84395 9.23411 2.32291 9.7289 2.92065L10.4992 3.85128L11.2696 2.92066C11.7645 2.32274 12.375 1.84369 13.0589 1.51343C13.7426 1.18324 14.485 1.00881 15.2365 1.00049L15.2395 1.00045C15.8621 0.991678 16.4808 1.11208 17.0596 1.35549C17.6385 1.59893 18.1667 1.96099 18.6122 2.4221C19.0577 2.88329 19.4112 3.43415 19.6502 4.04303C19.8892 4.65196 20.0082 5.30544 19.9996 5.96453L19.9995 5.96452V5.97767C19.9995 7.7269 19.2523 9.38624 17.7489 11.2798C16.2331 13.1888 14.0406 15.225 11.2944 17.7395L11.2715 17.7605L11.2499 17.7828L10.5002 18.5601Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
