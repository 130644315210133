import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise";
import {
  applicationReducer,
  globalReducer,
  filterReducer,
  masterDataConfigurationReducer,
  qcDashboardReducer,
  vehicleReducer,
  makeModelReducer,
  authReducer,
  dmsDashboardReducer,
  dmsSalesPersonReducer,
  userManagementReducer,
  dealerApplicationFormReducer,
  dmsUserManagementReducer,
  dmsSalesPersonDashboardReducer,
  packageConfigurationReducer,
  packagesReducer,
  packageSubscriptionReducer,
  invoiceReducer,
  myVehicleReducer,
  promotionReducer,
  feedBackReducer,
  notificationReducer,
  packageServiceReducer,
  promotionCodeReducer,
  cmsConfigurationReducer,
  templateConfigurationReducer,
  vasReducer,
  dealOfferReducer,
} from "./reducers/index";

const rootReducer = combineReducers({
  application: applicationReducer,
  filterReducer: filterReducer,
  makeModelReducer: makeModelReducer,
  masterDataConfigurationReducer: masterDataConfigurationReducer,
  qcDashboardReducer: qcDashboardReducer,
  vehicleReducer: vehicleReducer,
  globalReducer: globalReducer,
  authReducer: authReducer,
  dmsDashboardReducer: dmsDashboardReducer,
  dmsSalesPersonReducer: dmsSalesPersonReducer,
  userManagementReducer: userManagementReducer,
  dealerApplicationFormReducer: dealerApplicationFormReducer,
  dmsUserManagementReducer: dmsUserManagementReducer,
  dmsSalesPersonDashboardReducer: dmsSalesPersonDashboardReducer,
  packageConfigurationReducer: packageConfigurationReducer,
  packagesReducer: packagesReducer,
  packageSubscriptionReducer: packageSubscriptionReducer,
  invoiceReducer: invoiceReducer,
  myVehicleReducer: myVehicleReducer,
  promotionReducer: promotionReducer,
  feedBackReducer: feedBackReducer,
  notificationReducer: notificationReducer,
  packageServiceReducer: packageServiceReducer,
  promotionCodeReducer: promotionCodeReducer,
  cmsConfigurationReducer: cmsConfigurationReducer,
  templateConfigurationReducer: templateConfigurationReducer,
  vasReducer: vasReducer,
  dealOfferReducer: dealOfferReducer,
});

const initialState = {};
const middleware = [thunk];

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "authReducer",
    "masterDataConfigurationReducer",
    "myVehicleReducer",
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  initialState,
  compose(applyMiddleware(...middleware, promiseMiddleware))
);
export const persistor = persistStore(store);
export default { store, persistor };
