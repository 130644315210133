import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import MessageBox, {
  MessageBoxType,
} from "../../components/common/message-box";
import { BackIcon } from "../../components/gogo-icons";
import VehicleSpecificationList from "../../components/vehicle-specification-list";
import {
  clearTrimData,
  getTrimByIdV1,
  getVehicleSpecsV1,
  getVehicleTrimV1,
} from "../../redux/actions/vehicleAction";
import { FiledTypes } from "../../utilities/constants/global.constants";

const VariantSpecifications = ({ trimId, handleSave, specifications }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [allSpecification, setAllSpecification] = useState([]);
  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  const trimData = useSelector((state) => state.vehicleReducer.trimData);
  const vehicleTrims = useSelector(
    (state) => state.vehicleReducer.vehicleTrimData
  );

  useEffect(() => {
    setAllSpecification(specifications);
    if (trimId) {
      dispatch(getTrimByIdV1(trimId));
    }
    return () => {
      dispatch(clearTrimData());
    };
  }, [dispatch, specifications, trimId]);

  useEffect(() => {
    if (trimData?.ModelId) {
      dispatch(getVehicleTrimV1({ modelId: trimData.ModelId, languageId }));
    }
  }, [dispatch, languageId, trimData]);

  const handleDropDownChange = (e) => {
    const payload = {
      languageId,
      id: e.TrimId,
    };

    dispatch(getVehicleSpecsV1(payload))
      .then(async (res) => {
        if (!res) {
          MessageBox.open({
            content: i18next.t("INVALID_TRIM_DETAILS_FOR_SPEC"),
            type: MessageBoxType.Alert,
          }).then(() => {});
          return;
        }
        if (res) {
          const merged = allSpecification.map((item) => {
            const updatedSpecObj = res.find(
              (a) =>
                a.Specification === item.Specification &&
                a.SpecificationId &&
                (a.Values?.length > 0 || a?.CustomOptions)
            );
            if (updatedSpecObj) {
              if (updatedSpecObj.FieldType === FiledTypes.Custom) {
                return {
                  ...item,
                  IsActive: true,
                  CustomOptions: [...updatedSpecObj.CustomOptions],
                };
              }
              return {
                ...item,
                IsActive: true,
                Values: updatedSpecObj.Values,
              };
            } else {
              return item;
            }
          });
          setAllSpecification(merged);
        }
      })
      .catch((error) => {
        if (error?.message_code === "INVALID_TRIM_DETAILS_FOR_SPEC") {
          MessageBox.open({
            content: i18next.t("INVALID_TRIM_DETAILS_FOR_SPEC"),
            type: MessageBoxType.Alert,
          }).then(() => {});
        }
      });
  };

  const saveData = (selectedSpecs) => {
    handleSave(selectedSpecs);
  };

  const getVariantName = () => {
    let label;
    let labelData = trimData?.Values.filter(
      (itm) => itm.LanguageId === languageId
    );
    label =
      labelData?.length > 0 ? labelData[0].Label : trimData?.Values[0].Label;
    return label;
  };

  return (
    <div className="mt-1">
      <div className="container mx-auto">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          <BackIcon className="back-button arabic-back-icon" />
          <span className="page-header">{t("EDIT_TRIM_SPECS")}</span>
        </div>
        <div className="bg-white my-4 px-8 py-6">
          <div className="flex items-center justify-between">
            <div>
              {trimData?.Values && (
                <div className="flex gap-1">
                  <p className="page-header">{t("TRIM")} -</p>
                  <p className="page-header">{getVariantName()}</p>
                </div>
              )}
            </div>
            <div>
              {trimData && trimId ? (
                <div className="w-80">
                  <label>{`${t("COPY_FROM")}`}</label>
                  <div className="frm-dropdown mt-2 w-full">
                    <Select
                      name="stateId"
                      placeholder={t("SELECT")}
                      height={36}
                      borderRadius={"none"}
                      options={vehicleTrims?.filter(
                        (eachTrim) =>
                          parseInt(eachTrim.TrimId) !== parseInt(trimId)
                      )}
                      onChange={handleDropDownChange}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          boxShadow: "none",
                          borderColor: "#e5e7eb",
                          height: 38.25,
                          borderRadius: "0px",
                        }),
                      }}
                      getOptionLabel={(options) => options.Trim}
                      getOptionValue={(options) => options.TrimId}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <VehicleSpecificationList
            specifications={allSpecification}
            saveData={saveData}
          />
        </div>
      </div>
    </div>
  );
};
export default VariantSpecifications;
