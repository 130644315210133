import { SortType } from "../../utilities/constants/global.constants";
import { ApplicationRoles } from "../../utilities/constants/role.constants";
import {
  INIT_LOAD_VEHICLE_VIEW,
  UPDATE_MODEL_FILTER_DATA,
  UPDATE_USER_FILTER,
  UPDATE_SPECS_FILTER_DATA,
  CLEAR_USER_FILTER,
  FETCH_VEHICLES_STARTED,
  FETCH_VEHICLES_ENDED,
  LOAD_VEHICLES,
  UPDATE_META_DATA,
  UPDATE_STATUS_FILTER,
  GET_QC_STATUS_FILTER,
  FETCH_DEALER_DATA,
  FETCH_SALES_PERSON_DETAILS,
  FETCH_QC_DETAILS,
  UPDATE_SELECTED_VEHICLE,
  UPDATE_SALES_PERSON_ASSIGN_TO_VALUE,
  UPDATE_QC_ASSIGN_TO_VALUE,
  SET_DELIST_DATA,
  SET_INSPECTION_VALUE,
  GET_VEHICLE_DELETION_REASON,
  SET_USER_SEARCH,
  CLEAR_METADATA,
  CURRENT_QC_DMS_ASSIGN_TO_VALUE,
  UPDATE_SELECTED_VEHICLE_VIA_CHECKBOX,
  FETCH_INITIALS_STARTED,
  FETCH_INITIALS_ENDED,
  UPDATE_LISTING_ID,
  UPDATE_LISTED_VEHICLE_CHECKED,
} from "../types";

const initialState = {
  filterData: {
    qcStatusFilter: [],
    cities: [],
    exteriorColors: [],
    interiorColors: [],
    minVehiclePrice: 0,
    maxVehiclePrice: 0,
    minVehicleMileage: 0,
    maxVehicleMileage: 0,
    vehicleConditions: [],
    features: [],
    years: [],
    ownerships: [],
    transmissions: [],
    bodyTypes: [],
    listingId: "",
    fuelTypes: [],
    modelYears: [],
    makes: [],
    models: [],
    specs: [],
    dealers: [],
    salesPersonDetails: [],
    qcDetails: [],
  },
  listingIdValue: "",
  filterSelection: {
    cities: [],
    ownerships: [],
    transmissions: [],
    years: [],
    makes: [],
    models: [],
    specs: [],
    exteriorColors: [],
    interiorColors: [],
    fuelTypes: [],
    features: [],
    bodyTypes: [],
    listingId: "",
    minVehiclePrice: 0,
    maxVehiclePrice: 0,
    minVehicleMileage: 0,
    maxVehicleMileage: 0,
    vehicleCreatedDate: { min: "", max: "" },
    vehicleAddedToQC: { min: "", max: "" },
    dealers: [],
    salesPersonDetails: [],
    vehicleAge: [],
    qcDetails: [],
    selectedVehicleType: "All",
  },
  statusFilterSelection: {
    type: ApplicationRoles.PETROMIN_ADMIN,
    status: [],
  },
  metadata: {
    vehicleView: "row",
    pageNo: 1,
    pageSize: 6,
    sortBy: "UpdatedDate",
    sortOrder: SortType.DESC,
  },
  paginatedVehicles: {
    DataCount: 0,
    DataList: [],
    UserVehicleCount: 0,
  },
  vehiclesLoading: true,
  dealerInformation: null,
  selectedVehicles: [],
  salesPersonAssignTo: null,
  qcAssignTo: null,
  currentQCAssignTo: null,
  delistData: null,
  inspectionValue: false,
  deletionReasons: [],
  userData: null,
  selectedVehiclesCheckBox: [],
  initialDataLoading: true,
};

export const myVehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD_VEHICLE_VIEW: {
      return {
        ...state,
        filterData: {
          ...state.filterData,
          cities: action.payload.cities || [],
          exteriorColors: action.payload.filterMetaData?.ExteriorColors || [],
          interiorColors: action.payload.filterMetaData?.InteriorColors || [],
          minVehiclePrice: action.payload.filterMetaData?.MinVehiclePrice || 0,
          maxVehiclePrice: action.payload.filterMetaData?.MaxVehiclePrice || 0,
          minVehicleMileage:
            action.payload.filterMetaData?.MinVehicleMileage || 0,
          maxVehicleMileage:
            action.payload.filterMetaData?.MaxVehicleMileage || 0,
          vehicleConditions:
            action.payload.filterMetaData?.VehicleConditions || [],
          features: action.payload.filterMetaData?.Features || [],
          years: action.payload.filterMetaData?.Years || [],
          ownerships: action.payload.filterMetaData?.Ownerships || [],
          transmissions: action.payload.filterMetaData?.Transmissions || [],
          bodyTypes: action.payload.bodyTypes || [],
          listingId: action.payload.listingId || "",
          fuelTypes: action.payload.fuelTypes || [],
          modelYears: action.payload.modelYears || [],
          makes: action.payload.makes || [],
        },
        paginatedVehicles: {
          DataCount: 0,
          DataList: [],
          UserVehicleCount: 0,
        },
        statusFilterSelection: {
          ...state.statusFilterSelection,
          type: action.payload.userRole,
        },
        dealerInformation: action.payload.dealerInformation,
        selectedVehicles: [],
        selectedVehiclesViaCheckBox: [],
        listedVehicleChecked: [],
      };
    }
    case FETCH_DEALER_DATA:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          dealers: action.payload || [],
        },
      };
    case FETCH_SALES_PERSON_DETAILS:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          salesPersonDetails: action.payload || [],
        },
      };
    case FETCH_QC_DETAILS:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          qcDetails: action.payload || [],
        },
      };
    case GET_QC_STATUS_FILTER:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          qcStatusFilter: action.payload || [],
        },
      };
    case UPDATE_MODEL_FILTER_DATA:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          models: action.payload || [],
        },
      };

    case UPDATE_SPECS_FILTER_DATA:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          specs: action.payload || [],
        },
      };
    case UPDATE_META_DATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload,
        },
      };
    case UPDATE_STATUS_FILTER:
      return {
        ...state,
        statusFilterSelection: {
          ...state.statusFilterSelection,
          ...action.payload,
        },
      };
    case UPDATE_USER_FILTER:
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          ...action.payload,
        },
      };
    case UPDATE_LISTING_ID:
      return {
        ...state,
        listingIdValue: action.payload,
      };

    case SET_DELIST_DATA:
      return {
        ...state,
        delistData: action.payload,
      };
    case SET_INSPECTION_VALUE:
      return {
        ...state,
        inspectionValue: action.payload,
      };
    case GET_VEHICLE_DELETION_REASON:
      return {
        ...state,
        deletionReasons: action.payload,
      };
    case UPDATE_SALES_PERSON_ASSIGN_TO_VALUE: {
      return {
        ...state,
        salesPersonAssignTo: action.payload,
      };
    }
    case UPDATE_QC_ASSIGN_TO_VALUE: {
      return {
        ...state,
        qcAssignTo: action.payload,
      };
    }
    case CURRENT_QC_DMS_ASSIGN_TO_VALUE: {
      return {
        ...state,
        currentQCAssignTo: action.payload,
      };
    }
    case UPDATE_SELECTED_VEHICLE:
      return {
        ...state,
        selectedVehicles: action.payload || [],
      };
    case CLEAR_USER_FILTER:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          models: [],
          specs: [],
        },
        filterSelection: {
          ...initialState.filterSelection,
          vehicleCreatedDate: { min: "", max: "" },
          vehicleAddedToQC: { min: "", max: "" },
          minVehiclePrice: 0,
          maxVehiclePrice: 0,
          minVehicleMileage: state.filterData.minVehicleMileage || 0,
          maxVehicleMileage: state.filterData.minVehicleMileage || 0,
        },
        statusFilterSelection: {
          type: ApplicationRoles.PETROMIN_ADMIN,
          status: [],
        },
        selectedVehicles: [],
        selectedVehiclesViaCheckBox: [],
        listingIdValue: "",
      };
    case LOAD_VEHICLES:
      return {
        ...state,
        paginatedVehicles: action.payload,
        selectedVehicles: [],
        selectedVehiclesViaCheckBox: [],
      };
    case FETCH_VEHICLES_STARTED:
      return { ...state, vehiclesLoading: true };
    case FETCH_VEHICLES_ENDED:
      return { ...state, vehiclesLoading: false };
    case SET_USER_SEARCH:
      return {
        ...state,
        userData: action.payload,
      };
    case CLEAR_METADATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload,
        },
      };
    case UPDATE_SELECTED_VEHICLE_VIA_CHECKBOX:
      return {
        ...state,
        selectedVehiclesViaCheckBox: action.payload || [],
      };
    case FETCH_INITIALS_STARTED:
      return { ...state, initialDataLoading: true };
    case FETCH_INITIALS_ENDED:
      return { ...state, initialDataLoading: false };
    case UPDATE_LISTED_VEHICLE_CHECKED:
      return {
        ...state,
        listedVehicleChecked: action.payload || [],
      };
    default:
      return state;
  }
};
