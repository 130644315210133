import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackIcon } from "../../components/gogo-icons";
import VehicleSpecificationList from "../../components/vehicle-specification-list";
import { useDispatch, useSelector } from "react-redux";
import {
  clearVehicleProfileData,
  getCopyOption,
  getVehicleProfileById,
  getVehicleSpecs,
} from "../../redux/actions/vehicleAction";
import Select from "react-select";
import { FeatureTypes } from "../../utilities/constants/global.constants";
import MessageBox, {
  MessageBoxType,
} from "../../components/common/message-box";
import { useState } from "react";

const VehicleProfileSpecification = ({
  vehicleProfileId,
  handleSave,
  specifications,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [allSpecification, setAllSpecification] = useState([]);
  const [trimData, setTrimData] = useState([]);
  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );
  const vehicleProfile = useSelector(
    (state) => state.vehicleReducer.vehicleProfile
  );

  useEffect(() => {
    setAllSpecification(specifications);
    dispatch(getCopyOption(languageId, vehicleProfileId)).then((res) => {
      setTrimData(res);
    });
    dispatch(getVehicleProfileById(vehicleProfileId, languageId));
    return () => {
      dispatch(clearVehicleProfileData());
    };
  }, [dispatch, vehicleProfileId, specifications, languageId]);

  const saveData = (selectedSpecs) => {
    handleSave(selectedSpecs);
  };

  const handleDropDownChange = (e) => {
    const payload = {
      languageId,
      id: e.VehicleProfileId,
      type: FeatureTypes.VehicleProfile,
    };
    dispatch(getVehicleSpecs(payload)).then(async (res) => {
      if (res) {
        if (res.MessageCode === "INVALID_TRIM_DETAILS_FOR_SPEC") {
          await MessageBox.open({
            content: t("INVALID_TRIM_DETAILS_FOR_SPEC"),
            type: MessageBoxType.Alert,
          });
        } else {
          const merged = allSpecification.map((item) => {
            const updatedSpecObj = res.Specifications.find(
              (a) =>
                a.Specification === item.Specification &&
                a.SpecificationId &&
                a.Values.length > 0
            );
            if (updatedSpecObj) {
              return { ...item, IsActive: true, Values: updatedSpecObj.Values };
            } else {
              return item;
            }
          });
          setAllSpecification(merged);
        }
      }
    });
  };

  return (
    <div className="mt-1">
      <div className="container mx-auto">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          <BackIcon className="back-button arabic-back-icon" />
          <span className="page-header">{t("EDIT_VEHICLE_PROFILE_SPECS")}</span>
        </div>
        <div className="bg-white my-4 px-8 py-6">
          <div className="flex justify-between items-center">
            {vehicleProfile && (
              <p className="page-header">
                {vehicleProfile?.Year +
                  " - " +
                  vehicleProfile?.Make +
                  " - " +
                  vehicleProfile?.Model +
                  `${
                    vehicleProfile?.Trim ? ` - ${vehicleProfile?.Trim}` : ""
                  }` +
                  `${
                    vehicleProfile?.ColorVariant
                      ? ` - ${vehicleProfile?.ColorVariant}`
                      : ""
                  }`}
              </p>
            )}
            {trimData.length > 0 ? (
              <div className="w-80">
                <label>{`${t("COPY_FROM")}`}</label>
                <div className="frm-dropdown mt-2 w-full">
                  <Select
                    name="stateId"
                    placeholder={t("SELECT")}
                    height={36}
                    borderRadius={"none"}
                    options={trimData}
                    onChange={(e) => handleDropDownChange(e)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        boxShadow: "none",
                        borderColor: "#e5e7eb",
                        height: 38.25,
                        borderRadius: "0px",
                      }),
                    }}
                    getOptionLabel={(options) => options.Trim}
                    getOptionValue={(options) => options.VehicleProfileId}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <VehicleSpecificationList
            specifications={allSpecification}
            saveData={saveData}
          />
        </div>
      </div>
    </div>
  );
};
export default VehicleProfileSpecification;
