import { API, API2 } from "../../middleware";
import {
  GET_FILTER_CITY_LIST,
  GET_FILTER_MAKE_MODEL,
  SET_OTHER_FILTER_DATA,
  SET_FILTER_BODY_TYPE,
  SET_FILTER_FUEL_TYPE,
  SET_VEHICLE_FILTERS,
  SET_SLIDER_DATA,
  REMOVE_SELECTED_FILTER,
  SET_SORT_API,
  GET_MODEL_YEAR,
  GET_MAKE,
  GET_MODEL_BY_MAKE,
  GET_STYLE,
  SET_DATE_FILTERS,
  GET_DEALERS,
  SET_FILTER_BODY_STYLE,
  SET_FILTER_FUEL_TYPE_V1,
} from "../types";

export const setCityList = (data) => ({
  type: GET_FILTER_CITY_LIST,
  payload: data,
});

export const setMakeModalData = (data) => ({
  type: GET_FILTER_MAKE_MODEL,
  payload: data,
});

export const setOtherData = (data) => ({
  type: SET_OTHER_FILTER_DATA,
  payload: data,
});

export const setFilterBodyType = (data) => ({
  type: SET_FILTER_BODY_TYPE,
  payload: data,
});

export const setFuelType = (data) => ({
  type: SET_FILTER_FUEL_TYPE,
  payload: data,
});

export const setFuelTypeV1 = (data) => ({
  type: SET_FILTER_FUEL_TYPE_V1,
  payload: data,
});

export const setBodyStyle = (data) => ({
  type: SET_FILTER_BODY_STYLE,
  payload: data,
});

export const setVehiclesFilterData = (data) => ({
  type: SET_VEHICLE_FILTERS,
  payload: data,
});

export const setDateFilterData = (data) => ({
  type: SET_DATE_FILTERS,
  payload: data,
});

export const setSliderData = (data) => ({
  type: SET_SLIDER_DATA,
  payload: data,
});

export const removeClickedFilter = (data) => ({
  type: REMOVE_SELECTED_FILTER,
  payload: data,
});

export const setSortByOrder = (data) => ({
  type: SET_SORT_API,
  payload: data,
});

export const setModelYear = (data) => ({
  type: GET_MODEL_YEAR,
  payload: data,
});

export const setMake = (data) => ({
  type: GET_MAKE,
  payload: data,
});

export const setModelByYearMake = (data) => ({
  type: GET_MODEL_BY_MAKE,
  payload: data,
});

export const setStyleByYearMakeModel = (data) => ({
  type: GET_STYLE,
  payload: data,
});

export const setDealers = (data) => ({
  type: GET_DEALERS,
  payload: data,
});

export const getCityList = (id) => {
  return (dispatch) => {
    return API.get("/api/Vehicle/browsebycity", { params: { languageId: id } })
      .then((response) => {
        dispatch(setCityList(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getMakeAndModel = () => {
  return (dispatch) => {
    return API.get("/api/Vehicle/makemodel", { params: { languageId: 1 } })
      .then((response) => {
        dispatch(setMakeModalData(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getOtherFilterData = () => {
  return (dispatch) => {
    return API.get("/api/FilterMetaData/vehicleparameters", {
      params: { languageId: 1 },
    })
      .then((response) => {
        dispatch(setOtherData(response.data));
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getFilterBodyTypes = (id) => {
  return (dispatch) => {
    return API.get("/api/Vehicle/bodytype", { params: { languageId: id } })
      .then((response) => {
        dispatch(setFilterBodyType(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getFilterBodyTypesV1 = (id) => {
  return (dispatch) => {
    return API.get("/api/Vehicle/bodytype", { params: { languageId: id } })
      .then((response) => {
        dispatch(setFilterBodyType(response.data));
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getFilterBodyStylesV1 = (id) => {
  return (dispatch) => {
    return API.get("/api/Vehicle/bodystyles", { params: { languageId: id } })
      .then((response) => {
        dispatch(setBodyStyle(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getFilterBodyStylesV2 = (id) => {
  return (dispatch) => {
    return API2.get(`/master-data/body-styles?languageId=${id}`)
      .then((response) => {
        dispatch(setBodyStyle(response.data.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getFuelTypes = (id) => {
  return (dispatch) => {
    return API.get("/api/Vehicle/fueltype", { params: { languageId: id } })
      .then((response) => {
        dispatch(setFuelType(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getModelYear = () => async (dispatch) => {
  try {
    const response = await API.get("/api/Vehicle/modelyear");

    if (response.status === 200) {
      dispatch(setModelYear(response && response.data));
      return Promise.resolve(response);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMake = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/make?languageId=${languageId}`
    );

    if (response.status === 200) {
      dispatch(setMake(response && response.data));
      return Promise.resolve(response);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getModelByMake = (languageId, make) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/modelsbymakecode?languageId=${languageId}&makeCode=${make}`
    );

    if (response.status === 200) {
      dispatch(setModelByYearMake(response && response.data));
      return Promise.resolve(response);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getStyleByYearMakeModel = (make, model) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/spec?&makeCode=${make}&modelCode=${model}`
    );

    if (response.status === 200) {
      dispatch(setStyleByYearMakeModel(response && response.data));
      return Promise.resolve(response);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDealers = (payload) => async (dispatch) => {
  payload = payload || {};
  try {
    const res = await API.get(`/api/dms/dealers`, {
      params: {
        languageId: payload.languageId,
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber,
        searchKey: payload.searchKey ? payload.searchKey : null,
      },
    });
    dispatch(setDealers(res.data));
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDealersExport = (payload) => async () => {
  try {
    const res = await API.get(`/api/dms/dealers`, {
      params: {
        languageId: payload.languageId,
      },
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};
