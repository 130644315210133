import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FeatureTypes } from "../utilities/constants/global.constants";
import VehicleProfileSpecification from "./vehicle-profile/VehicleProfileSpecification";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getVehicleSpecsV1,
  saveVehicleSpecifications,
  saveVehicleSpecificationsV1,
} from "../redux/actions/vehicleAction";
import ModelSpecifications from "./manage/ModelSpecifications";
import VariantSpecifications from "./manage/VariantSpecifications";
import MessageBox, {
  MessageBoxResult,
  MessageBoxType,
} from "../components/common/message-box";
import i18next from "i18next";

const Specifications = ({ type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  const [specifications, setSpecifications] = useState([]);

  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  useEffect(() => {
    const payload = {
      languageId,
      id,
    };
    dispatch(getVehicleSpecsV1(payload)).then(async (res) => {
      if (res) {
        if (res?.message_code === "INVALID_TRIM_DETAILS_FOR_SPEC") {
          const result = await MessageBox.open({
            content: i18next.t("INVALID_TRIM_DETAILS_FOR_SPEC"),
            type: MessageBoxType.Alert,
          });
          if (result === MessageBoxResult.OK) {
            history.goBack();
          }
        } else {
          setSpecifications(res);
        }
      }
    });
  }, [dispatch, history, id, languageId, type]);

  const handleSave = (selectedSpecs) => {
    if (type === FeatureTypes.VehicleProfile || type === FeatureTypes.Trim) {
      const payload = {
        trimId: id,
        specifications: selectedSpecs,
      };
      dispatch(saveVehicleSpecificationsV1(payload)).then((res) => {
        if (res) {
          const payload = {
            languageId,
            id,
          };
          dispatch(getVehicleSpecsV1(payload)).then(async (res) => {
            if (res) {
              if (res?.message_code === "INVALID_TRIM_DETAILS_FOR_SPEC") {
                const result = await MessageBox.open({
                  content: i18next.t("INVALID_TRIM_DETAILS_FOR_SPEC"),
                  type: MessageBoxType.Alert,
                });
                if (result === MessageBoxResult.OK) {
                  // history.goBack();
                }
              } else {
                setSpecifications(res);
              }
            }
          });
          setSpecifications([]);
          history.goBack();
        }
      });
      return;
    }

    const payload = {
      ids: [id],
      specifications: selectedSpecs,
      type,
      languageId,
    };
    dispatch(saveVehicleSpecifications(payload)).then((res) => {
      if (res) {
        setSpecifications([]);
        history.goBack();
      }
    });
  };

  if (type === FeatureTypes.VehicleProfile) {
    return (
      <VehicleProfileSpecification
        vehicleProfileId={id}
        handleSave={handleSave}
        specifications={specifications}
      />
    );
  }

  if (type === FeatureTypes.Model) {
    return (
      <ModelSpecifications
        modelId={id}
        handleSave={handleSave}
        specifications={specifications}
      />
    );
  }

  if (type === FeatureTypes.Trim) {
    return (
      <VariantSpecifications
        trimId={id}
        handleSave={handleSave}
        specifications={specifications}
        setSpecifications={setSpecifications}
      />
    );
  }

  return <></>;
};
export default Specifications;
