import React, { useEffect, useState } from "react";
import { FeatureTypes } from "../utilities/constants/global.constants";
import VehicleProfileFeatures from "./vehicle-profile/VehicleProfileFeatures";
import ModelFeatures from "./manage/ModelFeatures";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getVehicleFeatures,
  saveVehicleFeatures,
} from "../redux/actions/vehicleAction";
import VariantFeatures from "./manage/VariantFeatures";

const Features = ({ type }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const payload = {
      languageId,
      id,
      type,
    };
    dispatch(getVehicleFeatures(payload)).then((res) => {
      if (res) {
        setFeatures(res);
      }
    });
  }, [dispatch, id, languageId, type]);

  const handleSave = (selectedFeatures) => {
    const payload = {
      Ids: [id],
      FeatureIds: selectedFeatures,
      Type: type,
    };
    dispatch(saveVehicleFeatures(payload));
    setFeatures([]);
  };

  if (type === FeatureTypes.VehicleProfile) {
    return (
      <VehicleProfileFeatures
        vehicleProfileId={id}
        handleSave={handleSave}
        features={features}
      />
    );
  }

  if (type === FeatureTypes.Model) {
    return (
      <ModelFeatures modelId={id} handleSave={handleSave} features={features} />
    );
  }

  if (type === FeatureTypes.Trim) {
    return (
      <VariantFeatures
        trimId={id}
        handleSave={handleSave}
        features={features}
      />
    );
  }
  return <></>;
};
export default Features;
