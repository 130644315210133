import { API } from "../../middleware";
import { hideLoader, showLoader } from "./applicationAction";
import { toast } from "react-toastify";
import {
  INIT_LOAD_VEHICLE_VIEW,
  UPDATE_MODEL_FILTER_DATA,
  UPDATE_SPECS_FILTER_DATA,
  UPDATE_USER_FILTER,
  CLEAR_USER_FILTER,
  FETCH_VEHICLES_STARTED,
  FETCH_VEHICLES_ENDED,
  LOAD_VEHICLES,
  UPDATE_META_DATA,
  UPDATE_STATUS_FILTER,
  GET_QC_STATUS_FILTER,
  FETCH_DEALER_DATA,
  FETCH_SALES_PERSON_DETAILS,
  FETCH_QC_DETAILS,
  UPDATE_SELECTED_VEHICLE,
  UPDATE_SALES_PERSON_ASSIGN_TO_VALUE,
  UPDATE_QC_ASSIGN_TO_VALUE,
  GET_ACTIVE_SUBSCRIPTION_LIST,
  FETCH_PACKAGES,
  SET_DELIST_DATA,
  SET_INSPECTION_VALUE,
  GET_VEHICLE_DELETION_REASON,
  SET_USER_SEARCH,
  CLEAR_METADATA,
  CURRENT_QC_DMS_ASSIGN_TO_VALUE,
  UPDATE_SELECTED_VEHICLE_VIA_CHECKBOX,
  FETCH_INITIALS_STARTED,
  FETCH_INITIALS_ENDED,
  UPDATE_LISTING_ID,
  UPDATE_LISTED_VEHICLE_CHECKED,
} from "../types";
import { ApplicationRoles } from "../../utilities/constants/role.constants";
import i18next from "i18next";
import MessageBox, {
  MessageBoxType,
} from "../../components/common/message-box";
import { VehicleFilterType } from "../../utilities/constants/global.constants";

const getCityList = (languageId) => {
  return API.get("/api/Vehicle/browsebycity", { params: { languageId } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const getOtherFilterData = (languageId) => {
  return API.get("/api/FilterMetaData/vehicleparameters", {
    params: { languageId },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const getBodyTypes = (languageId) => {
  return API.get("/api/Vehicle/bodytype", { params: { languageId } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const getFuelTypes = (languageId) => {
  return API.get("/api/Vehicle/fueltype", { params: { languageId } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const getModelYear = () => {
  return API.get("api/vehicle/modelyears")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const getMake = (languageId) => {
  return API.get(`api/vehicle/makes?languageId=${languageId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

const getDealerInfo = () => {
  return API.get(`/api/dms/dealer`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const fetchInitialData = (languageId, userRole) => async (dispatch) => {
  try {
    dispatch(showLoader());
    dispatch({ type: FETCH_INITIALS_STARTED });
    const responses = await Promise.all([
      getCityList(languageId),
      getOtherFilterData(languageId),
      getBodyTypes(languageId),
      getFuelTypes(languageId),
      getModelYear(),
      getMake(languageId),
      getDealerInfo(),
    ]);

    dispatch({
      type: INIT_LOAD_VEHICLE_VIEW,
      payload: {
        cities: responses[0],
        filterMetaData: responses[1],
        bodyTypes: (responses[2] || []).filter((x) => x.IsSearchable),
        fuelTypes: responses[3],
        modelYears: responses[4],
        makes: responses[5],
        dealerInformation: responses[6],
        userRole,
      },
    });
    dispatch({ type: FETCH_INITIALS_ENDED });
    dispatch(hideLoader());
  } catch (err) {
    dispatch(hideLoader());
    console.error(err);
  }
};

export const fetchDealers = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(`/api/dms/dealers`, {
      params: {
        languageId,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: FETCH_DEALER_DATA,
        payload: response.data?.Records || [],
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchSalesPersonDetails = () => async (dispatch) => {
  try {
    const response = await API.get(`/api/dms/salespersonsstatistic`);
    dispatch({
      type: FETCH_SALES_PERSON_DETAILS,
      payload: response.data || [],
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchQCdetails = (payload) => async (dispatch) => {
  try {
    const response = await API.get(`/api/QC/qcanalystswithassignmentdetails`, {
      params: {
        isActiveOnly: payload?.isActiveOnly,
      },
    });
    dispatch({
      type: FETCH_QC_DETAILS,
      payload: response.data || [],
    });
  } catch (err) {
    console.error(err);
  }
};

export const updateUserFilter = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_USER_FILTER, payload });
};
export const updateListingIdField = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_LISTING_ID, payload });
};

export const updateStatusFilter = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_STATUS_FILTER, payload });
};

export const fetchQCStatusFilters = (languageId) => (dispatch) => {
  API.get("/api/qc/status", {
    params: { languageId: languageId },
  }).then((res) => {
    dispatch({ type: GET_QC_STATUS_FILTER, payload: res.data });
  });
};

export const updateMetaData = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_META_DATA, payload });
};

export const updateModelFilterData = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_MODEL_FILTER_DATA, payload });
};

export const updateSpecFilterData = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_SPECS_FILTER_DATA, payload });
};

export const setDelistData = (payload) => (dispatch) => {
  dispatch({ type: SET_DELIST_DATA, payload });
};

export const setInspectionValue = (payload) => (dispatch) => {
  dispatch({ type: SET_INSPECTION_VALUE, payload });
};

export const getModelsByMake = (languageId, make) => (dispatch, getState) => {
  API.get(`/api/Vehicle/modelsbymakeId?languageId=${languageId}&makeId=${make}`)
    .then((response) => {
      const { models } = getState().myVehicleReducer.filterData;
      dispatch(updateModelFilterData([...models, ...response.data]));
    })
    .catch((error) => {
      return error;
    });
};

export const getSpecsByMakeModel =
  (makeCode, modelCode, languageId) => (dispatch, getState) => {
    API.get(
      `/api/Vehicle/trimsbymodelIdmakeId?modelId=${modelCode}&makeId=${makeCode}&languageId=${languageId}`
    )
      .then((response) => {
        const { specs } = getState().myVehicleReducer.filterData;
        dispatch(updateSpecFilterData([...specs, ...response.data]));
      })
      .catch((error) => {
        return error;
      });
  };

export const clearModelsByMakes = (makes) => (dispatch, getState) => {
  const { models } = getState().myVehicleReducer.filterData;
  dispatch(
    updateModelFilterData(models.filter((x) => makes.includes(x.MakeCode)))
  );
};

export const clearSpecsByMakesAndModels = (payload) => (dispatch, getState) => {
  const { specs } = getState().myVehicleReducer.filterData;
  dispatch(
    updateSpecFilterData(
      specs.filter(
        (x) =>
          payload.findIndex(
            (y) => y.makeCode === x.MakeCode && y.modelCode === x.ModelCode
          ) !== -1
      )
    )
  );
};

export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_USER_FILTER });
};

export const updateSelectedVehicle = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_VEHICLE, payload });
};

export const updateSelectedVehicleViaCheckBox = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_VEHICLE_VIA_CHECKBOX, payload });
};

export const updateListedVehicleChecked = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_LISTED_VEHICLE_CHECKED, payload });
};

export const loadVehicles = () => async (dispatch, getState) => {
  try {
    dispatch(showLoader());
    dispatch({ type: FETCH_VEHICLES_STARTED });
    const payload = getUserFilter(
      getState().myVehicleReducer.filterSelection,
      getState().myVehicleReducer.metadata,
      getState().myVehicleReducer.statusFilterSelection,
      getState().myVehicleReducer.dealerInformation,
      getState().globalReducer.selectedLanguage
    );
    const response = await API.post(`/api/vehicle/myvehicles`, payload);
    if (response.status === 200) {
      await dispatch({ type: LOAD_VEHICLES, payload: response.data });
      await dispatch({ type: FETCH_VEHICLES_ENDED });
      dispatch(hideLoader());
    }
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

const getUserFilter = (
  filterSelection,
  metadata,
  statusSelection,
  dealerInformation,
  languageId
) => {
  /**
   * Prepare Term for Filter
   */
  const termsFilter = [];
  const terms = {
    cities: "CityId",
    ownerships: "OwnershipId",
    transmissions: "TransmissionId",
    years: "ManufactureYear",
    makes: "VehicleMakeId",
    models: "VehicleModelId",
    specs: "SpecId",
    fuelTypes: "FuelTypeId",
    dealers: "DealerId",
    salesPersonDetails: "DealerUserId",
    qcDetails: "AnalystUserId",
  };
  Object.keys(filterSelection).forEach((type) => {
    const k = terms[type];
    if (k) {
      const v = filterSelection[type].map((x) => x.value).join(",");
      v && termsFilter.push(`${k}=${v}`);
    }
  });

  /**
   * Prepare Terms for body Types
   */
  if (filterSelection.bodyTypes) {
    const v = filterSelection.bodyTypes.map((x) => x.BodyTypeId).join(",");
    v && termsFilter.push(`BodyTypeId=${v}`);
  }

  /**
   * Prepare Terms for listingId
   */
  if (filterSelection.listingId) {
    const v = filterSelection.listingId;
    v && termsFilter.push(`Id=${v}`);
  }

  /**
   * Prepare Terms for Exterior Colors
   */
  if (
    filterSelection.exteriorColors &&
    filterSelection.exteriorColors.length > 0
  ) {
    const v = filterSelection.exteriorColors.map((x) => x.ColorId).join(",");
    v && termsFilter.push(`ExteriorColorId=${v}`);
  }
  /**
   * Prepare Terms for vehicle type
   */
  if (filterSelection.selectedVehicleType) {
    if (filterSelection.selectedVehicleType === VehicleFilterType.Outlet) {
      termsFilter.push("IsNew=True");
      termsFilter.push("IsOutlet=True");
    } else if (filterSelection.selectedVehicleType === VehicleFilterType.New) {
      termsFilter.push("IsNew=True");
      termsFilter.push("IsOutlet=False");
    } else if (
      filterSelection.selectedVehicleType === VehicleFilterType.PreOwned
    ) {
      termsFilter.push("IsNew=False");
      termsFilter.push("IsOutlet=False");
      termsFilter.push("IsSelfListedVehicle=False");
    } else if (
      filterSelection.selectedVehicleType === VehicleFilterType.SelfListed
    ) {
      termsFilter.push("IsNew=False");
      termsFilter.push("IsOutlet=False");
      termsFilter.push("IsSelfListedVehicle=True");
    }
  }

  /**
   * Prepare Terms for Interior Colors
   */
  if (
    filterSelection.interiorColors &&
    filterSelection.interiorColors.length > 0
  ) {
    const v = filterSelection.interiorColors.map((x) => x.ColorId).join(",");
    v && termsFilter.push(`InteriorColorId=${v}`);
  }

  /**
   * Prepare Terms with Status Filter
   */

  // For PO Admin
  if (
    (statusSelection.type === ApplicationRoles.PETROMIN_ADMIN ||
      statusSelection.type === ApplicationRoles.QC_ADMIN) &&
    statusSelection.status.length > 0
  ) {
    const vehicleListingStatusIds = [];
    statusSelection.status.forEach((status) => {
      if (status.VehicleListingStatusId !== undefined) {
        vehicleListingStatusIds.push(status.VehicleListingStatusId);
      }
    });
    if (vehicleListingStatusIds.length > 0) {
      termsFilter.push(
        `VehicleListingStatusId=${vehicleListingStatusIds.join(",")}`
      );
    }
  }
  // For DMS Admin and SalePerson
  let dmsVehicleStatus = [];
  if (
    (statusSelection.type === ApplicationRoles.DMS_ADMIN ||
      statusSelection.type === ApplicationRoles.DMS_SALESPERSON) &&
    statusSelection.status.length > 0
  ) {
    dmsVehicleStatus = statusSelection.status.map((x) => x.StatusCode);
  }

  // For QC Admin and Analyst
  if (
    (statusSelection.type === ApplicationRoles.QC_ANALYST ||
      statusSelection.type === ApplicationRoles.QC_ADMIN) &&
    statusSelection.status.length > 0
  ) {
    const qualityAnalysisStatusIds = [];
    statusSelection.status.forEach((status) => {
      if (status.QualityAnalysisStatusId !== undefined) {
        qualityAnalysisStatusIds.push(status.QualityAnalysisStatusId);
      }
    });

    if (qualityAnalysisStatusIds.length > 0) {
      termsFilter.push(
        `QualityAnalysisStatusId=${qualityAnalysisStatusIds.join(",")}`
      );
    }
  }

  /**
   * Prepare Range for Filter
   */
  const rangeFilter = [];
  if (filterSelection.minVehicleMileage !== filterSelection.maxVehicleMileage) {
    rangeFilter.push(
      `Mileage=${filterSelection.minVehicleMileage},${filterSelection.maxVehicleMileage}`
    );
  } else if (
    filterSelection.minVehicleMileage === filterSelection.maxVehicleMileage &&
    (filterSelection.minVehicleMileage > 1 ||
      filterSelection.maxVehicleMileage > 1)
  ) {
    rangeFilter.push(
      `Mileage=${filterSelection.minVehicleMileage},${filterSelection.maxVehicleMileage}`
    );
  }
  if (filterSelection.minVehiclePrice !== filterSelection.maxVehiclePrice) {
    rangeFilter.push(
      `AskingPrice=${filterSelection.minVehiclePrice},${filterSelection.maxVehiclePrice}`
    );
  } else if (
    filterSelection.minVehiclePrice === filterSelection.maxVehiclePrice
  ) {
    rangeFilter.push(`AskingPrice=${filterSelection.maxVehiclePrice}`);
  }

  if (filterSelection.vehicleAge && filterSelection.vehicleAge.length > 0) {
    const v = filterSelection.vehicleAge.map((x) => x.value);
    rangeFilter.push(`VehicleAge=${v}`);
  }

  /**
   * Prepare Date Range for Filter
   */
  const dateRangeFilter = [];
  if (
    filterSelection.vehicleCreatedDate.min &&
    filterSelection.vehicleCreatedDate.max
  ) {
    dateRangeFilter.push(
      `VehicleCreatedDate=${filterSelection.vehicleCreatedDate.min},${filterSelection.vehicleCreatedDate.max}`
    );
  }
  if (
    filterSelection.vehicleAddedToQC.min &&
    filterSelection.vehicleAddedToQC.max
  ) {
    dateRangeFilter.push(
      `AssignmentDate=${filterSelection.vehicleAddedToQC.min},${filterSelection.vehicleAddedToQC.max}`
    );
  }

  /**
   * Prepare CSV Filter
   */
  const csvFilter = [];
  if (filterSelection.features && filterSelection.features.length > 0) {
    const v = filterSelection.features.map((x) => x.value).join(",");
    v && csvFilter.push(`VehicleFeatureCsv=${v}`);
  }

  /**
   * Return Filter Object
   */
  return {
    LanguageId: languageId,
    PageSize: metadata.pageSize,
    PageNumber: metadata.pageNo,
    Filter: {
      Term: termsFilter,
      Range: rangeFilter,
      DateRange: dateRangeFilter,
      DMSVehicleStatus: dmsVehicleStatus,
      CSV: csvFilter,
    },
    OrderBy: [`${metadata.sortBy} ${metadata.sortOrder}`],
    DealerId: dealerInformation?.DealerId,
    DealerUserId: dealerInformation?.DealerUserId,
  };
};

export const updateSalesPersonAssignToValue = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_SALES_PERSON_ASSIGN_TO_VALUE, payload });
};

export const updateQCAssignToValue = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_QC_ASSIGN_TO_VALUE, payload });
};

export const CurrentQCDMSAssignToValue = (payload) => (dispatch) => {
  dispatch({ type: CURRENT_QC_DMS_ASSIGN_TO_VALUE, payload });
};

export const assignVehicleToDMSSalesperson = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return API.post("/api/dms/vehicleassignment", data)
      .then(async (res) => {
        await dispatch(loadVehicles());
        dispatch(updateSalesPersonAssignToValue());
        if (res.data && res.status === 200) {
          toast.success(i18next.t("VEHICLE_ASSIGNMENT_SUCCESS"));
          return Promise.resolve(res);
        } else {
          toast.error(i18next.t("VEHICLE_ASSIGNMENT_FAIL"));
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        return error;
      });
  };
};

export const saveQualityAnalystAssignment = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return API.put("/api/QC/qualityanalystassignment", data)
      .then(async (response) => {
        await dispatch(loadVehicles());
        if (
          response.data.IsSuccess &&
          response.data.MessageCode === "VEHICLE_ASSIGNMENT_SUCCESS"
        ) {
          toast.success(i18next.t("VEHICLE_ASSIGNMENT_SUCCESS"));
        } else {
          toast.error(i18next.t("VEHICLE_ASSIGNMENT_FAIL"));
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
        toast.error(i18next.t("VEHICLE_ASSIGNMENT_FAIL"));
        return Promise.reject(error);
      });
  };
};
export const getActiveSubscriptionList =
  (languageId, dealerId) => async (dispatch) => {
    try {
      dispatch(showLoader());
      dispatch({ type: FETCH_PACKAGES });
      const response = await API.get(
        `/api/PackageSubscription/get?languageId=${languageId}&dealerId=${dealerId}`
      );
      dispatch({
        type: GET_ACTIVE_SUBSCRIPTION_LIST,
        payload: response.data,
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      return error;
    }
  };

export const listVehicle = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      "/api/PackageSubscription/listvehicle",
      payload
    );
    if (response.data.IsSuccess) {
      await dispatch(loadVehicles());
      toast.success(i18next.t("VEHICLE_LISTED_SUCCESS"));
    } else {
      await dispatch(loadVehicles());
      toast.error(i18next.t("CREDIT_COUNT_LIMIT_OVER"));
    }
    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("VEHICLE_LISTED_ERROR_MSG"));
    return error;
  }
};

export const delistVehicle = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      "/api/PackageSubscription/movetoawaitlisting",
      payload
    );
    if (response.data.IsSuccess) {
      await dispatch(loadVehicles());
      toast.success(i18next.t("DELIST_VEHICLE_SUCCESS_MSG"));
    } else {
      dispatch(hideLoader());
      toast.error(i18next.t("DELIST_VEHICLE_ERROR_MSG"));
    }
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const approveRejectInspection = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/inspection/approveRejectInspection`,
      payload
    );
    await dispatch(loadVehicles());
    if (response.data.MessageCode === "MSG_INSPECTION_APPROVED") {
      toast.success(i18next.t("MSG_INSPECTION_APPROVED"));
    }
    if (response.data.MessageCode === "MSG_INSPECTION_REJECTED") {
      toast.error(i18next.t("MSG_INSPECTION_REJECTED"));
    }
    if (response.data.MessageCode === "SOME_VEHICLE_NOT_VERIFIED") {
      toast.error(i18next.t("SOME_VEHICLE_NOT_VERIFIED"));
    }
    if (
      response.data.MessageCode === "SOME_VEHICLE_REJECTED_IMAGES_NOT_UPDATED"
    ) {
      MessageBox.open({
        content: i18next.t("SOME_VEHICLE_REJECTED_IMAGES_NOT_UPDATED"),
        type: MessageBoxType.Alert,
      });
    }
  } catch (error) {
    dispatch(hideLoader());
    if (error && error.response && error.response.status === 400) {
      const findError = error.response.data.find(
        (x) => x.ErrorMessage === "ERROR_CAN_NOT_EDIT_INSPECTION"
      );
      if (findError) {
        toast.error(i18next.t("ERROR_CAN_NOT_EDIT_INSPECTION"));
      }
    }
    return Promise.reject(error);
  }
};

export const getVehicleDeletionReasons = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/deletionreasons?languageId=${languageId}`
    );
    dispatch({
      type: GET_VEHICLE_DELETION_REASON,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const deleteVehicleByVehicleListingId =
  (vehicleListingId, deletionReasonId, deletionSummary) => async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.delete(
        `/api/Vehicle/vehicle?vehicleListingId=${vehicleListingId}&deletionReasonId=${deletionReasonId}&deletionSummary=${deletionSummary}`
      );
      await dispatch(loadVehicles());
      if (response.data) {
        toast.success(i18next.t("VEHICLE_DELETED"));
      } else {
        toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
      }
      return Promise.resolve(response);
    } catch (error) {
      dispatch(hideLoader());
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
      return Promise.reject(error);
    }
  };

export const featureVehicle = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/DMS/featurevehicle", data);
    if (response.status === 200) {
      if (response.data.IsSuccess) {
        await dispatch(loadVehicles());
        toast.success(i18next.t("FEATURE_VEHICLE_SUCCESS"));
      } else {
        dispatch(hideLoader());
        if (response.data.MessageCode === "FEATURE_VEHICLE_FAILURE") {
          toast.error(i18next.t("FEATURE_VEHICLE_FAILURE"));
        } else if (
          response.data.MessageCode === "FEATURE_COUNT_LIMIT_EXCEEDED"
        ) {
          toast.error(i18next.t("FEATURE_COUNT_LIMIT_EXCEEDED"));
        } else if (
          response.data.MessageCode ===
          "FEATURE_VEHICLE_VEHICLE_NOT_EXIST_IN_PACKAGESUBSCRIPTIONITEM"
        ) {
          toast.error(
            i18next.t(
              "FEATURE_VEHICLE_VEHICLE_NOT_EXIST_IN_PACKAGESUBSCRIPTIONITEM"
            )
          );
        } else {
          toast.error(i18next.t("FEATURE_VEHICLE_ERROR_MSG"));
        }
      }
    }
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const removeFeatureVehicle = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.delete("/api/dms/featurevehicle", { data });
    if (response.status === 200) {
      if (response.data.IsSuccess) {
        await dispatch(loadVehicles());
        toast.success(i18next.t("FEATURED_VEHICLE_LISTING_REMOVED_SUCCESS"));
      } else {
        dispatch(hideLoader());
        if (
          response.data.MessageCode ===
          "FEATURED_VEHICLE_LISTING_SUBSCRIPTIONITEM_NOT_FOUND"
        ) {
          toast.error(
            i18next.t("FEATURED_VEHICLE_LISTING_SUBSCRIPTIONITEM_NOT_FOUND")
          );
        } else if (
          response.data.MessageCode ===
          "FEATURED_VEHICLE_LISTING_REMOVED_FAILURE"
        ) {
          toast.error(i18next.t("FEATURED_VEHICLE_LISTING_REMOVED_FAILURE"));
        } else {
          toast.error(i18next.t("FEATURED_VEHICLE_LISTING_REMOVED_FAILURE"));
        }
      }
    }
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const validatePackageSubscriptionForFeature =
  (vehicleListingId) => async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(
        `api/dms/validatepackagesubscriptionforfeature?vehicleListingId=${vehicleListingId}`
      );
      dispatch(hideLoader());
      if (response.status === 200) {
        return Promise.resolve(response.data);
      } else {
        toast.error(
          i18next.t("VALIDATE_PACKAGE_SUBSCRIPTION_FOR_FEATURE_ERROR_MSG")
        );
      }
    } catch (error) {
      dispatch(hideLoader());
      return Promise.reject(error);
    }
  };

export const saveSoldTo = (payload) => async () => {
  try {
    const response = await API.put(`api/Vehicle/savesoldto`, payload);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadImageFile = (data) => async () => {
  try {
    const response = await API.post("/api/Vehicle/uploadlistingartifact", data);
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("DOCUMENT_UPLOAD_ERROR_MSG"));
  }
};

export const deleteImageFile = (data) => async () => {
  try {
    const response = await API.delete("/api/Vehicle/deletelistingartifact", {
      data,
    });
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("DOCUMENT_DELETE_ERROR_MSG"));
  }
};

export const setUserSearch = (data) => ({
  type: SET_USER_SEARCH,
  payload: data,
});

export const getUserSearch = (searchkey) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/usersearch?searchKey=${searchkey}`
    );

    if (!response.data.IsSucess) {
      dispatch(setUserSearch(null));
    } else {
      dispatch(setUserSearch(response.data));
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const clearMetaData = (payload) => ({
  type: CLEAR_METADATA,
  payload,
});
