import React from "react";

export const CloseTransparentIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <g id="Group_164780" transform="translate(-568 -232)">
        <rect
          id="Rectangle_1"
          width="24"
          height="24"
          rx="1"
          transform="translate(568 232)"
          fill="#ffffff00"
        ></rect>
        <g id="Group_96" transform="translate(571.967 236.623)">
          <path
            id="Path_13"
            d="M145.354,146.877l1.523-1.523a.351.351,0,0,1,.5,0l5.566,5.566c1.855-1.855,3.738-3.711,5.594-5.566a.351.351,0,0,1,.5,0c.5.5,1,1.025,1.523,1.523a.351.351,0,0,1,0,.5l-5.566,5.566c1.855,1.855,3.711,3.738,5.566,5.594a.351.351,0,0,1,0,.5c-.526.5-1.025,1-1.523,1.523a.351.351,0,0,1-.5,0c-1.855-1.855-3.738-3.711-5.594-5.566l-5.566,5.566a.351.351,0,0,1-.5,0c-.5-.526-1.025-1.025-1.523-1.523a.351.351,0,0,1,0-.5c1.855-1.855,3.711-3.738,5.566-5.594l-5.566-5.566A.351.351,0,0,1,145.354,146.877Z"
            transform="translate(-145.25 -145.25)"
            fill="#fff"
          ></path>
        </g>
      </g>
    </svg>
  );
};
