import React from "react";

export const EmailLogoIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 11.733"
      className={className}
    >
      <g id="Group_917" transform="translate(-1520 -40)">
        <path
          id="Path_755"
          d="M15.4,5H2.6A1.6,1.6,0,0,0,1,6.6v8.533a1.6,1.6,0,0,0,1.6,1.6H15.4a1.6,1.6,0,0,0,1.6-1.6V6.6A1.6,1.6,0,0,0,15.4,5Zm.533,10.133a.533.533,0,0,1-.533.533H2.6a.533.533,0,0,1-.533-.533V6.6A.533.533,0,0,1,2.6,6.067H15.4a.533.533,0,0,1,.533.533Z"
          transform="translate(1519 35)"
          fill="#4b124b"
        ></path>
        <path
          id="Path_756"
          d="M16.576,9.152a.533.533,0,0,0-.757,0l-4.955,4.96L5.909,9.152a.536.536,0,0,0-.757.757l2.827,2.821L5.152,15.552a.536.536,0,1,0,.757.757l2.821-2.827,1.755,1.76a.533.533,0,0,0,.757,0L13,13.483l2.821,2.827a.536.536,0,1,0,.757-.757l-2.827-2.821,2.827-2.821a.533.533,0,0,0,0-.757Z"
          transform="translate(1517.136 33.136)"
          fill="#4b124b"
        ></path>
      </g>
    </svg>
  );
};
