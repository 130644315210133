import {
  GET_APPLICATION_ROLE_HIERARCHY,
  CREATE_USER,
  GET_USER_STATISTIC,
  GET_PETROMIN_ADMIN_USERS,
  GET_PROFILE_DETAILS_BY_ID,
  GET_PROFILE_REQUEST_LIST,
  GET_PROFILE_CHANGE_REQ_LIST,
  SET_USER_PROFILE,
  GET_PO_ADMIN_STATISTIC,
  GET_DMS_ADMIN_STATISTIC,
  GET_INSPECTOR_STATISTICS,
  GET_ACTIVE_CUSTOMERS,
  GET_CUSTOMER_USER_STATISTIC,
} from "../types";

const initialState = {
  applicationHierarchy: {},
  createUserResponse: {},
  userStatistic: {},
  customerUserStatistic: {},
  adminUsers: [],
  profileDetails: null,
  requestList: [],
  profileChangeList: [],
  selfProfileDetails: null,
  adminStatistic: {},
  dmsAdminStatistic: {},
  inspectorData: {},
  activeCustomers: [],
};

export const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATION_ROLE_HIERARCHY:
      return {
        ...state,
        applicationHierarchy: action.payload,
      };
    case CREATE_USER:
      return {
        ...state,
        createUserResponse: action.payload,
      };
    case GET_USER_STATISTIC:
      return {
        ...state,
        userStatistic: action.payload,
      };
    case GET_CUSTOMER_USER_STATISTIC:
      return {
        ...state,
        customerUserStatistic: action.payload,
      };
    case GET_PETROMIN_ADMIN_USERS:
      return {
        ...state,
        adminUsers: action.payload,
      };
    case GET_PROFILE_DETAILS_BY_ID:
      return {
        ...state,
        profileDetails: action.payload,
      };
    case GET_PROFILE_REQUEST_LIST:
      return {
        ...state,
        requestList: action.payload,
      };
    case GET_PROFILE_CHANGE_REQ_LIST:
      return {
        ...state,
        profileChangeList: action.payload,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        selfProfileDetails: action.payload,
      };
    case GET_PO_ADMIN_STATISTIC:
      return {
        ...state,
        adminStatistic: action.payload,
      };
    case GET_DMS_ADMIN_STATISTIC:
      return {
        ...state,
        dmsAdminStatistic: action.payload,
      };
    case GET_INSPECTOR_STATISTICS:
      return {
        ...state,
        inspectorData: action.payload,
      };
    case GET_ACTIVE_CUSTOMERS:
      return {
        ...state,
        activeCustomers: action.payload,
      };
    default:
      return state;
  }
};
