import React from "react";

export const MobileMailIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.779 8.155"
      className={className}
    >
      <g id="Icon_ionic-ios-mail" transform="translate(-3.375 -7.875)">
        <path
          id="Path_54"
          d="M15.058,10.331l-3.047,3.1a.055.055,0,0,0,0,.079l2.132,2.271a.367.367,0,0,1,0,.521.369.369,0,0,1-.521,0L11.5,14.043a.058.058,0,0,0-.082,0l-.518.527a2.28,2.28,0,0,1-1.625.685,2.326,2.326,0,0,1-1.659-.705l-.5-.507a.058.058,0,0,0-.082,0L4.91,16.306a.369.369,0,0,1-.521,0,.367.367,0,0,1,0-.521l2.132-2.271a.06.06,0,0,0,0-.079l-3.05-3.1a.056.056,0,0,0-.1.04v6.21a.909.909,0,0,0,.906.906h9.967a.909.909,0,0,0,.906-.906v-6.21A.057.057,0,0,0,15.058,10.331Z"
          transform="translate(0 -1.457)"
        ></path>
        <path
          id="Path_55"
          d="M9.773,13.06A1.539,1.539,0,0,0,10.88,12.6l4.443-4.522a.89.89,0,0,0-.561-.2H4.786a.884.884,0,0,0-.561.2L8.668,12.6A1.54,1.54,0,0,0,9.773,13.06Z"
          transform="translate(-0.508 0)"
        ></path>
      </g>
    </svg>
  );
};
