import React, { Component } from "react";
import { connect } from "react-redux";

class PageLoader extends Component {
  render() {
    const { loading } = this.props;
    if (!loading) return null;
    return (
      <div className="po-loader loader-container">
        <div className="loader">
          <div className="w-16 h-16 border-b-2 border-t-2 border-red-600 rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ loading: state.application.loading });
export default connect(mapStateToProps)(PageLoader);
