import React from "react";

export const EditAppointmentIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14557_219217)">
        <path
          d="M7.3623 0.488281C7.61095 0.488281 7.8494 0.587053 8.02522 0.762869C8.20103 0.938684 8.2998 1.17714 8.2998 1.42578V2.36328H23.2998V1.42578C23.2998 1.17714 23.3986 0.938684 23.5744 0.762869C23.7502 0.587053 23.9887 0.488281 24.2373 0.488281C24.4859 0.488281 24.7244 0.587053 24.9002 0.762869C25.076 0.938684 25.1748 1.17714 25.1748 1.42578V2.36328H27.0498C28.0444 2.36328 28.9982 2.75837 29.7015 3.46163C30.4047 4.16489 30.7998 5.11872 30.7998 6.11328V26.7383C30.7998 27.7328 30.4047 28.6867 29.7015 29.3899C28.9982 30.0932 28.0444 30.4883 27.0498 30.4883H4.5498C3.55524 30.4883 2.60142 30.0932 1.89815 29.3899C1.19489 28.6867 0.799805 27.7328 0.799805 26.7383V9.86328H30.7998V7.98828H0.799805V6.11328C0.799805 5.11872 1.19489 4.16489 1.89815 3.46163C2.60142 2.75837 3.55524 2.36328 4.5498 2.36328H6.4248V1.42578C6.4248 1.17714 6.52358 0.938684 6.69939 0.762869C6.87521 0.587053 7.11366 0.488281 7.3623 0.488281Z"
          fill="#4C0055"
        />
      </g>
      <path
        d="M22.9235 14.3136C22.923 14.2565 22.9068 14.2006 22.8768 14.152C22.8468 14.1034 22.804 14.0639 22.7531 14.0379C22.7022 14.0119 22.6452 14.0004 22.5882 14.0046C22.5312 14.0088 22.4765 14.0285 22.4299 14.0616L21.2039 14.9404L21.1074 15.0099C19.7864 13.6916 17.9732 12.8672 15.9642 12.8672C11.9351 12.8672 8.65735 16.1453 8.65735 20.1742C8.65735 24.2031 11.9353 27.4812 15.9642 27.4812C17.1606 27.4815 18.3387 27.1878 19.3951 26.6262C20.4514 26.0645 21.3537 25.252 22.0226 24.26C22.0495 24.2203 22.0684 24.1756 22.0781 24.1285C22.0878 24.0815 22.0881 24.033 22.079 23.9858C22.0699 23.9386 22.0516 23.8937 22.0251 23.8536C21.9986 23.8135 21.9644 23.7791 21.9246 23.7522L20.0966 22.4836C20.0106 22.4261 19.9054 22.4049 19.8038 22.4247C19.7023 22.4445 19.6127 22.5036 19.5546 22.5892C18.7465 23.7838 17.4075 24.497 15.971 24.497C13.5871 24.497 11.6476 22.5578 11.6476 20.1746C11.6476 17.7914 13.5871 15.8522 15.971 15.8522C16.9771 15.8522 17.8998 16.202 18.6345 16.7804L18.428 16.9289L17.2013 17.8075C17.155 17.841 17.1188 17.8866 17.0967 17.9393C17.0746 17.992 17.0674 18.0498 17.0758 18.1063C17.0842 18.1628 17.108 18.2159 17.1446 18.2598C17.1812 18.3037 17.2291 18.3367 17.2831 18.3553L22.5314 20.1433C22.6265 20.1765 22.7306 20.1604 22.8135 20.1014C22.8958 20.0416 22.9433 19.9473 22.9428 19.8475L22.9235 14.3136Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_14557_219217">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.799805 0.488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
