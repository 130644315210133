import React from "react";

export const UpArrowIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.458984"
        y="0.457031"
        width="35.082"
        height="35.082"
        rx="4"
        fill="#929597"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0269 23.8303L25.8893 23.7115L18 15.8226L10.1248 23.6974C9.59192 24.2303 8.75887 24.2782 8.17126 23.8422L8.15518 23.8303L8.01759 23.7115L8.00353 23.6974C7.47068 23.1646 7.42278 22.3315 7.85876 21.7439L7.8707 21.7278L7.98948 21.5902L16.9394 12.6403C17.4722 12.1075 18.3053 12.0596 18.8929 12.4956L18.909 12.5075L19.0466 12.6263L27.9965 21.5762C28.5822 22.1619 28.5822 23.1117 27.9965 23.6974C27.4636 24.2303 26.6306 24.2782 26.043 23.8422L26.0269 23.8303Z"
        fill="white"
      />
    </svg>
  );
};
