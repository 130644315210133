import React from "react";

export const ArrowNextDisabled = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.5 32.5"
      className={className}
    >
      <g id="arrow_next_disabled" opacity="0.374">
        <path id="Path_158" d="M32.5,0H0V32.5H32.5Z" fill="none"></path>
        <path
          id="Path_159"
          d="M5.772,13.335H20.9L14.289,6.727a1.365,1.365,0,0,1,0-1.923,1.349,1.349,0,0,1,1.909,0l8.924,8.924a1.349,1.349,0,0,1,0,1.909L16.2,24.561a1.35,1.35,0,1,1-1.909-1.909L20.9,16.043H5.772a1.354,1.354,0,0,1,0-2.708Z"
          transform="translate(0.999 1.561)"
          fill="#7d8593"
        ></path>
      </g>
    </svg>
  );
};
