import { GET_SHOPPING_CART, REMOVE_FROM_CART, CART_LOADING } from "../types";

const initialState = {
  shoppingCart: null,
  isCartItemLoading: true,
};

export const packageSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOPPING_CART:
    case REMOVE_FROM_CART:
      return {
        ...state,
        shoppingCart: action.payload,
        isCartItemLoading: false,
      };
    case CART_LOADING:
      return {
        ...state,
        isCartItemLoading: true,
      };

    default:
      return state;
  }
};
