import {
  GET_YEAR_DATA,
  GET_SPEC_DATA_BY_ALL,
  GET_MODEL_BY_YEAR_MAKE,
  GET_MAKE_DATA_BY_YEAR,
  ADD_VEHICLE_DETAILS,
  GET_MAKE_BY_ID,
  GET_MODEL_BY_ID,
  CLEAR_MAKE_BY_ID_DETAILS,
  CLEAR_MODEL_BY_ID_DETAILS,
  GET_ALL_MAKES,
} from "../types";

const initialState = {
  yearData: [],
  makeData: [],
  modelData: [],
  specData: [],
  vehicleData: [],
  makeByIdData: null,
  modelByIdData: null,
  getAllMakes: [],
};

export const makeModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_YEAR_DATA:
      state = {
        ...state,
        yearData: action.payload,
      };
      break;
    case GET_SPEC_DATA_BY_ALL:
      return {
        ...state,
        specData: action.payload,
      };
    case GET_MODEL_BY_YEAR_MAKE:
      return {
        ...state,
        modelData: action.payload,
      };
    case GET_MAKE_DATA_BY_YEAR:
      return {
        ...state,
        makeData: action.payload,
      };
    case ADD_VEHICLE_DETAILS:
      state = {
        ...state,
        vehicleData: action.payload,
      };
      break;
    case GET_MAKE_BY_ID:
      return {
        ...state,
        makeByIdData: action.payload,
      };
    case GET_MODEL_BY_ID:
      return {
        ...state,
        modelByIdData: action.payload,
      };
    case CLEAR_MODEL_BY_ID_DETAILS:
      return {
        ...state,
        modelByIdData: null,
      };
    case CLEAR_MAKE_BY_ID_DETAILS:
      return {
        ...state,
        makeByIdData: null,
      };
    case GET_ALL_MAKES:
      return {
        ...state,
        getAllMakes: action.payload,
      };
    default:
      return state;
  }
};
