import React from "react";

export const FacebookIcon = ({ className = "" }) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      id="icon_fb"
      className={className}
    >
      <path
        d="M24 0C10.746 0 0 10.746 0 24C0 37.254 10.746 48 24 48C37.254 48 48 37.254 48 24C48 10.746 37.254 0 24 0ZM29.232 23.9985H25.8735V36.1845H20.838V24H18.3225V19.8H20.838V17.2785C20.838 13.8525 22.2585 11.8155 26.2935 11.8155H29.6535V16.0155H27.5535C25.983 16.0155 25.878 16.6035 25.878 17.697L25.8735 19.8H29.6775L29.232 23.9985Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
