import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

const ModalBackdrop = {
  Static: "static",
};

const ModalSize = {
  DEFAULT: 1,
  LARGE: 2,
  EXTRA_LARGE: 3,
};

const Modal = (props) => {
  const nodeRef = React.useRef(null);
  const closeOnEscapeKeyDown = useCallback(
    (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
      }
    },
    [props]
  );

  useEffect(() => {
    if (props.keyboard) {
      document.body.addEventListener("keydown", closeOnEscapeKeyDown);
      return function cleanUp() {
        document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
      };
    }
  }, [closeOnEscapeKeyDown, props.keyboard]);

  useEffect(() => {
    if (props.show) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
    return () => (document.body.style.overflow = "unset");
  }, [props.show]);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit={true}
      timeout={{ enter: 0, exit: 300 }}
      nodeRef={nodeRef}
    >
      <div
        className={`modal overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-0 z-50 justify-center items-center h-modal h-full md:inset-0  pt-4 modal-rtl 
         ${props.show && "enter-done"}`}
        onClick={() => {
          if (props.backdrop !== ModalBackdrop.Static && props.onClose)
            props.onClose();
        }}
        ref={nodeRef}
      >
        <div
          className={`modal-content relative px-4 w-full max-w-lg h-full md:h-auto ${
            props.size === ModalSize.LARGE
              ? "max-w-4xl"
              : props.size === ModalSize.EXTRA_LARGE
              ? "max-w-7xl"
              : ""
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`rounded relative dark:bg-gray-700 ${
              props.transparent ? "bg-transparent" : "bg-white shadow"
            }`}
            onClick={(e) => {
              props.keyboard && e.stopPropagation();
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  keyboard: PropTypes.bool,
  backdrop: PropTypes.oneOf(["static"]),
  onClose: PropTypes.func,
  transparent: PropTypes.bool,
  size: PropTypes.oneOf([
    ModalSize.SMALL,
    ModalSize.DEFAULT,
    ModalSize.LARGE,
    ModalSize.EXTRA_LARGE,
  ]),
};

export { Modal, ModalBackdrop, ModalSize };
