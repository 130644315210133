import React from "react";

export const AutomaticIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.276 13.926"
      className={className}
    >
      <path
        id="noun_Gears_2077845"
        d="M28.36,23.7V15.226a1.431,1.431,0,1,0-2.04-1.3,1.447,1.447,0,0,0,.816,1.3v3.839h-3.69V15.226a1.431,1.431,0,1,0-2.04-1.3,1.447,1.447,0,0,0,.816,1.3v3.839H18.94V15.226a1.431,1.431,0,1,0-2.04-1.3,1.447,1.447,0,0,0,.816,1.3v4.45a.617.617,0,0,0,.612.612h3.894V23.7a1.431,1.431,0,1,0,2.04,1.3,1.447,1.447,0,0,0-.816-1.3V20.288h3.69V23.7a1.431,1.431,0,1,0,2.04,1.3A1.447,1.447,0,0,0,28.36,23.7Z"
        transform="translate(-16.9 -12.5)"
        fill="#7d8593"
      ></path>
    </svg>
  );
};
