import {
  SET_VEHICLE_QUERY_LIST,
  SET_VEHICLE_QUERY_LIST_SUCCESS,
} from "../types";

const initialState = {
  queryList: [],
};

export const dmsDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VEHICLE_QUERY_LIST:
      return {
        ...state,
        queryList: [],
        loading: true,
      };
    case SET_VEHICLE_QUERY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        queryList: action.payload,
      };
    default:
      return state;
  }
};
