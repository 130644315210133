import React from "react";

export const InProgressIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.12109" cy="6.62891" r="6.48828" fill="#43474D" />
      <path
        d="M5.8406 5.30061H4.1397C4.03945 5.30061 3.94331 5.26078 3.87243 5.1899C3.80154 5.11902 3.76172 5.02288 3.76172 4.92263V3.22173C3.76172 3.12148 3.80154 3.02534 3.87243 2.95446C3.94331 2.88357 4.03945 2.84375 4.1397 2.84375C4.23994 2.84375 4.33608 2.88357 4.40697 2.95446C4.47785 3.02534 4.51767 3.12148 4.51767 3.22173V4.54465H5.8406C5.94084 4.54465 6.03698 4.58447 6.10787 4.65536C6.17875 4.72624 6.21858 4.82238 6.21858 4.92263C6.21858 5.02288 6.17875 5.11902 6.10787 5.1899C6.03698 5.26078 5.94084 5.30061 5.8406 5.30061Z"
        fill="white"
      />
      <path
        d="M10.5233 7.00099C10.4231 7.00099 10.3269 6.96117 10.2561 6.89029C10.1852 6.8194 10.1454 6.72326 10.1454 6.62301C10.1456 5.9576 9.92629 5.31069 9.52134 4.78268C9.1164 4.25467 8.5485 3.87507 7.90577 3.7028C7.26304 3.53053 6.58142 3.57522 5.96668 3.82993C5.35194 4.08463 4.83846 4.53512 4.50592 5.11148C4.45569 5.19824 4.37306 5.2615 4.2762 5.28734C4.17933 5.31318 4.07617 5.29948 3.98941 5.24925C3.90265 5.19903 3.83939 5.1164 3.81355 5.01953C3.78771 4.92267 3.80141 4.81951 3.85164 4.73275C4.26751 4.01241 4.90948 3.44945 5.67796 3.13121C6.44643 2.81297 7.29845 2.75723 8.10184 2.97265C8.90523 3.18807 9.61507 3.66259 10.1212 4.32261C10.6274 4.98263 10.9016 5.79125 10.9013 6.62301C10.9013 6.72326 10.8615 6.8194 10.7906 6.89029C10.7197 6.96117 10.6236 7.00099 10.5233 7.00099ZM10.0981 10.4028C9.99786 10.4028 9.90172 10.363 9.83083 10.2921C9.75995 10.2212 9.72012 10.1251 9.72012 10.0248V8.70189H8.3972C8.29696 8.70189 8.20082 8.66207 8.12993 8.59119C8.05905 8.5203 8.01922 8.42416 8.01922 8.32392C8.01922 8.22367 8.05905 8.12753 8.12993 8.05665C8.20082 7.98576 8.29696 7.94594 8.3972 7.94594H10.0981C10.1983 7.94594 10.2945 7.98576 10.3654 8.05665C10.4363 8.12753 10.4761 8.22367 10.4761 8.32392V10.0248C10.4761 10.1251 10.4363 10.2212 10.3654 10.2921C10.2945 10.363 10.1983 10.4028 10.0981 10.4028Z"
        fill="white"
      />
      <path
        d="M7.11962 10.4078C6.11753 10.4066 5.15683 10.0079 4.44824 9.29936C3.73966 8.59077 3.34104 7.63007 3.33984 6.62798C3.33984 6.52773 3.37967 6.43159 3.45055 6.36071C3.52144 6.28982 3.61758 6.25 3.71782 6.25C3.81807 6.25 3.91421 6.28982 3.98509 6.36071C4.05598 6.43159 4.0958 6.52773 4.0958 6.62798C4.09551 7.29339 4.31486 7.9403 4.71981 8.46831C5.12475 8.99633 5.69265 9.37592 6.33538 9.54819C6.97811 9.72046 7.65973 9.67577 8.27447 9.42107C8.88921 9.16636 9.40269 8.71587 9.73523 8.13951C9.7601 8.09655 9.79319 8.05891 9.8326 8.02874C9.87202 7.99856 9.91699 7.97645 9.96495 7.96365C10.0129 7.95086 10.0629 7.94764 10.1121 7.95417C10.1613 7.96071 10.2088 7.97687 10.2517 8.00174C10.2947 8.02661 10.3323 8.05969 10.3625 8.09911C10.3927 8.13852 10.4148 8.1835 10.4276 8.23146C10.4404 8.27942 10.4436 8.32943 10.4371 8.37864C10.4305 8.42785 10.4144 8.47529 10.3895 8.51825C10.0571 9.09144 9.58025 9.56752 9.00654 9.89904C8.43282 10.2306 7.78224 10.406 7.11962 10.4078Z"
        fill="white"
      />
    </svg>
  );
};
