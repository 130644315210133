import { GET_SALES_PERSON_DETAILS } from "../types";

const initialState = {
  salesPersonDetails: [],
  loading: false,
};

export const dmsSalesPersonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_PERSON_DETAILS:
      return {
        ...state,
        salesPersonDetails: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
