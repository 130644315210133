import React from "react";

export const StatusActiveIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      className={className}
    >
      <rect x="0.902344" width="22" height="22" rx="4" fill="#4C0055" />
      <path
        d="M9.96883 15.9566C9.74883 15.9566 9.54715 15.8832 9.38215 15.7182L5.73384 12.0699C5.40384 11.7399 5.40384 11.2266 5.73384 10.8966C6.06384 10.5666 6.57715 10.5666 6.90715 10.8966L9.98716 13.9582L17.3022 6.66156C17.6322 6.33156 18.1455 6.33156 18.4755 6.66156C18.8055 6.99156 18.8055 7.5049 18.4755 7.8349L10.5738 15.7182C10.3905 15.8832 10.1888 15.9566 9.96883 15.9566Z"
        fill="white"
      />
    </svg>
  );
};
