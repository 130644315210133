import React from "react";

export const ArrowBackIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.5 32.5"
      className={className}
    >
      <path id="Path_158" d="M0,32.5H32.5V0H0Z" fill="none"></path>
      <path
        id="Path_159"
        d="M19.747,11.622H4.621l6.608,6.608a1.365,1.365,0,0,1,0,1.923,1.349,1.349,0,0,1-1.909,0L.4,11.229A1.349,1.349,0,0,1,.4,9.32L9.32.4a1.35,1.35,0,0,1,1.909,1.909L4.621,8.914H19.747a1.354,1.354,0,1,1,0,2.708Z"
        transform="translate(5.982 5.982)"
        fill="#465166"
      ></path>
    </svg>
  );
};
