import React from "react";

export const FeaturedStarIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1697"
            data-name="Rectangle 1697"
            width="23.189"
            height="22.133"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_167582"
        data-name="Group 167582"
        transform="translate(902 23299)"
      >
        <circle
          id="Ellipse_352"
          data-name="Ellipse 352"
          cx="19.5"
          cy="19.5"
          r="19.5"
          transform="translate(-902 -23299)"
          fill="#fa4210"
        />
        <g
          id="Ellipse_353"
          data-name="Ellipse 353"
          transform="translate(-899 -23296)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        >
          <circle cx="16.5" cy="16.5" r="16.5" stroke="none" />
          <circle cx="16.5" cy="16.5" r="16" fill="none" />
        </g>
        <g
          id="Group_166951"
          data-name="Group 166951"
          transform="translate(-894.218 -23291)"
        >
          <g
            id="Group_166950"
            data-name="Group 166950"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_13720"
              data-name="Path 13720"
              d="M217.08,147.41h.046v.453a2.032,2.032,0,0,1-.411.525q-2.392,2.325-4.78,4.655c-.054.052-.105.107-.158.161-.11.044-.206-.016-.3-.046-.794-.239-1.578-.509-2.366-.765-1.111-.362-2.225-.713-3.331-1.088a.373.373,0,0,1-.159-.082.11.11,0,0,1,0-.105.552.552,0,0,1,.243-.112c2.8-.928,5.619-1.823,8.425-2.744.83-.273,1.657-.556,2.5-.805.1-.029.194-.091.3-.045"
              transform="translate(-193.936 -139.032)"
              fill="#f6ab16"
            />
            <path
              id="Path_13721"
              data-name="Path 13721"
              d="M210.312,224.489a.725.725,0,0,1-.759.036c-1.885-.955-3.742-1.963-5.615-2.939-.246-.128-.489-.261-.734-.391a.345.345,0,0,1-.072-.275q0-2.993,0-5.986a.918.918,0,0,1,.017-.179.2.2,0,0,1,.031-.069c.128-.054.174.058.225.128,1.046,1.429,2.082,2.865,3.124,4.3q1.4,1.919,2.792,3.839c.315.432.626.867.94,1.3.051.07.128.14.053.24"
              transform="translate(-191.606 -202.493)"
              fill="#f6ab16"
            />
            <path
              id="Path_13722"
              data-name="Path 13722"
              d="M212.293,224.331a1.95,1.95,0,0,0-.407-.634c-1.019-1.42-2.05-2.83-3.079-4.243q-1.489-2.047-2.98-4.092c-.2-.279-.4-.563-.6-.845a.028.028,0,0,1,.046,0,3.049,3.049,0,0,1,.581.175l4.608,1.5.963.314.414,2.381q.216,1.246.432,2.491c.127.735.267,1.468.376,2.206a.714.714,0,0,1-.355.747"
              transform="translate(-193.587 -202.335)"
              fill="#f1d322"
            />
            <path
              id="Path_13723"
              data-name="Path 13723"
              d="M216.568,122.031l-3.729,1.22-4.032,1.314-3.475,1.128c-.071.023-.145.035-.218.051a.039.039,0,0,1-.056-.007.535.535,0,0,1,.161-.315q1.741-2.384,3.47-4.778c.048-.067.092-.14.184-.157.705.1,1.411.2,2.116.3.883.127,1.766.257,2.648.385.764.11,1.53.21,2.293.332a.724.724,0,0,1,.639.528"
              transform="translate(-193.425 -113.653)"
              fill="#f1d322"
            />
            <path
              id="Path_13724"
              data-name="Path 13724"
              d="M136.406,6.833q1.055-2.146,2.111-4.291.508-1.032,1.019-2.063A.719.719,0,0,1,140.257,0a.585.585,0,0,1,.073.413q0,4.758,0,9.515,0,.882,0,1.763a.875.875,0,0,1-.029.312c-.009.023-.017.048-.024.072-.12.068-.16-.039-.207-.1-.731-1.006-1.466-2.01-2.195-3.018-.459-.635-.926-1.265-1.378-1.906-.045-.063-.126-.116-.091-.216"
              transform="translate(-128.66 0)"
              fill="#f1d322"
            />
            <path
              id="Path_13725"
              data-name="Path 13725"
              d="M204.428,12.115q0-6.044,0-12.089a.755.755,0,0,1,.727.506q.837,1.7,1.676,3.4l1.447,2.931-3.8,5.255a.036.036,0,0,1-.045,0"
              transform="translate(-192.831 -0.024)"
              fill="#f6ab16"
            />
            <path
              id="Path_13726"
              data-name="Path 13726"
              d="M125.027,214.582q0,3.26,0,6.52-1.624.859-3.249,1.718c-.409.216-.822.424-1.233.636a.476.476,0,0,1-.284-.45,1.78,1.78,0,0,1,.03-.54c.121-.637.21-1.28.335-1.916a.4.4,0,0,1,.053-.133q1.5-2.065,3-4.128c.408-.559.8-1.128,1.228-1.675.036-.029.072-.054.121-.032"
              transform="translate(-113.429 -202.401)"
              fill="#f1d322"
            />
            <path
              id="Path_13727"
              data-name="Path 13727"
              d="M52.23,120.443l3.805,5.258a.124.124,0,0,1-.06.039.453.453,0,0,1-.242-.038c-2.114-.7-4.241-1.371-6.356-2.073a1.955,1.955,0,0,1-.253-.1,1.024,1.024,0,0,1-.12-.068c-.581-.468-1.071-1.03-1.6-1.548a.792.792,0,0,1-.233-.453.278.278,0,0,1,.084-.287,10.656,10.656,0,0,1,1.129-.182c.889-.136,1.779-.265,2.669-.393.393-.057.788-.106,1.182-.159"
              transform="translate(-44.483 -113.61)"
              fill="#f6ab16"
            />
            <path
              id="Path_13728"
              data-name="Path 13728"
              d="M4.531,148.614a.1.1,0,0,1,.093.047,2.036,2.036,0,0,1,.344.289c.918.933,1.891,1.81,2.8,2.756.108.113.308.153.308.359a.319.319,0,0,1-.108.083c-.807.306-1.641.534-2.457.813a.186.186,0,0,1-.153,0Q2.826,150.493.3,148.026a.76.76,0,0,1-.247-.861c.09-.062.175-.011.258.016,1.278.422,2.563.823,3.84,1.248a1.239,1.239,0,0,1,.384.185"
              transform="translate(0 -138.788)"
              fill="#eeb516"
            />
            <path
              id="Path_13729"
              data-name="Path 13729"
              d="M74.066,235.26l2.621-.861c.1-.048.12.022.137.089a.524.524,0,0,1-.01.224c-.228,1.3-.447,2.6-.67,3.9a2.223,2.223,0,0,1-.137.5l-.046.09a7.547,7.547,0,0,1-.758,1.114c-.594.831-1.2,1.654-1.8,2.479-.069.095-.106.268-.294.214-.342-.28-.273-.645-.21-1.01.148-.851.293-1.7.441-2.553q.241-1.386.486-2.772c.082-.47.162-.94.242-1.41"
              transform="translate(-68.713 -221.087)"
              fill="#f38a19"
            />
            <path
              id="Path_13730"
              data-name="Path 13730"
              d="M77.465,323.073c.092,0,.12-.073.165-.133q1.293-1.78,2.587-3.558c.031-.042.051-.092.1-.114q-.1.638-.205,1.276c-.053.33-.107.66-.155.991a.778.778,0,0,0,.226.676c-.613.327-1.219.667-1.841.976a.71.71,0,0,1-.879-.113"
              transform="translate(-73.07 -301.155)"
              fill="#eeb516"
            />
            <path
              id="Path_13731"
              data-name="Path 13731"
              d="M5.36,135.632q-1.564-.51-3.129-1.019c-.45-.146-.9-.287-1.353-.43a.637.637,0,0,1,.5-.49c.732-.139,1.47-.251,2.213-.32a.649.649,0,0,0,.2.711c.5.48.95,1,1.483,1.444a.645.645,0,0,1,.084.1"
              transform="translate(-0.828 -125.807)"
              fill="#f38a19"
            />
            <path
              id="Path_13732"
              data-name="Path 13732"
              d="M202.3,213.127h.183v.089h-.091l-.089.046a.086.086,0,0,1,0-.136"
              transform="translate(-190.796 -201.035)"
              fill="#f4bf1c"
            />
            <path
              id="Path_13733"
              data-name="Path 13733"
              d="M132.819,214.08v.047c-.433.6-.864,1.21-1.3,1.812q-1.183,1.633-2.372,3.262c-.177.242-.36.48-.54.72.077-.5.15-.991.234-1.485.161-.952.33-1.9.489-2.853.02-.122.148-.269-.043-.372a.1.1,0,0,1,.091-.045.382.382,0,0,1,.2-.106c.991-.342,2-.64,2.987-.98.084-.029.171-.071.257,0"
              transform="translate(-121.312 -201.899)"
              fill="#f6ab16"
            />
            <path
              id="Path_13734"
              data-name="Path 13734"
              d="M88.4,176.331a2.258,2.258,0,0,0-.515.15c-.911.294-1.82.6-2.73.893-.063.021-.13.03-.2.044l-1.017-.993q-1.131-1.108-2.261-2.216c-.059-.058-.109-.123-.164-.185l3.128,1.019,3.6,1.168a1.412,1.412,0,0,0,.153.032q0,.044,0,.089"
              transform="translate(-76.892 -164.15)"
              fill="#f1d322"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
