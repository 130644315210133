import React from "react";

export const WhatsappIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.801 18"
      className={className}
    >
      <path
        id="Path_777"
        d="M119.156,107a8.533,8.533,0,0,1,3.3.654,8.287,8.287,0,0,1,2.815,1.877,8.654,8.654,0,0,1,2.531,6.114,8.676,8.676,0,0,1-.654,3.327,8.573,8.573,0,0,1-1.877,2.815,8.722,8.722,0,0,1-2.815,1.877,8.533,8.533,0,0,1-3.3.654,9,9,0,0,1-2.161-.284,9.7,9.7,0,0,1-1.791-.682l-4.436,1.422-.341.085L110,125l.114-.427.085-.341h0l1.251-4.635a9.167,9.167,0,0,1-.682-1.735,9.478,9.478,0,0,1-.284-2.218,8.525,8.525,0,0,1,2.559-6.114,8.454,8.454,0,0,1,2.787-1.877A8.675,8.675,0,0,1,119.156,107Zm-4.322,15.555.37-.114.085-.028.085.057a8.428,8.428,0,0,0,1.82.739,8.826,8.826,0,0,0,1.962.227,7.566,7.566,0,0,0,2.986-.6,7.866,7.866,0,0,0,2.531-1.678,7.886,7.886,0,0,0,2.275-5.517,7.841,7.841,0,0,0-.6-2.957,7.717,7.717,0,0,0-1.678-2.531,7.951,7.951,0,0,0-2.531-1.706,8.1,8.1,0,0,0-2.986-.6,7.753,7.753,0,0,0-5.517,2.3,7.717,7.717,0,0,0-1.678,2.531,7.329,7.329,0,0,0-.6,2.957,7.424,7.424,0,0,0,.256,1.962,6.93,6.93,0,0,0,.711,1.791l.057.085-.028.114-.142.512-.114.4h0l-.853,3.185,3.441-1.081.142-.057Z"
        transform="translate(-110 -107)"
        fill="#4b124b"
      ></path>
      <path
        id="Path_778"
        d="M244.806,244.984a1.559,1.559,0,0,0-.256.2l2.616,2.5c.284-.284.483-.6.142-.938-.4-.37-1.877-1.791-1.877-1.791A.437.437,0,0,0,244.806,244.984Zm-.512.455a2.983,2.983,0,0,0-.256,3.412,11.272,11.272,0,0,0,1.877,2.588,13.628,13.628,0,0,0,2.986,2.36,4.824,4.824,0,0,0,4.379.37l-2.588-2.5c-.227.228-.427.4-.6.4-.427.028-4.521-2.986-3.526-3.867a3.023,3.023,0,0,1,.313-.256Zm9.3,8.5c.37-.37.37-.882-.2-1.422a14.5,14.5,0,0,1-1.365-1.393.533.533,0,0,0-.825,0l-.227.256Z"
        transform="translate(-239.829 -240.918)"
        fill="#4b124b"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};
