import ReactDOM from "react-dom";
import React from "react";
import i18next from "i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";

export const MessageBoxType = {
  Message: "Message",
  Alert: "Alert",
  Confirmation: "Confirmation",
};

export const MessageBoxResult = {
  Nope: "nope",
  Yes: "Yes",
  No: "No",
  OK: "OK",
};

const defaultProps = {
  content: "",
  container: "#app-message-box",
  type: MessageBoxType.Message,
};

const MessageBox = {
  open: (props) => {
    return new Promise((resolve) => {
      const { container, content, type } = {
        ...defaultProps,
        ...props,
      };

      const handleClose = (value) => {
        ReactDOM.unmountComponentAtNode(containerElement);
        return resolve(value);
      };
      const containerElement = document.querySelector(container);
      if (!containerElement) throw Error(`can't find container ${container}`);

      ReactDOM.render(
        <Modal show={true}>
          <ModalHeader onCloseClick={() => handleClose(MessageBoxResult.Nope)}>
            {type === MessageBoxType.Alert ? (
              <h1>{i18next.t("ALERT")}</h1>
            ) : type === MessageBoxType.Message ? (
              <h1>{i18next.t("MESSAGE")}</h1>
            ) : (
              <h1>{i18next.t("CONFIRMATION")}</h1>
            )}
          </ModalHeader>
          <ModalBody>
            <div className="text-center">{content}</div>
          </ModalBody>
          <ModalFooter>
            {(type === MessageBoxType.Alert ||
              type === MessageBoxType.Message) && (
              <div className="flex justify-center items-center gap-4">
                <button
                  className="btn btn-primary"
                  onClick={() => handleClose(MessageBoxResult.OK)}
                >
                  {i18next.t("LBL_OK")}
                </button>
              </div>
            )}
            {type === MessageBoxType.Confirmation && (
              <div className="flex justify-center items-center gap-4 arabic">
                <button
                  className="btn btn-primary"
                  onClick={() => handleClose(MessageBoxResult.Yes)}
                >
                  {i18next.t("LBL_YES")}
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handleClose(MessageBoxResult.No)}
                >
                  {i18next.t("LBL_NO")}
                </button>
              </div>
            )}
          </ModalFooter>
        </Modal>,
        containerElement
      );
    });
  },
};

export default MessageBox;
