import React from "react";

export const DocumentSucessIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7234 13.3827C25.7234 20.1848 20.2092 25.699 13.4071 25.699C6.60501 25.699 1.09082 20.1848 1.09082 13.3827C1.09082 6.58059 6.60501 1.06641 13.4071 1.06641C20.2092 1.06641 25.7234 6.58059 25.7234 13.3827Z"
        stroke="#824C88"
        strokeWidth="2"
      />
      <path
        d="M11.1413 15.9006L7.82489 12.6462C7.706 12.5296 7.56485 12.4372 7.40951 12.3741C7.25417 12.311 7.08767 12.2785 6.91953 12.2785C6.75139 12.2785 6.5849 12.311 6.42955 12.3741C6.27421 12.4372 6.13307 12.5296 6.01417 12.6462C5.77406 12.8817 5.63916 13.2011 5.63916 13.5341C5.63916 13.8671 5.77406 14.1864 6.01417 14.4219L10.237 18.5631C10.3556 18.6802 10.4966 18.7732 10.652 18.8367C10.8074 18.9002 10.9741 18.9328 11.1424 18.9328C11.3107 18.9328 11.4774 18.9002 11.6328 18.8367C11.7882 18.7732 11.9292 18.6802 12.0478 18.5631L20.7921 9.98587C20.9126 9.86976 21.0084 9.73141 21.0741 9.57879C21.1398 9.42617 21.174 9.26231 21.1748 9.09666C21.1756 8.93101 21.1429 8.76685 21.0787 8.61364C21.0145 8.46043 20.9199 8.32121 20.8006 8.20401C20.6812 8.08681 20.5393 7.99394 20.3832 7.93077C20.227 7.8676 20.0596 7.83537 19.8907 7.83595C19.7218 7.83652 19.5547 7.86989 19.399 7.93412C19.2433 7.99835 19.1021 8.09218 18.9835 8.2102L11.1413 15.9006Z"
        fill="#824C88"
      />
    </svg>
  );
};
