import {
  GET_CMS_CONFIGURATION,
  UPDATE_CMS_CONFIGURATION,
  GET_CMS_TEXT_CONFIGURATION,
  GET_CMS_TEXT_CONFIGURATION_KEY,
} from "../types";

const initialState = {
  configuration: [],
  cmsTextConfiguration: [],
  cmsTextConfigurationKey: [],
};
export const cmsConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CMS_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload,
      };
    case UPDATE_CMS_CONFIGURATION:
      return {
        ...state,
      };

    case GET_CMS_TEXT_CONFIGURATION:
      return {
        ...state,
        cmsTextConfiguration: action.payload,
        cmsTextConfigurationKey: [],
      };
    case GET_CMS_TEXT_CONFIGURATION_KEY:
      return {
        ...state,
        cmsTextConfigurationKey: action.payload,
      };
    default:
      return state;
  }
};
