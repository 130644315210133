export const SelectMarkIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.39968 14.107L3.84938 8.53497C3.6504 8.33534 3.41418 8.17699 3.1542 8.06895C2.89423 7.96092 2.61559 7.90531 2.33419 7.90531C2.0528 7.90531 1.77416 7.96092 1.51419 8.06895C1.25421 8.17699 1.01799 8.33534 0.819015 8.53497C0.417164 8.93814 0.191406 9.48495 0.191406 10.0551C0.191406 10.6253 0.417164 11.1721 0.819015 11.5753L7.88629 18.6657C8.0847 18.8663 8.32074 19.0255 8.58079 19.1342C8.84084 19.2429 9.11976 19.2988 9.40147 19.2988C9.68317 19.2988 9.96209 19.2429 10.2221 19.1342C10.4822 19.0255 10.7182 18.8663 10.9166 18.6657L25.5509 3.97992C25.7525 3.78112 25.9129 3.54423 26.0229 3.28292C26.1328 3.02162 26.1901 2.74106 26.1914 2.45743C26.1927 2.17381 26.138 1.89273 26.0305 1.63041C25.923 1.36808 25.7648 1.12971 25.565 0.929038C25.3653 0.728364 25.1278 0.56936 24.8665 0.461198C24.6052 0.353036 24.3251 0.297856 24.0424 0.298841C23.7597 0.299826 23.48 0.356955 23.2194 0.466935C22.9588 0.576915 22.7225 0.737569 22.5241 0.93963L9.39968 14.107Z"
        fill="#03E10C"
      />
    </svg>
  );
};
