import React from "react";

export const AssignIcon = ({ className = "" }) => {
  return (
    <svg
      id="Group_166890"
      data-name="Group 166890"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32.002"
      viewBox="0 0 32 32.002"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1663"
            data-name="Rectangle 1663"
            width="32"
            height="32.002"
            fill="currentColor"
          />
        </clipPath>
      </defs>
      <g id="Group_166889" data-name="Group 166889" clipPath="url(#clip-path)">
        <path
          id="Path_13680"
          data-name="Path 13680"
          d="M26.671,203.46c0,1.3-.006,2.6.005,3.9,0,.255-.068.332-.328.325q-1.034-.028-2.069,0a.381.381,0,0,1-.406-.264c-.345-.712-.717-1.411-1.053-2.127a.437.437,0,0,0-.473-.285q-9.018.01-18.036,0a.416.416,0,0,0-.441.273c-.34.714-.711,1.413-1.051,2.128a.412.412,0,0,1-.444.274c-.689-.015-1.379-.011-2.069,0-.2,0-.3-.021-.3-.266.009-2.6-.009-5.205.018-7.808a4.543,4.543,0,0,1,.747-2.389c.18-.286.386-.556.555-.848.125-.217.251-.255.5-.189a45.193,45.193,0,0,0,23.056,0,.371.371,0,0,1,.47.173,6.179,6.179,0,0,1,1.329,4.291c-.055.934-.01,1.873-.01,2.81"
          transform="translate(-0.004 -175.688)"
          fill="currentColor"
        />
        <path
          id="Path_13681"
          data-name="Path 13681"
          d="M161.359,16a8,8,0,1,1,8-7.982,8,8,0,0,1-8,7.982"
          transform="translate(-137.355 -0.001)"
          fill="currentColor"
        />
        <path
          id="Path_13682"
          data-name="Path 13682"
          d="M23.785,110.316a34.894,34.894,0,0,1-5.751,1.077,44.227,44.227,0,0,1-8.646.077,46.372,46.372,0,0,1-7.806-1.426c-.439-.116-.878-.233-1.32-.331-.178-.039-.267-.094-.261-.3.015-.507.017-1.015,0-1.521-.008-.228.094-.247.277-.246,1,.006,2-.008,3,.009a.476.476,0,0,0,.559-.368c.436-1.22.922-2.422,1.37-3.638a.588.588,0,0,1,.478-.421,32.8,32.8,0,0,1,7.726-.9c.229,0,.3.08.364.285a10.567,10.567,0,0,0,8.649,7.583c.229.043.464.063.7.083s.441.023.662.034"
          transform="translate(0 -91.652)"
          fill="currentColor"
        />
        <path
          id="Path_13683"
          data-name="Path 13683"
          d="M93.455,238.279c-1.2,0-2.394-.012-3.59.008-.32.006-.394-.1-.378-.394.023-.441.016-.885,0-1.326-.006-.205.028-.294.267-.293q3.727.014,7.453,0c.225,0,.283.066.276.282-.016.468-.021.937,0,1.4.012.261-.071.326-.324.323-1.236-.013-2.472-.006-3.707-.006"
          transform="translate(-80.147 -211.618)"
          fill="#fefefe"
        />
        <path
          id="Path_13684"
          data-name="Path 13684"
          d="M33.691,238.29c-.468,0-.937-.007-1.4,0-.186,0-.249-.053-.245-.242q.017-.76,0-1.522c0-.183.043-.245.236-.243.949.01,1.9.008,2.848,0,.174,0,.259.032.254.232-.013.507-.014,1.015,0,1.522.006.224-.1.254-.283.251-.468-.008-.936,0-1.4,0"
          transform="translate(-28.697 -211.629)"
          fill="#fdfdfd"
        />
        <path
          id="Path_13685"
          data-name="Path 13685"
          d="M193.23,236.294c.468,0,.935.006,1.4,0,.183,0,.284.018.276.245-.017.506-.013,1.013,0,1.52,0,.194-.07.241-.249.24-.948-.006-1.9-.008-2.845,0-.212,0-.261-.078-.257-.274.012-.493.015-.988,0-1.481-.007-.219.08-.257.271-.252.467.011.935,0,1.4,0"
          transform="translate(-171.566 -211.632)"
          fill="#fdfdfd"
        />
        <path
          id="Path_13686"
          data-name="Path 13686"
          d="M193.037,43.251c-.4,0-.807-.008-1.21,0-.2.006-.276-.047-.27-.259.015-.494.016-.989,0-1.483-.007-.221.083-.254.273-.252.807.008,1.614-.008,2.42.01.26.006.332-.071.327-.326-.015-.794,0-1.588-.01-2.381,0-.231.048-.325.3-.315.468.02.937.022,1.4,0,.261-.012.3.095.292.318-.011.794.006,1.588-.011,2.381-.006.26.073.328.327.323.78-.016,1.562,0,2.342-.012.261-.005.37.049.353.336a11.643,11.643,0,0,0,0,1.326c.017.292-.1.341-.358.336-.78-.015-1.562.005-2.342-.011-.262-.006-.327.078-.322.331.016.793,0,1.587.011,2.381,0,.23-.044.322-.3.31-.467-.022-.937-.022-1.4,0-.256.012-.3-.087-.3-.314.012-.794-.006-1.588.011-2.381.005-.261-.075-.339-.331-.326-.4.02-.806.005-1.21.005"
          transform="translate(-171.567 -34.255)"
          fill="#fefefe"
        />
      </g>
    </svg>
  );
};
