import { GET_NOTIFICATION_LIST, GET_NOTIFICATION_UNREAD_COUNT } from "../types";

const initialState = {
  notificationList: [],
  notificationUnreadCount: null,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case GET_NOTIFICATION_UNREAD_COUNT:
      return {
        ...state,
        notificationUnreadCount: action.payload,
      };

    default:
      return state;
  }
};
