import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import LanguageSwitcher from "./LanguageSwitcher";
import ProfileDropdown from "./ProfileDropdown";
import { ApplicationRoles } from "../../utilities/constants/role.constants";
import {
  GridPageSize,
  languages,
} from "../../utilities/constants/global.constants";
import {
  BellLogoIcon,
  EmailLogoIcon,
  PhoneIcon,
  WhatsappIcon,
} from "../gogo-icons";
import "moment/locale/ar";
import moment from "moment";
import { convertUTCToLocal } from "../../utilities/helpers/validation.helpers";
import {
  getNotificationsList,
  getNotificationsUnreadCount,
} from "../../redux/actions/notificationAction";
import {
  getUserRole,
  handleChangeUserRole,
  setRoleId,
} from "../../redux/actions/authAction";
import { getUserProfile } from "../../redux/actions/userManagementAction";

function HeaderTop({ onLanguageChange }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [pageNumber] = useState(1);
  const [isRead] = useState(0);
  const [open, setOpen] = useState(false);
  const filterDropdownRef = useRef();
  const userRoles = useSelector((state) => state.authReducer.userRoles);
  const { isAuthentic } = useSelector((state) => state.authReducer);
  const { availableRoles, selectedRole } = useSelector(
    (state) => state.authReducer
  );

  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );
  const userDetails = useSelector(
    (state) => state.userManagementReducer.selfProfileDetails
  );

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    if (availableRoles && availableRoles.length > 0) {
      const selectedRoleData = availableRoles.find(
        (x) => x.RoleKey === selectedRole
      );
      dispatch(setRoleId(selectedRoleData.RoleId));
    }
  }, [availableRoles, dispatch, selectedRole]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        open &&
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(e.target)
      ) {
        dispatch(getNotificationsUnreadCount());
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open, dispatch]);

  useEffect(() => {
    if (isAuthentic) {
      dispatch(getUserProfile(selectedLanguage));
    }
  }, [dispatch, selectedLanguage, isAuthentic]);

  useEffect(() => {
    if (isAuthentic) {
      dispatch(getUserRole(selectedLanguage));
    }
  }, [dispatch, selectedLanguage, isAuthentic]);

  useEffect(() => {
    moment.locale(selectedLanguage === languages.eng ? "en" : "ar");
    dispatch(
      getNotificationsList(pageNumber, GridPageSize, isRead, selectedLanguage)
    );
    dispatch(getNotificationsUnreadCount());
  }, [dispatch, isRead, pageNumber, isAuthentic, selectedLanguage]);

  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationList
  );
  const notificationUnreadCount = useSelector(
    (state) => state.notificationReducer.notificationUnreadCount
  );

  const gotoNotifications = () => {
    if (isAuthentic) {
      history.push("/notifications");
      setOpen(false);
    }
  };

  return (
    <>
      <div className="header-top">
        <div className="header-top-container">
          <div className="flex flex-end">
            <button
              type="button"
              className="sm:inline-flex lg:hidden items-center justify-center p-4 text-gray-400  "
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggleMenu}
            >
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className={openMenu ? "active" : "inactive"}>
            <div
              className={`lg:flex justify-end items-center ${
                selectedLanguage === languages.eng
                  ? "flex-row "
                  : "flex-row-reverse"
              } `}
            >
              {availableRoles && availableRoles.length > 1 && (
                <select
                  className="border rounded-sm h-8"
                  value={selectedRole}
                  onChange={(e) => {
                    const data = {
                      roleKey: e.target.value,
                      selectedLanguage,
                    };
                    dispatch(handleChangeUserRole(data));
                    history.push("/dashboard");
                  }}
                >
                  {availableRoles &&
                    availableRoles.length > 0 &&
                    availableRoles.map((x) => (
                      <option key={x.RoleKey} value={x.RoleKey}>
                        {x.Role}
                      </option>
                    ))}
                </select>
              )}

              <div className="lg:w-full lg:flex xl:text-base text-sm ">
                <ul
                  className={`list-wrapper ${
                    selectedLanguage === languages.eng
                      ? "flex-row justify-end"
                      : "flex-row-reverse justify-start"
                  } `}
                >
                  {(userRoles[0] === ApplicationRoles.DMS_ADMIN ||
                    userRoles[0] === ApplicationRoles.DMS_SALESPERSON) && (
                    <li
                      className={` ${
                        selectedLanguage === languages.eng
                          ? "border-right-li"
                          : "border-left-li"
                      } `}
                    >
                      <Link to="/user-feedback">{t("HEADER_FEEDBACK")}</Link>
                    </li>
                  )}
                  <li
                    className={` ${
                      selectedLanguage === languages.eng
                        ? "border-right-li"
                        : "border-left-li"
                    } `}
                  >
                    <a target="_blank" rel="noopener noreferrer">
                      <WhatsappIcon className="icon mr-0" />
                    </a>
                  </li>
                  <li
                    className={` ${
                      selectedLanguage === languages.eng
                        ? "border-right-li"
                        : "border-left-li"
                    } `}
                  >
                    <PhoneIcon className="icon" />
                    <a>{userDetails?.MobileNumber}</a>
                  </li>

                  <li
                    className={` ${
                      selectedLanguage === languages.eng
                        ? "border-right-li"
                        : "border-left-li"
                    } `}
                  >
                    <a>
                      <EmailLogoIcon className="icon" />
                      {t(userDetails?.EmailAddress)}
                    </a>
                  </li>

                  <li
                    className={` ${
                      selectedLanguage === languages.eng
                        ? "border-right-li"
                        : isAuthentic
                        ? "border-left-li"
                        : ""
                    } `}
                  >
                    <div>
                      <LanguageSwitcher
                        onChange={onLanguageChange}
                        selectedLanguage={selectedLanguage}
                      />
                    </div>
                  </li>
                  {isAuthentic && (
                    <>
                      <li
                        className={` ${
                          selectedLanguage === languages.eng
                            ? "border-right-li"
                            : "border-left-li"
                        } `}
                      >
                        <div
                          className="relative inline-block text-left notification-holder mx-2"
                          ref={filterDropdownRef}
                        >
                          <Menu>
                            {() => (
                              <>
                                <span className="rounded-md shadow-sm">
                                  <Menu.Button className="">
                                    <div onClick={() => setOpen(!open)}>
                                      <BellLogoIcon className="w-4 mt-2" />
                                    </div>

                                    {notificationUnreadCount &&
                                      notificationUnreadCount.data > 0 && (
                                        <span
                                          className={`${
                                            notificationUnreadCount.data > 9
                                              ? "px-1 ar-notification-count-2"
                                              : "px-2 ar-notification-count"
                                          }  absolute bottom-2  left-3 inline-flex items-center justify-center py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full`}
                                        >
                                          {notificationUnreadCount.data > 9
                                            ? "9+"
                                            : notificationUnreadCount.data}
                                        </span>
                                      )}
                                  </Menu.Button>
                                </span>

                                <Transition show={open}>
                                  <Menu.Items
                                    static
                                    className={`absolute ${
                                      selectedLanguage === languages.eng
                                        ? "right-0"
                                        : "left-0"
                                    } w-auto mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded shadow-lg outline-none`}
                                  >
                                    <div className="heading flex flex-row justify-between px-3 py-3 arabic-direction">
                                      <div className="heading-left flex items-center">
                                        <h6 className="heading-title ">
                                          {t("HEADER_NOTIFICATIONS")}
                                        </h6>
                                      </div>
                                      <div className="heading-right flex items-center pb-1">
                                        <a
                                          onClick={() => gotoNotifications()}
                                          className="notification-link cursor-pointer"
                                        >
                                          {t("SEE_ALL")}
                                        </a>
                                      </div>
                                    </div>

                                    <div className="py-1 bell-notification">
                                      {notificationData &&
                                      notificationData.DataList &&
                                      notificationData.DataList.length > 0 ? (
                                        notificationData.DataList.slice(
                                          0,
                                          5
                                        ).map((list) => (
                                          <Menu.Item
                                            key={list.AlertNotificationId}
                                          >
                                            {({ active }) => (
                                              <div
                                                className={`${
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700"
                                                } flex justify-between w-full  text-sm leading-5 text-left`}
                                              >
                                                <div>
                                                  <div
                                                    className="notification-item cursor-pointer"
                                                    onClick={() =>
                                                      gotoNotifications()
                                                    }
                                                  >
                                                    <span
                                                      className="name arabic-direction"
                                                      dangerouslySetInnerHTML={{
                                                        __html: list.AlertText,
                                                      }}
                                                    />

                                                    <span className="time arabic-text-right">
                                                      {moment(
                                                        convertUTCToLocal(
                                                          list.CreatedDate
                                                        )
                                                      ).fromNow()}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Menu.Item>
                                        ))
                                      ) : (
                                        <div className="py-1">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                href=""
                                                className={`${
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700"
                                                } flex justify-between w-full  text-sm leading-5 text-left`}
                                              >
                                                <div>
                                                  <div className="notification-item">
                                                    <span className="name">
                                                      {t(
                                                        "HEADER_NO_NOTIFICATIONS"
                                                      )}
                                                    </span>{" "}
                                                  </div>
                                                </div>
                                              </a>
                                            )}
                                          </Menu.Item>
                                        </div>
                                      )}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        </div>
                      </li>
                      {/* {userRoles[0] === ApplicationRoles.DMS_ADMIN && (
                        <li
                          className={` ${
                            selectedLanguage === languages.eng
                              ? "border-right-li"
                              : "border-left-li"
                          } `}
                        >
                          <Link to="/cart">
                            <CartHeaderIcon className="icon mr-0" />
                          </Link>
                        </li>
                      )} */}
                      <li>
                        <ProfileDropdown />
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default connect(null)(HeaderTop);
