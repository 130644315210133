import React from "react";

export const AddUserIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="25.6"
      viewBox="0 0 32 25.6"
      className={className}
    >
      <path
        id="Icon_awesome-user-plus"
        data-name="Icon awesome-user-plus"
        d="M31.2,10.4H28V7.2a.8.8,0,0,0-.8-.8H25.6a.8.8,0,0,0-.8.8v3.2H21.6a.8.8,0,0,0-.8.8v1.6a.8.8,0,0,0,.8.8h3.2v3.2a.8.8,0,0,0,.8.8h1.6a.8.8,0,0,0,.8-.8V13.6h3.2a.8.8,0,0,0,.8-.8V11.2A.8.8,0,0,0,31.2,10.4Zm-20,2.4A6.4,6.4,0,1,0,4.8,6.4,6.4,6.4,0,0,0,11.2,12.8Zm4.48,1.6h-.835a8.7,8.7,0,0,1-7.29,0H6.72A6.722,6.722,0,0,0,0,21.12V23.2a2.4,2.4,0,0,0,2.4,2.4H20a2.4,2.4,0,0,0,2.4-2.4V21.12A6.722,6.722,0,0,0,15.68,14.4Z"
        fill="#fff"
      />
    </svg>
  );
};
