import React from "react";

export const ExportExcelIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 32 31.416"
      id=".6747183542972228"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        data-name="Icon simple-microsoftexcel"
        d="M31.4 3.845H20.684v1.984h3.16v3.143h-3.16v1h3.16v3.143h-3.16v1.028h3.16v2.972h-3.16v1.191h3.16v2.979h-3.16v1.187h3.16v3h-3.16v2.185H31.4c.169-.051.311-.251.424-.6a2.924 2.924 0 0 0 .176-.851v-22c0-.171-.059-.273-.172-.309a1.467 1.467 0 0 0-.428-.052zm-1.387 21.618h-5.142v-2.991h5.143v2.993zm0-4.184h-5.142v-2.98h5.143zm0-4.171h-5.142V14.15h5.143v2.959zm0-4h-5.142V9.972h5.143v3.14zm0-4.159h-5.142V5.83h5.143v3.12zM0 3.35v24.8l18.88 3.265V.001L0 3.36zm11.191 18.761q-.108-.292-1.011-2.484c-.6-1.461-.96-2.312-1.065-2.555h-.034l-2.025 4.82-2.707-.183 3.211-6-2.94-6 2.76-.145 1.82 4.695h.04l2.06-4.908 2.852-.18-3.4 6.493 3.5 6.624-3.065-.18z"
        fill="#ffffff"
      />
    </svg>
  );
};
