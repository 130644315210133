import { useCallback, useRef } from "react";

const FileUpload = (props) => {
  const inputRef = useRef(null);
  const removeButtonRef = useRef(null);

  const handleFileUpload = useCallback(() => {
    if (inputRef.current) inputRef.current.click();
  }, [inputRef]);

  const onInputChange = async (e) => {
    const response = await handleChange("UPLOADED", e.target.files);
    if (inputRef.current) inputRef.current.value = "";
    return response;
  };

  const handleChange = async (status, files) => {
    return await props.onChange?.(status, files);
  };

  const handleDeleteClickButton = useCallback(() => {
    if (removeButtonRef.current) removeButtonRef.current.click();
  }, [removeButtonRef]);

  const handleRemoveFile = useCallback(() => {
    handleDeleteClickButton();
  }, [handleDeleteClickButton]);

  if (!props.children || typeof props.children !== "function") {
    return <></>;
  } else {
    const fileData = props.defaultValue ? props.defaultValue : "";
    return (
      <>
        <input
          type="file"
          accept={props.accept || "*"}
          ref={inputRef}
          multiple={props?.multiple || false}
          onChange={async (e) => {
            await onInputChange(e);
          }}
          style={{ display: "none" }}
          disabled={props.disabled || fileData ? true : false}
        />
        <button
          type="button"
          ref={removeButtonRef}
          style={{ display: "none" }}
          onClick={async () => {
            await handleChange("REMOVED", null);
          }}
          disabled={!fileData ? true : false}
        />
        {props.children &&
          props.children({
            fileData,
            handleFileUpload,
            handleRemoveFile,
          })}
      </>
    );
  }
};

export default FileUpload;
