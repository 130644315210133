import React from "react";

export const SaveIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M6.25 26.25H23.75C24.413 26.25 25.0489 25.9866 25.5178 25.5178C25.9866 25.0489 26.25 24.413 26.25 23.75V10L20 3.75H6.25C5.58696 3.75 4.95107 4.01339 4.48223 4.48223C4.01339 4.95107 3.75 5.58696 3.75 6.25V23.75C3.75 24.413 4.01339 25.0489 4.48223 25.5178C4.95107 25.9866 5.58696 26.25 6.25 26.25ZM8.75 6.25H13.75V8.75H16.25V6.25H18.75V11.25H8.75V6.25ZM8.75 16.25H21.25V23.75H8.75V16.25Z"
        fill="#4C0055"
      />
    </svg>
  );
};
