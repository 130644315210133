import axios from "axios";
import { store } from "../src/redux/store";
import { ConfigConstants } from "./utilities/constants/global.constants";
import {
  getAuthTokenFromRefreshToken,
  isTokenValid,
} from "./redux/actions/authAction";
import MessageBox, { MessageBoxType } from "./components/common/message-box";
import i18next from "i18next";
import { FETCH_AUTH_TOKEN_ERROR, FETCH_AUTH_TOKEN_FAIL } from "./redux/types";

let isRefreshing = false;
let subscribers = [];

function onRefreshed({ authorizationToken }) {
  subscribers.map((cb) => cb(authorizationToken));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const API2 = axios.create({
  baseURL: process.env.REACT_APP_CS_API_URL,
});

API.interceptors.request.use(
  (request) => {
    const { allowAnonymous, ...req } = request;
    const state = store.getState();
    const token = state && state.authReducer && state.authReducer.token;
    const isAuthentic =
      state && state.authReducer && state.authReducer.isAuthentic;

    if (isAuthentic === true && !allowAnonymous) {
      if (isTokenValid(token)) {
        req.headers.Authorization = token ? `Bearer ${token}` : "";
      }

      // else {
      //   (error) => {
      //     Promise.reject(error.code === 401);
      //   };
      // }
    }
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errorMessage = "";
    if (error.message == ConfigConstants.NetworkError) {
      errorMessage = ConfigConstants.NetworkError;
      console.error(errorMessage);
    }

    if (error && error.response && error.response.status === 500) {
      errorMessage =
        ConfigConstants.refreshError +
        ConfigConstants.ErrorDetails +
        error.response.statusText;
      console.error(errorMessage);
    }
    if (error && error.response && error.response.status === 404) {
      errorMessage =
        ConfigConstants.serverError +
        error.response.config.url +
        ConfigConstants.ErrorDetails +
        error.response.statusText;
      console.error(errorMessage);
    }

    const originalRequest = error.config;
    //this block will check if the request was from token api
    if (
      String(error.request.responseURL)
        .toLowerCase()
        .indexOf("api/webauth/token") > -1 &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      MessageBox.open({
        content: i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"),
        type: MessageBoxType.Alert,
      }).then(() => {
        store.dispatch({ type: FETCH_AUTH_TOKEN_ERROR });
        store.dispatch({ type: FETCH_AUTH_TOKEN_FAIL });
      });
    } else if (error && error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        store.dispatch(getAuthTokenFromRefreshToken()).then((newTokens) => {
          if (newTokens) {
            isRefreshing = false;
            onRefreshed(newTokens && newTokens.data && newTokens.data.Token);
            subscribers = [];
          }
        });
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh(() => {
          const state = store.getState();
          const token = state && state.authReducer && state.authReducer.token;
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  }
);
