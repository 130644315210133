import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";

const VehicleFeatureList = ({ features, saveData }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [featureList, setFeatureList] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [isFormChange, setIsFormChange] = useState(false);
  // const [preSelected, setPreSelected] = useState([]);

  useEffect(() => {
    if (features?.length > 0) {
      const selected = features
        .filter((sel) => sel.IsAvailable)
        .map((a) => a.FeatureId);
      setSelectedFeatures([...selected]);
      // setPreSelected([...selected]);
      // Grouping objects by FeatureCategoryKey
      const grouped = features.reduce((acc, obj) => {
        const key = obj.FeatureCategoryKey;
        if (!acc[key]) {
          acc[key] = { title: key, list: [] };
        }
        acc[key].list.push(obj);
        return acc;
      }, {});
      const groupedFeatures = Object.values(grouped);
      setFeatureList(groupedFeatures);
    }
  }, [features]);

  // const isArrayEquals = (a, b) => {
  //   return a.length === b.length && a.every((element) => b.includes(element));
  // };

  const handleFeatureSelection = (id) => {
    setIsFormChange(true);
    if (selectedFeatures.includes(id)) {
      setSelectedFeatures(
        selectedFeatures.filter(
          (featureID) => parseInt(featureID) !== parseInt(id)
        )
      );
    } else {
      setSelectedFeatures([...selectedFeatures, id]);
    }
    // if (preSelected.length > 0) {
    //   setIsFormChange(isArrayEquals(preSelected, selectedFeatures));
    // } else {
    //   setIsFormChange(true);
    // }
  };

  return (
    <>
      <div className="pb-8">
        {featureList?.map((itm, index) => (
          <div className="border p-6 rounded-lg relative mt-12" key={index}>
            <p className="feature-title">{itm.title}</p>
            <div className="flex flex-wrap gap-4 my-2">
              {itm.list.map((data, index) => (
                <p
                  className={`!text-base btn btn-auto rounded !min-h-[2rem] !p-2 !font-normal 
                ${
                  selectedFeatures?.includes(data.FeatureId)
                    ? "btn-primary"
                    : "btn-secondary feature-btn"
                }
              `}
                  onClick={() => handleFeatureSelection(data.FeatureId)}
                  key={index}
                >
                  {data.Feature}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-6">
        <div className="flex flex-row gap-4 sm:justify-start justify-center">
          <button
            type="button"
            className="btn btn-cancel p-2 sm:w-36 w-32 uppercase"
            onClick={() => history.goBack()}
          >
            {t("LBL_CANCEL")}
          </button>
          <button
            className="btn btn-primary p-2 sm:w-36 w-32 uppercase"
            type="submit"
            onClick={() => saveData(selectedFeatures)}
            disabled={!isFormChange}
          >
            {t("SAVE")}
          </button>
        </div>
      </div>
    </>
  );
};

export default VehicleFeatureList;
