import { toast } from "react-toastify";
import { API } from "../../middleware";
import { GET_NOTIFICATION_LIST, GET_NOTIFICATION_UNREAD_COUNT } from "../types";
import { hideLoader, showLoader } from "./applicationAction";
import i18next from "i18next";

export const getNotificationsList =
  (pageNumber, pageSize, isRead, languageId) => async (dispatch) => {
    try {
      const response = await API.get(
        `/api/Notification/usernotifications?pageNumber=${pageNumber}&pageSize=${pageSize}&isRead=${isRead}&languageId=${languageId}`
      );
      dispatch({ type: GET_NOTIFICATION_LIST, payload: response.data });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getNotificationsUnreadCount = () => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Notification/userNotificationUnreadCount`
    );

    dispatch({ type: GET_NOTIFICATION_UNREAD_COUNT, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendPreview = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());

    const response = await API.post(`/api/notification/emailpreview`, payload);
    dispatch(hideLoader());
    if (response.data.emailSendResult) {
      toast.success(i18next.t("SEND_PREVIEW_SUCCESSFULLY"));
    } else {
      toast.error(i18next.t("ERROR_SEND_PREVIEW"));
    }
  } catch (e) {
    return e;
  }
};
