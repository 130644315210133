import React from "react";

export const PageGoLastArrowIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 17.453 16.532"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g data-name="Group 166851" fill="#292929">
        <path
          data-name="Icon ionic-ios-arrow-forward"
          d="M14.6 8.269L8.347 14.52a1.176 1.176 0 0 0 0 1.669 1.191 1.191 0 0 0 1.674 0l7.087-7.083a1.179 1.179 0 0 0 .034-1.629L10.025.345a1.182 1.182 0 0 0-1.673 1.668z"
        />
        <path
          data-name="Icon ionic-ios-arrow-forward"
          d="M6.6 8.269L.347 14.52a1.176 1.176 0 0 0 0 1.669 1.191 1.191 0 0 0 1.674 0l7.087-7.083a1.179 1.179 0 0 0 .034-1.629L2.025.345A1.182 1.182 0 0 0 .352 2.013z"
        />
      </g>
    </svg>
  );
};
