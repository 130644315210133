import {
  GET_DURATION,
  GET_PACKAGE_DETAIL,
  GET_PACKAGE_SUBSCRIPTION_HISTORY,
  GET_PACKAGE_LIST,
  GET_ACTIVE_SUBSCRIPTION_LIST,
  FETCH_PACKAGES,
  GET_B2C_PACKAGE_DETAIL,
  GET_B2C_PACKAGES_BY_ID,
  GET_B2C_PACKAGE_CONFIG_DETAIL,
  SET_PACKAGE_DETAILS,
  SET_ASSOCIATE_SERVICES,
  SET_CURRENT_STEP,
} from "../types";
const initialState = {
  durationData: [],
  configurationSuccessState: null,
  packageDetail: [],
  packageSubscriptionHistory: [],
  packageList: [],
  activeSubscriptionList: [],
  fetchPackage: false,
  b2cPackageDetail: [],
  b2cPackages: {},
  b2cPackageConfigDetail: {},
  isAddPackageDetailsCompleted: false,
  isAssociateServicesCompleted: false,
  currentStep: 1,
};

// const getPackageByID = {
//   B2CPackageDescription: [
//     {
//       LanguageId: 1,
//       PackageName: "DIAMOND",
//       PackageDescription:
//         "Great package for listing - Avail additional features for 15 days",
//     },
//     {
//       LanguageId: 2,
//       PackageName: "ماسية",
//       PackageDescription:
//         "Great package for listing - Avail additional features for 15 days",
//     },
//   ],
//   B2CPackageId: 4,
//   B2CPackageName: "Package Gold",
//   DisplayName: "Gold1",
//   // B2CPackageDescription: "string",
//   StartDate: "2023-05-19T14:24:17.100Z",
//   EndDate: "2023-05-19T14:24:17.100Z",
//   Price: 1000,
//   DisplayPrice: 1150,
//   PackageStatusId: 0,
//   PackageStatusKey: "Saved",
//   PackageStatus: "Saved",
//   PackageCRMReferenceId: 0,
//   SequenceNumber: 0,
//   IsRecommended: true,
//   IsStandAlone: true,
//   DecommisionedBy: "string",
//   Country: 1,
//   RegionId: 4,
//   CityId: 1,
//   CurrencyId: 1,
//   PackageColor: "#fff",
//   Service: [
//     {
//       ServiceId: 0,
//       ServiceName: "string",
//       ServiceDescription: "string",
//       ServiceCRMReferenceId: 0,
//       ReferenceCode: "string",
//       ServicePrice: "string",
//       ServiceDisplayPrice: 0,
//       ServiceDisplayName: "string",
//       ServiceStatusId: 0,
//       ServiceStatusKey: "string",
//       ServiceStatus: "string",
//       ServiceSequenceNumber: 0,
//       ServiceAttribute: [
//         {
//           ServiceAttributeId: 0,
//           ServiceAttributeValueId: 0,
//           ServiceAttributeName: "string",
//           ServiceAttributeDisplayText: "string",
//           AttributeValue: "string",
//           AttributeValueDisplayText: "string",
//           AttributeMappingIsActive: true,
//         },
//       ],
//     },
//   ],
// };

export const packagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DURATION:
      return {
        ...state,
        durationData: action.payload,
      };
    case GET_PACKAGE_DETAIL:
      return {
        ...state,
        packageDetail: action.payload,
      };
    case GET_PACKAGE_SUBSCRIPTION_HISTORY:
      return {
        ...state,
        packageSubscriptionHistory: action.payload,
      };
    case GET_PACKAGE_LIST:
      return {
        ...state,
        packageList: action.payload,
      };
    case GET_ACTIVE_SUBSCRIPTION_LIST:
      return {
        ...state,
        activeSubscriptionList: action.payload,
        fetchPackage: false,
      };
    case FETCH_PACKAGES:
      return {
        ...state,
        fetchPackage: true,
      };
    case GET_B2C_PACKAGE_DETAIL:
      return {
        ...state,
        b2cPackageDetail: action.payload,
      };
    case GET_B2C_PACKAGES_BY_ID:
      return {
        ...state,
        b2cPackages: action.payload,
      };
    case GET_B2C_PACKAGE_CONFIG_DETAIL:
      return {
        ...state,
        b2cPackageConfigDetail: action.payload,
      };
    case SET_PACKAGE_DETAILS:
      return {
        ...state,
        isAddPackageDetailsCompleted: action.payload,
      };
    case SET_ASSOCIATE_SERVICES:
      return {
        ...state,
        isAssociateServicesCompleted: action.payload,
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    default:
      return state;
  }
};
