import React from "react";

export const VehicleHistoryIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.001 29.557"
      className={className}
    >
      <g id="Group_1" transform="translate(-1049.999 -1105.443)">
        <path
          id="directions_car_FILL0_wght400_GRAD0_opsz48"
          d="M8.889,30.078v1.95a1.05,1.05,0,0,1-1.083,1.083H7.083A1.05,1.05,0,0,1,6,32.028v-11.7l3.069-9.244a1.591,1.591,0,0,1,.6-.794,1.634,1.634,0,0,1,.953-.29H27.378a1.634,1.634,0,0,1,.957.289,1.591,1.591,0,0,1,.6.794L32,20.328v11.7a1.05,1.05,0,0,1-1.083,1.083h-.758A.993.993,0,0,1,29.4,32.8a1.088,1.088,0,0,1-.289-.776v-1.95ZM9,18.161H29l-1.986-5.994H10.983Zm-.831,2.167ZM12,26.106a1.857,1.857,0,0,0,1.39-.578,1.9,1.9,0,0,0,.56-1.372,2.01,2.01,0,0,0-.56-1.426,1.83,1.83,0,0,0-1.39-.6,2.034,2.034,0,0,0-2.022,2.022,1.83,1.83,0,0,0,.6,1.39,2.011,2.011,0,0,0,1.419.564Zm14.047,0a1.977,1.977,0,0,0,1.426-.578,1.847,1.847,0,0,0,.6-1.372,2.034,2.034,0,0,0-2.022-2.022,1.83,1.83,0,0,0-1.39.6,2.01,2.01,0,0,0-.56,1.426,1.857,1.857,0,0,0,.578,1.39,1.9,1.9,0,0,0,1.366.556ZM8.167,27.911H29.833V20.328H8.167Z"
          transform="translate(1044 1095.444)"
          fill="#4C0055"
        ></path>
        <circle
          id="Ellipse_1"
          cx="11"
          cy="11"
          r="11"
          transform="translate(1066 1113)"
          fill="#4C0055"
        ></circle>
        <path
          id="history_FILL0_wght400_GRAD0_opsz48"
          d="M12.942,20a6.648,6.648,0,0,1-4.919-2.051A6.816,6.816,0,0,1,6,12.981H7.167a5.708,5.708,0,0,0,1.672,4.142,5.514,5.514,0,0,0,4.1,1.711A5.655,5.655,0,0,0,17.122,17.1a5.752,5.752,0,0,0,1.711-4.2A5.43,5.43,0,0,0,17.1,8.829a5.792,5.792,0,0,0-4.161-1.662,5.288,5.288,0,0,0-2.479.6,6.761,6.761,0,0,0-2.01,1.6h2.042v1.167H6.428V6.486H7.594V8.547A7.4,7.4,0,0,1,10,6.681,6.614,6.614,0,0,1,12.942,6a6.947,6.947,0,0,1,2.742.544A7.184,7.184,0,0,1,17.93,8.032a6.983,6.983,0,0,1,1.517,2.207A6.7,6.7,0,0,1,20,12.961a6.841,6.841,0,0,1-.554,2.742,7.038,7.038,0,0,1-1.517,2.236,7.238,7.238,0,0,1-2.246,1.507A6.841,6.841,0,0,1,12.942,20Zm2.489-3.831-2.994-2.956V9.053H13.6v3.675l2.664,2.606Z"
          transform="translate(1064 1111)"
          fill="#4C0055"
        ></path>
      </g>
    </svg>
  );
};
