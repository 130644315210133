import {
  GET_ALERT_NOTIFICATION,
  GET_ALERT_NOTIFICATION_BY_ID,
  GET_EMAIL_COMMON_TEMPLATE,
  GET_EMAIL_NOTIFICATION,
  GET_EMAIL_NOTIFICATION_BY_ID,
  GET_MOBILE_NOTIFICATION,
  GET_MOBILE_NOTIFICATION_BY_ID,
  GET_SMS_NOTIFICATION,
  GET_SMS_NOTIFICATION_BY_ID,
  GET_TRANSLATION_FILTER_DATA,
  GET_WHATSAPP_NOTIFICATION,
  GET_WHATSAPP_NOTIFICATION_BY_ID,
  SET_TRANSLATION_FILTER,
} from "../types";

const initialState = {
  emailTemplateData: [],
  emailTemplateDataById: null,
  commonEmailTemplateData: [],
  smsNotificationData: [],
  smsNotificationDataById: null,
  alertNotificationData: [],
  alertNotificationDataById: null,
  whatsappTemplateData: [],
  whatsappNotificationDataById: null,
  mobileNotificationData: [],
  mobileNotificationDataById: null,
  translationPayload: {
    translationCreatedDate: {
      max: "",
      min: "",
    },
    serialNoRange: {
      max: "",
      min: "",
    },
    translationStatusId: [],
  },
  translationFilter: {
    translationCreatedDate: {
      max: "",
      min: "",
    },
    serialNoRange: {
      max: "",
      min: "",
    },
    translationStatusId: [],
  },
};

export const templateConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_NOTIFICATION:
      return {
        ...state,
        emailTemplateData: action.payload,
      };
    case GET_EMAIL_NOTIFICATION_BY_ID:
      return {
        ...state,
        emailTemplateDataById: action.payload,
      };
    case GET_EMAIL_COMMON_TEMPLATE:
      return {
        ...state,
        commonEmailTemplateData: action.payload,
      };
    case GET_SMS_NOTIFICATION:
      return {
        ...state,
        smsNotificationData: action.payload,
      };
    case GET_SMS_NOTIFICATION_BY_ID:
      return {
        ...state,
        smsNotificationDataById: action.payload,
      };
    case GET_ALERT_NOTIFICATION:
      return {
        ...state,
        alertNotificationData: action.payload,
      };
    case GET_ALERT_NOTIFICATION_BY_ID:
      return {
        ...state,
        alertNotificationDataById: action.payload,
      };
    case GET_WHATSAPP_NOTIFICATION:
      return {
        ...state,
        whatsappTemplateData: action.payload,
      };
    case GET_WHATSAPP_NOTIFICATION_BY_ID:
      return {
        ...state,
        whatsappNotificationDataById: action.payload,
      };
    case GET_MOBILE_NOTIFICATION:
      return {
        ...state,
        mobileNotificationData: action.payload,
      };
    case GET_MOBILE_NOTIFICATION_BY_ID:
      return {
        ...state,
        mobileNotificationDataById: action.payload,
      };
    case SET_TRANSLATION_FILTER:
      return {
        ...state,
        translationFilter: action.payload,
      };
    case GET_TRANSLATION_FILTER_DATA:
      return {
        ...state,
        translationPayload: {
          ...state.translationPayload,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
