import React from "react";

export const FeatherPackageIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.289 33.164"
      className={className}
    >
      <g id="Icon_feather-package" transform="translate(-2.855 -1.456)">
        <path
          id="Path_4298"
          d="M24.75,14.1,11.25,6.315"
          fill="none"
          stroke="#465166"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        ></path>
        <path
          id="Path_4299"
          d="M31.5,24V12A3,3,0,0,0,30,9.4l-10.5-6a3,3,0,0,0-3,0L6,9.4A3,3,0,0,0,4.5,12V24A3,3,0,0,0,6,26.595l10.5,6a3,3,0,0,0,3,0l10.5-6A3,3,0,0,0,31.5,24Z"
          fill="none"
          stroke="#465166"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        ></path>
        <path
          id="Path_4300"
          d="M4.905,10.44,18,18.015,31.095,10.44"
          fill="none"
          stroke="#465166"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        ></path>
        <path
          id="Path_4301"
          d="M18,33.12V18"
          fill="none"
          stroke="#465166"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        ></path>
      </g>
    </svg>
  );
};
