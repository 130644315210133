import {
  GET_PROVIDER_TYPE,
  GET_PROVIDER_LIST,
  GET_OFFER_TYPE_LIST,
  GET_DEAL_LIST,
  GET_OFFER_BY_DEAL_LIST,
  GET_OFFER_SUB_TYPE_LIST,
  SET_OPEN_OFFER_MODAL,
  SET_OPEN_SUB_OFFER_MODAL,
  GET_OFFER_LEAD_LIST,
} from "../types";

const initialState = {
  providerTypeData: [],
  providerList: [],
  offerTypeList: [],
  dealsList: [],
  offersList: [],
  offerSubTypeList: [],
  offerLeadList: [],
  openOfferModal: false,
  openSubOfferModal: false,
};

export const dealOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVIDER_TYPE:
      return {
        ...state,
        providerTypeData: action.payload,
      };
    case GET_PROVIDER_LIST:
      return {
        ...state,
        providerList: action.payload,
      };
    case GET_OFFER_TYPE_LIST:
      return {
        ...state,
        offerTypeList: action.payload,
      };
    case GET_OFFER_SUB_TYPE_LIST:
      return {
        ...state,
        offerSubTypeList: action.payload,
      };
    case GET_DEAL_LIST:
      return {
        ...state,
        dealsList: action.payload,
      };
    case GET_OFFER_BY_DEAL_LIST:
      return {
        ...state,
        offersList: action.payload,
      };
    case SET_OPEN_OFFER_MODAL:
      return {
        ...state,
        openOfferModal: action.payload,
      };

    case SET_OPEN_SUB_OFFER_MODAL:
      return {
        ...state,
        openSubOfferModal: action.payload,
      };
    case GET_OFFER_LEAD_LIST:
      return {
        ...state,
        offerLeadList: action.payload,
      };
    default:
      return state;
  }
};
