export const VisaCardIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 100 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2482_4421)">
        <path
          d="M96 0H4C1.79086 0 0 1.79086 0 4V56C0 58.2091 1.79086 60 4 60H96C98.2092 60 100 58.2091 100 56V4C100 1.79086 98.2092 0 96 0Z"
          fill="#25459A"
        />
        <path
          d="M50.1336 26.3939C50.096 29.1798 52.7794 30.7346 54.8009 31.6589C56.8779 32.6074 57.5755 33.2155 57.5676 34.0636C57.5517 35.3617 55.9108 35.9345 54.3748 35.9569C51.6953 35.9959 50.1376 35.278 48.8989 34.735L47.9338 38.9734C49.1764 39.5109 51.4773 39.9795 53.8635 40C59.4642 40 63.1287 37.4056 63.1485 33.3829C63.1703 28.2779 55.6234 27.9952 55.6749 25.7132C55.6928 25.0214 56.3963 24.2831 57.9382 24.0952C58.7012 24.0004 60.8079 23.9278 63.1961 24.96L64.1335 20.8592C62.8493 20.4203 61.1984 20 59.1432 20C53.8714 20 50.1634 22.6297 50.1336 26.3939ZM73.1411 20.3534C72.1184 20.3534 71.2563 20.9131 70.8718 21.7724L62.8711 39.6987H68.4678L69.5817 36.8105H76.4211L77.0672 39.6987H82L77.6954 20.3534H73.1411ZM73.9239 25.5793L75.5391 32.8436H71.1156L73.9239 25.5793ZM43.3477 20.3534L38.9361 39.6987H44.2693L48.6789 20.3534H43.3477ZM35.4579 20.3534L29.9067 33.5206L27.6613 22.3247C27.3977 21.0749 26.3572 20.3534 25.2018 20.3534H16.1268L16 20.915C17.863 21.2944 19.9796 21.9063 21.2618 22.5609C22.0467 22.9608 22.2706 23.3104 22.5283 24.2607L26.7813 39.6987H32.4178L41.0587 20.3534H35.4579Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2482_4421">
          <rect width="100" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
