import React from "react";

export const PageBackArrowIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 9.453 16.532"
      id=".31897614326901125"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        data-name="Icon ionic-ios-arrow-forward"
        d="M2.849 8.27l6.257 6.251a1.176 1.176 0 0 1 0 1.669 1.191 1.191 0 0 1-1.674 0L.344 9.107A1.179 1.179 0 0 1 .31 7.478L7.427.346a1.182 1.182 0 1 1 1.672 1.668z"
        fill="#292929"
      />
    </svg>
  );
};
