import React from "react";

export const DeActivateIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g data-name="Group 166832" fill="none" stroke="#fff" strokeWidth="2">
        <g data-name="Ellipse 347">
          <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
          <circle cx="7.5" cy="7.5" r="6.5" />
        </g>
        <path data-name="Line 797" d="M3.5 12.5l8-10" />
      </g>
    </svg>
  );
};
