import React from "react";

export const DocumentsIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2317 26.5029L2.63672 26.5029C2.08443 26.5029 1.63672 26.0552 1.63672 25.5029V2.49219C1.63672 1.9399 2.08443 1.49219 2.63672 1.49219H14.6008C14.8678 1.49219 15.1237 1.59898 15.3115 1.78876L21.2182 7.75711C21.4035 7.94434 21.5075 8.19712 21.5075 8.46054V14.6073"
        stroke="#4C0055"
        strokeWidth="1.5"
      />
      <path
        d="M14.8242 21.6875C14.8242 18.719 17.2307 16.3125 20.1992 16.3125C23.1677 16.3125 25.5742 18.719 25.5742 21.6875C25.5742 24.656 23.1677 27.0625 20.1992 27.0625C17.2307 27.0625 14.8242 24.656 14.8242 21.6875Z"
        stroke="#4C0055"
        strokeWidth="1.5"
      />
      <path
        d="M19.6024 24.2015V23.1765L19.6024 22.267L18.8387 22.262H17.7734V21.1114H18.8387H19.6024L19.6024 20.2019V19.1719H20.7882V20.2019V21.1114H21.4917H22.6222V22.262H21.4917L20.7882 22.267V23.1765V24.2015H19.6024Z"
        fill="#4C0055"
      />
      <path
        d="M14.9531 1.49219V6.15167C14.9531 7.25624 15.8486 8.15166 16.9531 8.15166H21.475"
        stroke="#4C0055"
        strokeWidth="1.5"
      />
      <path d="M4.98047 10.6328H17.1238" stroke="#4C0055" strokeWidth="1.5" />
      <path d="M4.98047 15.8008H14.0742" stroke="#4C0055" strokeWidth="1.5" />
      <path d="M4.98047 20.9609H12.5859" stroke="#4C0055" strokeWidth="1.5" />
    </svg>
  );
};
