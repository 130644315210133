import React from "react";

export const PhoneIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.215 16.221"
      className={className}
    >
      <g id="noun_call_685377" transform="translate(-14.076 -996.43)">
        <path
          id="Path_754"
          data-name="Path 754"
          d="M17.557,996.517a3.089,3.089,0,0,0-1.283.775c-1.5,1.5-2.376,2.958-2.167,4.613s1.376,3.229,3.427,5.279l2.01,2.01c2.05,2.05,3.623,3.218,5.279,3.427s3.1-.683,4.6-2.179a3.036,3.036,0,0,0,.775-1.284,1.663,1.663,0,0,0-.327-1.683,18.345,18.345,0,0,0-1.441-1.271,9.119,9.119,0,0,0-1.489-1.029,1.737,1.737,0,0,0-1.731.23,11.1,11.1,0,0,0-1.526,1.441c-.291.128-.093.126-.387,0a7.13,7.13,0,0,1-1.066-.92l-1.429-1.441a7.543,7.543,0,0,1-.932-1.053c-.181-.268-.056-.32,0-.387a12.145,12.145,0,0,0,1.453-1.538,1.713,1.713,0,0,0,.218-1.719,9.125,9.125,0,0,0-1.029-1.489,18.443,18.443,0,0,0-1.259-1.441,1.692,1.692,0,0,0-1.7-.339Zm.593,1.429c.285.285.746.813,1.15,1.32a10.823,10.823,0,0,1,.86,1.211,7.374,7.374,0,0,1-.726.835c-.229.229-.466.429-.69.666a1.809,1.809,0,0,0-.533,1.005,1.912,1.912,0,0,0,.351,1.271,25.235,25.235,0,0,0,3.9,3.9,1.945,1.945,0,0,0,1.283.351,1.809,1.809,0,0,0,1-.533c.237-.224.425-.449.654-.678a6.511,6.511,0,0,1,.848-.726,10.812,10.812,0,0,1,1.211.86c.507.4,1.022.853,1.308,1.138a3.832,3.832,0,0,1-.436.787c-1.368,1.368-2.3,1.86-3.318,1.732s-2.415-1.023-4.383-2.991l-2.01-2c-1.968-1.968-2.85-3.362-2.979-4.383s.363-1.95,1.731-3.318A1.873,1.873,0,0,1,18.15,997.946Z"
          transform="translate(0 0)"
          fill="#4b124b"
        />
      </g>
    </svg>
  );
};
