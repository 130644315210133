import React from "react";

export const PlusIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 73 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="36.2677" cy="37.1771" r="36.2357" fill="#EEEEEE" />
      <path
        d="M36.2676 26.3054C35.9861 26.3054 35.7161 26.4173 35.517 26.6164C35.3179 26.8155 35.2061 27.0855 35.2061 27.367V46.9853C35.2061 47.2668 35.3179 47.5369 35.517 47.7359C35.7161 47.935 35.9861 48.0469 36.2676 48.0469C36.5492 48.0469 36.8192 47.935 37.0183 47.7359C37.2174 47.5369 37.3292 47.2668 37.3292 46.9853V27.367C37.3292 27.0855 37.2174 26.8155 37.0183 26.6164C36.8192 26.4173 36.5492 26.3054 36.2676 26.3054Z"
        fill="#929597"
      />
      <path
        d="M46.0768 36.1151H26.4586C26.177 36.1151 25.907 36.2269 25.7079 36.426C25.5088 36.6251 25.397 36.8951 25.397 37.1767C25.397 37.4582 25.5088 37.7283 25.7079 37.9273C25.907 38.1264 26.177 38.2383 26.4586 38.2383H46.0768C46.3584 38.2383 46.6284 38.1264 46.8275 37.9273C47.0266 37.7283 47.1384 37.4582 47.1384 37.1767C47.1384 36.8951 47.0266 36.6251 46.8275 36.426C46.6284 36.2269 46.3584 36.1151 46.0768 36.1151Z"
        fill="#929597"
      />
    </svg>
  );
};
