import React from "react";
import { LANGUAGE_CODE } from "../../config/i18n";
import { languageConst } from "../../utilities/constants/global.constants";

function LanguageSwitcher({ onChange, selectedLanguage, isMobile }) {
  return (
    <div className="flex flex-row">
      <div
        className={` ${
          selectedLanguage === 1
            ? "background-gradient-color text-white cursor-default "
            : "cursor-pointer"
        } ${
          isMobile ? "px-1 py-0.5 text-sm" : "px-2"
        }  border-t border-r border-b border-l rounded-l border-solid border-primary-color text-center xl:w-16 w-14 h-6  flex items-center justify-center`}
        onClick={() => onChange(LANGUAGE_CODE.ENGLISH)}
      >
        {languageConst.ENGLISH}
      </div>
      <div
        className={` ${
          selectedLanguage === 2
            ? "background-gradient-color text-white cursor-default"
            : "cursor-pointer"
        } ${
          isMobile ? "px-1 py-0.5" : "px-2"
        } border-t border-r border-b border-l rounded-r-md border-solid border-primary-color text-center xl:w-16 w-14 h-6 flex items-center justify-center`}
        onClick={() => onChange(LANGUAGE_CODE.ARABIC)}
      >
        {languageConst.ARABIC}
      </div>
    </div>
  );
}

export default LanguageSwitcher;
