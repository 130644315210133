import {
  SET_VEHICLE_LISTING_HISTORY,
  SET_QC_STATISTICS,
  SET_QC_ANALYST_WITH_DETAILS,
  GET_QC_ANALYST_VEHICLES,
} from "../types";

import { QCVehicleStatus } from "../../utilities/constants/global.constants";

const initialState = {
  vehicleHistory: [],
  qcStatistics: [
    {
      StatusCode: QCVehicleStatus.QC_ASSIGNED,
      Count: 0,
    },
    {
      StatusCode: QCVehicleStatus.QC_REJECTED,
      Count: 0,
    },
    {
      StatusCode: QCVehicleStatus.QC_UNASSIGNED,
      Count: 0,
    },
    {
      StatusCode: QCVehicleStatus.QC_PASSED,
      Count: 0,
    },
  ],
  qcAnalystDetails: [],
  toBeAssigneeObj: {},
  vehicleData: [],
};

export const qcDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VEHICLE_LISTING_HISTORY:
      return {
        ...state,
        vehicleHistory: action.payload,
      };
    case SET_QC_STATISTICS: {
      let qcStatistics = [];
      if (action.payload.length) {
        qcStatistics = initialState.qcStatistics.map(({ StatusCode }) => {
          const index = action.payload.findIndex(
            (x) => x.StatusCode === StatusCode
          );
          return {
            StatusCode,
            Count: index !== -1 ? action.payload[index].Count : 0,
          };
        });
      } else {
        qcStatistics.push(...initialState.qcStatistics);
      }
      return {
        ...state,
        qcStatistics: qcStatistics,
      };
    }
    case SET_QC_ANALYST_WITH_DETAILS:
      return {
        ...state,
        qcAnalystDetails: action.payload,
      };
    case GET_QC_ANALYST_VEHICLES:
      return {
        ...state,
        vehicleData: action.payload,
      };
    default:
      return state;
  }
};
