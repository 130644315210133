import React from "react";

export const ErrorIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#DE2A00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.62167 6.62155C6.91721 6.32602 7.39636 6.32602 7.69189 6.62155L12 10.9297L16.3082 6.62155C16.6037 6.32602 17.0828 6.32602 17.3784 6.62155C17.6739 6.91708 17.6739 7.39623 17.3784 7.69177L13.0702 11.9999L17.3784 16.308C17.6739 16.6036 17.6739 17.0827 17.3784 17.3783C17.0828 17.6738 16.6037 17.6738 16.3082 17.3783L12 13.0701L7.69189 17.3783C7.39636 17.6738 6.91721 17.6738 6.62167 17.3783C6.32614 17.0827 6.32614 16.6036 6.62167 16.308L10.9298 11.9999L6.62167 7.69177C6.32614 7.39623 6.32614 6.91708 6.62167 6.62155Z"
        fill="white"
      />
    </svg>
  );
};
