import React from "react";

export const PageNextArrowIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 9.453 16.532"
      id=".36230389704821575"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        data-name="Icon ionic-ios-arrow-forward"
        d="M6.604 8.263L.347 2.012a1.176 1.176 0 0 1 0-1.669 1.191 1.191 0 0 1 1.674 0l7.088 7.083a1.179 1.179 0 0 1 .034 1.629l-7.117 7.132a1.182 1.182 0 1 1-1.672-1.668z"
        fill="#292929"
      />
    </svg>
  );
};
