import React from "react";

export const AppointmentIcon = ({ className = "" }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_10564_359)">
        <path
          d="M23.7999 2.80002H20.9999V1.40001C20.9999 1.0287 20.8524 0.672605 20.5898 0.410053C20.3273 0.1475 19.9712 0 19.5999 0C19.2286 0 18.8725 0.1475 18.6099 0.410053C18.3474 0.672605 18.1999 1.0287 18.1999 1.40001V2.80002H9.79981V1.40001C9.79981 1.0287 9.65231 0.672605 9.38976 0.410053C9.12721 0.1475 8.77111 0 8.3998 0C8.0285 0 7.6724 0.1475 7.40985 0.410053C7.1473 0.672605 6.9998 1.0287 6.9998 1.40001V2.80002H4.19978C3.08586 2.80002 2.01757 3.24252 1.22991 4.03017C0.442257 4.81783 -0.000244141 5.88612 -0.000244141 7.00004V23.8001C-0.000244141 24.9141 0.442257 25.9823 1.22991 26.77C2.01757 27.5577 3.08586 28.0002 4.19978 28.0002H23.7999C24.9138 28.0002 25.9821 27.5577 26.7698 26.77C27.5574 25.9823 27.9999 24.9141 27.9999 23.8001V7.00004C27.9999 5.88612 27.5574 4.81783 26.7698 4.03017C25.9821 3.24252 24.9138 2.80002 23.7999 2.80002ZM25.1999 23.8001C25.1999 24.1714 25.0524 24.5275 24.7898 24.7901C24.5273 25.0526 24.1712 25.2001 23.7999 25.2001H4.19978C3.82847 25.2001 3.47238 25.0526 3.20982 24.7901C2.94727 24.5275 2.79977 24.1714 2.79977 23.8001V14.0001H25.1999V23.8001ZM25.1999 11.2001H2.79977V7.00004C2.79977 6.62873 2.94727 6.27264 3.20982 6.01008C3.47238 5.74753 3.82847 5.60003 4.19978 5.60003H6.9998V7.00004C6.9998 7.37135 7.1473 7.72744 7.40985 7.98999C7.6724 8.25255 8.0285 8.40005 8.3998 8.40005C8.77111 8.40005 9.12721 8.25255 9.38976 7.98999C9.65231 7.72744 9.79981 7.37135 9.79981 7.00004V5.60003H18.1999V7.00004C18.1999 7.37135 18.3474 7.72744 18.6099 7.98999C18.8725 8.25255 19.2286 8.40005 19.5999 8.40005C19.9712 8.40005 20.3273 8.25255 20.5898 7.98999C20.8524 7.72744 20.9999 7.37135 20.9999 7.00004V5.60003H23.7999C24.1712 5.60003 24.5273 5.74753 24.7898 6.01008C25.0524 6.27264 25.1999 6.62873 25.1999 7.00004V11.2001Z"
          fill="#4C0055"
        />
        <rect
          x="4.11304"
          y="15.2266"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
        <rect
          x="9.64429"
          y="15.2266"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
        <rect
          x="15.1755"
          y="15.2266"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
        <rect
          x="20.7063"
          y="15.2266"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
        <rect
          x="4.11304"
          y="20.7686"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
        <rect
          x="9.64429"
          y="20.7686"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
        <rect
          x="15.1755"
          y="20.7686"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
        <rect
          x="20.7063"
          y="20.7686"
          width="3.31885"
          height="3.31885"
          rx="1"
          fill="#4C0055"
        />
      </g>
      <defs>
        <clipPath id="clip0_10564_359">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
