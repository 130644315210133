import React from "react";

export const DownArrowIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="35.541"
        y="35.5391"
        width="35.082"
        height="35.082"
        rx="4"
        transform="rotate(180 35.541 35.5391)"
        fill="#929597"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97313 12.1658L10.1107 12.2846L18 20.1735L25.8752 12.2987C26.4081 11.7658 27.2411 11.7179 27.8287 12.1539L27.8448 12.1658L27.9824 12.2846L27.9965 12.2987C28.5293 12.8315 28.5772 13.6646 28.1412 14.2522L28.1293 14.2683L28.0105 14.4059L19.0606 23.3558C18.5278 23.8886 17.6947 23.9365 17.1071 23.5005L17.091 23.4886L16.9534 23.3698L8.00353 14.4199C7.41777 13.8341 7.41777 12.8844 8.00353 12.2987C8.53639 11.7658 9.36945 11.7179 9.95705 12.1539L9.97313 12.1658Z"
        fill="white"
      />
    </svg>
  );
};
