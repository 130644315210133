import React from "react";

export const TwitterIcon = ({ className = "" }) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      id="icon_twitter"
      className={className}
    >
      <path
        d="M24 0C10.746 0 0 10.746 0 24C0 37.254 10.746 48 24 48C37.254 48 48 37.254 48 24C48 10.746 37.254 0 24 0ZM36.66 16.2855C36.549 16.4715 35.979 17.124 35.37 17.7195C34.338 18.744 34.2825 18.855 34.2825 19.713C34.2825 21.0345 33.657 23.793 33.03 25.3005C31.869 28.1325 29.382 31.0575 26.8935 32.5305C23.3925 34.5975 18.7305 35.121 14.805 33.909C13.497 33.498 11.2485 32.4555 11.2485 32.2695C11.2485 32.2125 11.9295 32.139 12.7605 32.1195C14.493 32.0835 16.2255 31.6005 17.6985 30.741L18.693 30.144L17.55 29.754C15.9285 29.1945 14.4735 27.909 14.1045 26.6985C13.9935 26.307 14.031 26.2875 15.0615 26.2875L16.1295 26.2695L15.2265 25.8405C14.157 25.299 13.1805 24.3885 12.702 23.4555C12.3525 22.785 11.91 21.09 12.039 20.9595C12.0765 20.904 12.4635 21.0165 12.9045 21.165C14.175 21.6315 14.3415 21.519 13.605 20.736C12.2235 19.32 11.799 17.2155 12.462 15.222L12.7755 14.328L13.992 15.5385C16.4805 17.979 19.41 19.4325 22.7625 19.86L23.6835 19.971L23.628 19.0575C23.463 16.692 24.918 14.5305 27.222 13.692C28.0695 13.3935 29.508 13.3575 30.447 13.617C30.816 13.728 31.515 14.1015 32.013 14.436L32.916 15.051L33.9105 14.733C34.464 14.5665 35.2005 14.286 35.532 14.1C35.8455 13.932 36.1215 13.839 36.1215 13.8945C36.1215 14.211 35.439 15.2925 34.869 15.888C34.0935 16.7265 34.3155 16.8015 35.8815 16.242C36.825 15.9315 36.8445 15.9315 36.66 16.2855Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
