import React from "react";

export const StatusInActiveIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.402344"
        y="0.148438"
        width="22"
        height="22"
        rx="4"
        fill="#FFFFFF"
        stroke="#4C0055"
      />
    </svg>
  );
};
