import {
  CHECK_AUTH_DETAILS,
  CHECK_AUTH_DETAILS_SUCCESS,
  CHECK_AUTH_DETAILS_FAIL,
  FETCH_AUTH_TOKEN,
  FETCH_AUTH_TOKEN_SUCCESS,
  FETCH_AUTH_TOKEN_FAIL,
  FETCH_REFRESH_AUTH_TOKEN,
  FETCH_REFRESH_AUTH_TOKEN_FAIL,
  FETCH_REFRESH_AUTH_TOKEN_SUCCESS,
  USER_LOGOUT,
  GET_DEALER_INFORMATION,
  FETCH_AUTH_TOKEN_ERROR,
  GET_USER_ROLE,
  CHANGE_USER_ROLE,
  REFRESH_DASHBOARD_DATA,
  SET_ROLE_ID,
} from "../types";

const initialState = {
  appLoadingFailed: false,
  isAuthentic: false,
  token: "",
  refreshTokenAD: "",
  sendRequestToGetAuthToken: false,
  loginRequired: false,
  logoutUrl: "",
  userId: 0,
  firstName: "",
  lastName: "",
  emailAddress: "",
  mobileNumber: "",
  userStatusId: 0,
  userPrivileges: [],
  userRoles: [],
  dealerInformation: [],
  isError: false,
  availableRoles: [],
  selectedRole: null,
  refreshDashboardData: true,
  selectedRoleId: 0,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_AUTH_DETAILS:
      return {
        ...state,
        isAuthentic: false,
        loginRequired: false,
      };
    case CHECK_AUTH_DETAILS_SUCCESS:
      return {
        ...state,
        isAuthentic: true,
        loginRequired: false,
      };
    case CHECK_AUTH_DETAILS_FAIL:
      return {
        ...state,
        isAuthentic: false,
        loginRequired: true,
      };
    case FETCH_AUTH_TOKEN:
      return {
        ...state,
        sendRequestToGetAuthToken: true,
        loginRequired: false,
      };
    case FETCH_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthentic: true,
        loginRequired: false,
        sendRequestToGetAuthToken: false,
        token: action.payload.Token,
        refreshTokenAD: action.payload.RefreshTokenAD,
        logoutUrl: action.payload.LogoutUrl,
        userId: action.payload.UserId,
        firstName: action.payload.FirstName,
        lastName: action.payload.LastName,
        emailAddress: action.payload.EmailAddress,
        mobileNumber: action.payload.MobileNumber,
        userStatusId: action.payload.UserStatusId,
        userPrivileges: action.payload.UserPrivileges,
        userRoles: action.payload.UserRoles,
        selectedRole: action.payload.UserRoles[0],
        isError: false,
      };
    case FETCH_AUTH_TOKEN_FAIL:
      return {
        ...state,
        sendRequestToGetAuthToken: false,
        isAuthentic: false,
        loginRequired: true,
      };
    case REFRESH_DASHBOARD_DATA:
      return {
        ...state,
        refreshDashboardData: action.payload,
      };

    case GET_USER_ROLE:
      return {
        ...state,
        availableRoles: action.payload,
      };

    case CHANGE_USER_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };

    case FETCH_REFRESH_AUTH_TOKEN:
      return {
        ...state,
        loginRequired: false,
      };
    case FETCH_REFRESH_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthentic: true,
        loginRequired: false,
      };
    case FETCH_REFRESH_AUTH_TOKEN_FAIL:
      return {
        ...state,
        isAuthentic: false,
        loginRequired: true,
      };
    case USER_LOGOUT: {
      return {
        ...initialState,
        loginRequired: true,
      };
    }
    case GET_DEALER_INFORMATION: {
      return {
        ...state,
        dealerInformation: action.payload,
      };
    }
    case FETCH_AUTH_TOKEN_ERROR: {
      return {
        ...state,
        isError: true,
      };
    }
    case SET_ROLE_ID:
      return {
        ...state,
        selectedRoleId: action.payload,
      };
    default:
      return state;
  }
};
