import React from "react";

export const EditIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="28.439"
      viewBox="0 0 32 28.439"
      className={className}
    >
      <path
        id="Icon_awesome-edit"
        data-name="Icon awesome-edit"
        d="M22.367,4.624l5.011,5.011a.543.543,0,0,1,0,.767L15.244,22.535l-5.156.572a1.081,1.081,0,0,1-1.194-1.194l.572-5.156L21.6,4.624A.543.543,0,0,1,22.367,4.624Zm9-1.272L28.656.64a2.173,2.173,0,0,0-3.067,0L23.622,2.607a.543.543,0,0,0,0,.767l5.011,5.011a.543.543,0,0,0,.767,0l1.967-1.967a2.173,2.173,0,0,0,0-3.067ZM21.333,19.235V24.89H3.556V7.113H16.322a.683.683,0,0,0,.472-.194L19.017,4.7a.667.667,0,0,0-.472-1.139H2.667A2.667,2.667,0,0,0,0,6.224V25.779a2.667,2.667,0,0,0,2.667,2.667H22.222a2.667,2.667,0,0,0,2.667-2.667V17.013a.668.668,0,0,0-1.139-.472l-2.222,2.222A.683.683,0,0,0,21.333,19.235Z"
        transform="translate(0 -0.007)"
        fill="currentColor"
      />
    </svg>
  );
};
