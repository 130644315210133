import React from "react";

export const DownloadPDFIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.887 30.516"
      className={className}
    >
      <path
        id="Icon_awesome-file-download"
        d="M13.351,8.106V0H1.43A1.427,1.427,0,0,0,0,1.43V29.085a1.427,1.427,0,0,0,1.43,1.43H21.456a1.427,1.427,0,0,0,1.43-1.43V9.536H14.781A1.435,1.435,0,0,1,13.351,8.106Zm4.556,12.6-5.747,5.7a1.016,1.016,0,0,1-1.433,0L4.981,20.7a.953.953,0,0,1,.671-1.631H9.536V14.3a.953.953,0,0,1,.954-.954H12.4a.953.953,0,0,1,.954.954v4.768h3.885A.954.954,0,0,1,17.907,20.7ZM22.47,6.258,16.635.417A1.429,1.429,0,0,0,15.621,0h-.364V7.629h7.629V7.265A1.426,1.426,0,0,0,22.47,6.258Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
