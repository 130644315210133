import React from "react";

export const WarningColoredIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="15.4531" r="15" fill="#FF8300" />
      <path
        d="M14.219 18.2981L13.841 7.56112H16.883L16.577 18.2981H14.219ZM15.254 23.4011C14.792 23.4011 14.408 23.2421 14.102 22.9241C13.802 22.6061 13.649 22.2131 13.643 21.7451C13.637 21.4151 13.712 21.1121 13.868 20.8361C14.03 20.5601 14.246 20.3381 14.516 20.1701C14.786 20.0021 15.08 19.9181 15.398 19.9181C15.902 19.9181 16.295 20.0801 16.577 20.4041C16.859 20.7221 17.003 21.1091 17.009 21.5651C17.009 21.8951 16.931 22.2011 16.775 22.4831C16.619 22.7591 16.406 22.9811 16.136 23.1491C15.872 23.3171 15.578 23.4011 15.254 23.4011Z"
        fill="white"
      />
    </svg>
  );
};
