import {
  GET_HEADER_MENU,
  GET_HOMEPAGE_CITY_LIST,
  SET_CITY,
  SET_LANGUAGE,
  GET_SUMMARY_MENU,
  GET_CURRENCY,
  GET_SOCIAL_MEDIA_DATA,
  GET_ABOUT_US,
  GET_PRIVACY_POLICY,
  GET_TERMS_CONDITIONS,
  POST_FEEDBACK,
  GET_CITY_LIST_BY_STATE,
  GET_STATE_LIST,
  GET_COUNTRY_LIST,
  GET_LANGUAGES,
  GET_LOCATION_NAME,
  GET_LOCATION_NAME_ID,
  GET_LOCATION_TYPES,
  GET_ADDRESS_TYPES,
  GET_WORKING_SECTOR,
  GET_WORKING_SECTOR_ID_SUCCESS,
  CLEAR_WORKING_SECTOR_EDIT_DETAILS,
  GET_BANK_DETAILS,
  GET_BANK_DETAILS_BY_ID_SUCCESS,
  CLEAR_BANK_DETAIL,
  GET_ROLES,
  GET_LIST_HEADERS,
  GET_LIST_ACTIVE_HEADERS,
  GET_LIST_PRIVILEGES,
  GET_LIST_ACTIVE_PRIVILEGES,
  GET_PRIVILEGES,
  GET_PRODUCT_CATALOGUE,
  GET_PRODUCT_CATALOGUE_DATA,
} from "../types";

const initialState = {
  headerMenu: [],
  selectedLanguage: 1,
  selectedCity: "",
  cities: [],
  summaryMenu: [],
  currencyData: [],
  socialMediaData: [],
  aboutUs: [],
  privacypolicy: [],
  termsconditions: [],
  postFeedback: [],
  cityList: [],
  stateList: [],
  countryList: [],
  langauges: [],
  locationName: [],
  locationStatusById: null,
  addressTypes: [],
  locationTypes: [],
  workingSectorList: [],
  workingSectors: [],
  bankList: [],
  bankConfiguration: [],
  roles: [],
  listHeaders: [],
  listActiveHeaders: [],
  listPrivileges: [],
  listActivePrivileges: [],
  privileges: [],
  productCatalogue: [],
  productCataloguePayload: null,
};

export const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEADER_MENU:
      return {
        ...state,
        headerMenu: action.payload,
      };
    case GET_HOMEPAGE_CITY_LIST:
      return {
        ...state,
        cities: action.payload,
      };
    case SET_CITY:
      return {
        ...state,
        selectedCity: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    case GET_SUMMARY_MENU:
      return {
        ...state,
        summaryMenu: action.payload,
      };
    case GET_CURRENCY:
      return {
        ...state,
        currencyData: action.payload,
      };
    case GET_SOCIAL_MEDIA_DATA:
      return {
        ...state,
        socialMediaData: action.payload,
      };
    case GET_ABOUT_US:
      return {
        ...state,
        aboutUs: action.payload,
      };
    case GET_PRIVACY_POLICY:
      return {
        ...state,
        privacypolicy: action.payload,
      };
    case GET_TERMS_CONDITIONS:
      return {
        ...state,
        termsconditions: action.payload,
      };
    case POST_FEEDBACK:
      return {
        ...state,
        postFeedback: action.payload,
      };
    case GET_CITY_LIST_BY_STATE:
      return {
        ...state,
        cityList: action.payload,
      };
    case GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case GET_STATE_LIST:
      return {
        ...state,
        stateList: action.payload,
      };
    case GET_LANGUAGES:
      return {
        ...state,
        langauges: action.payload,
      };
    case GET_LOCATION_NAME:
      return {
        ...state,
        locationName: action.payload,
      };
    case GET_LOCATION_NAME_ID:
      return {
        ...state,
        locationStatusById: action.payload,
      };
    case GET_ADDRESS_TYPES:
      return {
        ...state,
        addressTypes: action.payload,
      };
    case GET_LOCATION_TYPES:
      return {
        ...state,
        locationTypes: action.payload,
      };
    case GET_WORKING_SECTOR:
      return {
        ...state,
        workingSectorList: action.payload,
      };
    case GET_WORKING_SECTOR_ID_SUCCESS:
      return {
        ...state,
        workingSectors: action.payload,
      };
    case CLEAR_WORKING_SECTOR_EDIT_DETAILS:
      return {
        ...state,
        workingSectorList: [],
        workingSectors: [],
      };
    case GET_BANK_DETAILS:
      return {
        ...state,
        bankList: action.payload,
      };
    case GET_BANK_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        bankConfiguration: action.payload,
      };
    case CLEAR_BANK_DETAIL:
      return {
        ...state,
        bankList: [],
        bankConfiguration: [],
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_LIST_HEADERS:
      return {
        ...state,
        listHeaders: action.payload,
      };
    case GET_LIST_ACTIVE_HEADERS:
      return {
        ...state,
        listActiveHeaders: action.payload,
      };
    case GET_LIST_PRIVILEGES:
      return {
        ...state,
        listPrivileges: action.payload,
      };
    case GET_LIST_ACTIVE_PRIVILEGES:
      return {
        ...state,
        listActivePrivileges: action.payload,
      };
    case GET_PRIVILEGES:
      return {
        ...state,
        privileges: action.payload,
      };
    case GET_PRODUCT_CATALOGUE:
      return {
        ...state,
        productCatalogue: action.payload,
      };
    case GET_PRODUCT_CATALOGUE_DATA:
      return {
        ...state,
        productCataloguePayload: {
          ...state.productCataloguePayload,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
