import React from "react";

export const PetrolIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 13.717"
      className={className}
    >
      <path
        id="Path_21"
        d="M35.393,102.755H45.533v-.862H35.393Zm8.277-11.268v3.953H37.257V91.486Zm-.923.922H38.179v2.108h4.568Zm1.668-1.526h-7.9V100.97h7.9Zm.923,2.054a1.39,1.39,0,0,1,1.836,1.306c-.029,1.509,0,3.024,0,4.534,0,1.206,2.663.663,2.34-.344-.193-.6-.928-3.2-1.081-3.62H48.2V93.459l-1.648-1.648.978-.978,2.053,2.053v1.926h-.213c.165.552.8,2.667,1.015,3.339.76,2.366-4.138,3.26-4.138.625,0-1.514-.027-3.036,0-4.548.009-.477-.627-.505-.917-.3v7.044h1.119v2.707H34.47V100.97h1.119V89.96h9.749v2.976Z"
        transform="translate(-34.47 -89.96)"
        fill="#7d8593"
      ></path>
    </svg>
  );
};
