import React from "react";

export const ViewIcon = ({ className = "" }) => {
  return (
    <svg
      id="Group_166908"
      data-name="Group 166908"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="20.672"
      viewBox="0 0 32 20.672"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1667"
            data-name="Rectangle 1667"
            width="32"
            height="20.672"
            fill="currentColor"
          />
        </clipPath>
      </defs>
      <g id="Group_166907" data-name="Group 166907" clipPath="url(#clip-path)">
        <path
          id="Path_13701"
          data-name="Path 13701"
          d="M61.219,27.468a4.929,4.929,0,1,0,4.906,4.944,4.943,4.943,0,0,0-4.906-4.944"
          transform="translate(-45.191 -22.06)"
          fill="currentColor"
        />
        <path
          id="Path_13702"
          data-name="Path 13702"
          d="M31.144,8.538c-.382-.412-.744-.843-1.132-1.249a26.353,26.353,0,0,0-7.5-5.672A14.512,14.512,0,0,0,15.541.009,15.263,15.263,0,0,0,8.893,1.925,28,28,0,0,0,.5,8.991a2.1,2.1,0,0,0,.08,2.8,32.472,32.472,0,0,0,2.771,2.92,24.146,24.146,0,0,0,7.414,4.916,13.762,13.762,0,0,0,6.442.991,15.6,15.6,0,0,0,6.286-2.059,28.73,28.73,0,0,0,7.223-5.94A4.684,4.684,0,0,0,32,10.709v-.687a3.193,3.193,0,0,0-.855-1.483m-15.15,8.689a6.889,6.889,0,1,1,6.9-6.873,6.888,6.888,0,0,1-6.9,6.873"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
