import React from "react";

export const CalenderIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.492 15"
      className={className}
    >
      <path
        id="Path_22"
        d="M29.824,35H40.667a1.826,1.826,0,0,0,1.824-1.811V22.917a1.82,1.82,0,0,0-1.824-1.805h-1.29V20.54a.54.54,0,0,0-1.081,0v.572h-6.1V20.54a.54.54,0,0,0-1.081,0v.572h-1.29A1.82,1.82,0,0,0,28,22.917V33.189A1.826,1.826,0,0,0,29.824,35Zm10.843-1.081H29.824a.742.742,0,0,1-.744-.731V25.5H41.411v7.691A.742.742,0,0,1,40.667,33.919ZM29.824,22.193h1.29v.559a.54.54,0,1,0,1.081,0v-.559h6.1v.559a.54.54,0,1,0,1.081,0v-.559h1.29a.737.737,0,0,1,.744.725v1.5H29.081v-1.5A.737.737,0,0,1,29.824,22.193Z"
        transform="translate(-28 -20)"
        fill="#7d8593"
      ></path>
      <path
        id="Path_23"
        d="M144.3,351H137.84a.54.54,0,1,0,0,1.081H144.3a.54.54,0,0,0,0-1.081Z"
        transform="translate(-133.826 -340.481)"
        fill="#7d8593"
      ></path>
      <path
        id="Path_24"
        d="M144.3,266H137.84a.54.54,0,1,0,0,1.081H144.3a.54.54,0,0,0,0-1.081Z"
        transform="translate(-133.826 -258.182)"
        fill="#7d8593"
      ></path>
    </svg>
  );
};
