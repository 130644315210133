import moment from "moment";

// Add all the helper methods/common methods here

export const EmailRegEx = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

// apply className depending on km
export const setKmColor = (km) => {
  let kmNumber = parseInt(km);
  if (kmNumber >= 0 && kmNumber < 30000) {
    return "km-color-normal";
  } else if (kmNumber >= 30000 && kmNumber < 60000) {
    return "km-color-warning";
  } else {
    return "km-color-danger";
  }
};
export const checkNonEmpty = RegExp(/^(?!\s*$).+/);
export const allowOnlyNineDigits = RegExp("^\\d{9}$");
export const onlyNumbers = RegExp(/^[0-9\b]+$/);
export const allowOnlyThirtyChars = RegExp(/^.{0,30}$/);
export const allowOnlyThirtyLetters = RegExp(/^[aA-zZ]{0,30}$/);
export const allowOnlyFiveDigits = RegExp(/^[0-9]{0,5}$/);
export const allowOnlyThirtyLettersWithSpaces = RegExp(/^[a-zA-Z ]{0,30}$/);
export const allowOnlyHundredChars = RegExp(/^.{0,100}$/);
export const allowOnly250Chars = RegExp(/^.{0,250}$/);
export const allowOnly250LettersWithSpaces = RegExp(/^[a-zA-Z ]{0,250}$/);
export const allowOnly500Chars = RegExp(/^.{0,500}$/);
export const allowUptoFourDigits = RegExp("^\\d{0,4}$");
export const allowUptoThreeDigits = RegExp("^\\d{0,3}$");
export const allowUptoTwoDigits = RegExp("^\\d{0,2}$");
export const allowUptoSixDigits = RegExp("^\\d{0,6}$");

// dealer form constants
export const dealerFormFields = {
  DealerName: "dealerName",
  OwnerName: "ownerName",
  PrimaryContactFirstName: "primaryContactFirstName",
  PrimaryContactLastName: "primaryContactLastName",
  DealerAddress: "dealerAddress",
  Address1: "address1",
  Address2: "address2",
  Address3: "address3",
  Pincode: "pincode",
  PrimaryEmail: "primaryEmail",
  SecondaryEmail: "secondaryEmail",
  RegistrationId: "registrationId",
};

export const convertUTCToLocal = (date) => {
  const local = moment.utc(date).local().format("");
  return local;
};

export const encodeB64 = (params) => {
  const encode = Buffer.from(`${params}`).toString("base64");
  return encode;
};

export const decodeB64 = (params) => {
  const decode = Buffer.from(`${params}`, "base64").toString("ascii");
  return decode;
};
