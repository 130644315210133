import {
  GET_CITY_LIST_BY_STATE,
  GET_MAKE_LIST,
  GET_STATE_LIST,
  GET_DISTRICT_LIST,
} from "../types";

const initialState = {
  cityList: [],
  makeList: [],
  stateList: [],
  districtList: [],
};

export const dealerApplicationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITY_LIST_BY_STATE:
      return {
        ...state,
        cityList: action.payload,
      };
    case GET_MAKE_LIST:
      return {
        ...state,
        makeList: action.payload,
      };
    case GET_STATE_LIST:
      return {
        ...state,
        stateList: action.payload,
      };
    case GET_DISTRICT_LIST:
      return {
        ...state,
        districtList: action.payload,
      };
    default:
      return state;
  }
};
