import React from "react";

export const LinkedInIcon = ({ className = "" }) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 48"
      id="icon_linkedin"
      className={className}
    >
      <path
        d="M24.334 0C11.08 0 0.333984 10.7445 0.333984 24C0.333984 37.254 11.08 48 24.334 48C37.588 48 48.334 37.254 48.334 24C48.334 10.7445 37.588 0 24.334 0ZM18.1 33.4155H13.204V18.6855H18.1V33.4155ZM15.652 16.674H15.6205C13.9765 16.674 12.9145 15.5415 12.9145 14.1285C12.9145 12.684 14.0095 11.5845 15.6835 11.5845C17.3575 11.5845 18.3895 12.684 18.421 14.1285C18.421 15.543 17.3575 16.674 15.652 16.674ZM35.7535 33.4155H30.8575V25.5345C30.8575 23.5545 30.1495 22.2045 28.3765 22.2045C27.025 22.2045 26.2195 23.115 25.8655 23.9955C25.7365 24.3105 25.7035 24.75 25.7035 25.1895V33.4155H20.809C20.809 33.4155 20.8735 20.0685 20.809 18.6855H25.705V20.772C26.3545 19.7685 27.5185 18.339 30.118 18.339C33.3385 18.339 35.7535 20.445 35.7535 24.969V33.4155V33.4155Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
