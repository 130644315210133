import React from "react";

export const CancelDocumentIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M28.1597 20.6127L25.333 23.4527L22.5063 20.6127L20.613 22.506L23.453 25.3327L20.613 28.1593L22.5063 30.0527L25.333 27.2127L28.1597 30.0527L30.053 28.1593L27.213 25.3327L30.053 22.506M7.99967 2.66602C6.51967 2.66602 5.33301 3.85268 5.33301 5.33268V26.666C5.33301 28.146 6.51967 29.3327 7.99967 29.3327H18.413C17.7063 28.1193 17.333 26.7327 17.333 25.3327C17.333 24.8927 17.373 24.4393 17.4397 23.9993H7.99967V21.3327H18.413C19.0263 20.266 19.8797 19.3327 20.9063 18.666H7.99967V15.9993H23.9997V17.4393C24.4397 17.3727 24.893 17.3327 25.333 17.3327C25.7863 17.3327 26.2263 17.3727 26.6663 17.4393V10.666L18.6663 2.66602M17.333 4.66602L24.6663 11.9993H17.333V4.66602Z"
        fill="#4C0055"
      />
    </svg>
  );
};
