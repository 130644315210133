import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { NavLink, useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getHeaderMenu } from "../../redux/actions/globalAction";
import {
  getUserRole,
  handleChangeUserRole,
  logOut,
} from "../../redux/actions/authAction";
import {
  menuHeaderIds,
  GridPageSize,
  languages,
} from "../../utilities/constants/global.constants";
import LanguageSwitcher from "./LanguageSwitcher";
import { Menu, Transition } from "@headlessui/react";
import * as moment from "moment";
import "moment/locale/ar";
import { convertUTCToLocal } from "../../utilities/helpers/validation.helpers";
import "../../assets/styles/views/_mobile-header.scss";
import Logo from "../../assets/images/gogo-motors.png";
import { ApplicationRoles } from "../../utilities/constants/role.constants";
import {
  BellLogoIcon,
  // CartHeaderIcon,
  CloseBlackIcon,
  CloseTransparentIcon,
  MobileCallIcon,
  MobileMailIcon,
  ProfileChangeRequestIcon,
  ProfileIcon,
} from "../gogo-icons";
import {
  getNotificationsList,
  getNotificationsUnreadCount,
} from "../../redux/actions/notificationAction";

const MenuNavType = {
  Language: "Language",
  Search: "Search",
  Notification: "Notification",
  Menus: "Menus",
  Location: "Location",
};

function MobileHeader({ onLanguageChange }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [navigationType, setNavigationType] = useState(undefined);
  const [pageNumber] = useState(1);
  const [isRead] = useState(0);
  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );
  const isAuthentic = useSelector((state) => state.authReducer.isAuthentic);
  const { availableRoles, selectedRole } = useSelector(
    (state) => state.authReducer
  );

  const userRoles = useSelector((state) => state.authReducer.userRoles);
  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationList
  );
  const notificationUnreadCount = useSelector(
    (state) => state.notificationReducer.notificationUnreadCount
  );
  const headerMenu = useSelector((state) => state.globalReducer.headerMenu);
  const userDetails = useSelector(
    (state) => state.userManagementReducer.selfProfileDetails
  );

  useEffect(() => {
    selectedLanguage &&
      isAuthentic &&
      dispatch(getHeaderMenu(selectedLanguage));
  }, [dispatch, selectedLanguage, isAuthentic]);

  useEffect(() => {
    if (isAuthentic) {
      dispatch(getUserRole(selectedLanguage));
    }
  }, [dispatch, selectedLanguage, isAuthentic]);

  useEffect(() => {
    moment.locale(selectedLanguage === languages.eng ? "en" : "ar");
    dispatch(
      getNotificationsList(pageNumber, GridPageSize, isRead, selectedLanguage)
    );
    dispatch(getNotificationsUnreadCount());
  }, [dispatch, isRead, pageNumber, isAuthentic, selectedLanguage]);

  useEffect(() => {
    if (openMenu) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [openMenu, history]);

  const toggleMenu = (navType) => {
    setOpenMenu(!openMenu);
    setNavigationType(navType);
  };

  const gotoNotifications = () => {
    if (isAuthentic) {
      history.push("/notifications");
    }
  };

  const logOutUser = () => {
    dispatch(logOut());
  };

  return (
    <>
      <header
        className={`po-mobile-header bg-gray-100 ${
          openMenu && navigationType === MenuNavType.Menus && "h-screen"
        }`}
      >
        {(!openMenu || (openMenu && navigationType !== MenuNavType.Menus)) && (
          <div
            className={`w-full flex justify-between align-middle px-2 py-3 bg-white m-logo-wrapper  ${
              selectedLanguage === languages.eng
                ? "flex-row"
                : "flex-row-reverse"
            }`}
          >
            <div className="flex items-center">
              <img
                src={Logo}
                alt="logo"
                className="m-logo-mobile"
                onClick={() => history.push("/")}
              />
            </div>
            <div
              className={`flex justify-between items-center gap-4 ${
                selectedLanguage === languages.eng
                  ? "flex-row"
                  : "flex-row-reverse"
              }`}
            >
              {isAuthentic && (
                <>
                  <div className="language-switcher">
                    <LanguageSwitcher
                      isMobile={true}
                      selectedLanguage={selectedLanguage}
                      onChange={(lang) => {
                        onLanguageChange(lang);
                      }}
                    />
                  </div>
                  {/* {userRoles[0] === ApplicationRoles.DMS_ADMIN && (
                    <div>
                      <Link to="/cart">
                        <CartHeaderIcon className="w-6 h-6 p-1 mr-0 no-r-b" />
                      </Link>
                    </div>
                  )} */}
                  <div className="relative inline-block text-left m-notification-holder">
                    <Menu>
                      {({ open }) => (
                        <>
                          <span className="rounded-md shadow-sm flex">
                            <Menu.Button className="">
                              <BellLogoIcon className="w-6 h-6 p-1 mr-0 no-r-b" />

                              {notificationUnreadCount &&
                                notificationUnreadCount.data > 0 && (
                                  <span
                                    className={`${
                                      notificationUnreadCount.data > 9
                                        ? "px-1"
                                        : "px-2"
                                    } absolute bottom-2  left-3 inline-flex items-center justify-center py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full`}
                                  >
                                    {notificationUnreadCount.data > 9
                                      ? "9+"
                                      : notificationUnreadCount.data}
                                  </span>
                                )}
                            </Menu.Button>
                          </span>
                          <Transition show={open}>
                            <Menu.Items
                              static
                              className={`absolute ${
                                selectedLanguage === languages.ar
                                  ? "left-0"
                                  : "right-0"
                              }  w-auto mt-2 origin-top-right bg-white border border-gray-200 rounded shadow-lg outline-none`}
                            >
                              <div className="flex flex-row justify-between px-2 py-3">
                                <div className="heading-left flex items-center">
                                  <h6 className="heading-title uppercase text-sm font-sans">
                                    {t("HEADER_NOTIFICATIONS")}
                                  </h6>
                                </div>
                                <div className="heading-right flex items-center">
                                  <div
                                    className="notification-link cursor-pointer text-red-500 text-xs"
                                    onClick={() => gotoNotifications()}
                                  >
                                    {t("SEE_ALL")}
                                  </div>
                                </div>
                              </div>

                              <div className="py-1">
                                {notificationData &&
                                notificationData.DataList &&
                                notificationData.DataList.length > 0 ? (
                                  notificationData.DataList.slice(0, 5).map(
                                    (list) => (
                                      <Menu.Item key={list.AlertNotificationId}>
                                        {({ active }) => (
                                          <div
                                            className={`${
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700"
                                            } flex justify-between w-full  text-sm leading-5 text-left`}
                                          >
                                            <div>
                                              <div
                                                className="px-2 py-3 cursor-pointer"
                                                onClick={() =>
                                                  gotoNotifications()
                                                }
                                              >
                                                <span
                                                  className="name"
                                                  dangerouslySetInnerHTML={{
                                                    __html: list.AlertText,
                                                  }}
                                                />
                                                <span className="block">
                                                  {moment(
                                                    convertUTCToLocal(
                                                      list.CreatedDate
                                                    )
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Menu.Item>
                                    )
                                  )
                                ) : (
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          className={`${
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700"
                                          } flex justify-between w-full  text-sm leading-5 text-left`}
                                        >
                                          <div>
                                            <div className="notification-item ml-2 ">
                                              <span className="name font-bold">
                                                {t("HEADER_NO_NOTIFICATIONS")}
                                              </span>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Menu.Item>
                                  </div>
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </>
              )}
              <button
                type="button"
                className="sm:inline-flex lg:hidden items-center justify-center text-black  "
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => toggleMenu(MenuNavType.Menus)}
              >
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
        {openMenu && navigationType === MenuNavType.Menus && (
          <div
            className="nav-wrapper w-full top-0 left-0 fixed z-30 -mt-2"
            style={{ height: `${window.innerHeight + 10}px` }}
          >
            <div className="relative bg-gray-100 h-full flex flex-col justify-between z-20">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <div className="p-4 flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <a href="/" className="flex items-center">
                        <img src={Logo} alt="logo" className="m-logo-mobile" />
                      </a>
                    </div>
                    <div onClick={toggleMenu}>
                      <CloseBlackIcon className="h-4 w-4" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-div h-full overflow-auto">
                <div className="flex flex-col h-full">
                  <div>
                    {isAuthentic && (
                      <div>
                        {headerMenu &&
                          headerMenu.data &&
                          headerMenu.data.length > 0 &&
                          headerMenu.data.map((menu, index) => (
                            <nav
                              className={`mobile-link-holder bg-white flex ${
                                selectedLanguage === languages.eng
                                  ? "justify-start"
                                  : "justify-end"
                              }`}
                              key={index}
                            >
                              {menu.URL.toLowerCase() ===
                                menuHeaderIds.NewCars ||
                              menu.URL.toLowerCase() ===
                                menuHeaderIds.SellItForMe ||
                              menu.URL.toLowerCase() ===
                                menuHeaderIds.Services ? (
                                <a
                                  key={menu.MenuId}
                                  href={menu.URL}
                                  target={
                                    menu.IsNewTab === false ? "_self" : "_blank"
                                  }
                                  rel="noopener noreferrer"
                                  className={
                                    "mr-2 hover:text-gray-900 text-sm text-black font-bold"
                                  }
                                  onClick={toggleMenu}
                                >
                                  {menu.Menu}
                                </a>
                              ) : (
                                <NavLink
                                  key={menu.MenuId}
                                  to={menu.URL}
                                  exact={true}
                                  className="mr-2 hover:text-gray-900 text-sm text-black"
                                  activeClassName="is-active"
                                  onClick={toggleMenu}
                                >
                                  {menu.Menu}
                                </NavLink>
                              )}
                            </nav>
                          ))}
                      </div>
                    )}
                    <div
                      className={`bg-gray-100 text-black font-bold flex ${
                        selectedLanguage === languages.eng
                          ? "justify-start"
                          : "justify-end"
                      }`}
                    >
                      <div className="p-3">
                        {availableRoles && availableRoles.length > 1 && (
                          <select
                            className="border rounded-sm px-1 py-0.5 text-sm h-8"
                            value={selectedRole}
                            onChange={(e) => {
                              const data = {
                                roleKey: e.target.value,
                                selectedLanguage,
                              };
                              dispatch(handleChangeUserRole(data));
                              setOpenMenu(!openMenu);
                            }}
                          >
                            {availableRoles &&
                              availableRoles.length > 0 &&
                              availableRoles.map((x) => (
                                <option key={x.RoleKey} value={x.RoleKey}>
                                  {x.Role}
                                </option>
                              ))}
                          </select>
                        )}
                        <div
                          className={`flex items-center mt-4 gap-4 ${
                            selectedLanguage === languages.eng
                              ? "flex-row"
                              : "flex-row-reverse"
                          }`}
                        >
                          <MobileCallIcon className="h-4 w-4" />
                          <a>{userDetails?.MobileNumber}</a>
                        </div>
                        <div
                          className={`flex items-center mt-5 gap-4 ${
                            selectedLanguage === languages.eng
                              ? "flex-row"
                              : "flex-row-reverse"
                          }`}
                        >
                          <MobileMailIcon className="h-4 w-4" />
                          <a>{t(userDetails?.EmailAddress)}</a>
                        </div>
                        <div
                          className={`flex items-center mt-5 gap-4 ${
                            selectedLanguage === languages.eng
                              ? "flex-row"
                              : "flex-row-reverse"
                          }`}
                        >
                          <svg className="h-4 w-4">
                            <use xlinkHref="#mobile-feedback"></use>
                          </svg>
                          <a href="/feedback">{t("HEADER_FEEDBACK")}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex h-full w-full ">
                    {isAuthentic && (
                      <div
                        className={`p-3 text-black font-bold bg-white pb-6 w-full ${
                          selectedLanguage === languages.ar && "direction-rtl"
                        }`}
                      >
                        <div className="grid grid-cols-2 gap-4">
                          <div className="flex items-center">
                            <ProfileIcon className="h-4 w-4" />
                            {userRoles[0] === ApplicationRoles.PETROMIN_ADMIN ||
                            userRoles[0] === ApplicationRoles.QC_ADMIN ||
                            userRoles[0] === ApplicationRoles.QC_ANALYST ||
                            userRoles[0] === ApplicationRoles.INSPECTOR ? (
                              <Link
                                to="/view-profile"
                                className={`hover:text-red-500 ${
                                  selectedLanguage === languages.eng
                                    ? "ml-4 "
                                    : "mr-4"
                                } `}
                                onClick={toggleMenu}
                              >
                                {t("PROFILE")}
                              </Link>
                            ) : (
                              <Link
                                to="/profile"
                                className={`hover:text-red-500 ${
                                  selectedLanguage === languages.eng
                                    ? "ml-4 "
                                    : "mr-4"
                                } `}
                                onClick={toggleMenu}
                              >
                                {t("PROFILE")}
                              </Link>
                            )}
                          </div>
                          {(userRoles[0] === ApplicationRoles.PETROMIN_ADMIN ||
                            userRoles[0] === ApplicationRoles.DMS_ADMIN) && (
                            <div className="flex items-center">
                              <ProfileChangeRequestIcon className="h-4 w-4" />
                              <Link
                                to="/profile-change-request"
                                className={`ml-4 hover:text-red-500 ${
                                  selectedLanguage === languages.eng
                                    ? "ml-4 "
                                    : "mr-4"
                                } `}
                                onClick={toggleMenu}
                              >
                                {t("PROFILE_CHANGE_REQ")}
                              </Link>
                            </div>
                          )}
                        </div>
                        <div className="flex items-center justify-center mt-4">
                          <button
                            className="logout-button"
                            onClick={() => logOutUser()}
                          >
                            {t("LOGOUT")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {openMenu && navigationType === MenuNavType.Search && (
          <div className="nav-wrapper header-overlay w-full">
            <div className="flex items-center" onClick={toggleMenu}>
              <div className="h-6 w-6">
                <CloseTransparentIcon className="h-7 w-7 cursor-pointer" />
              </div>
            </div>
            <div className="flex justify-center mt-12"></div>
          </div>
        )}
      </header>
    </>
  );
}
export default connect((state) => state, {})(MobileHeader);
