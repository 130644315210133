import React from "react";

export const MobileCallIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.31 11.31"
      className={className}
    >
      <path
        id="Icon_ionic-ios-call"
        d="M15.474,13.358a9.5,9.5,0,0,0-1.984-1.328c-.595-.286-.813-.28-1.234.024-.35.253-.577.489-.98.4A5.849,5.849,0,0,1,9.305,11a5.807,5.807,0,0,1-1.457-1.97c-.085-.406.15-.63.4-.98.3-.421.312-.639.024-1.234A9.312,9.312,0,0,0,6.944,4.828c-.433-.433-.53-.339-.768-.253a4.373,4.373,0,0,0-.7.374,2.123,2.123,0,0,0-.845.892c-.168.362-.362,1.036.627,2.8A15.608,15.608,0,0,0,8,12.3H8l0,0,0,0H8a15.669,15.669,0,0,0,3.66,2.744c1.761.989,2.435.795,2.8.627a2.087,2.087,0,0,0,.892-.845,4.373,4.373,0,0,0,.374-.7C15.812,13.888,15.909,13.791,15.474,13.358Z"
        transform="translate(-4.49 -4.502)"
      ></path>
    </svg>
  );
};
