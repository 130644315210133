import React from "react";

export const CommentsIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10.4C3 8.16 3 7.04 3.436 6.184C3.81949 5.43139 4.43139 4.81949 5.184 4.436C6.04 4 7.16 4 9.4 4H14.6C16.84 4 17.96 4 18.816 4.436C19.5686 4.81949 20.1805 5.43139 20.564 6.184C21 7.04 21 8.16 21 10.4V11.6C21 13.84 21 14.96 20.564 15.816C20.1805 16.5686 19.5686 17.1805 18.816 17.564C17.96 18 16.84 18 14.6 18H7.414C7.14881 18.0001 6.89449 18.1054 6.707 18.293L4.707 20.293C4.077 20.923 3 20.477 3 19.586V13V10.4ZM9 8C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10H15C15.2652 10 15.5196 9.89464 15.7071 9.70711C15.8946 9.51957 16 9.26522 16 9C16 8.73478 15.8946 8.48043 15.7071 8.29289C15.5196 8.10536 15.2652 8 15 8H9ZM9 12C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H12C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13C13 12.7348 12.8946 12.4804 12.7071 12.2929C12.5196 12.1054 12.2652 12 12 12H9Z"
        fill="#4C0055"
      />
    </svg>
  );
};
