import React from "react";

export const DMGImageIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="23.5" fill="white" stroke="#E0E0E0" />
      <path
        d="M23.4029 23.274C23.4029 28.4112 23.4002 33.5484 23.4101 38.6856C23.4101 38.9756 23.3508 39.0636 23.0482 39.0528C22.3327 39.0268 21.6154 39.025 20.8998 39.0528C20.5721 39.0663 20.5173 38.9487 20.5245 38.6587C20.5434 37.9315 20.5182 37.2034 20.5371 36.477C20.5434 36.2256 20.4796 36.1655 20.2274 36.1664C17.8994 36.1772 15.5714 36.1664 13.2433 36.1754C12.219 36.179 11.0895 35.4284 10.6945 34.3887C10.5365 33.9731 10.5024 33.5637 10.5024 33.1345C10.5042 26.4082 10.4889 19.6818 10.5185 12.9555C10.5239 11.8351 11.34 10.8331 12.5395 10.5162C12.9641 10.404 13.4014 10.3725 13.8449 10.3743C15.9718 10.3824 18.0978 10.3743 20.2247 10.3842C20.4725 10.3851 20.5434 10.3321 20.5371 10.0763C20.5173 9.32659 20.5443 8.57603 20.5227 7.82726C20.5147 7.54805 20.6098 7.49598 20.863 7.50136C21.601 7.51663 22.3408 7.51932 23.0788 7.50046C23.3436 7.49328 23.4083 7.5678 23.4083 7.83086C23.4002 12.9789 23.402 18.1278 23.402 23.2757L23.4029 23.274ZM13.3672 31.8533C13.4696 31.8659 13.501 31.8722 13.5324 31.8722C15.7805 31.8722 18.0295 31.8677 20.2776 31.8794C20.5281 31.8803 20.5344 31.7779 20.5344 31.5876C20.5299 28.9256 20.5317 26.2636 20.529 23.6017C20.529 23.5235 20.5757 23.4257 20.4563 23.3449C18.1014 26.1712 15.7482 28.9956 13.3681 31.8525L13.3672 31.8533Z"
        fill="#FE5000"
      />
      <path
        d="M26.2817 23.3308C28.6582 26.1822 31.0015 28.9932 33.36 31.8231C33.448 31.7019 33.4183 31.6005 33.4183 31.508C33.4201 25.5304 33.4183 19.5528 33.4255 13.5753C33.4255 13.3176 33.3681 13.2431 33.1005 13.244C30.9404 13.2557 28.7794 13.244 26.6193 13.2593C26.3104 13.2619 26.2512 13.1704 26.2584 12.8858C26.2781 12.1361 26.2727 11.3855 26.2602 10.6359C26.2566 10.4357 26.3006 10.3728 26.5142 10.3737C28.7534 10.3827 30.9925 10.3432 33.2307 10.3926C34.6474 10.424 35.6359 10.977 36.1028 12.3615C36.2249 12.7251 36.2895 13.103 36.2895 13.4855C36.2931 20.0116 36.2967 26.5378 36.2868 33.063C36.285 34.012 35.9986 34.8729 35.2391 35.5059C34.7632 35.9018 34.2093 36.1631 33.5871 36.1676C31.2475 36.1837 28.9078 36.1739 26.5681 36.1748C26.4029 36.1748 26.2575 36.2188 26.2584 35.9368C26.2691 31.7836 26.2665 27.6313 26.2665 23.478C26.2665 23.4448 26.2727 23.4116 26.2817 23.3299V23.3308Z"
        fill="#FE5000"
      />
    </svg>
  );
};
