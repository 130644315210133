import React from "react";

export const UnverifiedIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="10.9855" cy="11.5" rx="10.4825" ry="11.5" fill="#FF8300" />
      <path
        d="M10.5425 14.473L10.2635 3.358H12.5945L12.3605 14.473H10.5425ZM11.3075 19.198C10.9415 19.198 10.6295 19.066 10.3715 18.802C10.1135 18.532 9.97854 18.199 9.96654 17.803C9.96054 17.509 10.0265 17.242 10.1645 17.002C10.3025 16.762 10.4855 16.57 10.7135 16.426C10.9475 16.282 11.1905 16.21 11.4425 16.21C11.8805 16.21 12.2135 16.348 12.4415 16.624C12.6695 16.9 12.7865 17.224 12.7925 17.596C12.7985 17.896 12.7325 18.169 12.5945 18.415C12.4565 18.655 12.2735 18.847 12.0455 18.991C11.8235 19.129 11.5775 19.198 11.3075 19.198Z"
        fill="white"
      />
    </svg>
  );
};
