import {
  FETCH_FEEDBACK_ENDED,
  FETCH_FEEDBACK_STARTED,
  GET_ALL_FEEDBACK,
  GET_FEEDBACK_RATING_COUNT,
  GET_FEEDBACK_STATISTICS,
  GET_FEEDBACK_TRENDS,
  GET_FEEDBACK_TYPE,
  SET_FEEDBACK_PAYLOAD,
  UPDATE_FEEDBACK_META_DATA,
  GET_FEEDBACK_EMAIL_DATA,
  SET_FEEDBACK_EMAIL_DATA,
  SET_FEEDBACK_EMAIL_API_DATA,
  SET_FEEDBACK_STATUS,
} from "../types";
const initialState = {
  feedBackData: {},
  feedBackRatingCount: [],
  feedBackTrendData: [],
  metadata: {
    pageNo: 1,
    pageSize: 6,
  },
  allFeedback: [],
  feedBackLoading: true,
  feedBackTotalCount: 0,
  feedBackTypes: [],
  feedbackPayloadData: {
    feedbackType: "",
    feedBackCreatedDate: { min: "", max: "" },
    ratings: "",
  },
  feedBackPayload: {
    IsInternal: true,
    EmailAddress: "",
    UserName: "",
    FeedbackTypeIds: "",
    CityName: "",
    Mobile: "",
    OrderId: "",
    VehicleListingId: "",
    FeedbackActionStatusIds: "",
    StartDate: "",
    EndDate: "",
  },
  feedBackStatus: [],
};

export const feedBackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEEDBACK_STATISTICS:
      return {
        ...state,
        feedBackData: action.payload,
      };
    case GET_FEEDBACK_RATING_COUNT:
      return {
        ...state,
        feedBackRatingCount: action.payload,
      };
    case GET_FEEDBACK_TRENDS:
      return {
        ...state,
        feedBackTrendData: action.payload,
      };
    case FETCH_FEEDBACK_STARTED:
      return { ...state, feedBackLoading: true };
    case FETCH_FEEDBACK_ENDED:
      return { ...state, feedBackLoading: false };

    case GET_ALL_FEEDBACK:
      return {
        ...state,
        allFeedback: action.payload.Records,
        feedBackTotalCount: action.payload.TotalRecords,
      };

    case GET_FEEDBACK_TYPE:
      return {
        ...state,
        feedBackTypes: action.payload,
      };

    case SET_FEEDBACK_PAYLOAD:
      return {
        ...state,
        feedbackPayloadData: {
          ...state.feedbackPayloadData,
          ...action.payload,
        },
      };

    case UPDATE_FEEDBACK_META_DATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload,
        },
      };
    case GET_FEEDBACK_EMAIL_DATA:
      return {
        ...state,
        feedBackEmailsData: action.payload,
      };
    case SET_FEEDBACK_EMAIL_DATA:
      return {
        ...state,
        feedBackPayload: {
          ...state.feedBackPayload,
          ...action.payload,
        },
      };
    case SET_FEEDBACK_EMAIL_API_DATA:
      return {
        ...state,
        feedBackAPIData: action.payload,
      };
    case SET_FEEDBACK_STATUS:
      return {
        ...state,
        feedBackStatus: action.payload,
      };
    default:
      return state;
  }
};
