import React from "react";

export const ArrowBackDisabledIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.5 32.5"
      className={className}
    >
      <g id="arrow_back_disabled" opacity="0.374">
        <path id="Path_158" d="M0,0H32.5V32.5H0Z" fill="none"></path>
        <path
          id="Path_159"
          d="M24.165,13.335H9.039l6.608-6.608a1.365,1.365,0,0,0,0-1.923,1.349,1.349,0,0,0-1.909,0L4.814,13.728a1.349,1.349,0,0,0,0,1.909l8.924,8.924a1.35,1.35,0,1,0,1.909-1.909L9.039,16.043H24.165a1.354,1.354,0,0,0,0-2.708Z"
          transform="translate(1.565 1.561)"
          fill="#7d8593"
        ></path>
      </g>
    </svg>
  );
};
