import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, connect, useSelector } from "react-redux";
import { setLanguage } from "./redux/actions/globalAction";
import { AppRoute } from "./routes";
import { languages } from "./utilities/constants/global.constants";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  useEffect(() => {
    dispatch(setLanguage(i18n.language));
  }, [i18n, i18n.language, dispatch]);

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          href={`${process.env.REACT_APP_CDN_URL}/b2cpages/favicon.ico`}
        />
        <link
          rel="apple-touch-icon"
          href={`${process.env.REACT_APP_CDN_URL}/b2cpages/favicon.ico`}
        />
        <link
          rel="android-touch-icon"
          href={`${process.env.REACT_APP_CDN_URL}/b2cpages/favicon.ico`}
        />
      </Helmet>
      <div
        className={`app ${
          selectedLanguage === languages.ar ? "app-rtl arabic-font-family" : ""
        }`}
      >
        <AppRoute />
        <ToastContainer limit={1} autoClose={2000} />
      </div>
    </>
  );
}

export default withAITracking(reactPlugin, connect((state) => state, {})(App));
