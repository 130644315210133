import { toast } from "react-toastify";
import { API } from "../../middleware";
import {
  GET_HEADER_MENU,
  GET_HOMEPAGE_CITY_LIST,
  SET_CITY,
  SET_LANGUAGE,
  GET_SUMMARY_MENU,
  GET_CURRENCY,
  GET_SOCIAL_MEDIA_DATA,
  GET_ABOUT_US,
  GET_TERMS_CONDITIONS,
  GET_PRIVACY_POLICY,
  POST_FEEDBACK,
  GET_CITY_LIST_BY_STATE,
  GET_STATE_LIST,
  GET_COUNTRY_LIST,
  GET_LANGUAGES,
  GET_LOCATION_NAME,
  GET_LOCATION_NAME_ID,
  GET_LOCATION_TYPES,
  GET_ADDRESS_TYPES,
  GET_WORKING_SECTOR,
  GET_WORKING_SECTOR_ID_SUCCESS,
  CLEAR_WORKING_SECTOR_EDIT_DETAILS,
  GET_BANK_DETAILS,
  GET_BANK_DETAILS_BY_ID_SUCCESS,
  CLEAR_BANK_DETAIL,
  GET_ROLES,
  GET_LIST_HEADERS,
  GET_LIST_ACTIVE_HEADERS,
  GET_LIST_PRIVILEGES,
  GET_LIST_ACTIVE_PRIVILEGES,
  GET_PRIVILEGES,
  GET_PRODUCT_CATALOGUE,
  GET_PRODUCT_CATALOGUE_DATA,
} from "../types";
import { hideLoader, showLoader } from "./applicationAction";
import i18next from "i18next";

export const getHeaderMenu = (languageid) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/GlobalData/dmssiteheadermenu?languageId=${languageid}`
    );
    dispatch(setHeaderMenu(response));
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setHeaderMenu = (data) => ({
  type: GET_HEADER_MENU,
  payload: data,
});

export const setLanguage = (data) => ({
  type: SET_LANGUAGE,
  payload: data === "ar" ? 2 : 1,
});

export const uploadExcelFile = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/DMS/bulkuploadartifact", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const downloadFile = (payload) => {
  return () => {
    return API.get("/api/DMS/downloadbulkuploadsamplefile", {
      params: {
        fileFormat: payload.fileFormat,
        isFailedRecordsOnly: payload.isFailedRecordsOnly,
        fileType: payload.fileType,
      },
    }).catch((error) => {
      return error;
    });
  };
};

export function getAllBatches(languageId) {
  return () => {
    return API.get("/api/DMS/batches", { params: { languageId } }).catch(
      (error) => {
        return error;
      }
    );
  };
}

export function getBatchReportById(payload) {
  return () => {
    return API.get("api/DMS/bulkuploadbybatchid", {
      params: {
        batchId: payload.batchId,
        isFailedRecordsOnly: payload.isFailedRecordsOnly,
      },
    }).catch((error) => {
      return error;
    });
  };
}

export const setCity = (data) => ({
  type: SET_CITY,
  payload: data,
});

export function getAutoDetectCity(lan, lat, long) {
  return () => {
    return API.get("/api/GlobalData/cityname", {
      params: {
        languageId: lan,
        latitude: lat,
        longitude: long,
      },
    });
  };
}

export const setHomepageCityList = (data) => ({
  type: GET_HOMEPAGE_CITY_LIST,
  payload: data,
});

export function getHomepageCityList(lan) {
  return (dispatch) => {
    return API.get("/api/Vehicle/browsebycity", { params: { languageId: lan } })
      .then((response) => {
        dispatch(setHomepageCityList(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}

export const getSummaryMenu = (languageid) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/GlobalData/listingsummarymenu?languageId=${languageid}`
    );
    dispatch({
      type: GET_SUMMARY_MENU,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const setSummaryMenu = (data) => ({
  type: GET_SUMMARY_MENU,
  payload: data,
});

export const getCurrency = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `api/GlobalData/currency?languageId=${languageId}`
    );
    dispatch({
      type: GET_CURRENCY,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const setSocialMediaData = (data) => ({
  type: GET_SOCIAL_MEDIA_DATA,
  payload: data,
});

export function getSocialMediaData() {
  return (dispatch) => {
    return API.get("/api/GlobalData/socialmedia")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSocialMediaData(response && response.data));
        }
      })
      .catch((error) => {
        return error;
      });
  };
}

export const setAboutUs = (data) => ({
  type: GET_ABOUT_US,
  payload: data,
});

export function getAboutUs(languageId) {
  return (dispatch) => {
    return API.get("/api/globaldata/aboutus", {
      params: { languageId: languageId },
    })
      .then((response) => {
        dispatch(setAboutUs(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}

export const setPrivacyPolicy = (data) => ({
  type: GET_PRIVACY_POLICY,
  payload: data,
});

export function getPrivacyPolicy(lan) {
  return (dispatch) => {
    return API.get("/api/globaldata/privacypolicy", {
      params: { languageId: lan },
    })
      .then((response) => {
        dispatch(setPrivacyPolicy(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}
export const setTermsConditions = (data) => ({
  type: GET_TERMS_CONDITIONS,
  payload: data,
});

export function getTermsConditions(lan) {
  return (dispatch) => {
    return API.get("/api/globaldata/termsandconditions", {
      params: { languageId: lan },
    })
      .then((response) => {
        dispatch(setTermsConditions(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
}

export const setPostFeedback = (data) => ({
  type: POST_FEEDBACK,
  payload: data,
});

export const postFeedback = (data) => async (dispatch) => {
  try {
    const response = await API.post("/api/Support/feedback", data);
    dispatch(setPostFeedback(response));
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export function getBulkUploadErrorRecords(payload) {
  return () => {
    return API.get("api/DMS/errorreportbybatchid", {
      params: {
        batchId: payload.batchId,
      },
    }).catch((error) => {
      return error;
    });
  };
}

export const getCityListByState = (stateId, languageId, countryId) => {
  return (dispatch) => {
    return API.get("/api/GlobalData/countrystatecity", {
      params: {
        languageId: languageId,
        countryId: countryId,
        stateId: stateId,
      },
    })
      .then((response) => {
        dispatch({ type: GET_CITY_LIST_BY_STATE, payload: response.data });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getCountryList = (selectedLanguage) => {
  return (dispatch) => {
    return API.get("/api/GlobalData/countrystatecity", {
      params: { languageId: selectedLanguage, countryId: 0, stateId: 0 },
    })
      .then((response) => {
        dispatch({
          type: GET_COUNTRY_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getStateList = (selectedLanguage, countryId) => {
  return (dispatch) => {
    return API.get("/api/GlobalData/countrystatecity", {
      params: {
        languageId: selectedLanguage,
        countryId: countryId,
        stateId: 0,
      },
    })
      .then((response) => {
        dispatch({ type: GET_STATE_LIST, payload: response.data });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getLanguages = () => {
  return (dispatch) => {
    dispatch(showLoader());
    return API.get("/api/GlobalData/language")
      .then((response) => {
        dispatch({ type: GET_LANGUAGES, payload: response.data });
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };
};

export const getLocationName = (languageId, searchKey) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/GlobalData/getalllocations?languageId=${languageId}&searchKey=${
        searchKey ? searchKey : ""
      }`
    );
    dispatch(hideLoader());
    dispatch({
      type: GET_LOCATION_NAME,
      payload: response.data,
    });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const saveLocationNameStatus = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/GlobalData/savelocationdetails",
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const downloadVinFile = (payload) => {
  return () => {
    return API.get("/api/DMS/downloadmarksoldsamplefile", {
      params: {
        fileFormat: payload.fileFormat,
        isFailedRecordsOnly: payload.isFailedRecordsOnly,
      },
    }).catch((error) => {
      return error;
    });
  };
};

export const uploadVINExcelFile = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/DMS/marksoldbulkprocess", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const getLocationStatusById = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/GlobalData/getlocationbylocationid?locationId=${id}`
    );
    dispatch({ type: GET_LOCATION_NAME_ID, payload: response.data });
  } catch (error) {
    return error;
  }
};

export const getAddressTypes = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/GlobalData/getaddresstypes?languageId=${id}`
    );
    dispatch({ type: GET_ADDRESS_TYPES, payload: response.data });
  } catch (error) {
    return error;
  }
};

export const getLocationTypes = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/GlobalData/getlocationtypes?languageId=${id}`
    );
    dispatch({ type: GET_LOCATION_TYPES, payload: response.data });
  } catch (error) {
    return error;
  }
};

export const getInspectionCities = (languageId) => {
  return () => {
    return API.get("/api/globaldata/inspectioncities", {
      params: { languageId: languageId },
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getWorkingSector = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/GlobalData/getworkingsectors?languageId=${languageId}`
    );
    dispatch(hideLoader());
    dispatch({ type: GET_WORKING_SECTOR, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getWorkingSectorById = (workingSectorId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/GlobalData/getworkingsectors`, {
      params: { workingSectorId },
    });
    dispatch({ type: GET_WORKING_SECTOR_ID_SUCCESS, payload: response.data });
    dispatch(hideLoader());
  } catch (error) {
    return error;
  }
};

export const saveWorkingSector = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/GlobalData/saveworkingsector",
      payload
    );
    dispatch(hideLoader());
    return response.data;
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const cleanWorkingSectorData = () => async (dispatch) => {
  dispatch({ type: CLEAR_WORKING_SECTOR_EDIT_DETAILS });
};

export const getListOfBanks = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/GlobalData/getbankdetails?languageId=${languageId}`
    );
    dispatch(hideLoader());
    dispatch({ type: GET_BANK_DETAILS, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getBankDetailsByID = (bankId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/GlobalData/getbankdetails`, {
      params: { bankId },
    });
    dispatch({ type: GET_BANK_DETAILS_BY_ID_SUCCESS, payload: response.data });
    dispatch(hideLoader());
  } catch (error) {
    return error;
  }
};

export const saveBankDetails = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/GlobalData/savebankdetails", payload);
    dispatch(hideLoader());
    return response.data;
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const cleanBankData = () => async (dispatch) => {
  dispatch({ type: CLEAR_BANK_DETAIL });
};

export const getRoles =
  (languageId, roleType, searchKey) => async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(`/api/GlobalData/getallrole`, {
        params: {
          languageId: languageId,
          searchKey: searchKey ? searchKey : null,
          roleType: roleType ? roleType : null,
        },
      });
      dispatch(hideLoader());
      dispatch({
        type: GET_ROLES,
        payload: response.data,
      });
    } catch (error) {
      dispatch(hideLoader());
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
      return error;
    }
  };

export const saveRoleDetails = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/GlobalData/saveroledetails", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const downloadTranslationFile = (payload) => {
  return () => {
    return API.get("/api/DMS/downloadtranslationsamplefile", {
      params: {
        fileFormat: payload.fileFormat,
        isFailedRecordsOnly: payload.isFailedRecordsOnly,
      },
    }).catch((error) => {
      return error;
    });
  };
};

export const uploadTranslationsExcelFile = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/DMS/translationbulkprocess", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const getListOfActiveHeaders = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/GlobalData/menu/getall`, {
      params: payload,
    });
    dispatch(hideLoader());
    dispatch({ type: GET_LIST_ACTIVE_HEADERS, payload: response.data });
    return response;
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getListOfHeaders = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/GlobalData/menu/getall`, {
      params: payload,
    });
    dispatch(hideLoader());
    dispatch({ type: GET_LIST_HEADERS, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const addMenuRole = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/GlobalData/menu/addmenutorole",
      payload
    );
    dispatch(hideLoader());
    return response.data;
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const getListOfActivePrivileges = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/GlobalData/getallprivilege`, {
      params: payload,
    });
    dispatch(hideLoader());
    dispatch({ type: GET_LIST_ACTIVE_PRIVILEGES, payload: response.data });
    return response;
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getListOfPrivileges = () => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/GlobalData/getallprivilege`);
    dispatch(hideLoader());
    dispatch({ type: GET_LIST_PRIVILEGES, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const addPrivilegeToRole = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/GlobalData/addprivilegetorole",
      payload
    );
    dispatch(hideLoader());
    return response.data;
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const getPrivileges = (searchKey) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/GlobalData/getallprivilege?&searchKey=${searchKey ? searchKey : ""}`
    );
    dispatch(hideLoader());
    dispatch({
      type: GET_PRIVILEGES,
      payload: response.data,
    });
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const savePrivilege = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/GlobalData/saveprivilege", payload);
    dispatch(hideLoader());
    return response.data;
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const downloadCatalogueFile = (payload) => {
  return () => {
    return API.get("/api/ProductCatalogue/downloadproductcataloguesamplefile", {
      params: {
        fileFormat: payload.fileFormat,
      },
    }).catch((error) => {
      return error;
    });
  };
};

export const uploadCatalogueExcelFile = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      "/api/ProductCatalogue/productcatalogue",
      data
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const getProductCatalogue =
  ({ selectedLanguage, GridPageSize, pageNumber, data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(`/api/ProductCatalogue/getall`, {
        params: data
          ? {
              languageId: selectedLanguage,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
              makeId: data.make ? data.make : null,
              modelId: data.model ? data.model : null,
              trimId: data.trim ? data.trim : null,
              modelYearId: data.year ? data.year : null,
              colorVariantId: data.color ? data.color : null,
              cityId: data.city ? data.city : null,
              importedById: data.importedBy ? data.importedBy : null,
              suppliedById: data.supportedBy ? data.supportedBy : null,
              fulFilledById: data.fulfilledBy ? data.fulfilledBy : null,
            }
          : {
              languageId: selectedLanguage,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
            },
      });
      dispatch(hideLoader());
      dispatch({ type: GET_PRODUCT_CATALOGUE, payload: response.data });
    } catch (error) {
      return error;
    }
  };

export const setProductCatalogueData = (data) => ({
  type: GET_PRODUCT_CATALOGUE_DATA,
  payload: data,
});

export const updateProductCatalogue = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/ProductCatalogue/update", payload);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};
