import React from "react";

export const RequestIcon = ({ className = "" }) => {
  return (
    <svg
      id="Group_167547"
      data-name="Group 167547"
      xmlns="http://www.w3.org/2000/svg"
      width="47.998"
      height="48.204"
      viewBox="0 0 47.998 48.204"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1691"
            data-name="Rectangle 1691"
            width="47.998"
            height="48.204"
            fill="currentColor"
          />
        </clipPath>
      </defs>
      <g id="Group_166938" data-name="Group 166938" clipPath="url(#clip-path)">
        <path
          id="Path_13712"
          data-name="Path 13712"
          d="M126.3,12.732c0-.431-.122-.5-.515-.5-3.357.016-6.714.01-10.071.009a1.46,1.46,0,0,1-1.662-1.674c0-3.373-.007-6.746.009-10.119,0-.375-.1-.453-.461-.452q-9.789.017-19.578.009a4.234,4.234,0,0,0-4.443,4.424c0,5.052,0,10.1-.01,15.155,0,.382.1.431.463.414a15.29,15.29,0,0,1,12.5,5.142,1.3,1.3,0,0,0,1.074.479q7.271-.026,14.542-.009a1.415,1.415,0,1,1,.043,2.821q-3.083.006-6.165,0h-7.188l-.007.01a15.4,15.4,0,0,1,1.241,3.4,16.932,16.932,0,0,1,.327,1.848c.035.336.148.394.454.393q5.577-.016,11.154-.006a2.681,2.681,0,0,1,.561.032,1.409,1.409,0,0,1-.331,2.788c-2.573.007-5.145,0-7.718,0-1.239,0-2.479,0-3.718,0-.22,0-.356,0-.393.3a15.394,15.394,0,0,1-6.265,10.821c-.074.056-.145.118-.217.178h22.685c.276-.079.557-.145.828-.24a4.229,4.229,0,0,0,2.841-4.076c.008-3.765,0-7.53,0-11.3q0-9.931.012-19.861m-6.714,6.355a1.387,1.387,0,0,1-1.375.876h-4.563q-7.973,0-15.946,0a1.416,1.416,0,0,1-1.434-1.926,1.446,1.446,0,0,1,1.457-.9q5.1,0,10.208,0,5.127,0,10.255,0a1.407,1.407,0,0,1,1.4,1.945"
          transform="translate(-78.305 0)"
          fill="currentColor"
        />
        <path
          id="Path_13713"
          data-name="Path 13713"
          d="M315.025,16.082c-.069.054-.2.008-.314.008-2.57,0-5.14,0-7.71.011-.342,0-.441-.066-.438-.427.018-2.57.01-5.14.01-7.71V7.631l8.452,8.451"
          transform="translate(-268 -6.671)"
          fill="currentColor"
        />
        <path
          id="Path_13714"
          data-name="Path 13714"
          d="M25.213,191.652A12.7,12.7,0,1,0,9.707,206.11a8.362,8.362,0,0,1,1.569.358h2.636a5.405,5.405,0,0,1,1.209-.233,12.7,12.7,0,0,0,10.092-14.583m-8.8,3.517q-1.834.016-3.668,0a1.41,1.41,0,0,1-1.467-1.487c-.007-.925,0-1.85,0-2.775,0-.894,0-1.787,0-2.681a1.415,1.415,0,1,1,2.821-.011c.006,1.238.019,2.477-.009,3.715-.008.369.106.439.443.424.626-.028,1.254-.015,1.881-.006a1.41,1.41,0,1,1,0,2.819"
          transform="translate(0 -158.264)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
