export const VinDecodeIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 44 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="43" height="21" rx="2" fill="#4C0055" />
      <path
        d="M5.27778 9.75L6.61111 5.8125H16.3889L17.7222 9.75M16.3889 14.125C16.0353 14.125 15.6961 13.9867 15.4461 13.7406C15.196 13.4944 15.0556 13.1606 15.0556 12.8125C15.0556 12.4644 15.196 12.1306 15.4461 11.8844C15.6961 11.6383 16.0353 11.5 16.3889 11.5C16.7425 11.5 17.0816 11.6383 17.3317 11.8844C17.5817 12.1306 17.7222 12.4644 17.7222 12.8125C17.7222 13.1606 17.5817 13.4944 17.3317 13.7406C17.0816 13.9867 16.7425 14.125 16.3889 14.125ZM6.61111 14.125C6.25749 14.125 5.91835 13.9867 5.6683 13.7406C5.41825 13.4944 5.27778 13.1606 5.27778 12.8125C5.27778 12.4644 5.41825 12.1306 5.6683 11.8844C5.91835 11.6383 6.25749 11.5 6.61111 11.5C6.96473 11.5 7.30387 11.6383 7.55392 11.8844C7.80397 12.1306 7.94444 12.4644 7.94444 12.8125C7.94444 13.1606 7.80397 13.4944 7.55392 13.7406C7.30387 13.9867 6.96473 14.125 6.61111 14.125ZM17.6511 5.375C17.4733 4.8675 16.9756 4.5 16.3889 4.5H6.61111C6.02444 4.5 5.52667 4.8675 5.34889 5.375L3.5 10.625V17.625C3.5 17.8571 3.59365 18.0796 3.76035 18.2437C3.92705 18.4078 4.15314 18.5 4.38889 18.5H5.27778C5.51353 18.5 5.73962 18.4078 5.90632 18.2437C6.07302 18.0796 6.16667 17.8571 6.16667 17.625V16.75H16.8333V17.625C16.8333 17.8571 16.927 18.0796 17.0937 18.2437C17.2604 18.4078 17.4865 18.5 17.7222 18.5H18.6111C18.8469 18.5 19.073 18.4078 19.2397 18.2437C19.4064 18.0796 19.5 17.8571 19.5 17.625V10.625L17.6511 5.375Z"
        fill="white"
      />
      <rect x="22.5" y="16.5" width="18" height="2" rx="1" fill="white" />
      <rect x="27.5" y="12.5" width="13" height="2" rx="1" fill="white" />
      <path
        d="M25.612 10.516L23.452 4.556H25.036L25.988 7.556L26.284 8.716L26.58 7.556L27.468 4.556H29.052L26.908 10.516H25.612ZM31.0078 10.5V4.556H32.5118V10.5H31.0078ZM35.1531 10.5V4.556H36.5451L38.5771 7.82L39.3371 9.42H39.0651L38.8251 7.172V4.556H40.1771V10.5H38.6811L36.7531 7.316L35.8811 5.492H36.2091L36.5051 7.932V10.5H35.1531Z"
        fill="white"
      />
    </svg>
  );
};
