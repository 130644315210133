import React from "react";

export const GridViewIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      className={className}
    >
      <g id="grid-view" transform="translate(-1248 -257)">
        <rect
          id="Rectangle_60"
          width="40"
          height="40"
          rx="1"
          transform="translate(1248 257)"
          fillOpacity="0"
        ></rect>
        <g id="Group_64" transform="translate(1249.5 259.081)">
          <path
            id="Path_7"
            d="M15.341,2.5H3.8A1.3,1.3,0,0,0,2.5,3.8V15.341a1.3,1.3,0,0,0,1.3,1.3H15.341a1.3,1.3,0,0,0,1.3-1.3V3.8A1.322,1.322,0,0,0,15.341,2.5ZM14.3,14.3H4.8V4.8h9.5Z"
            fill="#465166"
          ></path>
          <path
            id="Path_8"
            d="M66.741,2.5H55.2a1.3,1.3,0,0,0-1.3,1.3V15.341a1.3,1.3,0,0,0,1.3,1.3H66.741a1.3,1.3,0,0,0,1.3-1.3V3.8A1.3,1.3,0,0,0,66.741,2.5ZM65.736,14.3h-9.5V4.8h9.5Z"
            transform="translate(-33.538)"
            fill="#465166"
          ></path>
          <path
            id="Path_9"
            d="M15.341,54H3.8a1.3,1.3,0,0,0-1.3,1.3V66.841a1.3,1.3,0,0,0,1.3,1.3H15.341a1.3,1.3,0,0,0,1.3-1.3V55.265A1.294,1.294,0,0,0,15.341,54ZM14.3,65.8H4.8V56.3h9.5Z"
            transform="translate(0 -33.638)"
            fill="#465166"
          ></path>
          <path
            id="Path_10"
            d="M66.741,54H55.2a1.3,1.3,0,0,0-1.3,1.3V66.841a1.3,1.3,0,0,0,1.3,1.3H66.741a1.3,1.3,0,0,0,1.3-1.3V55.265A1.273,1.273,0,0,0,66.741,54ZM65.736,65.8h-9.5V56.3h9.5Z"
            transform="translate(-33.538 -33.638)"
            fill="#465166"
          ></path>
        </g>
      </g>
    </svg>
  );
};
