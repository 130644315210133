import React from "react";
import PropTypes from "prop-types";
import { CloseIcon } from "../../gogo-icons";

const ModalHeader = (props) => {
  return (
    <div className="flex justify-between items-start p-5 border dark:border-gray-600 arabic">
      <div className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
        {props.children}
      </div>
      {props.onCloseClick && (
        <button
          type="button"
          className={`ml-auto text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white modal-header-rtl-btn`}
          onClick={() => props.onCloseClick()}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  onCloseClick: PropTypes.func,
};

export { ModalHeader };
