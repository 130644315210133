import React from "react";

const ModalFooter = (props) => {
  return (
    <div className="p-6 space-x-2 rounded-b border-gray-200 dark:border-gray-600">
      {props.children}
    </div>
  );
};

export { ModalFooter };
