export const SearchIcon = ({ className = "" }) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      id="Search"
      className={className}
    >
      <path
        d="M29.5072 27.1413L23.8423 21.4931C25.67 19.1646 26.6618 16.2892 26.6581 13.329C26.6581 10.6928 25.8763 8.11578 24.4117 5.92383C22.9471 3.73188 20.8654 2.02346 18.4298 1.01462C15.9943 0.00577522 13.3143 -0.258184 10.7287 0.25612C8.1431 0.770423 5.76809 2.03989 3.90399 3.90399C2.03989 5.76809 0.770423 8.1431 0.25612 10.7287C-0.258184 13.3143 0.00577522 15.9943 1.01462 18.4298C2.02346 20.8654 3.73188 22.9471 5.92383 24.4117C8.11578 25.8763 10.6928 26.6581 13.329 26.6581C16.2892 26.6618 19.1646 25.67 21.4931 23.8423L27.1413 29.5072C27.2961 29.6633 27.4804 29.7873 27.6835 29.8719C27.8865 29.9565 28.1043 30 28.3242 30C28.5442 30 28.7619 29.9565 28.965 29.8719C29.168 29.7873 29.3523 29.6633 29.5072 29.5072C29.6633 29.3523 29.7873 29.168 29.8719 28.965C29.9565 28.7619 30 28.5442 30 28.3242C30 28.1043 29.9565 27.8865 29.8719 27.6835C29.7873 27.4804 29.6633 27.2961 29.5072 27.1413ZM3.33227 13.329C3.33227 11.3519 3.91857 9.41909 5.01703 7.77513C6.11549 6.13117 7.67677 4.84986 9.50344 4.09322C11.3301 3.33659 13.3401 3.13862 15.2793 3.52435C17.2185 3.91008 18.9998 4.86218 20.3978 6.26025C21.7959 7.65833 22.748 9.43958 23.1337 11.3788C23.5195 13.318 23.3215 15.328 22.5649 17.1546C21.8082 18.9813 20.5269 20.5426 18.883 21.6411C17.239 22.7395 15.3062 23.3258 13.329 23.3258C10.6777 23.3258 8.13501 22.2726 6.26025 20.3978C4.38549 18.5231 3.33227 15.9804 3.33227 13.329Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
