import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useSelector, connect, useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/authAction";
import { ApplicationRoles } from "../../utilities/constants/role.constants";
import "./css/ProfileDropdown.scss";
import { LogoutIcon, ProfileChangeIcon, ProfileIcon } from "../gogo-icons";
import { languages } from "../../utilities/constants/global.constants";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function ProfileDropdown() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userRoles = useSelector((state) => state.authReducer.userRoles);
  const { t } = useTranslation();
  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  const logOutUser = async () => {
    dispatch(logOut());
  };

  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div className="">
        <Menu.Button className="ar-header-profile-dropdown-button dropdown-button">
          <ProfileIcon className="profile-select mb-1" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`origin-top-right absolute ${
            selectedLanguage === languages.eng ? "lg:right-0" : "lg:left-0"
          }  mt-2 w-56 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
        >
          {userRoles[0] === ApplicationRoles.PETROMIN_ADMIN ||
          userRoles[0] === ApplicationRoles.QC_ADMIN ||
          userRoles[0] === ApplicationRoles.QC_ANALYST ||
          userRoles[0] === ApplicationRoles.INSPECTOR ? (
            <div className="py-1 cursor-pointer">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => history.push("/view-profile")}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 icons-position items-center"
                        : "text-gray-700 items-center",
                      "block px-4 py-2 text-sm icons-position"
                    )}
                  >
                    <ProfileIcon className="profile-icons mt-1 ml-1" />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {t("PROFILE")}
                  </div>
                )}
              </Menu.Item>
            </div>
          ) : (
            <div className="py-1 cursor-pointer">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => history.push("/profile")}
                    className={classNames(
                      active
                        ? "bg-gray-100 default-color icons-position items-center"
                        : "text-gray-700 items-center",
                      "block px-4 py-2 text-sm icons-position"
                    )}
                  >
                    <ProfileIcon className="profile-icons mt-1 ml-1" />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {t("PROFILE")}
                  </div>
                )}
              </Menu.Item>
            </div>
          )}
          {(userRoles[0] === ApplicationRoles.PETROMIN_ADMIN ||
            userRoles[0] === ApplicationRoles.DMS_ADMIN) && (
            <div className="py-1 cursor-pointer">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 icons-position items-center"
                        : "text-gray-700 items-center",
                      "block px-4 py-2 text-sm icons-position"
                    )}
                    onClick={() => history.push("/profile-change-request")}
                  >
                    <ProfileChangeIcon className="profile-icons ml-1" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {t("PROFILE_CHANGE_REQ")}
                  </div>
                )}
              </Menu.Item>
            </div>
          )}
          <div className="py-1">
            <div className="logout-border"></div>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to=""
                  onClick={() => logOutUser()}
                  className={classNames(
                    active
                      ? "bg-gray-100 text-red-600 icons-position items-center mt-1"
                      : "text-red-600 flex items-center mt-1",
                    "block px-4 py-1 text-sm icons-position "
                  )}
                >
                  <LogoutIcon className="profile-icons ml-1" />
                  <span className="ml-3">{t("LOGOUT")}</span>
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
export default connect((state) => state, { logOut })(ProfileDropdown);
