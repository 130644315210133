import React from "react";

export const PackageRightTick = ({ className = "" }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.78495 18L0 9.53701L1.76344 7.90453L7.56989 14.2196L18.0645 0L20 1.41766L7.78495 18Z"
        fill="#00C980"
      />
    </svg>
  );
};
