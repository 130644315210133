export const VehicleTypeIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.77778 8.71826L5.11111 4.78076H14.8889L16.2222 8.71826M14.8889 13.0933C14.5353 13.0933 14.1961 12.955 13.9461 12.7088C13.696 12.4627 13.5556 12.1289 13.5556 11.7808C13.5556 11.4327 13.696 11.0988 13.9461 10.8527C14.1961 10.6065 14.5353 10.4683 14.8889 10.4683C15.2425 10.4683 15.5816 10.6065 15.8317 10.8527C16.0817 11.0988 16.2222 11.4327 16.2222 11.7808C16.2222 12.1289 16.0817 12.4627 15.8317 12.7088C15.5816 12.955 15.2425 13.0933 14.8889 13.0933ZM5.11111 13.0933C4.75749 13.0933 4.41835 12.955 4.1683 12.7088C3.91825 12.4627 3.77778 12.1289 3.77778 11.7808C3.77778 11.4327 3.91825 11.0988 4.1683 10.8527C4.41835 10.6065 4.75749 10.4683 5.11111 10.4683C5.46473 10.4683 5.80387 10.6065 6.05392 10.8527C6.30397 11.0988 6.44444 11.4327 6.44444 11.7808C6.44444 12.1289 6.30397 12.4627 6.05392 12.7088C5.80387 12.955 5.46473 13.0933 5.11111 13.0933ZM16.1511 4.34326C15.9733 3.83576 15.4756 3.46826 14.8889 3.46826H5.11111C4.52444 3.46826 4.02667 3.83576 3.84889 4.34326L2 9.59326V16.5933C2 16.8253 2.09365 17.0479 2.26035 17.212C2.42705 17.3761 2.65314 17.4683 2.88889 17.4683H3.77778C4.01353 17.4683 4.23962 17.3761 4.40632 17.212C4.57302 17.0479 4.66667 16.8253 4.66667 16.5933V15.7183H15.3333V16.5933C15.3333 16.8253 15.427 17.0479 15.5937 17.212C15.7604 17.3761 15.9865 17.4683 16.2222 17.4683H17.1111C17.3469 17.4683 17.573 17.3761 17.7397 17.212C17.9064 17.0479 18 16.8253 18 16.5933V9.59326L16.1511 4.34326Z"
        fill="#43474D"
      />
    </svg>
  );
};
