export const LeftViewIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 85.241 38.625"
      className={className}
    >
      <path
        id="rgba_0_0_0_0.92_"
        data-name="rgba(0,0,0,0.92)"
        d="M74.669,140H66.178l-.083.083v1.582h7.742l.083.25,4.995,10.322.166,1.082a2.838,2.838,0,0,0-1.831.166l-.166.416v6.16l.583.583H80.58l.166,1.082-1.665,1.332-.333.832-.083,5.244q-.466-4.612-3.5-6.659l-3-1.5-2.83-.333-.166.166-1.831.167-2,.832L62.6,164.224l-1.5,2.83-.333,1.665v1.748H24.723l-.083-.083v-2.331a9.43,9.43,0,0,0-3.246-5.411,6.557,6.557,0,0,0-3.912-1.665l-.166,1.665a6.079,6.079,0,0,1,4,2.248q2,1.915,1.665,6.16a7.321,7.321,0,0,1-2.747,4.412l-2.164,1.165-1.5.333H14.9a7.387,7.387,0,0,1-4.578-2.414l-1.5-2.5-.333-1.5v-1.831a7.215,7.215,0,0,1,2.914-4.911l2.914-1.415-.333-1.415a7.724,7.724,0,0,0-5.328,3.247q-1.981,2.148-1.831,6.243H1.665v-2q2.647.383,3.663-1.082a2.376,2.376,0,0,0,.5-2.164,2.658,2.658,0,0,0-1.249-1.748l-.666-.333H1.831a10.183,10.183,0,0,1,4.079-7.325,10.408,10.408,0,0,1,4.329-2l4-.333.166-.166h1.665l.166-.166H17.9l.166-.167h1.5l.166-.166h1.665l10.655-8.99a9.963,9.963,0,0,1,4.5-2q.8.216,1-.166H62.766v-1.582L62.682,140H37.376l-3,.666L31.05,142.5l-10.156,8.657-4.995.333-1.831.333H12.4l-.166.166H10.9l-1.665.333A12.73,12.73,0,0,0,.166,162.06q.216.8-.166,1v8.491l.583.583H7.076l.25.25a7.336,7.336,0,0,0,2.581,4.079,8.087,8.087,0,0,0,3.33,1.831l1.665.333h1.831a9.034,9.034,0,0,0,6.077-3.413,6.055,6.055,0,0,0,1.5-3.08h36.71l.25.25a7.727,7.727,0,0,0,2.414,3.912l3,1.831,2.164.5h1.831a9.175,9.175,0,0,0,6.243-3.579l1.165-2,.166-.916h5.244l.583-.583,1-7.825.166-.166v-1.332l-.416-.416H82.661l-.25-.25L80.58,151.9l-4.5-9.656-.832-1.665Zm4.745,14.984.832,4h-1.5V155.15Zm-9.323,7.325,2.164.5,1.831,1,1.831,2,.832,1.831.166,1.831.166.167-.5,2.664-1,1.831-2,1.665-2.331,1a1.262,1.262,0,0,0-1.165.166l-2.664-.333-2.164-1.165a7.114,7.114,0,0,1-2.83-4.995q-.166-3.5,1.5-5.161a8.779,8.779,0,0,1,2.664-2.331l1.5-.5Zm13.319,1.165-.666,4.162-.166,2.331-.166.167-.166.333H80.413v-6.16l.166-.333.5-.5ZM3.5,164.807l.666.832L4,166.471l-.666.333H1.665v-2Z"
        transform="translate(0 -140)"
        fill="currentColor"
      />
      <path
        id="rgba_0_0_0_0.92_2"
        data-name="rgba(0,0,0,0.92)"
        d="M81.069,158H69.415l-.416.167-.333,1.415v1.5l-.333,1.665L68,167.24l.166.416.416.167H84.565l.416-.167.166-.916-3.663-8.574Zm-.749,1.665,2.664,6.327-.166.166H69.831l.333-4.662.166-.167v-1.665Z"
        transform="translate(-11.228 -155.003)"
        fill="currentColor"
      />
      <path
        id="rgba_0_0_0_0.92_3"
        data-name="rgba(0,0,0,0.92)"
        d="M206.551,158H189.4l-1.5.333a13.3,13.3,0,0,0-4.828,2.5l-5.91,5.577-.166.832.583.583h27.97l.583-.583q-.2-.782.166-1v-1.5l.166-.167.5-5.994Zm-1.415,1.665-.5,6.327-.333.166H180l4.662-4.5,3.33-1.665,1.665-.333Z"
        transform="translate(-151.195 -155.003)"
        fill="currentColor"
      />
      <path
        id="rgba_0_0_0_0.92_4"
        data-name="rgba(0,0,0,0.92)"
        d="M436.811,232h-3.163a2.144,2.144,0,0,0-1.582,1.249l-.333,1.665.916,1.582a4.387,4.387,0,0,0,3.5.666,2.708,2.708,0,0,0,1.915-1.082,2.376,2.376,0,0,0,.5-2.164l-.916-1.415Zm-.583,1.665.5.166.167.5-.167.832-.832.333a2.337,2.337,0,0,1-2-.167l-.5-.666.167-.5.333-.5Z"
        transform="translate(-425.24 -216.683)"
        fill="currentColor"
      />
      <path
        id="rgba_0_0_0_0.92_5"
        data-name="rgba(0,0,0,0.92)"
        d="M209.493,236h-6.41l-.083.083v1.582h6.41l.083-.083Z"
        transform="translate(-158.049 -220.017)"
        fill="currentColor"
      />
      <path
        id="rgba_0_0_0_0.92_6"
        data-name="rgba(0,0,0,0.92)"
        d="M69.334,289q-3.38-.033-4.578,2.081l-.832,2v1.665l.666,1.665.666.916.916.666,1.665.666H69.5a4.94,4.94,0,0,0,4.079-5.411A4.981,4.981,0,0,0,71.5,289.83Zm-.25,1.665,1.5.666a3.329,3.329,0,0,1,1.332,2.164v.832l-.5,1.332a3.328,3.328,0,0,1-2.164,1.332,3.071,3.071,0,0,1-2.331-.5,3.232,3.232,0,0,1-1.332-2,2.833,2.833,0,0,1,.5-2.331A3.127,3.127,0,0,1,69.085,290.662Z"
        transform="translate(1.006 -264.191)"
        fill="currentColor"
      />
      <path
        id="rgba_0_0_0_0.92_7"
        data-name="rgba(0,0,0,0.92)"
        d="M393.334,289q-3.38-.033-4.578,2.081l-.832,2v1.665l.666,1.665.666.916.916.666,1.665.666H393.5a4.94,4.94,0,0,0,4.079-5.411,4.981,4.981,0,0,0-2.081-3.413Zm-.25,1.665,1.5.666a3.329,3.329,0,0,1,1.332,2.164v.832l-.5,1.332a3.328,3.328,0,0,1-2.164,1.332,3.071,3.071,0,0,1-2.331-.5,3.232,3.232,0,0,1-1.332-2,2.833,2.833,0,0,1,.5-2.331A3.126,3.126,0,0,1,393.084,290.662Z"
        transform="translate(-376.935 -264.191)"
        fill="currentColor"
      />
      <path
        id="rgba_0_0_0_0_"
        data-name="rgba(0,0,0,0)"
        d="M91.489,168H81.583l-.083.083v1.5l-.167.166L81,174.493H94.069l.083-.25Z"
        transform="translate(-22.397 -163.338)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_2"
        data-name="rgba(0,0,0,0)"
        d="M213.14,168h-15.4l-1.665.333L192.745,170,188,174.41l24.39.083.25-.25Z"
        transform="translate(-159.198 -163.338)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_3"
        data-name="rgba(0,0,0,0)"
        d="M30.666,230l-.666.083V234l1.5-.083Z"
        transform="translate(48.748 -215.016)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_4"
        data-name="rgba(0,0,0,0)"
        d="M444.913,242h-2.331l-.416.416-.166.5.583.749a2.575,2.575,0,0,0,2,.167l.749-.416.167-.832-.167-.416Z"
        transform="translate(-433.842 -225.018)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_5"
        data-name="rgba(0,0,0,0)"
        d="M56.746,274l-2,.167-1.5.5a7.784,7.784,0,0,0-2.747,2.248,7.185,7.185,0,0,0-1.5,5.161,7.331,7.331,0,0,0,2.914,5.078l2.164,1.165,2.664.333q.25-.416,1.165-.166l2.331-1,1.915-1.748,1-1.831.5-2.664-.166-.166-.166-1.831-.832-1.831L60.741,275.5l-1.831-1Z"
        transform="translate(13.428 -251.691)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_6"
        data-name="rgba(0,0,0,0)"
        d="M14,281H11.749l-.583.416-.166.333v6.243h1.915l.083-.416.166-.167.166-2.331Z"
        transform="translate(69.413 -257.525)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_7"
        data-name="rgba(0,0,0,0)"
        d="M488.915,289H487v2h1.748l.583-.416.167-.832Z"
        transform="translate(-485.335 -264.193)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_8"
        data-name="rgba(0,0,0,0)"
        d="M77.618,298.986q-2.314-.067-3.08,1.415a2.866,2.866,0,0,0-.5,2.331,3.364,3.364,0,0,0,1.415,2.081,2.866,2.866,0,0,0,2.331.5,3.454,3.454,0,0,0,2.081-1.415l.5-1.332v-.832a3.255,3.255,0,0,0-1.249-2.081Z"
        transform="translate(-7.444 -272.514)"
        fill="rgba(0,0,0,0)"
      />
      <path
        id="rgba_0_0_0_0_9"
        data-name="rgba(0,0,0,0)"
        d="M401.618,298.986q-2.314-.067-3.08,1.415a2.866,2.866,0,0,0-.5,2.331,3.364,3.364,0,0,0,1.415,2.081,2.866,2.866,0,0,0,2.331.5,3.454,3.454,0,0,0,2.081-1.415l.5-1.332v-.832a3.255,3.255,0,0,0-1.249-2.081Z"
        transform="translate(-385.386 -272.514)"
        fill="rgba(0,0,0,0)"
      />
    </svg>
  );
};
