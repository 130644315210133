import React from "react";

export const DeleteIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.108 22.647"
      className={className}
    >
      <g id="noun_Delete_1839835" transform="translate(-40)">
        <path
          id="Path_1716"
          d="M197.53,58.627h.142a.6.6,0,0,1-.141-.017Z"
          transform="translate(-150.562 -56.018)"
          fill="currentColor"
        ></path>
        <path
          id="Path_1717"
          d="M311.332,58.771V58.76a.6.6,0,0,1-.112.011h.111Z"
          transform="translate(-259.223 -56.161)"
          fill="currentColor"
        ></path>
        <path
          id="Path_1718"
          d="M315,207.439h1.725V197H315Z"
          transform="translate(-262.836 -188.286)"
          fill="currentColor"
        ></path>
        <path
          id="Path_1719"
          d="M236,207.439h1.769V197H236Z"
          transform="translate(-187.33 -188.286)"
          fill="currentColor"
        ></path>
        <path
          id="Path_1720"
          d="M158,207.439h1.725V197H158Z"
          transform="translate(-112.781 -188.286)"
          fill="currentColor"
        ></path>
        <path
          id="Path_1721"
          d="M94.649,138H79v13.817a2.585,2.585,0,0,0,2.224,2.685.3.3,0,0,1,.077.041H92.358a5.622,5.622,0,0,0,.828-.277,2.454,2.454,0,0,0,1.463-2.292c.015-4.6.007-9.2.007-13.8C94.658,138.121,94.653,138.088,94.649,138ZM84.219,151.049H82.494V140.61h1.725Zm3.494,0H85.944V140.61h1.769Zm3.45,0H89.439V140.61h1.725Z"
          transform="translate(-37.275 -131.896)"
          fill="currentColor"
        ></path>
        <path
          id="Path_1722"
          d="M58.284,3.494c-.185-.708-.424-.881-1.117-.881-.988,0-1.975,0-2.964,0h-.265c-.008-.133-.011-.265-.022-.381A2.221,2.221,0,0,0,52.422.14C52.268.084,52.1.047,51.943,0H47.166c-.088.027-.177.059-.27.082A2.146,2.146,0,0,0,45.7.823a2.612,2.612,0,0,0-.517,1.472c-.006.1-.01.226-.015.315h-.287q-1.548,0-3.1,0a.868.868,0,0,0-.925.744,1.379,1.379,0,0,1-.038.135H40V5.219H59.108V3.494ZM52.11,2.6V2.61H46.968V2.592a.6.6,0,0,1,.142-1.177H52A.6.6,0,0,1,52.11,2.6Z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
};
