import React from "react";

export const HomeInspectionIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      viewBox="0 0 79 79"
      fill="none"
      className={className}
    >
      <path
        d="M70 40.0019C70 56.8466 56.3447 70.5019 39.5 70.5019C22.6553 70.5019 9 56.8466 9 40.0019C36.5 40.0019 22.6553 40 39.5 40C56.3447 40 58 40.0031 70 40.0019Z"
        fill="#4C0055"
      />
      <path
        d="M39.4966 79C17.7186 79 0 61.2814 0 39.4966C0 17.7117 17.7186 0 39.4966 0C61.2745 0 79 17.7186 79 39.4966C79 61.2745 61.2814 78.9931 39.4966 78.9931V79ZM39.4966 1.98854C18.8157 1.98854 1.98854 18.8157 1.98854 39.4966C1.98854 60.1774 18.8157 77.0046 39.4966 77.0046C60.1774 77.0046 77.0046 60.1774 77.0046 39.4966C77.0046 18.8157 60.1843 1.98854 39.4966 1.98854Z"
        fill="#4C0055"
      />
      <path
        d="M39.5 71C22.1266 71 8 56.8681 8 39.5034C8 22.1387 22.1334 8 39.5 8C56.8666 8 71 22.1319 71 39.4966C71 56.8613 56.8666 70.9932 39.5 70.9932V71ZM39.5 8.98896C22.6723 8.98896 8.98906 22.6775 8.98906 39.4966C8.98906 56.3157 22.6791 70.0042 39.5 70.0042C56.3209 70.0042 70.0109 56.3157 70.0109 39.4966C70.0109 22.6775 56.3277 8.98896 39.5 8.98896Z"
        fill="#4C0055"
      />
      <path
        d="M75.6264 43.926L74.5538 44.2904C74.4576 44.6548 74.3682 45.0261 74.2444 45.3836C74.0382 45.0742 73.8319 44.7648 73.605 44.4623L72.4844 44.5035L73.1375 43.6304C73 43.2935 72.8694 42.9566 72.7044 42.6266L73.7907 42.9291L74.6226 42.1797C74.6432 42.5578 74.6707 42.936 74.6776 43.3141L75.6264 43.9191V43.926Z"
        fill="#4C0055"
      />
      <path
        d="M74.4245 49.7979L73.3176 49.9835C73.1595 50.3273 72.9876 50.6642 72.8226 51.0079L72.3482 49.9973L71.2344 49.846C71.5094 49.6054 71.7638 49.351 72.025 49.0966L71.7706 48.0378L72.7882 48.5122C73.1182 48.3197 73.4276 48.1203 73.737 47.9141C73.7095 48.2922 73.6545 48.6635 73.5926 49.0416L74.4314 49.7979H74.4245Z"
        fill="#4C0055"
      />
      <path
        d="M72.2789 55.3934H71.1445L70.4982 56.3215L70.1819 55.2352L69.1094 54.9052L70.0238 54.3002C70.0032 53.9358 69.9619 53.5645 69.9344 53.207L70.8626 53.8464L71.9076 53.4202L71.5776 54.5065C71.8182 54.7952 72.0589 55.0977 72.2858 55.3934H72.2789Z"
        fill="#4C0055"
      />
      <path
        d="M69.2273 60.5496L68.1135 60.364C67.8591 60.6459 67.5841 60.9072 67.316 61.1753C67.2816 60.7972 67.2472 60.4328 67.1991 60.0615L66.1953 59.5596C66.5253 59.4152 66.8485 59.2571 67.1854 59.1058C67.2197 58.7414 67.2679 58.3908 67.2885 58.0195L68.0998 58.8033L69.1861 58.5421C69.0211 58.8789 68.8767 59.2365 68.691 59.5665L69.2342 60.5496H69.2273Z"
        fill="#4C0055"
      />
      <path
        d="M65.3786 65.1422C65.0279 65.0253 64.6635 64.9015 64.306 64.7709L63.3916 65.4447L63.4466 64.3171L62.5391 63.6571C62.8897 63.5677 63.2472 63.4852 63.5979 63.3752L63.866 62.3164L64.5329 63.2171C64.9042 63.1964 65.2823 63.1827 65.6604 63.1483C65.4473 63.4577 65.2136 63.7602 64.9935 64.0696L65.3786 65.1353V65.1422Z"
        fill="#4C0055"
      />
      <path
        d="M60.8164 69.0329L59.8194 68.4967C59.4894 68.6754 59.1525 68.8542 58.8019 69.0054C58.8913 68.6411 58.9807 68.2835 59.0494 67.9123L58.2656 67.1147L59.3519 67.0047C59.5101 66.6747 59.6613 66.3516 59.7988 66.0078L60.3145 67.0116L61.4283 67.1216C61.1601 67.3897 60.8989 67.6648 60.6307 67.9329L60.8232 69.0398L60.8164 69.0329Z"
        fill="#4C0055"
      />
      <path
        d="M55.6845 72.1294C55.382 71.9025 55.0932 71.6756 54.7976 71.4418L53.7113 71.7718L54.1306 70.7337L53.4844 69.8124C53.8488 69.8468 54.2132 69.8811 54.5775 69.888L55.1826 68.9805L55.5195 70.0461C55.877 70.1493 56.2414 70.2593 56.6058 70.3418C56.3101 70.5824 55.987 70.7749 55.6776 70.9949L55.6913 72.1294H55.6845Z"
        fill="#4C0055"
      />
      <path
        d="M50.1173 74.3367L49.3541 73.4979C48.976 73.5529 48.6047 73.6148 48.2266 73.656C48.4328 73.3467 48.6253 73.0098 48.811 72.6935L48.3297 71.6828L49.3954 71.9372C49.6566 71.6897 49.8973 71.4009 50.1379 71.1328L50.296 72.2466L51.3136 72.721L50.296 73.2229C50.241 73.601 50.186 73.9723 50.1173 74.3367Z"
        fill="#4C0055"
      />
      <path
        d="M44.2532 75.5873L43.6344 74.6454C43.2563 74.6454 42.8781 74.6248 42.5 74.6042C42.7544 74.3223 43.0019 74.0541 43.2357 73.7654L42.9263 72.6859C43.2563 72.8303 43.5932 72.961 43.9301 73.1053C44.2257 72.8853 44.5145 72.6791 44.7964 72.4453L44.7689 73.5729C45.0714 73.786 45.3808 73.9923 45.697 74.1985C45.3326 74.3085 44.9751 74.4391 44.6107 74.5216L44.2532 75.5873Z"
        fill="#4C0055"
      />
      <path
        d="M38.2707 75.8526L37.8169 74.8282L36.7031 74.5945L37.5694 73.8863L37.4388 72.7656C37.7413 72.9719 38.0507 73.1644 38.3601 73.35L39.3226 72.8413L39.1095 73.9413C39.3707 74.2094 39.6458 74.4707 39.9208 74.7182C39.5495 74.787 39.1714 74.8076 38.7932 74.8557L38.2638 75.8595L38.2707 75.8526Z"
        fill="#4C0055"
      />
      <path
        d="M32.3172 75.1589L32.0422 74.052L30.9766 73.6601L31.9529 73.0894L32.0079 71.9688L32.8191 72.7044L33.8573 72.34L33.4654 73.3988L34.1392 74.2995L33.0048 74.2513L32.3172 75.1589Z"
        fill="#4C0055"
      />
      <path
        d="M26.5725 73.4684L26.4832 72.3409C26.1532 72.1621 25.8231 71.9765 25.5 71.7702C25.8506 71.6465 26.2013 71.5227 26.5519 71.3783L26.7926 70.2852L27.4801 71.1308C27.8376 71.0827 28.1951 71.0346 28.5595 70.9589L28.0026 71.9352L28.5183 72.9253C28.147 72.8496 27.7757 72.7809 27.4045 72.6984L26.5863 73.4684H26.5725Z"
        fill="#4C0055"
      />
      <path
        d="M21.1744 70.8567C21.2019 70.4786 21.2294 70.1142 21.2638 69.736L20.3906 69.0004L21.4907 68.7872L21.9101 67.7422C22.0751 68.0653 22.2538 68.3885 22.4395 68.6979H23.5326L22.8245 69.571C22.9276 69.9285 23.0445 70.2929 23.1682 70.6436C22.8107 70.5198 22.467 70.3686 22.1094 70.2242L21.1675 70.8498L21.1744 70.8567Z"
        fill="#4C0055"
      />
      <path
        d="M16.28 67.3958L16.5619 66.2957C16.3144 66.0069 16.0531 65.7388 15.8125 65.4432C16.1838 65.4432 16.5688 65.4225 16.94 65.4088L17.5244 64.4531L17.8957 65.4844C18.2464 65.56 18.6176 65.6082 18.9751 65.6701L18.1295 66.4126L18.2945 67.5264L17.3182 66.942C16.9744 67.107 16.6307 67.2582 16.2869 67.4026L16.28 67.3958Z"
        fill="#4C0055"
      />
      <path
        d="M12.0281 63.1746L12.475 62.1434C12.2688 61.8271 12.0831 61.4902 11.8906 61.1671L12.9975 61.3252L13.7332 60.4727C13.7813 60.837 13.857 61.1877 13.9326 61.5452L14.957 61.9096L14.0082 62.5009C13.9876 62.879 13.9876 63.2434 13.9876 63.6215C13.692 63.3878 13.4032 63.1403 13.1213 62.8859L12.0281 63.1746Z"
        fill="#4C0055"
      />
      <path
        d="M8.51562 58.3223L9.14128 57.3735L8.70813 56.3216L9.78755 56.6516L10.647 55.9297L10.6607 57.0229C10.977 57.2016 11.307 57.3735 11.6233 57.5454L10.5851 57.9717L10.3788 59.0786L9.65005 58.2123C9.27878 58.2535 8.89377 58.2948 8.5225 58.3223H8.51562Z"
        fill="#4C0055"
      />
      <path
        d="M5.85938 52.9499L6.62253 52.118C6.52628 51.7536 6.45753 51.3755 6.3819 51.0042C6.71879 51.1761 7.04193 51.3549 7.37882 51.513L8.34823 50.9492C8.28635 51.3067 8.2451 51.6642 8.1901 52.0286C8.47886 52.2555 8.747 52.4893 9.04264 52.7024L7.94259 52.9568L7.56445 54.0087C7.37194 53.6856 7.15193 53.3693 6.98005 53.0324L5.85938 52.9499Z"
        fill="#4C0055"
      />
      <path
        d="M4.125 47.2116C4.42064 46.9779 4.7094 46.7441 5.01879 46.5172L4.96379 45.3828L5.87133 46.0497L6.92325 45.6509C6.80637 45.9947 6.67573 46.3385 6.57948 46.6891L7.31514 47.4935L6.19446 47.5554C6.00883 47.8785 5.80945 48.2086 5.63757 48.5317C5.49318 48.1811 5.36943 47.8235 5.23192 47.4729L4.13188 47.2048L4.125 47.2116Z"
        fill="#4C0055"
      />
      <path
        d="M3.35938 41.2693L4.36317 40.733L4.48005 39.6055L5.27758 40.4099L6.37763 40.1899L5.86886 41.1593C6.05449 41.4687 6.26763 41.7781 6.46701 42.0737L5.34634 41.9499L4.63818 42.8231L4.40442 41.7093C4.05378 41.5649 3.70314 41.4205 3.36625 41.2624L3.35938 41.2693Z"
        fill="#4C0055"
      />
      <path
        d="M3.57812 35.2818L4.65067 34.9174C4.74693 34.553 4.8363 34.1817 4.96006 33.8242C5.16632 34.1336 5.37258 34.443 5.59946 34.7455L6.72014 34.7043L6.06698 35.5774C6.20449 35.9143 6.33512 36.2512 6.50013 36.5812L5.41383 36.2787L4.58192 37.0281C4.56129 36.65 4.53379 36.2718 4.52692 35.8937L3.57812 35.2887V35.2818Z"
        fill="#4C0055"
      />
      <path
        d="M4.78125 29.4093L5.88817 29.2236C6.04631 28.8799 6.21819 28.543 6.38319 28.1992L6.85759 29.2099L7.97139 29.3611C7.69638 29.6018 7.44199 29.8562 7.18073 30.1106L7.43512 31.1693L6.42445 30.695C6.09443 30.8875 5.78504 31.0868 5.47566 31.2931C5.50316 30.915 5.55816 30.5437 5.62004 30.1656L4.78125 29.4093Z"
        fill="#4C0055"
      />
      <path
        d="M6.92875 23.8149H8.06317L8.70945 22.8867L9.02572 23.973L10.0983 24.303L9.18385 24.9081C9.20447 25.2724 9.24573 25.6437 9.27323 26.0012L8.34506 25.3618L7.30002 25.7881L7.63003 24.7018C7.3894 24.413 7.14876 24.1105 6.92188 23.8149H6.92875Z"
        fill="#4C0055"
      />
      <path
        d="M9.97563 18.6569L11.0894 18.8425C11.3438 18.5606 11.6188 18.2994 11.887 18.0312C11.9213 18.4094 11.9557 18.7738 12.0038 19.145L13.0076 19.6469C12.6776 19.7913 12.3545 19.9495 12.0176 20.1007C11.9832 20.4651 11.9351 20.8157 11.9145 21.187L11.1032 20.4032L10.0169 20.6645C10.1819 20.3276 10.3263 19.9701 10.5119 19.6401L9.96875 18.6569H9.97563Z"
        fill="#4C0055"
      />
      <path
        d="M13.8288 14.0642C14.1794 14.1811 14.5438 14.3049 14.9013 14.4355L15.8157 13.7617L15.7607 14.8893L16.6683 15.5493C16.3176 15.6387 15.9601 15.7212 15.6095 15.8312L15.3413 16.89L14.6744 15.9893C14.3032 16.0099 13.925 16.0237 13.5469 16.0581C13.76 15.7487 13.9938 15.4462 14.2138 15.1368L13.8288 14.0711V14.0642Z"
        fill="#4C0055"
      />
      <path
        d="M18.3853 10.1748L19.3823 10.7111C19.7123 10.5324 20.0492 10.3536 20.3998 10.2023C20.3104 10.5667 20.221 10.9243 20.1523 11.2955L20.9361 12.0931L19.8498 12.2031C19.6916 12.5331 19.5404 12.8562 19.4029 13.2L18.8872 12.1962L17.7734 12.0862C18.0416 11.818 18.3028 11.543 18.571 11.2749L18.3785 10.168L18.3853 10.1748Z"
        fill="#4C0055"
      />
      <path
        d="M23.515 7.08203C23.8176 7.30892 24.1063 7.5358 24.402 7.76956L25.4883 7.43955L25.0689 8.47772L25.7151 9.39901C25.3507 9.36463 24.9864 9.33025 24.622 9.32338L24.0169 10.2309L23.68 9.16525C23.3225 9.06212 22.9581 8.95211 22.5938 8.86961C22.8894 8.62897 23.2125 8.43646 23.5219 8.21645L23.5082 7.08203H23.515Z"
        fill="#4C0055"
      />
      <path
        d="M29.0869 4.875L29.8501 5.71379C30.2282 5.65878 30.5995 5.59691 30.9776 5.55565C30.7714 5.86504 30.5789 6.20193 30.3932 6.5182L30.8745 7.52886L29.8088 7.27448C29.5476 7.52199 29.3069 7.81075 29.0663 8.07889L28.9082 6.96509L27.8906 6.49069L28.915 5.9888C28.97 5.61066 29.0251 5.23939 29.0869 4.875Z"
        fill="#4C0055"
      />
      <path
        d="M34.9516 3.62109L35.5704 4.56301C35.9485 4.56301 36.3267 4.58364 36.7048 4.60426C36.4504 4.88615 36.2029 5.15429 35.9692 5.44305L36.2786 6.52247C35.9485 6.37809 35.6117 6.24746 35.2748 6.10308C34.9791 6.32309 34.6904 6.52934 34.4085 6.7631L34.436 5.63556C34.1335 5.42242 33.8241 5.21616 33.5078 5.0099C33.8722 4.8999 34.2297 4.76927 34.5941 4.68676L34.9516 3.62109Z"
        fill="#4C0055"
      />
      <path
        d="M40.9313 3.35453L41.3851 4.37895L42.4989 4.61271L41.6326 5.32087L41.7632 6.44154C41.4607 6.23528 41.1513 6.04277 40.8419 5.85714L39.8794 6.36591L40.0925 5.26586C39.8313 4.99773 39.5563 4.73647 39.2812 4.48896C39.6525 4.4202 40.0307 4.39958 40.4088 4.35145L40.9382 3.34766L40.9313 3.35453Z"
        fill="#4C0055"
      />
      <path
        d="M46.8844 4.04688L47.1595 5.1538L48.2251 5.54569L47.2488 6.11634L47.1938 7.23701L46.3826 6.50136L45.3444 6.86575L45.7363 5.80695L45.0625 4.90629L46.1969 4.95441L46.8844 4.04688Z"
        fill="#4C0055"
      />
      <path
        d="M52.6354 5.74219L52.7248 6.86974C53.0548 7.04849 53.3848 7.23413 53.7079 7.44039C53.3573 7.56414 53.0067 7.6879 52.656 7.83228L52.4154 8.92545L51.7279 8.07979C51.3703 8.12792 51.0128 8.17604 50.6484 8.25167L51.2053 7.27538L50.6897 6.28534C51.061 6.36096 51.4322 6.42972 51.8035 6.51222L52.6216 5.74219H52.6354Z"
        fill="#4C0055"
      />
      <path
        d="M58.0379 8.35547C58.0104 8.73361 57.9829 9.098 57.9485 9.47614L58.8217 10.2118L57.7216 10.4249L57.3023 11.47C57.1372 11.1468 56.9585 10.8237 56.7729 10.5143H55.6797L56.3878 9.64115C56.2847 9.28363 56.1678 8.91924 56.0441 8.5686C56.4016 8.69236 56.7454 8.84361 57.1029 8.988L58.0448 8.36234L58.0379 8.35547Z"
        fill="#4C0055"
      />
      <path
        d="M62.9217 11.8103L62.6398 12.9104C62.8873 13.1991 63.1486 13.4673 63.3892 13.7629C63.0179 13.7629 62.6329 13.7835 62.2616 13.7973L61.6773 14.7529L61.306 13.7217C60.9553 13.646 60.5841 13.5979 60.2266 13.536L61.0722 12.7935L60.9072 11.6797L61.8835 12.2641C62.2273 12.0991 62.571 11.9478 62.9148 11.8034L62.9217 11.8103Z"
        fill="#4C0055"
      />
      <path
        d="M67.1789 16.0328L66.732 17.0641C66.9382 17.3804 67.1239 17.7173 67.3164 18.0404L66.2095 17.8823L65.4738 18.7348C65.4257 18.3704 65.3501 18.0198 65.2744 17.6623L64.25 17.2979L65.1988 16.7066C65.2194 16.3285 65.2194 15.9641 65.2194 15.5859C65.5151 15.8197 65.8038 16.0672 66.0857 16.3216L67.1789 16.0328Z"
        fill="#4C0055"
      />
      <path
        d="M70.6858 20.8852L70.0601 21.834L70.4932 22.8859L69.4138 22.5559L68.5544 23.2778L68.5407 22.1846C68.2244 22.0059 67.8944 21.834 67.5781 21.6621L68.6163 21.2358L68.8226 20.1289L69.5513 20.9952C69.9226 20.9539 70.3076 20.9127 70.6789 20.8852H70.6858Z"
        fill="#4C0055"
      />
      <path
        d="M73.3395 26.258L72.5764 27.0899C72.6726 27.4543 72.7414 27.8325 72.817 28.2037C72.4801 28.0318 72.157 27.8531 71.8201 27.6949L70.8507 28.2587C70.9125 27.9012 70.9538 27.5437 71.0088 27.1793C70.72 26.9524 70.4519 26.7187 70.1562 26.5055L71.2563 26.2511L71.6344 25.1992C71.8269 25.5224 72.047 25.8386 72.2188 26.1755L73.3395 26.258Z"
        fill="#4C0055"
      />
      <path
        d="M75.0808 31.9958C74.7851 32.2296 74.4964 32.4634 74.187 32.6902L74.242 33.8247L73.3344 33.1578L72.2825 33.5565C72.3994 33.2128 72.53 32.869 72.6263 32.5184L71.8906 31.714L73.0113 31.6521C73.1969 31.3289 73.3963 30.9989 73.5682 30.6758C73.7126 31.0264 73.8363 31.3839 73.9738 31.7346L75.0739 32.0027L75.0808 31.9958Z"
        fill="#4C0055"
      />
      <path
        d="M75.842 37.9366L74.8382 38.4729L74.7213 39.6005L73.9238 38.796L72.8238 39.0161L73.3325 38.0466C73.1469 37.7372 72.9338 37.4279 72.7344 37.1322L73.855 37.256L74.5632 36.3828L74.797 37.4966C75.1476 37.641 75.4982 37.7854 75.8351 37.9435L75.842 37.9366Z"
        fill="#4C0055"
      />
      <path
        d="M32.0556 54.9043L33.4685 50.9068H43.8292L45.242 54.9043M43.8292 59.3461C43.4545 59.3461 43.0951 59.2057 42.8302 58.9558C42.5652 58.7059 42.4163 58.367 42.4163 58.0136C42.4163 57.6602 42.5652 57.3212 42.8302 57.0713C43.0951 56.8214 43.4545 56.6811 43.8292 56.6811C44.2039 56.6811 44.5632 56.8214 44.8282 57.0713C45.0931 57.3212 45.242 57.6602 45.242 58.0136C45.242 58.367 45.0931 58.7059 44.8282 58.9558C44.5632 59.2057 44.2039 59.3461 43.8292 59.3461ZM33.4685 59.3461C33.0938 59.3461 32.7344 59.2057 32.4694 58.9558C32.2045 58.7059 32.0556 58.367 32.0556 58.0136C32.0556 57.6602 32.2045 57.3212 32.4694 57.0713C32.7344 56.8214 33.0938 56.6811 33.4685 56.6811C33.8432 56.6811 34.2025 56.8214 34.4675 57.0713C34.7324 57.3212 34.8813 57.6602 34.8813 58.0136C34.8813 58.367 34.7324 58.7059 34.4675 58.9558C34.2025 59.2057 33.8432 59.3461 33.4685 59.3461ZM45.1666 50.4626C44.9783 49.9473 44.4508 49.5742 43.8292 49.5742H33.4685C32.8468 49.5742 32.3194 49.9473 32.131 50.4626L30.1719 55.7927V62.8995C30.1719 63.1351 30.2711 63.3611 30.4477 63.5277C30.6244 63.6943 30.864 63.7879 31.1138 63.7879H32.0556C32.3054 63.7879 32.545 63.6943 32.7217 63.5277C32.8983 63.3611 32.9975 63.1351 32.9975 62.8995V62.0112H44.3001V62.8995C44.3001 63.1351 44.3993 63.3611 44.576 63.5277C44.7526 63.6943 44.9922 63.7879 45.242 63.7879H46.1839C46.4337 63.7879 46.6732 63.6943 46.8499 63.5277C47.0265 63.3611 47.1258 63.1351 47.1258 62.8995V55.7927L45.1666 50.4626Z"
        fill="white"
      />
      <ellipse
        cx="43.8363"
        cy="52.5833"
        rx="7.65659"
        ry="7.38018"
        fill="#FE5000"
      />
      <path
        d="M53.0052 62.0138L49.3339 58.344M49.3339 58.344C50.0787 57.5994 50.6695 56.7155 51.0727 55.7427C51.4758 54.7699 51.6832 53.7273 51.6832 52.6743C51.6832 51.6214 51.4758 50.5787 51.0727 49.6059C50.6695 48.6331 50.0787 47.7492 49.3339 47.0047C48.589 46.2601 47.7047 45.6695 46.7316 45.2666C45.7584 44.8636 44.7153 44.6562 43.6619 44.6562C42.6086 44.6563 41.5655 44.8636 40.5923 45.2666C39.6191 45.6695 38.7349 46.2601 37.99 47.0047C36.4857 48.5084 35.6406 50.5478 35.6406 52.6743C35.6406 54.8009 36.4857 56.8403 37.99 58.344C39.4943 59.8477 41.5345 60.6924 43.6619 60.6924C45.7893 60.6924 47.8296 59.8477 49.3339 58.344Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.6408 56.134V53.4327H44.5002V56.134H46.8246V52.5323H48.2192L43.5705 48.4805L38.9219 52.5323H40.3165V56.134H42.6408Z"
        fill="white"
      />
      <path
        d="M25.4505 26.1738V19.0782H27.2364V21.9527H29.6716V19.0782H31.448V26.1738H29.6716V23.2324H27.2364V26.1738H25.4505Z"
        fill="#4C0055"
      />
      <path
        d="M35.8991 26.2502C35.1988 26.263 34.6003 26.1213 34.1037 25.8253C33.6103 25.526 33.2346 25.1011 32.9768 24.5503C32.7221 23.9964 32.6011 23.3438 32.6139 22.5926C32.6298 21.7522 32.7842 21.0725 33.0771 20.5536C33.3731 20.0316 33.7758 19.648 34.2851 19.4029C34.7945 19.1546 35.377 19.0209 36.0328 19.0018C36.7045 18.9827 37.2807 19.1195 37.7614 19.4124C38.242 19.7021 38.6081 20.1207 38.8596 20.6682C39.1143 21.2158 39.2353 21.8636 39.2225 22.6117C39.2066 23.4521 39.057 24.1381 38.7737 24.6697C38.4903 25.2013 38.102 25.5945 37.6086 25.8491C37.1151 26.1038 36.5453 26.2375 35.8991 26.2502ZM35.9277 24.9705C36.3862 24.9705 36.7475 24.7859 37.0117 24.4166C37.2759 24.0442 37.408 23.4266 37.408 22.5639C37.408 22.1183 37.3571 21.7267 37.2552 21.3893C37.1533 21.0518 36.9989 20.7892 36.792 20.6014C36.5851 20.4136 36.3225 20.3197 36.0041 20.3197C35.7145 20.3197 35.4502 20.3913 35.2115 20.5345C34.9727 20.6778 34.7817 20.9181 34.6385 21.2556C34.4984 21.593 34.4284 22.0546 34.4284 22.6403C34.4284 23.0924 34.4841 23.4935 34.5955 23.8436C34.7069 24.1938 34.8725 24.4692 35.0921 24.6697C35.315 24.8703 35.5935 24.9705 35.9277 24.9705Z"
        fill="#4C0055"
      />
      <path
        d="M39.9518 26.1738L40.7731 19.0782H43.1702L44.0297 22.7167L44.3544 24.3784L44.6123 22.7167L45.3763 19.0782H47.6969L48.5755 26.1738H46.8661L46.57 22.4398L46.465 20.3961L46.0257 22.4971L45.0707 26.212H43.4949L42.4635 22.4971L41.9955 20.3961L41.8714 22.4398L41.5944 26.1738H39.9518Z"
        fill="#4C0055"
      />
      <path
        d="M49.6614 26.1738V19.0782H54.1499V20.4056H51.4282V21.905H53.5292V23.1942H51.4282V24.8177H54.4269V26.1738H49.6614Z"
        fill="#4C0055"
      />
      <path d="M14 35.7239V29.3378H15.6159V35.7239H14Z" fill="#4C0055" />
      <path
        d="M17.0784 35.7239V29.3378H18.574L20.7571 32.8446L21.5736 34.5636H21.2814L21.0236 32.1484V29.3378H22.4761V35.7239H20.8689L18.7974 32.3031L17.8606 30.3434H18.213L18.531 32.9649V35.7239H17.0784Z"
        fill="#4C0055"
      />
      <path
        d="M25.657 35.7927C25.3533 35.7927 25.0625 35.7697 24.7846 35.7239C24.5096 35.6809 24.2704 35.6294 24.067 35.5692C23.8635 35.5062 23.7203 35.4489 23.6372 35.3973L23.8349 34.151C23.9409 34.2112 24.0841 34.2728 24.2646 34.3358C24.4451 34.3988 24.6443 34.4533 24.862 34.4991C25.0826 34.5421 25.3046 34.5636 25.5281 34.5636C25.8289 34.5636 26.0467 34.5206 26.1813 34.4346C26.3189 34.3487 26.3876 34.2112 26.3876 34.0221C26.3876 33.8846 26.3461 33.7685 26.263 33.674C26.1799 33.5766 26.0424 33.4777 25.8504 33.3775C25.6613 33.2772 25.4078 33.1511 25.0898 32.9993C24.8205 32.8703 24.5726 32.7185 24.3463 32.5437C24.12 32.3661 23.938 32.1541 23.8005 31.9077C23.663 31.6584 23.5942 31.3633 23.5942 31.0224C23.5942 30.7302 23.6487 30.4752 23.7575 30.2575C23.8693 30.0368 24.0254 29.8535 24.226 29.7074C24.4294 29.5613 24.67 29.4524 24.9479 29.3808C25.2259 29.3063 25.5338 29.269 25.8719 29.269C26.3532 29.269 26.7457 29.3134 27.0494 29.4022C27.3531 29.4911 27.5623 29.5698 27.6769 29.6386L27.4276 30.8333C27.2987 30.733 27.0996 30.6414 26.8303 30.5583C26.5638 30.4723 26.2902 30.4294 26.0094 30.4294C25.8089 30.4294 25.6441 30.4523 25.5152 30.4981C25.3863 30.5411 25.2903 30.6027 25.2273 30.6829C25.1643 30.7631 25.1327 30.8562 25.1327 30.9622C25.1327 31.0912 25.17 31.2058 25.2445 31.306C25.3218 31.4035 25.4407 31.5009 25.6012 31.5983C25.7645 31.6928 25.9751 31.7988 26.2329 31.9163C26.5079 32.0395 26.7529 32.1656 26.9678 32.2945C27.1855 32.4234 27.3703 32.5638 27.5222 32.7156C27.674 32.8675 27.7886 33.0408 27.866 33.2356C27.9462 33.4276 27.9863 33.6496 27.9863 33.9018C27.9863 34.2828 27.896 34.6151 27.7156 34.8988C27.5379 35.1824 27.2758 35.403 26.9291 35.5606C26.5824 35.7153 26.1584 35.7927 25.657 35.7927Z"
        fill="#4C0055"
      />
      <path
        d="M28.9974 35.7239V29.3378H29.6248C29.917 29.3378 30.2093 29.3263 30.5015 29.3034C30.7966 29.2805 31.1031 29.269 31.4212 29.269C31.934 29.269 32.3738 29.3421 32.7405 29.4882C33.1072 29.6314 33.388 29.8678 33.5828 30.1973C33.7805 30.5268 33.8793 30.968 33.8793 31.5209C33.8793 32.2773 33.6544 32.8603 33.2046 33.27C32.7577 33.6797 32.1088 33.8846 31.2579 33.8846C31.0917 33.8846 30.9585 33.8831 30.8582 33.8803C30.7579 33.8774 30.6734 33.876 30.6046 33.876V35.7239H28.9974ZM30.6046 32.6984C30.6963 32.707 30.7952 32.7156 30.9012 32.7242C31.0072 32.73 31.1261 32.7328 31.2579 32.7328C31.4584 32.7328 31.6375 32.6941 31.795 32.6168C31.9555 32.5366 32.0815 32.4076 32.1732 32.23C32.2678 32.0495 32.315 31.8103 32.315 31.5123C32.315 31.1685 32.2262 30.8921 32.0486 30.6829C31.8738 30.4709 31.5988 30.3721 31.2235 30.3864C31.1261 30.3864 31.0215 30.3907 30.9098 30.3993C30.798 30.405 30.6963 30.415 30.6046 30.4294V32.6984Z"
        fill="#4C0055"
      />
      <path
        d="M34.8057 35.7239V29.3378H38.8454V30.5325H36.3958V31.8819H38.2867V33.0422H36.3958V34.5034H39.0947V35.7239H34.8057Z"
        fill="#4C0055"
      />
      <path
        d="M39.6076 32.5437C39.6076 32.0022 39.6907 31.5281 39.8569 31.1213C40.023 30.7116 40.2551 30.3692 40.5531 30.0941C40.851 29.8191 41.1977 29.6128 41.5931 29.4753C41.9884 29.3378 42.4153 29.269 42.8737 29.269C43.1688 29.269 43.4152 29.2862 43.6129 29.3206C43.8135 29.3521 43.9854 29.3879 44.1286 29.428C44.2747 29.4681 44.4122 29.5011 44.5412 29.5269L44.3349 30.9021C44.2747 30.862 44.1888 30.8133 44.077 30.756C43.9682 30.6958 43.8249 30.6442 43.6473 30.6013C43.4725 30.5554 43.2576 30.5325 43.0027 30.5325C42.676 30.5325 42.3809 30.607 42.1174 30.756C41.8538 30.9021 41.6446 31.1184 41.4899 31.4049C41.3352 31.6885 41.2579 32.0366 41.2579 32.4492C41.2579 32.8703 41.3252 33.2385 41.4598 33.5537C41.5974 33.8688 41.8008 34.1152 42.0701 34.2928C42.3394 34.4676 42.6732 34.555 43.0714 34.555C43.2519 34.555 43.4353 34.5335 43.6215 34.4905C43.8077 34.4447 43.9753 34.3902 44.1243 34.3272C44.2733 34.2642 44.3836 34.2026 44.4552 34.1424V35.4575C44.3463 35.5033 44.2174 35.552 44.0684 35.6036C43.9223 35.6551 43.7519 35.6996 43.557 35.7368C43.3622 35.774 43.1402 35.7927 42.8909 35.7927C42.1861 35.7927 41.5888 35.6594 41.0989 35.393C40.6118 35.1265 40.2408 34.7498 39.9858 34.2627C39.7337 33.7757 39.6076 33.2027 39.6076 32.5437Z"
        fill="#4C0055"
      />
      <path
        d="M46.6002 35.7239V30.5669H44.8983V29.3378H49.9178V30.5669H48.216V35.7239H46.6002Z"
        fill="#4C0055"
      />
      <path
        d="M50.8143 35.7239V29.3378H52.4302V35.7239H50.8143Z"
        fill="#4C0055"
      />
      <path
        d="M56.5229 35.7927C55.8926 35.8041 55.3539 35.6766 54.907 35.4102C54.4629 35.1409 54.1249 34.7584 53.8928 34.2627C53.6636 33.7642 53.5547 33.1769 53.5662 32.5008C53.5805 31.7444 53.7195 31.1327 53.983 30.6657C54.2495 30.1959 54.6119 29.8506 55.0703 29.63C55.5287 29.4065 56.053 29.2862 56.6432 29.269C57.2477 29.2518 57.7663 29.375 58.1989 29.6386C58.6315 29.8993 58.961 30.2761 59.1873 30.7689C59.4165 31.2616 59.5254 31.8447 59.514 32.5179C59.4996 33.2743 59.365 33.8917 59.11 34.3702C58.855 34.8486 58.5055 35.2025 58.0614 35.4317C57.6173 35.6609 57.1045 35.7812 56.5229 35.7927ZM56.5487 34.6409C56.9612 34.6409 57.2864 34.4748 57.5242 34.1424C57.762 33.8072 57.8809 33.2514 57.8809 32.475C57.8809 32.0739 57.835 31.7215 57.7434 31.4178C57.6517 31.1141 57.5127 30.8777 57.3265 30.7087C57.1403 30.5397 56.9039 30.4551 56.6174 30.4551C56.3567 30.4551 56.1189 30.5196 55.904 30.6485C55.6891 30.7775 55.5173 30.9938 55.3883 31.2975C55.2623 31.6011 55.1992 32.0166 55.1992 32.5437C55.1992 32.9506 55.2494 33.3116 55.3496 33.6267C55.4499 33.9419 55.5989 34.1897 55.7966 34.3702C55.9971 34.5507 56.2478 34.6409 56.5487 34.6409Z"
        fill="#4C0055"
      />
      <path
        d="M60.5657 35.7239V29.3378H62.0612L64.2444 32.8446L65.0609 34.5636H64.7687L64.5108 32.1484V29.3378H65.9634V35.7239H64.3561L62.2847 32.3031L61.3479 30.3434H61.7002L62.0183 32.9649V35.7239H60.5657Z"
        fill="#4C0055"
      />
    </svg>
  );
};
