import React from "react";

export const ProfileIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56355 19.7258C5.96837 18.7529 6.55583 17.8692 7.29321 17.1239C8.02836 16.3765 8.89921 15.7806 9.85767 15.369C9.86625 15.3647 9.87484 15.3625 9.88342 15.3581C8.54647 14.3784 7.67735 12.7824 7.67735 10.9818C7.67735 7.99884 10.0594 5.58203 12.9994 5.58203C15.9394 5.58203 18.3214 7.99884 18.3214 10.9818C18.3214 12.7824 17.4523 14.3784 16.1154 15.3603C16.124 15.3647 16.1325 15.3669 16.1411 15.3712C17.1025 15.7827 17.9652 16.3728 18.7056 17.1261C19.4423 17.872 20.0296 18.7556 20.4352 19.728C20.8337 20.68 21.0486 21.7007 21.0683 22.7349C21.0689 22.7581 21.0649 22.7812 21.0565 22.8029C21.0481 22.8245 21.0356 22.8442 21.0196 22.8609C21.0036 22.8775 20.9844 22.8907 20.9633 22.8997C20.9422 22.9088 20.9195 22.9134 20.8966 22.9134H19.609C19.5146 22.9134 19.4395 22.8372 19.4374 22.7436C19.3944 21.0627 18.7292 19.4885 17.5532 18.2953C16.3364 17.0608 14.7205 16.3815 12.9994 16.3815C11.2783 16.3815 9.66238 17.0608 8.44561 18.2953C7.26961 19.4885 6.60435 21.0627 6.56143 22.7436C6.55929 22.8394 6.48418 22.9134 6.38975 22.9134H5.10216C5.07925 22.9134 5.05657 22.9088 5.03546 22.8997C5.01434 22.8907 4.99522 22.8775 4.97922 22.8609C4.96322 22.8442 4.95067 22.8245 4.9423 22.8029C4.93393 22.7812 4.92991 22.7581 4.93048 22.7349C4.95194 21.6941 5.1644 20.6817 5.56355 19.7258ZM12.9994 14.7267C13.9844 14.7267 14.9115 14.337 15.6089 13.6294C16.3064 12.9217 16.6905 11.9811 16.6905 10.9818C16.6905 9.98237 16.3064 9.04177 15.6089 8.33415C14.9115 7.62652 13.9844 7.23679 12.9994 7.23679C12.0144 7.23679 11.0873 7.62652 10.3899 8.33415C9.69243 9.04177 9.3083 9.98237 9.3083 10.9818C9.3083 11.9811 9.69243 12.9217 10.3899 13.6294C11.0873 14.337 12.0144 14.7267 12.9994 14.7267Z"
        fill="#4C0055"
      />
      <circle cx="13" cy="13" r="12" stroke="#4C0055" strokeWidth="2" />
    </svg>
  );
};
