export const CarDashboardIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 86.469 29.635"
      className={className}
      fill="currentColor"
    >
      <g id="Layer_x0020_1" transform="translate(-4682.243 -5786.333)">
        <g id="_1466911548912" transform="translate(4682.243 5786.333)">
          <path
            id="Path_15372"
            data-name="Path 15372"
            d="M4709.47,5842.014c2.808.859.8-2.655,1.9-7.027a11.362,11.362,0,0,1,3.452-5.708c-.123,5.815,1.074,9.221,4.035,12.4,6.015,6.443,17.8,6.091,23.107-2.225,2.731-4.266,3.038-8.071,1.5-13.457,1.933-.2,31.254-.045,34.046-.045,5.063,0,9.758-.2,13.241,3.851a12.656,12.656,0,0,1,2.946,5.723c.828,4.143-.921,7.319,1.779,6.49.492-1.841.123-6.214-.43-8.1a13.6,13.6,0,0,0-8.73-8.976c-2.669-.89-5.2-.706-8.163-.706h-17.706c-5.539,0-12.474.368-17.859-.046a14.676,14.676,0,0,0-9.359-7.242,14.914,14.914,0,0,0-16.678,7.242c-.552,1.043-.69,1.979-1.473,2.624-2.409,1.933-4.25,3.6-5.278,7.44A21.421,21.421,0,0,0,4709.47,5842.014Z"
            transform="translate(-4709.243 -5816.506)"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path
            id="Path_15373"
            data-name="Path 15373"
            d="M5189.524,5926.025a13.131,13.131,0,1,0,14.438,11.676A13.094,13.094,0,0,0,5189.524,5926.025Z"
            transform="translate(-5170.58 -5924.253)"
            fill="#eceaea"
            fillRule="evenodd"
          />
          <path
            id="Subtraction_1"
            data-name="Subtraction 1"
            d="M10849.23-11281.605a10.867,10.867,0,0,1-7.318-2.814,10.767,10.767,0,0,1-3.484-6.7,10.533,10.533,0,0,1,2.367-8.268,11.355,11.355,0,0,1,7.2-3.883,10.521,10.521,0,0,1,1.416-.1,10.492,10.492,0,0,1,7.15,2.873,11.067,11.067,0,0,1,3.443,6.6,10.8,10.8,0,0,1-2.309,8.256,10.915,10.915,0,0,1-7,3.939A11.957,11.957,0,0,1,10849.23-11281.605Zm8.453-14.576a16.329,16.329,0,0,0-3.467,1.828c.008.5.051.939.086,1.324l0,.029a4.126,4.126,0,0,1-.766,3.373,3.441,3.441,0,0,1-1.959,1.66l-.012.006a7.162,7.162,0,0,0-1.463.729v3.848a9.233,9.233,0,0,0,6.736-4.018c2.078-3,1.588-5.291.846-8.756l-.006-.023Z"
            transform="translate(-10828.891 11307.297)"
            fill="currentColor"
          />
          <path
            id="Path_15375"
            data-name="Path 15375"
            d="M5444.829,6552.78l.046-3.82c-1.687-1.074-2.3-.553-3.468-2.348-1.12-1.734-.89-2.685-.583-4.818-1.289-.476-2.163-1.411-3.544-1.795-.384,1.811-.921,2.562-.629,4.863C5437.127,6548.776,5440.472,6552.658,5444.829,6552.78Z"
            transform="translate(-5425.408 -6528.899)"
            fill="#eceaea"
            fillRule="evenodd"
          />
          <path
            id="Path_15376"
            data-name="Path 15376"
            d="M5556,6189.814a10.757,10.757,0,0,0,3.56,1.964,4.836,4.836,0,0,1,6.106-1.258c.783.414,1.059.9,1.8,1.228l3.252-1.857c-.936-2.1-4.465-3.9-7.364-3.9C5560.112,6185.979,5557,6187.866,5556,6189.814Z"
            transform="translate(-5543.008 -6180.325)"
            fill="#eceaea"
            fillRule="evenodd"
          />
          <path
            id="Path_15378"
            data-name="Path 15378"
            d="M5808.787,6555.506c-4.572,1.934-1.55,7.979,2.455,6.429C5815.492,6560.277,5812.991,6553.726,5808.787,6555.506Z"
            transform="translate(-5789.658 -6543.868)"
            fill="#eceaea"
            fillRule="evenodd"
          />
          <path
            id="Path_15379"
            data-name="Path 15379"
            d="M8793.261,6856.43c0,8.086-1.672,6.813,8.332,6.736,1.473-.015,1.933-.2,1.488-1.55-2.133-.659-5.815.246-8.1-.292q-.025-1.91,0-3.82c.015-1.642.429-2.823-1.4-2.578A2.118,2.118,0,0,0,8793.261,6856.43Z"
            transform="translate(-8730.496 -6838.962)"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path
            id="Path_15380"
            data-name="Path 15380"
            d="M7482.363,7003.408c-1.181-.629-5.155-.291-6.843-.291-1.243,0-6.981-.614-5.631,1.335.491.69,8.438.368,10.387.383C7481.8,7004.851,7482.809,7005.127,7482.363,7003.408Z"
            transform="translate(-7427.332 -6984.821)"
            fill="currentColor"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
