import { AuthConstants } from "../constants/auth.constants";
import { Buffer } from "buffer";
import moment from "moment";

class AuthHelper {
  /**
   * This method is responsible to parse JWT Token and get Claims data.
   * @param {string} token - JWT Token
   * @returns User details of JWT Claims in JSON Formate.
   */
  static parseJwt(token) {
    try {
      const data = Buffer.from(token.split(".")[1], "base64");
      return JSON.parse(data);
    } catch (e) {
      console.error("Error in AuthHelper.parseJwt()", e);
      return null;
    }
  }

  /**
   * This method is responsible to parse JWT Token and check token exp date and validate token.
   * @returns {boolean} true/false
   */
  static IsTokenValid() {
    const token = localStorage.getItem(AuthConstants.ApplicationToken);
    const token_data = AuthHelper.parseJwt(token);
    if (token_data) {
      const currentDate = moment().unix();
      const expiryDate = token_data.exp;
      return currentDate < expiryDate;
    }
    return false;
  }

  /**
   * This method is responsible to check user privileges with user token claims data.
   * @param {Array<string> | string} privilege - privilege
   * @returns true/false.
   */
  static checkPrivileges(privilege) {
    const userPrivileges = AuthHelper.getUserPrivileges();
    if (Array.isArray(privilege)) {
      return privilege.every((v) => userPrivileges.includes(v));
    } else {
      const index = userPrivileges.findIndex(
        (item) => item.toLowerCase() === privilege.toLowerCase()
      );
      return index !== -1;
    }
  }

  static getUserPrivileges() {
    const token = localStorage.getItem(AuthConstants.ApplicationToken);
    const token_data = AuthHelper.parseJwt(token);
    if (token && token_data.UserPrivileges) {
      return token_data?.UserPrivileges.split(",");
    }
    return [];
  }
}

export default AuthHelper;
