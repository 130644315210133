import {
  SET_ALL_CONFIGURATION_LIST,
  UPDATE_CONFIGURATION_DATA,
  SET_ALLOWED_VEHICLE_AGE,
  SET_MAX_VEHICLE_MILEAGE,
  SET_VEHICLE_DELISTING_DAYS,
  SET_INSPECTION_FEES,
  GET_MAKE_MODEL,
  GET_CITY_LIST,
  SET_MAX_PRICE_VEHICLE_FILTER,
  SET_MIN_PRICE_VEHICLE_FILTER,
  SET_MAX_ASKING_PRICE,
  SET_MIN_ASKING_PRICE,
  SET_CUSTOMER_SUPPORT_GENERAL_EMAIL,
  SET_CUSTOMER_SUPPORT_INSPECTION_EMAIL,
  SET_CUSTOMER_EMAIL,
  SET_QUALITY_CHECK_EMAIL_ADDRESS,
  SET_AUTO_CARE_CUSTOMER_SUPPORT_EMAIL_ID,
  SET_BODY_AND_PAINT_CUSTOMER_SUPPORT_EMAIL_ID,
  SET_AUTO_CARE_BOOK_NOW_TO_EMAIL_ID,
  SET_BODY_AND_PAINT_BOOK_NOW_TO_EMAIL_ID,
  SET_CAREERS_EMAIL_ID,
  SET_IS_CR_ADDRESS_VERIFICATION,
  SET_IS_PAYFORT_ENABLED,
  SET_MAX_EMI_PRICE_VEHICLE_FILTER,
  SET_MIN_EMI_PRICE_VEHICLE_FILTER,
  SET_ALLOWED_DAYS,
  SET_ELM_OTP_LENGTH,
  SET_AWS_LOW_THRESHOLD,
  SET_AWS_HIGH_THRESHOLD,
  GET_DATE_TIME_FORMAT,
  GET_FAQ_CATEGORIES,
  GET_FAQ_LIST,
  GET_TRANSLATIONS,
  GET_TRANSLATION_STATUS,
} from "../types";

const initialState = {
  masterConfigurationList: {},
  allowedVehicleAge: {},
  maxVehicleMileage: {},
  vehicleDelistingDays: {},
  inspectionFees: {},
  allBrandData: [],
  allCityList: [],
  maxPriceInVehicleFilter: {},
  minPriceInVehicleFilter: {},
  maxAskingPrice: {},
  minAskingPrice: {},
  customerSupportGeneralEmail: {},
  customerSupportInspectionEmail: {},
  customerEmail: {},
  qualityCheckEmailAddress: {},
  autoCareCustomerSupportEmailId: {},
  bodyAndPaintCustomerSupportEmailId: {},
  autoCareBookNowToEmailId: {},
  bodyAndPaintBookNowToEmailId: {},
  careersEmailId: {},
  isCRAddressVerificationRequired: {},
  isPayfortEnabled: {},
  emiConfigurationData: [],
  maxEMIPriceInVehicleFilter: {},
  minEMIPriceInVehicleFilter: {},
  allowedDays: {},
  elmOtpLength: {},
  checkAWSPriceLowThreshold: {},
  checkAWSPriceHighThreshold: {},
  dateFormat: null,
  dateTimeFormat: null,
  faqCategories: [],
  faqList: [],
  getTranslations: [],
  getTranslationStatus: [],
};

export const masterDataConfigurationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_ALL_CONFIGURATION_LIST:
      return {
        ...state,
        masterConfigurationList: action.payload,
      };
    case UPDATE_CONFIGURATION_DATA:
      return {
        ...state,
        masterConfigurationList: action.payload,
      };
    case SET_ALLOWED_VEHICLE_AGE:
      return {
        ...state,
        allowedVehicleAge: action.payload,
      };
    case SET_MAX_VEHICLE_MILEAGE:
      return {
        ...state,
        maxVehicleMileage: action.payload,
      };
    case SET_VEHICLE_DELISTING_DAYS:
      return {
        ...state,
        vehicleDelistingDays: action.payload,
      };
    case SET_INSPECTION_FEES:
      return {
        ...state,
        inspectionFees: action.payload,
      };
    case GET_MAKE_MODEL:
      return {
        ...state,
        allBrandData: action.payload,
      };
    case GET_CITY_LIST:
      return {
        ...state,
        allCityList: action.payload,
      };
    case SET_MAX_PRICE_VEHICLE_FILTER:
      return {
        ...state,
        maxPriceInVehicleFilter: action.payload,
      };
    case SET_MIN_PRICE_VEHICLE_FILTER:
      return {
        ...state,
        minPriceInVehicleFilter: action.payload,
      };
    case SET_MAX_ASKING_PRICE:
      return {
        ...state,
        maxAskingPrice: action.payload,
      };
    case SET_MIN_ASKING_PRICE:
      return {
        ...state,
        minAskingPrice: action.payload,
      };
    case SET_CUSTOMER_SUPPORT_GENERAL_EMAIL:
      return {
        ...state,
        customerSupportGeneralEmail: action.payload,
      };
    case SET_CUSTOMER_SUPPORT_INSPECTION_EMAIL:
      return {
        ...state,
        customerSupportInspectionEmail: action.payload,
      };
    case SET_CUSTOMER_EMAIL:
      return {
        ...state,
        customerEmail: action.payload,
      };
    case SET_QUALITY_CHECK_EMAIL_ADDRESS:
      return {
        ...state,
        qualityCheckEmailAddress: action.payload,
      };
    case SET_AUTO_CARE_CUSTOMER_SUPPORT_EMAIL_ID:
      return {
        ...state,
        autoCareCustomerSupportEmailId: action.payload,
      };
    case SET_BODY_AND_PAINT_CUSTOMER_SUPPORT_EMAIL_ID:
      return {
        ...state,
        bodyAndPaintCustomerSupportEmailId: action.payload,
      };
    case SET_AUTO_CARE_BOOK_NOW_TO_EMAIL_ID:
      return {
        ...state,
        autoCareBookNowToEmailId: action.payload,
      };
    case SET_BODY_AND_PAINT_BOOK_NOW_TO_EMAIL_ID:
      return {
        ...state,
        bodyAndPaintBookNowToEmailId: action.payload,
      };
    case SET_CAREERS_EMAIL_ID:
      return {
        ...state,
        careersEmailId: action.payload,
      };
    case SET_IS_CR_ADDRESS_VERIFICATION:
      return {
        ...state,
        isCRAddressVerificationRequired: action.payload,
      };
    case SET_IS_PAYFORT_ENABLED:
      return {
        ...state,
        isPayfortEnabled: action.payload,
      };
    case SET_MAX_EMI_PRICE_VEHICLE_FILTER:
      return {
        ...state,
        maxEMIPriceInVehicleFilter: action.payload,
      };
    case SET_MIN_EMI_PRICE_VEHICLE_FILTER:
      return {
        ...state,
        minEMIPriceInVehicleFilter: action.payload,
      };
    case SET_ALLOWED_DAYS:
      return {
        ...state,
        allowedDays: action.payload,
      };
    case SET_ELM_OTP_LENGTH:
      return {
        ...state,
        elmOtpLength: action.payload,
      };
    case SET_AWS_LOW_THRESHOLD:
      return {
        ...state,
        checkAWSPriceLowThreshold: action.payload,
      };
    case SET_AWS_HIGH_THRESHOLD:
      return {
        ...state,
        checkAWSPriceHighThreshold: action.payload,
      };
    case GET_DATE_TIME_FORMAT:
      return {
        ...state,
        dateFormat: action.payload.dateFormat,
        dateTimeFormat: action.payload.dateTimeFormat,
      };
    case GET_FAQ_CATEGORIES:
      return {
        ...state,
        faqCategories: action.payload,
      };
    case GET_FAQ_LIST:
      return {
        ...state,
        faqList: action.payload,
      };
    case GET_TRANSLATIONS:
      return {
        ...state,
        getTranslations: action.payload,
      };
    case GET_TRANSLATION_STATUS:
      return {
        ...state,
        getTranslationStatus: action.payload,
      };
    default:
      return state;
  }
};
