import {
  GET_PROMOTIONS,
  GET_PROMOTION_DETAILS,
  PROMOTION_TYPE,
} from "../types";

const initialState = {
  promotionData: [],
  promotionType: [],
  promotionDetailsById: {},
};

export const promotionCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMOTIONS:
      return {
        ...state,
        promotionData: action.payload,
      };
    case PROMOTION_TYPE:
      return {
        ...state,
        promotionType: action.payload,
      };
    case GET_PROMOTION_DETAILS:
      return {
        ...state,
        promotionDetailsById: action.payload,
      };
    default:
      return state;
  }
};
