import React from "react";

export const RightTickIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 11.942"
      className={className}
    >
      <path
        id="Path_14"
        d="M11.059,28.335a1.121,1.121,0,0,1-.764-.3l-4.958-4.78a1.106,1.106,0,1,1,1.528-1.6l4.14,4,8.067-8.9a1.116,1.116,0,0,1,1.564-.071,1.1,1.1,0,0,1,.071,1.564L11.876,27.98a1.077,1.077,0,0,1-.782.355Z"
        transform="translate(-4.999 -16.393)"
        fill="#fff"
      ></path>
    </svg>
  );
};
