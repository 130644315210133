import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackIcon } from "../../components/gogo-icons";
import VehicleFeatureList from "../../components/vehicle-feature-list";
import { useDispatch, useSelector } from "react-redux";
import {
  getModelByModelId,
  clearModelData,
} from "../../redux/actions/makeModelAction";

const ModelFeatures = ({ modelId, handleSave, features }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  const modelByIdData = useSelector(
    (state) => state.makeModelReducer.modelByIdData
  );

  useEffect(() => {
    if (modelId) {
      dispatch(getModelByModelId(modelId));
    }
    return () => {
      dispatch(clearModelData());
    };
  }, [dispatch, modelId]);

  const saveData = (selectedFeatures) => {
    handleSave(selectedFeatures);
    history.goBack();
  };

  const getModelName = () => {
    let label;
    let labelData = modelByIdData?.Values.filter(
      (itm) => itm.LanguageId === languageId
    );
    label =
      labelData?.length > 0
        ? labelData[0].Label
        : modelByIdData?.Values[0].Label;
    return label;
  };

  return (
    <div className="mt-1">
      <div className="container mx-auto">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          <BackIcon className="back-button arabic-back-icon" />
          <span className="page-header">{t("EDIT_MODEL_FEATURES")}</span>
        </div>
        <div className="bg-white my-4 px-8 py-4">
          {modelByIdData?.Values && (
            <div className="flex gap-1">
              <p className="page-header">{t("MODEL")} -</p>
              <p className="page-header">{getModelName()}</p>
            </div>
          )}
          <VehicleFeatureList features={features} saveData={saveData} />
        </div>
      </div>
    </div>
  );
};
export default ModelFeatures;
