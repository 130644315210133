import React from "react";

export const YoutubeIcon = ({ className = "" }) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 48"
      id="icon_youtube"
      className={className}
    >
      <path
        d="M21.0791 28.8886L29.2271 24.0001L21.0791 19.1116V28.8886Z"
        fill="currentColor"
      ></path>
      <path
        d="M24.333 0C11.079 0 0.333008 10.7445 0.333008 24C0.333008 37.2555 11.079 48 24.333 48C37.587 48 48.333 37.254 48.333 24C48.333 10.746 37.587 0 24.333 0ZM37.365 25.0515C37.365 27.3015 37.104 29.5515 37.104 29.5515C37.104 29.5515 36.849 31.4685 36.069 32.31C35.0775 33.417 33.9675 33.423 33.4575 33.489C29.8095 33.768 24.333 33.777 24.333 33.777C24.333 33.777 17.556 33.711 15.471 33.498C14.8905 33.3825 13.59 33.4155 12.597 32.31C11.8155 31.467 11.562 29.5515 11.562 29.5515C11.562 29.5515 11.301 27.303 11.301 25.0515V22.9425C11.301 20.6925 11.562 18.444 11.562 18.444C11.562 18.444 11.817 16.527 12.597 15.6825C13.5885 14.574 14.6985 14.568 15.2085 14.505C18.855 14.223 24.327 14.223 24.327 14.223H24.339C24.339 14.223 29.811 14.223 33.4575 14.505C33.966 14.568 35.0775 14.574 36.069 15.681C36.8505 16.5255 37.104 18.4425 37.104 18.4425C37.104 18.4425 37.365 20.6925 37.365 22.9425V25.0515Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
