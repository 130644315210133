import { API } from "../../middleware";
import { AuthConstants } from "../../utilities/constants/auth.constants";
import {
  CHECK_AUTH_DETAILS,
  CHECK_AUTH_DETAILS_SUCCESS,
  CHECK_AUTH_DETAILS_FAIL,
  FETCH_AUTH_TOKEN,
  FETCH_AUTH_TOKEN_SUCCESS,
  FETCH_AUTH_TOKEN_FAIL,
  FETCH_REFRESH_AUTH_TOKEN,
  FETCH_REFRESH_AUTH_TOKEN_SUCCESS,
  FETCH_REFRESH_AUTH_TOKEN_FAIL,
  GET_DEALER_INFORMATION,
  USER_LOGOUT,
  FETCH_AUTH_TOKEN_ERROR,
  GET_USER_ROLE,
  CHANGE_USER_ROLE,
  REFRESH_DASHBOARD_DATA,
  SET_ROLE_ID,
} from "../types";
import moment from "moment";
import AuthHelper from "../../utilities/helpers/auth.helpers";
import { clearFilter } from "./myVehicle";
import MessageBox, {
  MessageBoxType,
} from "../../components/common/message-box";
import i18next from "i18next";
import { getHeaderMenu } from "./globalAction";
import { hideLoader, showLoader } from "./applicationAction";

/**
 * This method is use to check user details and application token, if token is not valid send token request for new token with refresh token.
 * @returns boolean value
 */
export const validateAuthDetails = () => (dispatch) => {
  dispatch({ type: CHECK_AUTH_DETAILS });
  try {
    const user = localStorage.getItem(AuthConstants.UserDetails);
    const token = localStorage.getItem(AuthConstants.ApplicationToken);
    if (token && user) {
      // validate auth token expiry.
      if (
        isTokenValid(token) &&
        validateUserDetailsWithToken(token, JSON.parse(user))
      ) {
        dispatch({
          type: CHECK_AUTH_DETAILS_SUCCESS,
          payload: JSON.parse(user),
        });
        return Promise.resolve(true);
      } else {
        dispatch(getAuthTokenFromRefreshToken());
        return Promise.resolve(false);
      }
    }
    dispatch({ type: CHECK_AUTH_DETAILS_FAIL });
    return Promise.resolve(false);
  } catch (error) {
    dispatch({ type: CHECK_AUTH_DETAILS_FAIL });
    return Promise.reject(error);
  }
};

/**
 * This method is use to send request for refresh token
 * @returns Token API Response
 */
export const getAuthTokenFromRefreshToken = () => async (dispatch) => {
  dispatch({ type: FETCH_REFRESH_AUTH_TOKEN });
  try {
    // use refresh token to get new application token.
    const refresh_token = localStorage.getItem(AuthConstants.RefreshToken);
    if (refresh_token) {
      const request_payload = {
        Code: refresh_token,
        GrantType: AuthConstants.RefreshTokenGrantType,
        IsLocal: process.env.REACT_APP_IS_LOCAL === "true",
        ApplicationKey: AuthConstants.GGMBusiness,
        IsAADSignUpSignIn: false,
      };
      return dispatch(getAuthToken(request_payload))
        .then((res) => {
          if (res) {
            dispatch({ type: FETCH_REFRESH_AUTH_TOKEN_SUCCESS });
            return Promise.resolve(res);
          } else {
            dispatch({ type: FETCH_REFRESH_AUTH_TOKEN_FAIL });
            return Promise.resolve(null);
          }
        })
        .catch((err) => {
          throw err;
        });
    } else {
      return Promise.resolve(null);
    }
  } catch (error) {
    dispatch({ type: FETCH_REFRESH_AUTH_TOKEN_FAIL });
    return Promise.reject(error);
  }
};

/**
 * This method is responsible to send POST request to fetch user application token, role & privileges by access_code/refresh_token
 * @param {{Code: string, GrantType: "authorization_code"|"refresh_token", IsLocal: boolean, IsDMSTokenRequestor: boolean}} data - Request Payload
 * @returns Token API Response
 */
export const getAuthToken = (data) => async (dispatch) => {
  dispatch({ type: FETCH_AUTH_TOKEN });
  try {
    if (data) {
      // Fetch WebToken from API
      const response = await API.post("api/WebAuth/token", data, {
        allowAnonymous: 1,
      });
      if (response.status === 200 && response.data.Token !== null) {
        // clear search filter
        dispatch(clearFilter());
        // store token into storage
        localStorage.setItem(
          AuthConstants.ApplicationToken,
          response.data.Token
        );
        localStorage.setItem(
          AuthConstants.RefreshToken,
          response.data.RefreshTokenAD
        );
        localStorage.setItem(
          AuthConstants.UserDetails,
          JSON.stringify(response.data)
        );

        // dispatch action while receiving application token, to update application state.
        dispatch({ type: FETCH_AUTH_TOKEN_SUCCESS, payload: response.data });
        return Promise.resolve(response);
      } else if (response.status === 200 && response.data.IsForgotPassword) {
        await MessageBox.open({
          content: i18next.t("FORGOT_PASSWORD_SUCCESS_MESSAGE"),
          type: MessageBoxType.Alert,
        });
      } else {
        throw new Error(response.status);
      }
    }
    dispatch({ type: FETCH_AUTH_TOKEN_FAIL });
    dispatch(logOut());
    return Promise.resolve(false);
  } catch (error) {
    dispatch({ type: FETCH_AUTH_TOKEN_ERROR });
    await MessageBox.open({
      content: i18next.t("ACCOUNT_NOT_FOUND"),
      type: MessageBoxType.Alert,
    });
    dispatch({ type: FETCH_AUTH_TOKEN_FAIL });
    return Promise.reject(error);
  }
};

/**
 * This method is responsible to logout and clear user session/localStorage
 */
export const logOut = () => async (dispatch) => {
  /*
   * check user email address if logged-in user is AD user or not.
   * If user logged with AD credentials, need to logout from AD.
   */
  const userStringData = localStorage.getItem(AuthConstants.UserDetails);
  let logoutUrl = process.env.REACT_APP_LOGOUT_REDIRECT_URL;

  if (userStringData) {
    const user = JSON.parse(userStringData);
    if (
      user &&
      user.EmailAddress &&
      user.EmailAddress.toLowerCase() &&
      user.EmailAddress.toLowerCase().endsWith("@petromin.com")
    ) {
      logoutUrl = process.env.REACT_APP_AD_LOGOUT_REDIRECT_URL;
    }
  }

  localStorage.clear();
  await dispatch({ type: USER_LOGOUT });
  window.location.href = logoutUrl;
};

/**
 * This method is responsible to check whether token is expired or not.
 * @param {string} token - JWT Token
 * @returns {boolean} True/False
 */
export const isTokenValid = (token) => {
  const token_data = AuthHelper.parseJwt(token);
  if (token_data) {
    const currentDate = moment().unix();
    const expiryDate = token_data.exp;
    return currentDate < expiryDate;
  }
  return false;
};

/**
 * This method is responsible to compare token data and user details like, roles and privileges.
 * @param {string} token - JWT Token
 * @param {any} user - User Details stored in localStorage
 * @returns {boolean} - True/False
 */
const validateUserDetailsWithToken = (token, user = {}) => {
  const token_data = AuthHelper.parseJwt(token);
  if (token_data && user) {
    return (
      token_data.UserId === user.UserId &&
      arraysContainSame(token_data.UserRoles.split(","), user.UserRoles) &&
      arraysContainSame(
        token_data.UserPrivileges.split(","),
        user.UserPrivileges
      )
    );
  }
  return false;
};

/**
 * This Method is use to identify two array objects are same or not.
 * @param {Array<string>} arr1
 * @param {Array<string>} arr2
 * @returns {boolean} - True/False
 */
function arraysContainSame(arr1, arr2) {
  arr1 = Array.isArray(arr1) ? arr1 : [];
  arr2 = Array.isArray(arr2) ? arr2 : [];
  return arr1.length === arr2.length && arr1.every((el) => arr2.includes(el));
}

export const setDealerInformation = (data) => ({
  type: GET_DEALER_INFORMATION,
  payload: data,
});

export const getDealerInformation = () => {
  return (dispatch) => {
    return API.get("/api/dms/dealer")
      .then((response) => {
        dispatch(setDealerInformation(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getUserRole = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Profile/myroles?languageId=${languageId}`
    );
    dispatch({ type: GET_USER_ROLE, payload: response.data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeUserRole = (data) => ({
  type: CHANGE_USER_ROLE,
  payload: data,
});

export const handleChangeUserRole = (data) => async (dispatch) => {
  dispatch(showLoader());
  dispatch({ type: REFRESH_DASHBOARD_DATA, payload: false });
  dispatch({ type: FETCH_AUTH_TOKEN });
  try {
    // Fetch role token on the basis of role key from API
    const response = await API.post("/api/WebAuth/getRoleToken", {
      RoleKey: data.roleKey,
    });

    if (response.status === 200 && response.data.Token !== null) {
      //get the header menu on the basis of role key

      // clear search filter
      dispatch(clearFilter());
      // store token into storage
      localStorage.setItem(AuthConstants.ApplicationToken, response.data.Token);
      const user = localStorage.getItem(AuthConstants.UserDetails);
      const userJSON = JSON.parse(user);
      const userTokenDetails = { ...userJSON, ...response.data };
      localStorage.setItem(
        AuthConstants.UserDetails,
        JSON.stringify(userTokenDetails)
      );

      // dispatch action while receiving application token, to update application state.
      dispatch({ type: FETCH_AUTH_TOKEN_SUCCESS, payload: userTokenDetails });
      dispatch({
        type: CHANGE_USER_ROLE,
        payload: data.roleKey,
      });
      dispatch(getHeaderMenu(data.selectedLanguage));
      dispatch({ type: REFRESH_DASHBOARD_DATA, payload: true });
      dispatch(hideLoader());
      return Promise.resolve(response);
    } else {
      dispatch({ type: FETCH_AUTH_TOKEN_FAIL });
      dispatch(logOut());
      throw new Error(response.status);
    }
  } catch (error) {
    dispatch({ type: FETCH_AUTH_TOKEN_ERROR });
    await MessageBox.open({
      content: i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"),
      type: MessageBoxType.Alert,
    });
    dispatch({ type: FETCH_AUTH_TOKEN_FAIL });
    return Promise.reject(error);
  }
};

export const verifyAuthCode = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("api/Profile/verifyauthcode", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const setRoleId = (data) => ({
  type: SET_ROLE_ID,
  payload: data,
});
