import { API } from "../../middleware";
import {
  SET_ALL_CONFIGURATION_LIST,
  UPDATE_CONFIGURATION_DATA,
  SET_ALLOWED_VEHICLE_AGE,
  SET_MAX_VEHICLE_MILEAGE,
  SET_VEHICLE_DELISTING_DAYS,
  SET_INSPECTION_FEES,
  GET_CITY_LIST,
  GET_MAKE_MODEL,
  SET_MAX_PRICE_VEHICLE_FILTER,
  SET_MIN_PRICE_VEHICLE_FILTER,
  SET_MAX_ASKING_PRICE,
  SET_MIN_ASKING_PRICE,
  SET_CUSTOMER_SUPPORT_GENERAL_EMAIL,
  SET_CUSTOMER_SUPPORT_INSPECTION_EMAIL,
  SET_CUSTOMER_EMAIL,
  SET_QUALITY_CHECK_EMAIL_ADDRESS,
  SET_AUTO_CARE_CUSTOMER_SUPPORT_EMAIL_ID,
  SET_BODY_AND_PAINT_CUSTOMER_SUPPORT_EMAIL_ID,
  SET_AUTO_CARE_BOOK_NOW_TO_EMAIL_ID,
  SET_BODY_AND_PAINT_BOOK_NOW_TO_EMAIL_ID,
  SET_CAREERS_EMAIL_ID,
  SET_IS_CR_ADDRESS_VERIFICATION,
  SET_IS_PAYFORT_ENABLED,
  SET_MAX_EMI_PRICE_VEHICLE_FILTER,
  SET_MIN_EMI_PRICE_VEHICLE_FILTER,
  SET_ELM_OTP_LENGTH,
  SET_ALLOWED_DAYS,
  SET_AWS_LOW_THRESHOLD,
  SET_AWS_HIGH_THRESHOLD,
  GET_DATE_TIME_FORMAT,
  GET_FAQ_CATEGORIES,
  GET_FAQ_LIST,
  GET_TRANSLATIONS,
  GET_TRANSLATION_STATUS,
} from "../types";
import { toast } from "react-toastify";
import i18next from "../../utilities/i18n/i18n";
import { hideLoader, showLoader } from "./applicationAction";
import {
  ConfigurationKeys,
  languages,
} from "../../utilities/constants/global.constants";

export const setAllConfigurationList = (data) => ({
  type: SET_ALL_CONFIGURATION_LIST,
  payload: data,
});

export const getAllConfigurationList = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/allconfiguration", {
      params: data,
    })
      .then((response) => {
        dispatch(setAllConfigurationList(response.data));
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const updateConfiguration = (data) => ({
  type: UPDATE_CONFIGURATION_DATA,
  payload: data,
});

export const updateConfigurationDataOnSubmit = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    let showToast = true;

    await data.map((req) => {
      API.put("api/Configuration/configurationdata", req).then((res) => {
        if (res.data) {
          if (showToast) {
            toast.success(i18next.t("SAVE_SUCCESS"));
            showToast = false;
          }
        } else {
          if (showToast) {
            toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
            showToast = false;
          }
        }
      });
    });

    await data.map((req) => {
      if (
        req.ConfigurationKey === ConfigurationKeys.CustomerSupportGeneralEmail
      ) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.CustomerSupportGeneralEmail,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setCustomerSupportGeneralEmail(response.data));
        });
      }

      if (
        req.ConfigurationKey ===
        ConfigurationKeys.CustomerSupportInspectionEmail
      ) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.CustomerSupportInspectionEmail,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setCustomerSupportInspectionEmail(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.CustomerEmail) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.CustomerEmail,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setCustomerEmail(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.QualityCheckEmailAddress) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.QualityCheckEmailAddress,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setQualityCheckEmailAddress(response.data));
        });
      }

      if (
        req.ConfigurationKey ===
        ConfigurationKeys.AutoCareCustomerSupportEmailId
      ) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.AutoCareCustomerSupportEmailId,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setAutoCareCustomerSupportEmailId(response.data));
        });
      }

      if (
        req.ConfigurationKey ===
        ConfigurationKeys.BodyAndPaintCustomerSupportEmailId
      ) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey:
              ConfigurationKeys.BodyAndPaintCustomerSupportEmailId,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setBodyAndPaintCustomerSupportEmailId(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.AutoCareBookNowToEmailId) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.AutoCareBookNowToEmailId,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setAutoCareBookNowToEmailId(response.data));
        });
      }

      if (
        req.ConfigurationKey === ConfigurationKeys.BodyAndPaintBookNowToEmailId
      ) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.BodyAndPaintBookNowToEmailId,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setBodyAndPaintBookNowToEmailId(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.CareersEmailId) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.CareersEmailId,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setCareersEmailId(response.data));
        });
      }

      //vehicle acceptance criteria
      if (req.ConfigurationKey === ConfigurationKeys.AllowedVehicleAge) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.AllowedVehicleAge,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setAllowedVehicleAge(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.MaxVehicleMileage) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.MaxVehicleMileage,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setMaxVehicleMileage(response.data));
        });
      }

      //pricing configuration
      if (req.ConfigurationKey === ConfigurationKeys.MaxPriceInVehicleFilter) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.MaxPriceInVehicleFilter,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setMaxPriceInVehicleFilter(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.MinPriceInVehicleFilter) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.MinPriceInVehicleFilter,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setMinPriceInVehicleFilter(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.maximumAskingPrice) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.maximumAskingPrice,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setMaxAskingPrice(response.data));
        });
      }

      if (req.ConfigurationKey === ConfigurationKeys.minimumAskingPrice) {
        API.get("/api/Configuration/configurationdata", {
          params: {
            configurationKey: ConfigurationKeys.minimumAskingPrice,
            languageId: req.LanguageId,
          },
        }).then((response) => {
          dispatch(setMinAskingPrice(response.data));
        });
      }
    });

    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const updateConfigurationData = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("api/Configuration/configurationdata", data);
    dispatch(hideLoader());
    if (response.status === 200) {
      if (response.data) {
        toast.success(i18next.t("SAVE_SUCCESS"));
      } else {
        toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
      }
    }
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};
export const setAllowedVehicleAge = (data) => ({
  type: SET_ALLOWED_VEHICLE_AGE,
  payload: data,
});

export const getAllowedVehicleAge = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setAllowedVehicleAge(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};
export const setMaxVehicleMileage = (data) => ({
  type: SET_MAX_VEHICLE_MILEAGE,
  payload: data,
});

export const getMaxVehicleMileage = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setMaxVehicleMileage(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setVehicleDelistingDays = (data) => ({
  type: SET_VEHICLE_DELISTING_DAYS,
  payload: data,
});

export const getVehicleDelistingDays = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setVehicleDelistingDays(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setInspectionFees = (data) => ({
  type: SET_INSPECTION_FEES,
  payload: data,
});

export const getInspectionFees = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setInspectionFees(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setCityList = (data) => ({
  type: GET_CITY_LIST,
  payload: data,
});

export const getCityList = () => {
  return () => {
    return API.get("/api/Vehicle/browsebycity", { params: { languageId: 1 } })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getCityListWithLanguageId = (languageId) => {
  return () => {
    return API.get("/api/Vehicle/browsebycity", {
      params: { languageId: languageId },
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setMakeModalData = (data) => ({
  type: GET_MAKE_MODEL,
  payload: data,
});

export const getMakeAndModel = () => {
  return () => {
    return API.get("/api/Vehicle/makes", { params: { languageId: 1 } })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setMaxPriceInVehicleFilter = (data) => ({
  type: SET_MAX_PRICE_VEHICLE_FILTER,
  payload: data,
});

export const getMaxPriceInVehicleFilter = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setMaxPriceInVehicleFilter(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setMinPriceInVehicleFilter = (data) => ({
  type: SET_MIN_PRICE_VEHICLE_FILTER,
  payload: data,
});

export const getMinPriceInVehicleFilter = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setMinPriceInVehicleFilter(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setMaxAskingPrice = (data) => ({
  type: SET_MAX_ASKING_PRICE,
  payload: data,
});

export const getMaxAskingPrice = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setMaxAskingPrice(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setMinAskingPrice = (data) => ({
  type: SET_MIN_ASKING_PRICE,
  payload: data,
});

export const getMinAskingPrice = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setMinAskingPrice(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setCustomerSupportGeneralEmail = (data) => ({
  type: SET_CUSTOMER_SUPPORT_GENERAL_EMAIL,
  payload: data,
});

export const getCustomerSupportGeneralEmail = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setCustomerSupportGeneralEmail(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setCustomerSupportInspectionEmail = (data) => ({
  type: SET_CUSTOMER_SUPPORT_INSPECTION_EMAIL,
  payload: data,
});

export const getCustomerSupportInspectionEmail = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setCustomerSupportInspectionEmail(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setQualityCheckEmailAddress = (data) => ({
  type: SET_QUALITY_CHECK_EMAIL_ADDRESS,
  payload: data,
});

export const getQualityCheckEmailAddress = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setQualityCheckEmailAddress(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setCustomerEmail = (data) => ({
  type: SET_CUSTOMER_EMAIL,
  payload: data,
});

export const getCustomerEmail = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setCustomerEmail(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setAutoCareCustomerSupportEmailId = (data) => ({
  type: SET_AUTO_CARE_CUSTOMER_SUPPORT_EMAIL_ID,
  payload: data,
});

export const getAutoCareCustomerSupportEmailId = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setAutoCareCustomerSupportEmailId(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setBodyAndPaintCustomerSupportEmailId = (data) => ({
  type: SET_BODY_AND_PAINT_CUSTOMER_SUPPORT_EMAIL_ID,
  payload: data,
});

export const getBodyAndPaintCustomerSupportEmailId = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setBodyAndPaintCustomerSupportEmailId(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setAutoCareBookNowToEmailId = (data) => ({
  type: SET_AUTO_CARE_BOOK_NOW_TO_EMAIL_ID,
  payload: data,
});

export const getAutoCareBookNowToEmailId = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setAutoCareBookNowToEmailId(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setBodyAndPaintBookNowToEmailId = (data) => ({
  type: SET_BODY_AND_PAINT_BOOK_NOW_TO_EMAIL_ID,
  payload: data,
});

export const getBodyAndPaintBookNowToEmailId = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setBodyAndPaintBookNowToEmailId(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setCareersEmailId = (data) => ({
  type: SET_CAREERS_EMAIL_ID,
  payload: data,
});

export const getCareersEmailId = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setCareersEmailId(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setIsCRAddressVerification = (data) => ({
  type: SET_IS_CR_ADDRESS_VERIFICATION,
  payload: data,
});

export const getIsCRAddressVerification = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", {
      params: {
        ...data,
        configurationKey: ConfigurationKeys.IsCRAddressVerificationRequired,
      },
    })
      .then((response) => {
        dispatch(setIsCRAddressVerification(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setIsPayfortEnabled = (data) => ({
  type: SET_IS_PAYFORT_ENABLED,
  payload: data,
});

export const getIsPayfortEnabled = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", {
      params: {
        ...data,
        configurationKey: ConfigurationKeys.IsPayfortEnabled,
      },
    })
      .then((response) => {
        dispatch(setIsPayfortEnabled(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setEMIConfiguration = (data) => async () => {
  try {
    const response = await API.post(
      "/api/Configuration/emiconfigurationdata",
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setMaxEMIPriceInVehicleFilter = (data) => ({
  type: SET_MAX_EMI_PRICE_VEHICLE_FILTER,
  payload: data,
});

export const getMaxEMIPriceInVehicleFilter = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setMaxEMIPriceInVehicleFilter(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setMinEMIPriceInVehicleFilter = (data) => ({
  type: SET_MIN_EMI_PRICE_VEHICLE_FILTER,
  payload: data,
});

export const getMinEMIPriceInVehicleFilter = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setMinEMIPriceInVehicleFilter(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setElmOtpLength = (data) => ({
  type: SET_ELM_OTP_LENGTH,
  payload: data,
});

export const getElmOtpLength = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setElmOtpLength(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const setAllowedDays = (data) => ({
  type: SET_ALLOWED_DAYS,
  payload: data,
});

export const getAllowedDaysForPurchase = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(setAllowedDays(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const SetAwsLowThreshold = (data) => ({
  type: SET_AWS_LOW_THRESHOLD,
  payload: data,
});

export const getAwsLowThreshold = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(SetAwsLowThreshold(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const SetAwsHighThreshold = (data) => ({
  type: SET_AWS_HIGH_THRESHOLD,
  payload: data,
});

export const getAwsHighThreshold = (data) => {
  return (dispatch) => {
    return API.get("/api/Configuration/configurationdata", { params: data })
      .then((response) => {
        dispatch(SetAwsHighThreshold(response.data));
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getDateTimeFormat = () => async (dispatch) => {
  try {
    const dateTimeResponse = await API.get(
      "/api/Configuration/configurationdata",
      {
        params: {
          configurationKey: ConfigurationKeys.DateTimeFormat,
          languageId: languages.eng,
        },
      }
    ).then((response) => {
      return response.data;
    });
    const dateResponse = await API.get("/api/Configuration/configurationdata", {
      params: {
        configurationKey: ConfigurationKeys.DateFormat,
        languageId: languages.eng,
      },
    }).then((response) => {
      return response.data;
    });

    dispatch({
      type: GET_DATE_TIME_FORMAT,
      payload: {
        dateFormat: dateResponse.ConfigurationValue,
        dateTimeFormat: dateTimeResponse.ConfigurationValue,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getFAQCategory = () => {
  return (dispatch) => {
    return API.get("/api/Configuration/faqcategories")
      .then((response) => {
        dispatch({ type: GET_FAQ_CATEGORIES, payload: response.data });
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getFAQList = (faqCategoryId) => {
  return (dispatch) => {
    return API.get(
      `/api/Configuration/faqsbycategoryId?faqCategoryId=${faqCategoryId}`
    )
      .then((response) => {
        dispatch({ type: GET_FAQ_LIST, payload: response.data });
      })
      .catch((error) => {
        return error;
      });
  };
};

export const saveFAQCategory = (data) => async () => {
  try {
    const response = await API.post("/api/Configuration/savefaqcategory", data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveFAQTemplate = (data) => async () => {
  try {
    const response = await API.post("/api/Configuration/savefaq", data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteFAQCategory = (faqCategoryId) => async () => {
  try {
    const response = await API.delete(
      `/api/Configuration/deletefaqcategory?faqCategoryId=${faqCategoryId}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteFAQList = (faqId) => async () => {
  try {
    const response = await API.delete(
      `/api/Configuration/deletefaq?faqId=${faqId}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTranslations =
  ({ GridPageSize, pageNumber, data, searchKey }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.post(
        "/api/Configuration/translations",
        data
          ? {
              PageSize: GridPageSize,
              PageNumber: pageNumber,
              StartDate: data?.translationCreatedDate?.min
                ? data?.translationCreatedDate?.min
                : null,
              EndDate: data?.translationCreatedDate?.max
                ? data?.translationCreatedDate?.max
                : null,
              TranslationIdFrom: data?.serialNoRange?.min
                ? data.serialNoRange.min
                : null,
              TranslationIdTo: data?.serialNoRange?.max
                ? data.serialNoRange.max
                : null,
              TranslationStatusIds: data.translationStatusId
                ? data.translationStatusId
                : [],
              SearchKey: searchKey,
            }
          : {
              PageSize: GridPageSize,
              PageNumber: pageNumber,
              SearchKey: searchKey,
            }
      );
      dispatch({
        type: GET_TRANSLATIONS,
        payload: response.data,
      });
      dispatch(hideLoader());
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const saveTranslation = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      "/api/Configuration/savetranslation",
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const getTranslationStatus = (isVisibleUser) => {
  return (dispatch) => {
    return API.get(
      `/api/Configuration/translationstatus?isVisibleToUser=${
        isVisibleUser ? isVisibleUser : ""
      }`
    )
      .then((response) => {
        dispatch({ type: GET_TRANSLATION_STATUS, payload: response.data });
      })
      .catch((error) => {
        return error;
      });
  };
};
export const fetchTranslationExport =
  ({ GridPageSize, pageNumber, data, searchKey }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.post("/api/Configuration/translations", {
        PageSize: GridPageSize,
        PageNumber: pageNumber,
        StartDate: data.translationCreatedDate.min
          ? data.translationCreatedDate.min
          : null,
        EndDate: data.translationCreatedDate.max
          ? data.translationCreatedDate.max
          : null,
        TranslationIdFrom: data.serialNoRange.min
          ? data.serialNoRange.min
          : null,
        TranslationIdTo: data.serialNoRange.max ? data.serialNoRange.max : null,
        TranslationStatusIds: data.translationStatusId
          ? data.translationStatusId
          : null,
        SearchKey: searchKey,
      });

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const fetchConfigurationValue =
  (key, languageId) => async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(
        `/api/configuration/configurationdata?configurationKey=${key}&languageId=${languageId}`
      );
      dispatch(hideLoader());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(hideLoader());
      return Promise.reject(error);
    }
  };
