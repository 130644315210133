export const FrontSeatIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 68.879 70.509"
      className={className}
      fill="currentColor"
    >
      <g
        id="Group_167992"
        data-name="Group 167992"
        transform="translate(11380.439 -12136.708)"
      >
        <g id="Layer_x0020_1" transform="translate(-20122.439 9955.708)">
          <path
            id="Path_15430"
            data-name="Path 15430"
            d="M8755.081,2237.462c0-3.972,6.28-5.718,7.5-.059l2.835,12.037c-1.835,0-3.674,0-5.509,0-2.492.01-1.985.176-2.757-2.888C8756.877,2245.459,8755.081,2238.432,8755.081,2237.462Zm45.034-6.06a8.547,8.547,0,0,1,6.593,3.459,9.637,9.637,0,0,1,1.673,3.425c.479,1.879.785,4.557-1.238,5.513-1.3.62-10.378.171-12.545.263-1.756.073-1.79,2.21.147,2.21h6.747a3.147,3.147,0,0,1-1.786,2.761c-1.127.522-2.635.405-4.273.405h-27.816a26.131,26.131,0,0,1-.693-3.167H8789.1c1.572,0,1.908-1.278,1.095-1.923-.6-.473-2.3-.278-3.3-.278h-18.869c-2.082,0-1.6.551-2.234-2.445-1.454-6.9-2.156-5.469.918-5.9,8.193-1.151,16.829-2.083,24.961-3.269l6.217-.81A20.04,20.04,0,0,1,8800.115,2231.4Zm-45.449-40.488c0,.776-.208,2.723-.648,3.025a3.793,3.793,0,0,1-1.376-.034l-3.206-.1a4.763,4.763,0,0,0-2.893,1.1c-.5-2.166-1.044-4.318-1.6-6.523-.155-.625-.273-1.034-.41-1.659-.146-.659-.282-1.078-.4-1.679a1.608,1.608,0,0,1,1.912-1.9,9.341,9.341,0,0,1,6.059,2.62A7.463,7.463,0,0,1,8754.666,2190.915ZM8742,2184.167a10.873,10.873,0,0,0,.605,3.8c1.977,8.7,4.2,17.917,6.085,26.553l3.391,14.789c.2.849.278,2.366,1.347,2.366,1.946,0,.727-2.874.269-5.084-1.416-6.753-3.133-13.6-4.548-20.38-.186-.884-1.775-7.612-1.775-8.275,0-2.416,3.019-1.927,4.679-1.927a6.868,6.868,0,0,1,6.124,3.381c.537.893,2.37,5.772,2.707,6.792a67.628,67.628,0,0,1,3.313,15.97l1.215,11.593a13.476,13.476,0,0,0-1.928.273,5.71,5.71,0,0,0-5.1-2.342,5.812,5.812,0,0,0-4.674,2.9c-1.175,2.03-.779,3.781-.2,6.108l2.256,9.178a3.085,3.085,0,0,0,2.209,1.649H8798.6c2.571,0,4.855-2.337,5.095-5.235,2.326,0,3.556.1,5.02-.9,4.064-2.771,1.606-9.949-.85-12.491-5.108-5.289-9.455-3.332-16.286-2.581-6.571.722-13.466,1.884-20.1,2.63-.645.073-1.265.19-1.937.263s-1.444.264-2.069.273c0-1.708-.678-6.455-.819-8.543a76.5,76.5,0,0,0-5.1-23.132c-.908-2.289-1.581-4.323-3.523-5.841a5.848,5.848,0,0,0-1.986-1.18c3.051-5.767-2.453-13.769-10.605-13.769a3.742,3.742,0,0,0-2.309.99,3.655,3.655,0,0,0-.718.942A4.16,4.16,0,0,0,8742,2184.167Z"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
