import React from "react";

export const DeactivateUserIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 35.621 29.182"
      id=".3461317683182312"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        data-name="Icon feather-user-x"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <path
          data-name="Path 13672"
          d="M24 27.682v-3a6 6 0 0 0-6-6H7.5a6 6 0 0 0-6 6v3"
        />
        <path
          data-name="Path 13673"
          d="M18.5 7.5a6 6 0 1 1-6-6 6 6 0 0 1 6 6z"
        />
        <path data-name="Path 13674" d="M26 8.685l7.5 7.5" />
        <path data-name="Path 13675" d="M33.5 8.685l-7.5 7.5" />
      </g>
    </svg>
  );
};
