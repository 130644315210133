import {
  FETCH_PENDING_DEALER_APPLICATION,
  FETCH_PENDING_DEALER_APPLICATION_FAIL,
  FETCH_PENDING_DEALER_APPLICATION_SUCCESS,
  FETCH_DEALER_REJECTION_REASON,
  FETCH_DEALER_REJECTION_REASON_FAIL,
  FETCH_DEALER_REJECTION_REASON_SUCCESS,
  GET_DEALER_INFORMATION,
  GET_GLOBAL_DATA_CITIES,
  APPROVE_DEALER_APPLICATION,
  APPROVE_DEALER_APPLICATION_FAIL,
  REJECT_DEALER_APPLICATION,
  REJECT_DEALER_APPLICATION_FAIL,
  GET_DEALER_CATEGORY,
  GET_DEALER_SALES_USERS,
} from "../types";

const initialState = {
  applicationHierarchy: {},
  createUserResponse: {},
  dealerRejectionReason: { languageId: 0, rejectionReasonList: [] },
  dealerApplication: { languageId: 0, dealerApplicationList: [] },
  fetchingDealerRejectionReason: false,
  fetchingDealerApplication: false,
  updatingDealerApplicationStatus: false,
  dealerCategory: [],
  dealerSalesUserList: [],
};

export const dmsUserManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEALER_INFORMATION:
      return {
        ...state,
        dealerInformation: action.payload,
      };
    case GET_GLOBAL_DATA_CITIES:
      return {
        ...state,
        globalDataCities: action.payload,
      };
    case FETCH_DEALER_REJECTION_REASON:
      return { ...state, fetchingDealerRejectionReason: true };
    case FETCH_DEALER_REJECTION_REASON_SUCCESS:
      return {
        ...state,
        fetchingDealerRejectionReason: false,
        dealerRejectionReason: action.payload,
      };
    case FETCH_DEALER_REJECTION_REASON_FAIL:
      return { ...state, fetchingDealerRejectionReason: false };
    case FETCH_PENDING_DEALER_APPLICATION:
      return { ...state, fetchingDealerApplication: true };
    case FETCH_PENDING_DEALER_APPLICATION_SUCCESS:
      return {
        ...state,
        fetchingDealerApplication: false,
        dealerApplication: action.payload,
        updatingDealerApplicationStatus: false,
      };
    case FETCH_PENDING_DEALER_APPLICATION_FAIL:
      return {
        ...state,
        fetchingDealerApplication: false,
        updatingDealerApplicationStatus: false,
      };
    case APPROVE_DEALER_APPLICATION:
    case REJECT_DEALER_APPLICATION:
      return { ...state, updatingDealerApplicationStatus: true };
    case APPROVE_DEALER_APPLICATION_FAIL:
    case REJECT_DEALER_APPLICATION_FAIL:
      return { ...state, updatingDealerApplicationStatus: false };
    case GET_DEALER_CATEGORY:
      return { ...state, dealerCategory: action.payload };
    case GET_DEALER_SALES_USERS:
      return {
        ...state,
        dealerSalesUserList: action.payload,
      };
    default:
      return state;
  }
};
