import React from "react";

export const PassedVehicleIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77.178"
      height="52.516"
      viewBox="0 0 77.178 52.516"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1678"
            data-name="Rectangle 1678"
            width="77.178"
            height="36.764"
            fill="currentColor"
          />
        </clipPath>
      </defs>
      <g
        id="Group_166934"
        data-name="Group 166934"
        transform="translate(-1035 -360.75)"
      >
        <g
          id="Group_166927"
          data-name="Group 166927"
          transform="translate(1035 376.502)"
        >
          <g
            id="Group_166920"
            data-name="Group 166920"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_13706"
              data-name="Path 13706"
              d="M77.162,19.728a5.455,5.455,0,0,0-4.551-5.445c-4.031-.747-8.073-1.438-12.1-2.21a5.855,5.855,0,0,1-3.058-.993c-3.686-3.143-7.45-6.2-11.167-9.3A7.336,7.336,0,0,0,41.371.011C35.733.033,30.094.059,24.457,0A7.521,7.521,0,0,0,18.2,2.907c-2.32,2.813-4.731,5.551-7.086,8.336a1.036,1.036,0,0,1-.9.422c-1.5-.023-3.01-.016-4.515-.008A5.509,5.509,0,0,0,.006,17.349q-.014,3.138,0,6.277A6.848,6.848,0,0,0,8.13,30.571a9.662,9.662,0,0,1,.148-4.24,9.787,9.787,0,0,1,19.178,3.737c-.073.5.041.586.515.583,3.674-.019,7.348-.01,11.021-.01,4.184,0,8.368-.014,12.552.015.609,0,.7-.137.6-.714a8.529,8.529,0,0,1,.206-3.487,9.781,9.781,0,0,1,19.193,3.639c-.082.573.137.566.551.536a5.529,5.529,0,0,0,5.06-5.315c.043-1.862.025-3.725.009-5.587M26.943,9.754A1.823,1.823,0,0,1,25,11.651c-1.3.017-2.6,0-3.9,0-1.275,0-2.55-.006-3.825,0a1.016,1.016,0,0,1-1.065-.572.97.97,0,0,1,.271-1.111q2.555-3,5.113-5.992a1.978,1.978,0,0,1,1.6-.751c.637.008,1.276-.016,1.912.007a1.812,1.812,0,0,1,1.84,1.847c.026,1.555.024,3.111,0,4.667m9.327,8.624c-.455.036-.916.006-1.374.007-.433,0-.867.025-1.3,0a1.836,1.836,0,0,1-1.745-1.86,1.815,1.815,0,0,1,1.735-1.789c.889-.04,1.783-.043,2.672,0a1.811,1.811,0,0,1,1.708,1.815,1.831,1.831,0,0,1-1.7,1.828M51.44,11.1c-.2.573-.692.588-1.2.587q-4.244-.009-8.488,0v-.027c-2.8,0-5.608.007-8.412,0a1.878,1.878,0,0,1-2.106-2.124c-.006-1.428-.01-2.855,0-4.283a1.84,1.84,0,0,1,1.99-2.008c2.829-.011,5.659,0,8.489-.008a2.18,2.18,0,0,1,1.411.484q3.9,3.078,7.8,6.148c.41.322.7.688.507,1.237"
              transform="translate(0 0)"
              fill="currentColor"
            />
            <path
              id="Path_13707"
              data-name="Path 13707"
              d="M55.934,101.865a7.959,7.959,0,1,0,7.894,8.12,7.971,7.971,0,0,0-7.894-8.12m-.04,11.632a3.671,3.671,0,1,1,3.648-3.654,3.689,3.689,0,0,1-3.648,3.654"
              transform="translate(-38.107 -81.022)"
              fill="currentColor"
            />
            <path
              id="Path_13708"
              data-name="Path 13708"
              d="M271.266,101.865a7.961,7.961,0,1,0,8.054,7.961,7.969,7.969,0,0,0-8.054-7.961m.116,11.632a3.671,3.671,0,1,1,3.651-3.651,3.689,3.689,0,0,1-3.651,3.651"
              transform="translate(-209.506 -81.021)"
              fill="currentColor"
            />
          </g>
        </g>
        <g
          id="Icon_ionic-ios-checkmark-circle-outline"
          data-name="Icon ionic-ios-checkmark-circle-outline"
          transform="translate(1087.288 361.75)"
        >
          <path
            id="Path_13710"
            data-name="Path 13710"
            d="M22.058,13.254l-.955-.982a.205.205,0,0,0-.152-.065h0a.2.2,0,0,0-.152.065l-6.62,6.669-2.409-2.409a.21.21,0,0,0-.3,0L10.5,17.5a.216.216,0,0,0,0,.309l3.039,3.039a.961.961,0,0,0,.635.309,1.007,1.007,0,0,0,.629-.3h.005l7.255-7.293A.232.232,0,0,0,22.058,13.254Z"
            transform="translate(-4.986 -5.39)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            id="Path_13711"
            data-name="Path 13711"
            d="M14.662,4.894a9.764,9.764,0,1,1-6.908,2.86,9.7,9.7,0,0,1,6.908-2.86m0-1.519A11.287,11.287,0,1,0,25.95,14.662,11.286,11.286,0,0,0,14.662,3.375Z"
            transform="translate(-3.375 -3.375)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
