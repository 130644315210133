import React from "react";

export const EditMakeIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6652 1.29374C21.9453 -0.431247 18.944 -0.431247 17.222 1.29374L1.94181 16.6001C1.79634 16.7458 1.69152 16.9301 1.64232 17.1293L0.0336611 23.5814C-0.0626019 23.9693 0.0507732 24.3807 0.333145 24.6657C0.549201 24.8821 0.84013 25 1.13748 25C1.22946 25 1.32144 24.9893 1.41343 24.9657L7.85663 23.3522C8.05771 23.3029 8.23954 23.1979 8.38501 23.0522L23.6652 7.74584C24.5251 6.88442 25 5.738 25 4.51872C25 3.29944 24.5272 2.15516 23.6652 1.29374ZM6.99882 21.2157L2.70335 22.2914L3.77722 17.9886L16.4176 5.32657L19.6392 8.55369L6.99882 21.2157ZM22.0544 6.13228L21.25 6.93799L18.0284 3.71087L18.8328 2.90516C19.6927 2.04374 21.1944 2.04374 22.0544 2.90516C22.4843 3.33587 22.7218 3.90801 22.7218 4.51872C22.7218 5.12943 22.4843 5.70157 22.0544 6.13228Z"
        fill="#FF8300"
      />
    </svg>
  );
};
