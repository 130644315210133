import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePagination, useSortBy, useTable } from "react-table";
import {
  PageBackArrowIcon,
  PageGoFirstArrowIcon,
  PageGoLastArrowIcon,
} from "../../gogo-icons";
import { PageNextArrowIcon } from "../../gogo-icons/PageNextArrowIcon";
import { useSelector } from "react-redux";
import {
  GridPageSize,
  languages,
} from "../../../utilities/constants/global.constants";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function DataTable({
  data,
  tableOptions,
  allowServerLoading = false,
  totalRecords,
  fetchData = () => {},
  filterParams = null,
  pageCount: controlledPageCount,
  fixedColumn = { left: [], right: [] },
  onRowClick,
  showPagination = true,
  reloadDataTable = false,
  pageIndexParent = 0,
  pageSizeParent = GridPageSize,
  setPageIndexParent = () => {},
  setPageSizeParent = () => {},
  customPageSize,
}) {
  const previousFilterParam = usePrevious(filterParams);
  const [columns, setColumns] = useState([]);
  const { t } = useTranslation();

  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  useEffect(() => {
    if (tableOptions) {
      if (tableOptions.columns && tableOptions.columns.length > 0) {
        setColumns(
          tableOptions.columns.map((x) => ({
            HeaderRender: x?.headerRender || "",
            Header: x?.header || "",
            accessor: x?.accessor,
            disableSortBy: x.sortable === undefined ? false : !x.sortable,
            IsHeaderJsx: x?.headerRender ? true : false,
          }))
        );
      }
    }
  }, [tableOptions]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    allowServerLoading
      ? {
          columns,
          data,
          initialState: {
            pageSize: pageSizeParent,
            pageIndex: pageIndexParent,
          },
          manualPagination: allowServerLoading,
          pageCount: controlledPageCount,
          autoResetPage: false,
        }
      : {
          columns,
          data,
          initialState: showPagination
            ? { pageSize: pageSizeParent, pageIndex: pageIndexParent }
            : data?.length > 0
            ? {
                pageSize: data?.length,
                pageIndex: pageIndexParent,
              }
            : undefined,
        },
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    if (previousFilterParam && previousFilterParam != filterParams) {
      gotoPage(0);
    } else {
      fetchData({ pageIndex, pageSize, filterParams });
    }
  }, [
    fetchData,
    pageIndex,
    pageSize,
    filterParams,
    previousFilterParam,
    gotoPage,
  ]);

  useEffect(() => {
    if (reloadDataTable && gotoPage) {
      gotoPage(0);
    }
  }, [gotoPage, reloadDataTable]);

  useEffect(() => {
    setPageIndexParent(pageIndex);
    setPageSizeParent(pageSize);
  }, [pageSize, pageIndex, setPageIndexParent, setPageSizeParent]);

  useEffect(() => {
    if (customPageSize) {
      setPageSize(customPageSize);
    }
  }, [customPageSize, setPageSize]);

  const getFixedColumnInfo = (totalIndex, index) => {
    const { left = [], right = [] } = fixedColumn;
    const fixedRightColumns = right.length;
    const fixedLeftColumns = left.length;

    const isRightFixed = totalIndex - fixedRightColumns < index;
    const isLeftFixed = index < fixedLeftColumns;

    let css = {};
    let cssEng = {};
    let cssAr = {};
    if (isRightFixed) {
      const currentColumnRightIndex = totalIndex - index;
      if (currentColumnRightIndex < fixedRightColumns) {
        const rightPosition = right.reduce((prev, cur, curIndex) => {
          if (curIndex < currentColumnRightIndex) return prev + cur;
          return prev;
        }, 0);
        const width = right[currentColumnRightIndex];
        cssEng = {
          right: `${rightPosition}px`,
          boxShadow: `${
            fixedRightColumns === currentColumnRightIndex + 1
              ? "inset 1px 0px #ddd"
              : "none"
          }`,
        };
        cssAr = {
          left: `${rightPosition}px`,
          boxShadow: `${
            fixedRightColumns === currentColumnRightIndex + 1
              ? "inset -1px 0px #ddd"
              : "none"
          }`,
        };
        css = {
          minWidth: `${width}px`,
          maxWidth: `${width}px`,
          width: `${width}px`,
          ...(selectedLanguage === languages.eng ? cssEng : cssAr),
        };
      }
    } else if (isLeftFixed) {
      const leftPosition = left.reduce((prev, cur, curIndex) => {
        if (index <= curIndex) return prev;
        return prev + cur;
      }, 0);
      const width = left[index];
      cssEng = {
        left: `${leftPosition}px`,
        boxShadow: `${
          fixedLeftColumns === index + 1 ? "inset -1px 0px #ddd" : "none"
        }`,
      };
      cssAr = {
        right: `${leftPosition}px`,
        boxShadow: `${
          fixedLeftColumns === index + 1 ? "inset 1px 0px #ddd" : "none"
        }`,
      };
      css = {
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
        width: `${width}px`,
        ...(selectedLanguage === languages.eng ? cssEng : cssAr),
      };
    }
    return {
      isFixed: isRightFixed || isLeftFixed,
      css,
    };
  };

  const renderTableHeader = (column, index, totalIndex) => {
    const fixedColumnObj = getFixedColumnInfo(totalIndex, index);

    const minWidth = tableOptions.columns[index].minWidth;
    return (
      <th
        {...column.getHeaderProps(column.getSortByToggleProps())}
        key={index}
        className={`${fixedColumnObj.isFixed && "fixed"} ${
          column.canSort ? "cursor-pointer" : ""
        }`}
        style={
          minWidth
            ? { minWidth: `${minWidth}px`, ...fixedColumnObj.css }
            : { ...fixedColumnObj.css }
        }
        title={column.render("Header")}
      >
        <div className="flex justify-center items-center gap-1">
          {column.IsHeaderJsx
            ? column.render("HeaderRender")
            : column.render("Header")}

          {column.canSort && (
            <div className="arrow">
              <div
                className={`triangle up ${
                  column.isSorted ? (column.isSortedDesc ? "" : "active") : ""
                }`}
              ></div>
              <div
                className={`triangle down ${
                  column.isSorted ? (column.isSortedDesc ? "active" : "") : ""
                }`}
              ></div>
            </div>
          )}
        </div>
      </th>
    );
  };

  const renderTableCell = (cell, index, totalIndex) => {
    const fixedColumnObj = getFixedColumnInfo(totalIndex, index);
    const colIndex = tableOptions.columns.findIndex(
      (x) => x.header === cell.column.Header && x.cellRender
    );
    return (
      <td
        {...cell.getCellProps()}
        key={index}
        className={`${fixedColumnObj.isFixed && "fixed"}`}
        style={fixedColumnObj.css}
      >
        {colIndex > -1 ? (
          tableOptions.columns[colIndex].cellRender(
            cell.row.original,
            cell.value
          )
        ) : (
          <div className="flex items-center justify-center text-center">
            <p className="leading-none description">{cell.value}</p>
          </div>
        )}
      </td>
    );
  };
  return (
    <div className="flex flex-col relative po-table">
      {data.length === 0 && (
        <div className="flex items-center absolute w-full h-full top-0 right-0 justify-center">
          {t("GRID_DATA_NOT_AVAILABLE")}
        </div>
      )}
      <div
        className={
          page.length === 0
            ? "po-table no-pagination arabic-direction overflow-table-hidden"
            : showPagination
            ? "po-table pagination arabic-direction"
            : "po-table no-pagination arabic-direction"
        }
      >
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) =>
                  renderTableHeader(
                    column,
                    index,
                    headerGroup.headers.length - 1
                  )
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  className={`${
                    onRowClick ? "cursor-pointer hover:bg-gray-500" : ""
                  }`}
                  onClick={() => onRowClick && onRowClick(row.original)}
                >
                  {row.cells.map((cell, index) =>
                    renderTableCell(cell, index, row.cells.length - 1)
                  )}
                </tr>
              );
            })}
            {page.length === 0 && (
              <tr className="h-48">
                <td colSpan="100%" className="text-center"></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data.length > 0 && (showPagination || allowServerLoading) && (
        <div className="p-3 border border-gray-400 text-gray-800 text-sm font-light bg-white pagination-bar">
          <div className="flex gap-2 justify-between flex-wrap-reverse arabic">
            <div
              className={`flex md:justify-center items-center ${
                selectedLanguage === languages.eng
                  ? "flex-row"
                  : "flex-row-reverse"
              }`}
            >
              <span
                className={`${
                  selectedLanguage === languages.eng ? "pr-1" : "pl-1"
                }`}
              >
                {t("PAGE_SIZE")}
              </span>
              <span
                className={`${
                  selectedLanguage === languages.eng ? "pr-2" : "pl-2"
                }`}
              >
                :
              </span>
              {allowServerLoading ? (
                <select
                  className="border rounded-lg"
                  value={pageSize}
                  placeholder={t("SELECT_DROPDOWN_LITERAL")}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    gotoPage(0);
                  }}
                >
                  {[6, 15, 30, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  className="border rounded-lg"
                  value={pageSize}
                  placeholder={t("SELECT_DROPDOWN_LITERAL")}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    gotoPage(0);
                  }}
                >
                  {[6, 15, 30, 50, "All"].map((pageSize) => (
                    <option
                      key={pageSize}
                      value={pageSize === "All" ? data.length : pageSize}
                    >
                      {pageSize}
                    </option>
                  ))}
                </select>
              )}
              <span
                className={`${
                  selectedLanguage === languages.eng ? "pl-4" : "pr-4"
                }`}
              >
                {`${t("RESULTS")}`}
              </span>
              <span
                className={`${
                  selectedLanguage === languages.eng ? "pr-2 pl-1" : "pl-2 pr-1"
                }`}
              >
                :
              </span>
              <span className={``}>
                {allowServerLoading ? totalRecords : data.length}
              </span>
            </div>
            <div className="flex gap-2 arabic-direction">
              <button
                className="border p-2 hover:bg-gray-300 rounded-lg"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                title={t("FIRST").toUpperCase()}
              >
                <PageGoFirstArrowIcon className="h-3 w-3 arabic-back-icon " />
              </button>
              <button
                className="border p-2 hover:bg-gray-300 rounded-lg"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                title={t("BACK").toUpperCase()}
              >
                <PageBackArrowIcon className="h-3 w-3 arabic-back-icon " />
              </button>
              <div className="flex justify-center items-center">
                {t("GRID_PAGINATION", {
                  from: pageIndex + 1,
                  to: pageOptions.length,
                })}
              </div>
              <button
                className="border p-2 hover:bg-gray-300 rounded-lg"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                title={t("NEXT").toUpperCase()}
              >
                <PageNextArrowIcon className="h-3 w-3 arabic-forward-icon" />
              </button>
              <button
                className="border p-2 hover:bg-gray-300 rounded-lg"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                title={t("LAST").toUpperCase()}
              >
                <PageGoLastArrowIcon className="h-3 w-3 arabic-forward-icon" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataTable;
