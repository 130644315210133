import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { menuHeaderIds } from "../../utilities/constants/global.constants";
import { getHeaderMenu, setLanguage } from "../../redux/actions/globalAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import { ApplicationRoles } from "../../utilities/constants/role.constants";
import { getDealerInformation } from "../../redux/actions/authAction";

function Header() {
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [newHeaders, setNewHeaders] = useState([]);
  const selectedLanguage = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );
  const userRoles = useSelector((state) => state.authReducer.userRoles);
  const dealerInformation = useSelector(
    (state) => state.authReducer.dealerInformation
  );

  const headerMenu = useSelector((state) => state.globalReducer.headerMenu);

  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    userRoles[0] === ApplicationRoles.DMS_ADMIN &&
      dispatch(getDealerInformation());
  }, [dispatch, userRoles]);

  useEffect(() => {
    const langParam =
      location.search && new URLSearchParams(location.search).get("language");
    if (langParam) {
      i18n.changeLanguage(langParam);
      dispatch(setLanguage(langParam));
    }
  }, [location, dispatch, i18n]);

  useEffect(() => {
    if (selectedLanguage !== null) {
      dispatch(getHeaderMenu(selectedLanguage));
    }
  }, [selectedLanguage, dispatch]);

  useEffect(() => {
    if (
      userRoles[0] === ApplicationRoles.DMS_ADMIN &&
      !dealerInformation.GGMDigitalDealer
    ) {
      const header = headerMenu?.data?.filter((x) => x.URL !== "/deals");
      setNewHeaders(header);
    } else {
      setNewHeaders(headerMenu.data);
    }
  }, [dealerInformation.GGMDigitalDealer, headerMenu, userRoles]);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <header className="text-gray-600 body-font mb-1">
        <div className="">
          <div className="mx-auto md:flex flex-wrap py-1 flex-col md:flex-row items-center">
            <div className="logo-wrapper">
              <button
                type="button"
                className="sm:inline-flex lg:hidden items-center justify-center p-4 text-gray-400  "
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={toggleMenu}
              >
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div
              className={
                openMenu
                  ? "active"
                  : "inactive flex ar-header-menu justify-between"
              }
            >
              <div className="header-menu flex mt-4 gap-6 flex-wrap">
                {newHeaders &&
                  newHeaders.length > 0 &&
                  newHeaders.map((menu, index) => (
                    <nav
                      className="text-primary xl:text-base text-sm font-bold leading-7 uppercase cursor-pointer"
                      key={index}
                    >
                      {menu.MenuId === menuHeaderIds.NewCars ||
                      menu.MenuId === menuHeaderIds.SellItForMe ||
                      menu.MenuId === menuHeaderIds.Services ? (
                        <a
                          href={menu.URL}
                          target={menu.IsNewTab === false ? "_self" : "_blank"}
                          rel="noopener noreferrer"
                        >
                          {menu.Menu}
                        </a>
                      ) : (
                        <NavLink
                          to={`${menu.URL}`}
                          exact={true}
                          activeClassName="is-active"
                          className={
                            menu.URL?.replace("/", "") ==
                            location?.pathname?.split("/")[1]
                              ? "is-active"
                              : ""
                          }
                        >
                          {menu.Menu}
                        </NavLink>
                      )}
                    </nav>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default connect((state) => state, {})(Header);
