import React from "react";

export const LocationIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.512 16.863"
      className={className}
    >
      <path
        id="Path_748"
        d="M20.315,5.139a6.916,6.916,0,0,0-9.389,0A6.508,6.508,0,0,0,8.879,9.413a6.373,6.373,0,0,0,1.44,4.4l4.871,6.138a.547.547,0,0,0,.862,0l4.871-6.138a6.373,6.373,0,0,0,1.44-4.4A6.508,6.508,0,0,0,20.315,5.139Zm-.258,7.993-4.437,5.589-4.437-5.589a5.288,5.288,0,0,1-1.2-3.638,5.421,5.421,0,0,1,1.7-3.553,5.813,5.813,0,0,1,7.872,0,5.412,5.412,0,0,1,1.7,3.553A5.288,5.288,0,0,1,20.057,13.132Z"
        transform="translate(-8.864 -3.301)"
        fill="#465166"
      ></path>
      <path
        id="Path_749"
        d="M22.893,12.606a3.6,3.6,0,1,0,3.623,3.6A3.616,3.616,0,0,0,22.893,12.606Zm0,6.1a2.5,2.5,0,1,1,2.518-2.5A2.513,2.513,0,0,1,22.893,18.711Z"
        transform="translate(-16.137 -9.804)"
        fill="#465166"
      ></path>
    </svg>
  );
};
