import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PetrominAuth = React.lazy(() => import("../views/public/PetrominAuth"));
const PetrominSignUp = React.lazy(() =>
  import("../views/public/PetrominSignUp")
);
const PetrominResetPassword = React.lazy(() =>
  import("../views/public/PetrominResetPassword")
);
const AboutUs = React.lazy(() => import("../views/AboutUs"));
const PrivacyPolicy = React.lazy(() => import("../views/PrivacyPolicy"));
const TermsCondition = React.lazy(() => import("../views/TermsConditions"));
const FindVin = React.lazy(() => import("../views/FindVin"));

const PublicRoute = () => {
  return (
    <Switch>
      <Route exact path="/petromin-auth" component={PetrominAuth} />
      <Route exact path="/petromin-signup" component={PetrominSignUp} />
      <Route
        exact
        path="/petromin-reset-password"
        component={PetrominResetPassword}
      />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms-conditions" component={TermsCondition} />
      <Route
        exact
        path="/bae28f3c-bace-42b3-b2cb-4e97f32561d1"
        component={FindVin}
      />
      <Route exact path="**" component={PetrominAuth}>
        <Redirect to="/petromin-auth" />
      </Route>
    </Switch>
  );
};

export default connect((state) => state, {})(PublicRoute);
