import {
  GET_FILTER_CITY_LIST,
  GET_FILTER_MAKE_MODEL,
  SET_OTHER_FILTER_DATA,
  SET_FILTER_BODY_TYPE,
  SET_FILTER_FUEL_TYPE,
  SET_VEHICLE_FILTERS,
  SET_SLIDER_DATA,
  REMOVE_SELECTED_FILTER,
  SET_SORT_API,
  GET_MODEL_YEAR,
  GET_MAKE,
  GET_MODEL_BY_MAKE,
  GET_STYLE,
  SET_DATE_FILTERS,
  GET_DEALERS,
  SET_FILTER_BODY_STYLE,
  SET_FILTER_FUEL_TYPE_V1,
} from "../types";

const initialState = {
  cityList: [],
  makeModelData: [],
  otherFilterData: [],
  bodyTypes: [],
  fuelTypes: [],
  vehicleRecommendation: [],
  sliderData: [],
  vehicleFilter: [],
  removeFilter: null,
  sortByOrder: "AskingPrice desc",
  getModelYear: [],
  getMakeByYear: [],
  getModelByYearMake: [],
  getStyle: [],
  filterDateRange: [],
  dealers: [],
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILTER_CITY_LIST:
      return {
        ...state,
        cityList: action.payload,
      };
    case GET_FILTER_MAKE_MODEL:
      return {
        ...state,
        makeModelData: action.payload,
      };
    case SET_OTHER_FILTER_DATA:
      return {
        ...state,
        otherFilterData: action.payload,
      };
    case SET_FILTER_BODY_TYPE:
      return {
        ...state,
        bodyTypes: action.payload,
      };
    case SET_FILTER_BODY_STYLE:
      return {
        ...state,
        bodyStyles: action.payload,
      };
    case SET_FILTER_FUEL_TYPE:
      return {
        ...state,
        fuelTypes: action.payload,
      };
    case SET_FILTER_FUEL_TYPE_V1:
      return {
        ...state,
        fuelTypes: action.payload,
      };
    case SET_VEHICLE_FILTERS:
      return {
        ...state,
        vehicleFilter: action.payload,
      };
    case SET_SLIDER_DATA:
      return {
        ...state,
        sliderData: action.payload,
      };
    case REMOVE_SELECTED_FILTER:
      return {
        ...state,
        removeFilter: action.payload,
      };
    case SET_SORT_API:
      return {
        ...state,
        sortByOrder: action.payload,
      };
    case GET_MODEL_YEAR:
      return {
        ...state,
        getModelYear: action.payload,
      };
    case GET_MAKE:
      return {
        ...state,
        getMakeByYear: action.payload,
      };
    case GET_MODEL_BY_MAKE:
      return {
        ...state,
        getModelByYearMake: action.payload,
      };
    case GET_STYLE:
      return {
        ...state,
        getStyle: action.payload,
      };
    case SET_DATE_FILTERS:
      return {
        ...state,
        filterDateRange: action.payload,
      };
    case GET_DEALERS:
      return {
        ...state,
        dealers: action.payload,
      };
    default:
      return state;
  }
};
