import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import routes from "./routes";
import AuthHelper from "../utilities/helpers/auth.helpers";

const PrivateRoute = () => {
  return (
    <Switch>
      {routes.map(({ path, component, exact, redirectTo, privileges }, key) => {
        if (!privileges || AuthHelper.checkPrivileges(privileges)) {
          return redirectTo ? (
            <Route exact={exact} path={path} key={key}>
              <Redirect to={redirectTo} />
            </Route>
          ) : (
            <Route exact={exact} path={path} component={component} key={key} />
          );
        }
      })}
    </Switch>
  );
};

export default connect((state) => state, {})(PrivateRoute);
