export const FrontWindowIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 85.266 53.681"
      className={className}
      fill="currentColor"
    >
      <g id="Layer_x0020_1" transform="translate(-3378.328 -3565.741)">
        <g id="_1466911540048" transform="translate(3378.328 3565.741)">
          <path
            id="Path_15381"
            data-name="Path 15381"
            d="M4662.7,4594.239h8.167v2.131H4662.7Zm-2.043-.711c0,2.461-.462,4.974,1.191,4.974h9.871c1.653,0,1.19-2.513,1.19-4.974,0-1.984-2.14-1.42-5.1-1.42v-3.016c2.307,0,7.7.581,10.01.917a81.215,81.215,0,0,1,8.949,2.03,41.063,41.063,0,0,1,4.121,1.38c.762.284,1.275.451,2.011.747a82.269,82.269,0,0,1,9.454,4.514,9.4,9.4,0,0,1-.435,1.851l-7.9,29.043c-1.358-.679-11.855-2.387-14.154-2.635-.751-.08-1.564-.207-2.182-.207v-8.7c0-.512,6.8-10.566,7.559-12.015.783-1.477-.9-2.456-1.883-.885-.7,1.121-11.124,17.1-11.124,17.694a2.675,2.675,0,0,0,.171.888c2.475.6,1.576-1.221,3.233-2.488v5.326c-.992,0-1.25-.135-2.043-.179-.715-.041-1.5.044-2.213,0l-4.762-.178c-2.342,0-4.354.048-6.637.174-2.1.122-4.261.536-6.466.536v-8.524c0-.735,1.38-2.61,1.8-3.268,1-1.554,6.027-9.077,6.027-9.694a2.665,2.665,0,0,0-.171-.888c-1.506-.365-1.229-.386-2.79,2.062l-4.8,7.42c-1.082,1.7-2.081,3.26-3.132,4.9l-2.314,3.808c-.157.836.976,2.175,2.283.068.175-.283.28-.45.471-.75l.583-.816v6.036a24.435,24.435,0,0,0-2.992.435c-1.413.248-8.042,1.433-8.921,1.876a11.422,11.422,0,0,0-.452-1.839l-1.591-5.8c-.7-2.594-1.389-5.074-2.116-7.736l-4.183-15.344c0-.711,9.4-4.729,11.212-5.35,5.669-1.953,11.922-3.788,18.061-4.064,1.591-.073,3.646-.353,5.275-.353v3.016C4662.793,4592.108,4660.653,4591.544,4660.653,4593.528ZM4629,4597.97c0,1.287,1.506,6.134,1.945,7.736l6.128,22.371c1.51,5.541-.7,3.8,12.442,1.6a111.363,111.363,0,0,1,34.673.025,94.232,94.232,0,0,1,9.411,1.9c2.07.573,1.833.39,2.926-3.661,1.342-5,2.68-9.767,4.046-14.775.692-2.537,1.307-4.8,2-7.318.366-1.315.627-2.44,1.027-3.727,1.626-5.208,1.483-4.409-2.672-6.575a75.36,75.36,0,0,0-13.045-5.387,70.667,70.667,0,0,0-32.308-2.278,85.269,85.269,0,0,0-9.9,2.278C4643.166,4590.834,4629,4596.362,4629,4597.97Z"
            transform="translate(-4624.134 -4583)"
            fillRule="evenodd"
          />
          <path
            id="Path_15382"
            data-name="Path 15382"
            d="M3380.641,3579.364c.338-.185.677-.334,1.011-.536.358-.218.51-.343.969-.584a92.515,92.515,0,0,1,11.073-5.28,74.906,74.906,0,0,1,15.356-4.188c17.1-2.386,28.74-.347,43.676,6.078.447.193.61.306,1.031.516l5.451,2.817c.755.387,1.369.778,2.1,1.177a10.035,10.035,0,0,1-.549,2.431l-4.556,16.432c-.833,3.111-1.692,6.2-2.571,9.327-.261.919-2.518,8.726-2.533,9.54l-6.509-1.543c-15.858-3.519-31.21-3.245-47.057,0-1.051.217-2.207.467-3.346.766a19.335,19.335,0,0,1-3.334.778,43.5,43.5,0,0,0-1.21-4.74c-.432-1.532-.829-3.043-1.284-4.667l-7.131-25.754A10.981,10.981,0,0,1,3380.641,3579.364Zm38.8,35.791a107.2,107.2,0,0,1,25.371,2.628c8.054,1.745,7.3,2.91,8.384-.984.319-1.145.549-2,.864-3.156l8.571-30.966c.292-1.081.529-1.939.84-3.027.471-1.657-.443-1.657-2.56-2.874-.513-.3-.786-.5-1.307-.762-7.271-3.736-10.867-5.635-19.333-7.827-2.159-.56-4.139-1.036-6.462-1.418a78.2,78.2,0,0,0-34.3,2c-1.015.31-1.9.629-2.875.9s-1.969.713-2.914,1.036c-1.906.653-3.7,1.548-5.447,2.29-3.1,1.314-5.949,3.124-8.886,4.724-1.809.983-1.085,1.181.533,7.15l8.567,30.97c1.241,4.41.109,4.148,6.719,2.418a107.26,107.26,0,0,1,24.235-3.1Z"
            transform="translate(-3378.327 -3565.741)"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
