import {
  selectedAdminConfig,
  selectedTemplate,
} from "../../utilities/constants/global.constants";
import {
  GET_INVOICE,
  GET_INVOICE_STATUS,
  GET_INVOICE_DETAILS_BY_ID,
  GET_CHARGE_DETAILS,
  GET_PAYMENT_STATUS,
  SET_INVOICE_API_DATA,
  GET_INVOICE_DATA,
  GET_QUOTATION,
  SET_QUOTATION_FILTER,
  GET_QUOTATION_DATA,
  GET_QUOTATION_STATUS,
  GET_QUOTATION_BY_ID,
  GET_QUOTATION_BY_ID_SUCCESS,
  GET_QUOTATION_DOCUMENTS,
  GET_QUOTATION_DOCUMENTS_SUCCESS,
  SET_SELECTED_STATUS,
  GET_QUOTATION_STATUS_ID,
  SET_SELECTED_TEMPLATE,
} from "../types";

const initialState = {
  listOfInvoices: null,
  invoiceStatusList: [],
  invoiceDetailsbyIdData: [],
  chargeDetails: [],
  paymentDetails: [],
  invoiceStatus: {},
  invoicePayload: {
    invoiceCreatedDate: {
      max: "",
      min: "",
    },
    invoiceStatus: "",
    dealerName: "",
    phoneNumber: "+966",
    invoiceNumber: "",
    invoiceReferenceNumber: "",
    vin: "",
  },
  invoiceApiData: null,
  quotations: null,
  quotationFilter: null,
  quotationPayload: {
    quotationNumber: null,
    quotationCreatedDate: {
      max: null,
      min: null,
    },
    phoneNumber: null,
    emailAddress: null,
    dealerName: null,
    dealerId: null,
  },
  quotationStatus: [],
  quotationById: null,
  quotationDocuments: null,
  selectedStatus: selectedAdminConfig.Quotation,
  quotationStatusById: null,
  selectedTemplate: selectedTemplate.EmailTemplate,
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE:
      return {
        ...state,
        listOfInvoices: action.payload,
      };
    case GET_INVOICE_STATUS:
      return {
        ...state,
        invoiceStatusList: action.payload,
      };
    case GET_INVOICE_DETAILS_BY_ID:
      return {
        ...state,
        invoiceDetailsbyIdData: action.payload,
      };
    case GET_CHARGE_DETAILS:
      return {
        ...state,
        chargeDetails: action.payload,
      };
    case GET_PAYMENT_STATUS:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case GET_INVOICE_DATA:
      return {
        ...state,
        invoicePayload: {
          ...state.invoicePayload,
          ...action.payload,
        },
      };
    case SET_INVOICE_API_DATA:
      return {
        ...state,
        invoiceApiData: action.payload,
      };
    case GET_QUOTATION:
      return {
        ...state,
        quotations: action.payload,
      };
    case SET_QUOTATION_FILTER:
      return {
        ...state,
        quotationFilter: action.payload,
      };
    case GET_QUOTATION_DATA:
      return {
        ...state,
        quotationPayload: {
          ...state.quotationPayload,
          ...action.payload,
        },
      };
    case GET_QUOTATION_STATUS:
      return {
        ...state,
        quotationStatus: action.payload,
      };
    case GET_QUOTATION_BY_ID:
      return {
        ...state,
        quotationById: null,
      };
    case GET_QUOTATION_BY_ID_SUCCESS:
      return {
        ...state,
        quotationById: action.payload,
      };
    case GET_QUOTATION_DOCUMENTS:
      return {
        ...state,
        quotationDocuments: null,
      };
    case GET_QUOTATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        quotationDocuments: action.payload,
      };
    case SET_SELECTED_STATUS:
      return {
        ...state,
        selectedStatus: action.payload,
      };
    case GET_QUOTATION_STATUS_ID:
      return {
        ...state,
        quotationStatusById: action.payload,
      };
    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    default:
      return state;
  }
};
