import {
  GET_PROMOTIONS,
  GET_PROMOTIONS_CRITERIA,
  FETCH_PROMOTION_LIST,
  GET_PROMOTION_USAGE,
  GET_PROMOTON_DATA,
} from "../types";

const initialState = {
  promotionData: [],
  promotionCriteria: [],
  fetchPromotionList: false,
  getPromotionUsageData: [],
  promotionPayload: {
    promotionStartDate: "",
    promotionEndDate: "",
    isSuccess: false,
  },
};

export const promotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMOTIONS:
      return {
        ...state,
        promotionData: action.payload,
        fetchPromotionList: false,
      };
    case GET_PROMOTIONS_CRITERIA:
      return {
        ...state,
        promotionCriteria: action.payload,
      };
    case FETCH_PROMOTION_LIST:
      return {
        ...state,
        fetchPromotionList: true,
      };
    case GET_PROMOTION_USAGE:
      return {
        ...state,
        getPromotionUsageData: action.payload,
      };
    case GET_PROMOTON_DATA:
      return {
        ...state,
        promotionPayload: {
          ...state.promotionPayload,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
