export const SortIcon = ({ className = "" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_942_41)">
        <path
          d="M4.49621 11.6194C4.49621 11.6686 4.48652 11.7173 4.46769 11.7628C4.44887 11.8082 4.42128 11.8495 4.3865 11.8843C4.35172 11.9191 4.31042 11.9466 4.26498 11.9655C4.21953 11.9843 4.17083 11.994 4.12164 11.994C4.07245 11.994 4.02374 11.9843 3.9783 11.9655C3.93285 11.9466 3.89156 11.9191 3.85678 11.8843C3.822 11.8495 3.79441 11.8082 3.77558 11.7628C3.75676 11.7173 3.74707 11.6686 3.74707 11.6194V0.38238C3.74707 0.283039 3.78653 0.187766 3.85678 0.117521C3.92702 0.0472758 4.0223 0.0078125 4.12164 0.0078125C4.22098 0.0078125 4.31625 0.0472758 4.3865 0.117521C4.45674 0.187766 4.49621 0.283039 4.49621 0.38238V11.6194Z"
          fill="#43474D"
        />
        <path
          d="M4.40965 11.3802C4.44254 11.4178 4.46757 11.4616 4.48329 11.509C4.499 11.5564 4.50507 11.6065 4.50115 11.6563C4.49722 11.7061 4.48338 11.7546 4.46043 11.799C4.43749 11.8433 4.4059 11.8827 4.36753 11.9146C4.32916 11.9466 4.28479 11.9706 4.23701 11.9851C4.18924 11.9997 4.13904 12.0046 4.08935 11.9995C4.03967 11.9944 3.99151 11.9793 3.94771 11.9553C3.90392 11.9313 3.86536 11.8988 3.83432 11.8597L0.0811457 7.35739C0.0203664 7.28078 -0.00804786 7.18345 0.00197323 7.08618C0.0119943 6.98891 0.0596561 6.89942 0.134776 6.83682C0.209897 6.77422 0.306519 6.74348 0.404002 6.75116C0.501484 6.75884 0.592097 6.80434 0.656482 6.87794L4.40965 11.3802Z"
          fill="#43474D"
        />
        <path
          d="M7.49316 0.382447C7.49316 0.333252 7.50284 0.284538 7.52166 0.239085C7.54048 0.193633 7.56807 0.152333 7.60285 0.117544C7.63763 0.0827554 7.67893 0.0551591 7.72438 0.0363311C7.76982 0.0175032 7.81854 0.0078125 7.86773 0.0078125C7.91693 0.0078125 7.96564 0.0175032 8.01109 0.0363311C8.05654 0.0551591 8.09783 0.0827554 8.13261 0.117544C8.1674 0.152333 8.19499 0.193633 8.21381 0.239085C8.23263 0.284538 8.24231 0.333252 8.2423 0.382447V11.6195C8.24228 11.7188 8.20281 11.8141 8.13257 11.8843C8.06233 11.9545 7.96706 11.994 7.86773 11.994C7.7684 11.994 7.67314 11.9545 7.6029 11.8843C7.53265 11.8141 7.49318 11.7188 7.49316 11.6195V0.382447Z"
          fill="#43474D"
        />
        <path
          d="M7.57996 0.621197C7.54707 0.583607 7.52204 0.539818 7.50632 0.492409C7.49061 0.445 7.48454 0.394926 7.48846 0.345135C7.49239 0.295344 7.50623 0.246841 7.52918 0.202478C7.55212 0.158116 7.58371 0.118791 7.62208 0.0868169C7.66045 0.0548425 7.70483 0.0308646 7.7526 0.0162938C7.80037 0.00172307 7.85058 -0.00314633 7.90026 0.00197213C7.94994 0.0070906 7.9981 0.0220935 8.0419 0.0460983C8.0857 0.0701031 8.12425 0.102625 8.1553 0.141751L11.9085 4.64406C11.9692 4.72066 11.9977 4.81799 11.9876 4.91526C11.9776 5.01253 11.93 5.10202 11.8548 5.16462C11.7797 5.22722 11.6831 5.25797 11.5856 5.25028C11.4881 5.2426 11.3975 5.1971 11.3331 5.1235L7.57996 0.621197Z"
          fill="#43474D"
        />
      </g>
      <defs>
        <clipPath id="clip0_942_41">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
