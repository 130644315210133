import i18next from "i18next";
import { toast } from "react-toastify";
import { API } from "../../middleware";
import {
  GET_APPLICATION_ROLE_HIERARCHY,
  GET_USER_STATISTIC,
  GET_PETROMIN_ADMIN_USERS,
  GET_PROFILE_DETAILS_BY_ID,
  GET_PROFILE_REQUEST_LIST,
  GET_PROFILE_CHANGE_REQ_LIST,
  SET_USER_PROFILE,
  GET_PO_ADMIN_STATISTIC,
  GET_DMS_ADMIN_STATISTIC,
  GET_INSPECTOR_STATISTICS,
  GET_ACTIVE_CUSTOMERS,
  GET_CUSTOMER_USER_STATISTIC,
} from "../types";
import { hideLoader, showLoader } from "./applicationAction";
import {
  ProfileChangeMessageCodes,
  profileRequestStatus,
} from "../../utilities/constants/global.constants";
import { getDealers } from "./filterAction";

export const setApplicationRoleHierarchy = (data) => ({
  type: GET_APPLICATION_ROLE_HIERARCHY,
  payload: data,
});

export const setPetrominAdminUsers = (data) => ({
  type: GET_PETROMIN_ADMIN_USERS,
  payload: data,
});

export const setProfileReqList = (data) => ({
  type: GET_PROFILE_REQUEST_LIST,
  payload: data,
});

export const setProfileChangeReqList = (data) => ({
  type: GET_PROFILE_CHANGE_REQ_LIST,
  payload: data,
});

export const setActiveCustomers = (data) => ({
  type: GET_ACTIVE_CUSTOMERS,
  payload: data,
});

export const getApplicationRoleHierarchy = (languageId) => {
  return (dispatch) => {
    return API.get("/api/profile/applicationRoleHierarchy", {
      params: { languageId: languageId },
    })
      .then((response) => {
        dispatch(setApplicationRoleHierarchy(response.data));
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const createUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("api/Profile/createUser", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const setUserStatistic = (data) => ({
  type: GET_USER_STATISTIC,
  payload: data,
});

export const getUserStatistic = (payload) => {
  return (dispatch) => {
    return API.get("/api/Profile/userstatistics", {
      params: payload,
    })
      .then((response) => {
        dispatch(setUserStatistic(response.data));
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const fetchApplicationUsers = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get("/api/profile/userlist", {
      params: data,
    });
    dispatch(setPetrominAdminUsers(response.data));
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setProfileDetailsById = (data) => ({
  type: GET_PROFILE_DETAILS_BY_ID,
  payload: data,
});
export const fetchProfileDetailsById = (data) => async (dispatch) => {
  try {
    const response = await API.get("/api/profile/profileinfobyuserid", {
      params: data,
    });
    dispatch(setProfileDetailsById(response.data));
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const profileApproveReject = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/Profile/approverejectprofile", data);
    if (response.data) {
      if (data.ChangeRequestStatus === profileRequestStatus.APPROVED) {
        if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.EMAIL_ALREADY_EXISTS
        ) {
          toast.error(i18next.t("EMAIL_ALREADY_EXISTS"));
        } else if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.ALTERNATEEMAIL_ALREADY_EXISTS
        ) {
          toast.error(i18next.t("ALTERNATEEMAIL_ALREADY_EXISTS"));
        } else if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.MOBILENUMBER_ALREADY_EXISTS
        ) {
          toast.error(i18next.t("MOBILE_ALREADY_EXISTS"));
        } else if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.ALTERNATEMOBILENUMBER_ALREADY_EXISTS
        ) {
          toast.error(i18next.t("ALTERNATEMOBILENUMBER_ALREADY_EXISTS"));
        } else if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.PROFILE_REQUEST_APPROVED
        ) {
          toast.success(i18next.t("APPROVE_CHANGE_REQUEST"));
        } else if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.PROFILE_REQUEST_NOT_APPROVED
        ) {
          toast.error(i18next.t("PROFILE_REQUEST_NOT_APPROVED"));
        }
      } else {
        if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.REJECT_PROFILE_REQUEST_SUCCESSFUL
        ) {
          toast.success(i18next.t("REJECT_CHANGE_REQUEST"));
        } else if (
          response.data.MessageCode ===
          ProfileChangeMessageCodes.REJECT_PROFILE_REQUEST_NOT_SUCESSFUL
        ) {
          toast.error(i18next.t("REJECT_PROFILE_REQUEST_NOT_SUCESSFUL"));
        }
      }
    }
    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const getProfileReqList = (data) => async (dispatch) => {
  try {
    const response = await API.get("/api/Profile/profilechangerequest", {
      params: data,
    });
    dispatch(setProfileReqList(response.data));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProfileDetailsById = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/profile/updateuserprofile", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProfileChangeReqList = (data) => async (dispatch) => {
  try {
    const response = await API.get("/api/Profile/profilechangefield", {
      params: data,
    });
    dispatch(setProfileChangeReqList(response.data));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserProfile = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Profile/user?languageId=${languageId}`
    );
    dispatch(setUserProfileData(response.data));
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setUserProfileData = (data) => ({
  type: SET_USER_PROFILE,
  payload: data,
});

export const saveProfileRequest = (data) => async () => {
  try {
    const response = await API.post("/api/Profile/profilechangerequest", data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveProfileModeOfCommunicationRequest = (data) => async () => {
  try {
    const response = await API.put(
      "/api/Profile/updatemodeofcommunication",
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const userStatusAction = (userData, payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    await API.post("/api/Profile/updateaccountstatusbyid", userData).then(
      (res) => {
        dispatch(hideLoader());
        if (res.data.MessageCode === "ACTIVATE_SUCESS_MSG") {
          dispatch(fetchApplicationUsers(payload));
          dispatch(getUserStatistic({ IncludeCustomerList: false }));
          dispatch(getCustomerUserStatistic());
          toast.success(i18next.t("ACTIVATED"));
        } else if (res.data.MessageCode === "DEACTIVATE_SUCESS_MSG") {
          dispatch(fetchApplicationUsers(payload));
          dispatch(getUserStatistic({ IncludeCustomerList: false }));
          dispatch(getCustomerUserStatistic());
          toast.success(i18next.t("DEACTIVATED_API"));
        }

        return Promise.resolve(res);
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deactivateUsers = (data, payload) => async (dispatch) => {
  try {
    const response = await API.post("/api/Profile/bulkdeactivation", data);
    dispatch(fetchApplicationUsers(payload));
    toast.success(i18next.t("USERS_DEACTIVATED"));
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPOAdminStatistics = () => async (dispatch) => {
  try {
    const response = await API.get("/api/Statistic/admin");
    dispatch({ type: GET_PO_ADMIN_STATISTIC, payload: response.data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDMSAdminStatistics = () => async (dispatch) => {
  try {
    const response = await API.get("/api/Statistic/DMSadmin");
    dispatch({ type: GET_DMS_ADMIN_STATISTIC, payload: response.data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInspectorStatistic = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `api/Statistic/inspector?languageId=${languageId}`
    );
    dispatch({ type: GET_INSPECTOR_STATISTICS, payload: response.data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchApplicationUsersExport = (languageId) => async () => {
  try {
    const response = await API.get(
      `/api/profile/userlist?languageId=${languageId}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchActiveCustomers = () => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get("/api/Profile/users");
    dispatch(setActiveCustomers(response.data));
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerUserStatistic = () => {
  return (dispatch) => {
    return API.get("/api/Profile/customeruserstatistics")
      .then((response) => {
        dispatch({
          type: GET_CUSTOMER_USER_STATISTIC,
          payload: response.data,
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const deactivateDealerUser = (data, payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      `/api/Profile/deactiavtedealer?userId=${data}`
    );
    dispatch(getDealers(payload));
    if (response.data.IsSuccess) {
      toast.success(i18next.t("DEALER_DEACTIVATION_SUCCESS"));
    } else {
      if (
        response.data.MessageCode ===
        "DEALER_DEACTIVATION_FAILED_VEHICLES_FOUND"
      ) {
        toast.error(i18next.t("DEALER_DEACTIVATION_FAILED_VEHICLES_FOUND"));
      } else if (
        response.data.MessageCode ===
        "DEALER_DEACTIVATION_FAILED_SALESPERSONS_FOUND"
      ) {
        toast.error(i18next.t("DEALER_DEACTIVATION_FAILED_SALESPERSONS_FOUND"));
      } else if (response.data.MessageCode === "DEALER_DEACTIVATION_FAILED") {
        toast.error(i18next.t("DEALER_DEACTIVATION_FAILED"));
      }
    }
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const userStatusActionEdit = (userData, payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    await API.post("/api/Profile/updateaccountstatusbyid", userData).then(
      (res) => {
        dispatch(hideLoader());
        dispatch(fetchProfileDetailsByUserId(payload));
        if (res.data.MessageCode === "ACTIVE_MSG") {
          toast.success(i18next.t("ACTIVATED"));
        } else if (res.data.MessageCode === "DEACTIVE_MSG") {
          toast.success(i18next.t("DEACTIVATED_API"));
        }
        return Promise.resolve(res);
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFCNToken = (userId) => {
  return () => {
    return API.get(`/api/Profile/fcmtokens?UserId=${userId ? userId : ""}`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const createUserWithMutlipleRoles = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      "api/Profile/createUserWithMultipleRoles",
      data
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const updateProfileDetailsByMultipleId = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/profile/updateuserprofilewithmultipleroles",
      data
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProfileDetailsByUserId = (data) => async (dispatch) => {
  try {
    const response = await API.get("/api/profile/getprofileinfobyuserid", {
      params: data,
    });
    dispatch(setProfileDetailsById(response.data));
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRoles = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/GlobalData/getallrole?languageId=${languageId}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};
