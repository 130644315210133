import {
  GET_SALES_PERSON_DASHBOARD_DETAILS,
  GET_PENDING_VEHICLE_ASSIGNED,
  GET_PENDING_LEADS,
} from "../types";

const initialState = {
  salesPersonDashboardDetails: [],
  pendingVehicleAssigned: [],
  pendingLeads: [],
};
export const dmsSalesPersonDashboardReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SALES_PERSON_DASHBOARD_DETAILS:
      return {
        ...state,
        salesPersonDashboardDetails: action.payload,
      };
    case GET_PENDING_VEHICLE_ASSIGNED:
      return {
        ...state,
        pendingVehicleAssigned: action.payload,
      };
    case GET_PENDING_LEADS:
      return {
        ...state,
        pendingLeads: action.payload,
      };
    default:
      return state;
  }
};
