import React from "react";

export const DownloadRedIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.03125 9.21875C4.87516 9.06128 4.78802 8.84825 4.78899 8.62653C4.78997 8.4048 4.87899 8.19255 5.03646 8.03646C5.19393 7.88037 5.40696 7.79323 5.62868 7.7942C5.85041 7.79518 6.06266 7.88419 6.21875 8.04167L9.16667 10.9896V1.33333C9.16667 1.11232 9.25446 0.900358 9.41074 0.744078C9.56703 0.587798 9.77899 0.5 10 0.5C10.221 0.5 10.433 0.587798 10.5893 0.744078C10.7455 0.900358 10.8333 1.11232 10.8333 1.33333V10.9896L13.7813 8.04167C13.8585 7.96369 13.9504 7.90171 14.0517 7.85925C14.1529 7.81679 14.2615 7.79469 14.3713 7.7942C14.4811 7.79372 14.5899 7.81486 14.6915 7.85643C14.7931 7.898 14.8856 7.95917 14.9635 8.03646C15.0415 8.11375 15.1035 8.20564 15.146 8.30688C15.1884 8.40812 15.2105 8.51674 15.211 8.62653C15.2115 8.73631 15.1903 8.84512 15.1488 8.94673C15.1072 9.04835 15.046 9.14078 14.9688 9.21875L10.5938 13.5938C10.4345 13.7478 10.2216 13.8339 10 13.8339C9.77843 13.8339 9.56553 13.7478 9.40625 13.5938L5.03125 9.21875ZM19.1667 10.0333C18.9457 10.0333 18.7337 10.1211 18.5774 10.2774C18.4211 10.4337 18.3333 10.6457 18.3333 10.8667V16.7H1.66667V10.8667C1.66667 10.6457 1.57887 10.4337 1.42259 10.2774C1.26631 10.1211 1.05435 10.0333 0.833333 10.0333C0.61232 10.0333 0.400358 10.1211 0.244078 10.2774C0.0877975 10.4337 0 10.6457 0 10.8667V16.7C0 17.142 0.175595 17.566 0.488155 17.8785C0.800716 18.1911 1.22464 18.3667 1.66667 18.3667H18.3333C18.7754 18.3667 19.1993 18.1911 19.5118 17.8785C19.8244 17.566 20 17.142 20 16.7V10.8667C20 10.6457 19.9122 10.4337 19.7559 10.2774C19.5996 10.1211 19.3877 10.0333 19.1667 10.0333Z"
        fill="currentColor"
      />
    </svg>
  );
};
