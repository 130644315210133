import React from "react";

export const FilterIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={className}
    >
      <g id="Group_162635" transform="translate(-540 -211)">
        <path
          id="Path_881"
          d="M550,225a3,3,0,0,1,2.835,2.015l.049-.008h0L553,227h6a1,1,0,0,1,.117,1.993L559,229h-6a1.007,1.007,0,0,1-.166-.014,3,3,0,0,1-5.669,0l-.049.008h0L547,229h-6a1,1,0,0,1-.117-1.993L541,227h6a1.007,1.007,0,0,1,.166.014A3,3,0,0,1,550,225Zm0,2a1,1,0,1,0,1,1A1,1,0,0,0,550,227Zm-7-9a3,3,0,0,1,2.835,2.015l.049-.008h0L546,220h13a1,1,0,0,1,.117,1.993L559,222H546a1.007,1.007,0,0,1-.166-.014A3,3,0,1,1,543,218Zm0,2a1,1,0,1,0,1,1A1,1,0,0,0,543,220Zm11-9a3,3,0,0,1,2.835,2.015l.049-.008h0L557,213h2a1,1,0,0,1,.117,1.993L559,215h-2a1.007,1.007,0,0,1-.166-.014,3,3,0,0,1-5.669,0l-.049.008h0L551,215H541a1,1,0,0,1-.117-1.993L541,213h10a1.007,1.007,0,0,1,.166.014A3,3,0,0,1,554,211Zm0,2a1,1,0,1,0,1,1A1,1,0,0,0,554,213Z"
          fill="#465166"
        ></path>
      </g>
    </svg>
  );
};
