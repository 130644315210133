import React from "react";

export const EditUserIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.839"
      height="29.182"
      viewBox="0 0 34.839 29.182"
      className={className}
    >
      <g
        id="Group_166888"
        data-name="Group 166888"
        transform="translate(-1790.616 -945.5)"
      >
        <g
          id="Icon_feather-user-x"
          data-name="Icon feather-user-x"
          transform="translate(1790.616 942.5)"
        >
          <path
            id="Path_13672"
            data-name="Path 13672"
            d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3"
            transform="translate(0 -0.818)"
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_13673"
            data-name="Path 13673"
            d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z"
            transform="translate(-0.25)"
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
        <path
          id="Icon_feather-edit-2"
          data-name="Icon feather-edit-2"
          d="M10.357,3.664a1.387,1.387,0,1,1,1.962,1.962L5.7,12.246,3,12.982l.736-2.7Z"
          transform="translate(1812.116 950.381)"
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
