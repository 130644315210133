import React from "react";

export const PlayIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 46"
      className={className}
    >
      <path
        opacity="1"
        d="M24.7099 0.535156C31.0145 0.535156 37.0608 2.91464 41.5188 7.15014C45.9769 11.3856 48.4813 17.1302 48.4813 23.1201C48.4813 29.11 45.9769 34.8546 41.5188 39.0901C37.0608 43.3256 31.0145 45.7051 24.7099 45.7051C18.4053 45.7051 12.359 43.3256 7.90097 39.0901C3.44296 34.8546 0.938477 29.11 0.938477 23.1201C0.938477 17.1302 3.44296 11.3856 7.90097 7.15014C12.359 2.91464 18.4053 0.535156 24.7099 0.535156ZM5.39562 23.1201C5.39562 27.9869 7.43051 32.6544 11.0526 36.0957C14.6748 39.5371 19.5874 41.4704 24.7099 41.4704C29.8324 41.4704 34.745 39.5371 38.3672 36.0957C41.9893 32.6544 44.0242 27.9869 44.0242 23.1201C44.0242 18.2533 41.9893 13.5859 38.3672 10.1445C34.745 6.70317 29.8324 4.76984 24.7099 4.76984C19.5874 4.76984 14.6748 6.70317 11.0526 10.1445C7.43051 13.5859 5.39562 18.2533 5.39562 23.1201ZM19.8932 15.2916L32.5634 22.516C32.6729 22.5788 32.7635 22.6674 32.8263 22.7733C32.8892 22.8791 32.9222 22.9986 32.9222 23.1201C32.9222 23.2417 32.8892 23.3611 32.8263 23.467C32.7635 23.5728 32.6729 23.6614 32.5634 23.7243L19.8932 30.9486C19.7806 31.0131 19.6519 31.048 19.5204 31.0498C19.3888 31.0515 19.2592 31.0201 19.1447 30.9587C19.0301 30.8972 18.9348 30.808 18.8685 30.7001C18.8021 30.5922 18.7671 30.4695 18.767 30.3445V15.8986C18.7666 15.7734 18.8012 15.6503 18.8673 15.542C18.9335 15.4337 19.0287 15.3441 19.1434 15.2823C19.258 15.2205 19.3878 15.1888 19.5196 15.1905C19.6514 15.1921 19.7803 15.227 19.8932 15.2916Z"
        fill="currentColor"
      />
    </svg>
  );
};
