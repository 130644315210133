import React from "react";

export const ListViewIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      className={className}
    >
      <g id="list-view" transform="translate(-1248 -257)">
        <rect
          id="Rectangle_60"
          width="40"
          height="40"
          rx="1"
          transform="translate(1248 257)"
          fillOpacity="0"
        ></rect>
        <path
          id="Icon_awesome-list-ul"
          d="M3.037,2.375A3.037,3.037,0,1,0,6.075,5.412,3.037,3.037,0,0,0,3.037,2.375Zm0,11.06a3.037,3.037,0,1,0,3.037,3.037,3.037,3.037,0,0,0-3.037-3.037Zm0,11.06a3.037,3.037,0,1,0,3.037,3.037,3.037,3.037,0,0,0-3.037-3.037Zm28.349,1.012H11.137a1.012,1.012,0,0,0-1.012,1.012v2.025a1.012,1.012,0,0,0,1.012,1.012H31.386A1.012,1.012,0,0,0,32.4,28.545V26.52A1.012,1.012,0,0,0,31.386,25.507Zm0-22.12H11.137A1.012,1.012,0,0,0,10.125,4.4V6.425a1.012,1.012,0,0,0,1.012,1.012H31.386A1.012,1.012,0,0,0,32.4,6.425V4.4A1.012,1.012,0,0,0,31.386,3.387Zm0,11.06H11.137a1.012,1.012,0,0,0-1.012,1.012v2.025A1.012,1.012,0,0,0,11.137,18.5H31.386A1.012,1.012,0,0,0,32.4,17.485V15.46A1.012,1.012,0,0,0,31.386,14.447Z"
          transform="translate(1251.801 260.625)"
          fill="#465166"
        ></path>
      </g>
    </svg>
  );
};
