import React from "react";

export const PageGoFirstArrowIcon = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 17.453 16.532"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g data-name="Group 166851" fill="#292929">
        <path
          data-name="Icon ionic-ios-arrow-forward"
          d="M2.85 8.27l6.257 6.251a1.176 1.176 0 0 1 0 1.669 1.191 1.191 0 0 1-1.674 0L.345 9.107a1.179 1.179 0 0 1-.034-1.629L7.428.346A1.182 1.182 0 1 1 9.1 2.014z"
        />
        <path
          data-name="Icon ionic-ios-arrow-forward"
          d="M10.85 8.27l6.257 6.251a1.176 1.176 0 0 1 0 1.669 1.191 1.191 0 0 1-1.674 0L8.345 9.107a1.179 1.179 0 0 1-.034-1.629L15.428.346A1.182 1.182 0 1 1 17.1 2.014z"
        />
      </g>
    </svg>
  );
};
