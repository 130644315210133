import React from "react";

export const InstagramIcon = ({ className = "" }) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 48"
      id="icon_instagram"
      className={className}
    >
      <path
        d="M31.9298 24.2097C31.9298 28.0857 28.6853 31.2297 24.6833 31.2297C20.6843 31.2297 17.4383 28.0842 17.4383 24.2097C17.4383 23.5137 17.5448 22.8432 17.7398 22.2087L15.9233 21.8142V32.0157C15.9233 32.5272 16.1423 32.7447 16.6523 32.7447H32.6828C33.1928 32.7447 33.4118 32.5272 33.4118 32.0157V21.8142L31.6298 22.2087C31.8233 22.8447 31.9298 23.5137 31.9298 24.2097Z"
        fill="currentColor"
      ></path>
      <path
        d="M24.683 28.4947C27.2685 28.4947 29.3645 26.4632 29.3645 23.9572C29.3645 21.4512 27.2685 19.4197 24.683 19.4197C22.0974 19.4197 20.0015 21.4512 20.0015 23.9572C20.0015 26.4632 22.0974 28.4947 24.683 28.4947Z"
        fill="currentColor"
      ></path>
      <path
        d="M24.667 0C11.413 0 0.666992 10.7445 0.666992 24C0.666992 37.2555 11.413 48 24.667 48C37.921 48 48.667 37.254 48.667 24C48.667 10.746 37.921 0 24.667 0ZM36.3265 32.7435C36.3265 34.389 35.0575 35.658 33.412 35.658H15.9235C14.278 35.658 13.009 34.389 13.009 32.7435V15.255C13.009 13.6095 14.278 12.3405 15.9235 12.3405H33.412C35.0575 12.3405 36.3265 13.6095 36.3265 15.255V32.7435Z"
        fill="currentColor"
      ></path>
      <path
        d="M32.6831 15.2556H29.7671C29.3636 15.2556 29.0381 15.5811 29.0381 15.9846V18.8991C29.0381 19.3026 29.3636 19.6281 29.7671 19.6281H32.6816C33.0851 19.6281 33.4106 19.3026 33.4106 18.8991V15.9846C33.4106 15.5826 33.0851 15.2556 32.6831 15.2556Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
