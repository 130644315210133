import React from "react";

export const UploadDocumentIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3784_101147)">
        <path
          d="M18.0893 0.244895C17.9672 0.116275 17.7997 0.0390625 17.6262 0.0390625H7.23686C5.31975 0.0390625 3.7373 1.61521 3.7373 3.53211V23.1784C3.7373 25.0955 5.31975 26.6717 7.23686 26.6717H21.3379C23.255 26.6717 24.8374 25.0955 24.8374 23.1784V7.57845C24.8374 7.41123 24.7602 7.2505 24.6509 7.12818L18.0893 0.244895ZM18.2758 2.30992L22.6696 6.92235H19.8133C18.9642 6.92235 18.2758 6.24044 18.2758 5.39131V2.30992ZM21.3379 25.3851H7.23686C6.03397 25.3851 5.02391 24.388 5.02391 23.1784V3.53211C5.02391 2.32922 6.02747 1.32567 7.23686 1.32567H16.9892V5.39131C16.9892 6.95445 18.2502 8.20895 19.8133 8.20895H23.5508V23.1784C23.5508 24.388 22.5473 25.3851 21.3379 25.3851Z"
          fill="#43474D"
        />
        <path
          d="M19.5816 20.9453H8.99291C8.63916 20.9453 8.34961 21.2347 8.34961 21.5886C8.34961 21.9424 8.63916 22.2319 8.99291 22.2319H19.5881C19.9418 22.2319 20.2314 21.9424 20.2314 21.5886C20.2314 21.2347 19.9418 20.9453 19.5816 20.9453Z"
          fill="#43474D"
        />
        <path
          d="M10.9937 14.0255L13.6441 11.1756V18.2005C13.6441 18.5543 13.9336 18.8438 14.2874 18.8438C14.6413 18.8438 14.9307 18.5543 14.9307 18.2005V11.1756L17.5811 14.0255C17.7097 14.1605 17.8772 14.2314 18.0507 14.2314C18.2051 14.2314 18.366 14.1735 18.4881 14.0577C18.7456 13.8132 18.7649 13.4078 18.5205 13.1506L14.7507 9.10427C14.6283 8.97565 14.4611 8.89844 14.2811 8.89844C14.1009 8.89844 13.9336 8.97565 13.8115 9.10427L10.0417 13.1506C9.79727 13.4078 9.81658 13.8195 10.0738 14.0577C10.3441 14.3021 10.7492 14.2828 10.9937 14.0255Z"
          fill="#43474D"
        />
      </g>
      <defs>
        <clipPath id="clip0_3784_101147">
          <rect
            width="26.6326"
            height="26.6326"
            fill="white"
            transform="translate(0.928223 0.0898438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
