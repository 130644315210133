import React from "react";

export const CreditIcon = ({ className = "" }) => {
  return (
    <svg
      id="Group_166958"
      data-name="Group 166958"
      xmlns="http://www.w3.org/2000/svg"
      width="46.568"
      height="46.538"
      viewBox="0 0 46.568 46.538"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1699"
            data-name="Rectangle 1699"
            width="46.568"
            height="46.538"
            fill="currentColor"
          />
        </clipPath>
      </defs>
      <g id="Group_166957" data-name="Group 166957" clipPath="url(#clip-path)">
        <path
          id="Path_13735"
          data-name="Path 13735"
          d="M0,3.539A5.048,5.048,0,0,1,3.253,1.366,33.6,33.6,0,0,1,13.74,0a34.859,34.859,0,0,1,9.61,1.183,9.571,9.571,0,0,1,2.7,1.157c1.478.979,1.482,2.108-.013,3.071a12.447,12.447,0,0,1-4.367,1.569,42.469,42.469,0,0,1-13.019.492A23.517,23.517,0,0,1,2.428,6.08,5.627,5.627,0,0,1,.5,4.916,3.86,3.86,0,0,1,0,4.175Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
        <path
          id="Path_13736"
          data-name="Path 13736"
          d="M0,68.032c.8.268,1.543.662,2.348.913a34.163,34.163,0,0,0,7.714,1.346,46.824,46.824,0,0,0,10.241-.35,21.938,21.938,0,0,0,6.564-1.83c.26-.127.3-.061.3.194-.008,1.318,0,2.636,0,3.953a1.766,1.766,0,0,1-.832,1.435A9.75,9.75,0,0,1,22.812,75.2a36.155,36.155,0,0,1-9.088,1.032,36.391,36.391,0,0,1-9.667-1.11,11.5,11.5,0,0,1-2.571-1A3.242,3.242,0,0,1,0,72.668Z"
          transform="translate(0 -59.766)"
          fill="currentColor"
        />
        <path
          id="Path_13737"
          data-name="Path 13737"
          d="M0,139.859c.8.268,1.543.662,2.348.913a34.164,34.164,0,0,0,7.714,1.346,46.824,46.824,0,0,0,10.241-.35,21.938,21.938,0,0,0,6.564-1.83c.26-.127.3-.061.3.194-.008,1.318,0,2.636,0,3.953a1.766,1.766,0,0,1-.832,1.435,9.749,9.749,0,0,1-3.516,1.509,36.153,36.153,0,0,1-9.088,1.032,36.39,36.39,0,0,1-9.667-1.11,11.5,11.5,0,0,1-2.571-1A3.242,3.242,0,0,1,0,144.5Z"
          transform="translate(0 -122.866)"
          fill="currentColor"
        />
        <path
          id="Path_13738"
          data-name="Path 13738"
          d="M0,210.937a17.278,17.278,0,0,0,5.227,1.717,45.364,45.364,0,0,0,12.395.582c.315-.027.63-.045.94-.067a44.038,44.038,0,0,0-.12,5.414c-.007.314-.1.41-.4.437a42.421,42.421,0,0,1-12.925-.636,13.852,13.852,0,0,1-3.465-1.176A3.383,3.383,0,0,1,0,215.664Z"
          transform="translate(0 -185.308)"
          fill="currentColor"
        />
        <path
          id="Path_13739"
          data-name="Path 13739"
          d="M0,282.764a17.228,17.228,0,0,0,5.223,1.716,44.736,44.736,0,0,0,12.339.58c.872-.077.871-.085.871.779,0,1.529-.009,3.058.008,4.587,0,.295-.089.393-.372.417A40.693,40.693,0,0,1,4.1,289.952a11.468,11.468,0,0,1-2.572-.982A3.3,3.3,0,0,1,0,287.491Z"
          transform="translate(0 -248.407)"
          fill="currentColor"
        />
        <path
          id="Path_13740"
          data-name="Path 13740"
          d="M185.63,337.53a9.461,9.461,0,0,0-1.986-.263,22.046,22.046,0,0,1-5.709-1.374,5.676,5.676,0,0,1-1.731-1.059,1.717,1.717,0,0,1-.6-1.362c.022-.725.005-1.452.005-2.226,4,1.945,8.27,2.382,12.594,2.381s8.578-.425,12.616-2.39a19.23,19.23,0,0,1-.07,2.742,2.093,2.093,0,0,1-1.084,1.285,11.522,11.522,0,0,1-3.7,1.439,30.334,30.334,0,0,1-5.069.737c-.092.007-.219-.061-.268.089h-.273c-.06-.064-.139-.042-.212-.047h-.4c-.072,0-.151-.017-.21.047h-.727a.492.492,0,0,0-.455,0h-1.091c-.107-.089-.234-.029-.351-.047H186.2c-.073,0-.151-.017-.211.047Z"
          transform="translate(-154.265 -290.992)"
          fill="currentColor"
        />
        <path
          id="Path_13741"
          data-name="Path 13741"
          d="M280.594,382.289a.305.305,0,0,1,.455,0Z"
          transform="translate(-246.501 -335.75)"
          fill="currentColor"
        />
        <path
          id="Path_13742"
          data-name="Path 13742"
          d="M268.377,382.275c.128,0,.27-.059.368.081h-.364c-.029-.017-.039-.034-.031-.052s.017-.028.026-.028"
          transform="translate(-235.743 -335.818)"
          fill="currentColor"
        />
        <path
          id="Path_13743"
          data-name="Path 13743"
          d="M293.078,382.391h.271c.027.018.036.036.026.052s-.019.024-.029.024h-.273q-.046-.028-.029-.052t.033-.023"
          transform="translate(-257.435 -335.928)"
          fill="currentColor"
        />
        <path
          id="Path_13744"
          data-name="Path 13744"
          d="M261.139,382.389c.022-.115.1-.088.179-.076q.047.024.032.05c-.01.018-.02.026-.03.026Z"
          transform="translate(-229.41 -335.851)"
          fill="currentColor"
        />
        <path
          id="Path_13745"
          data-name="Path 13745"
          d="M262.618,382.47c0-.025,0-.051,0-.076h.181q.048.023.033.049t-.03.026Z"
          transform="translate(-230.707 -335.932)"
          fill="currentColor"
        />
        <path
          id="Path_13746"
          data-name="Path 13746"
          d="M264.109,382.468c0-.025,0-.05,0-.075h.181q.048.023.034.05t-.03.027Z"
          transform="translate(-232.016 -335.929)"
          fill="currentColor"
        />
        <path
          id="Path_13747"
          data-name="Path 13747"
          d="M265.6,382.457c0-.026,0-.051,0-.077h.182q.048.024.034.051c-.01.018-.02.028-.03.028Z"
          transform="translate(-233.328 -335.919)"
          fill="currentColor"
        />
        <path
          id="Path_13748"
          data-name="Path 13748"
          d="M267.1,382.436c0-.026,0-.052,0-.078l.181,0q0,.04,0,.081Z"
          transform="translate(-234.641 -335.898)"
          fill="currentColor"
        />
        <path
          id="Path_13749"
          data-name="Path 13749"
          d="M290.321,382.414c.037-.083.107-.091.185-.08q.041.028.026.055t-.029.026Z"
          transform="translate(-255.046 -335.876)"
          fill="currentColor"
        />
        <path
          id="Path_13750"
          data-name="Path 13750"
          d="M291.816,382.442a.676.676,0,0,1,0-.08l.183,0q0,.038,0,.076Z"
          transform="translate(-256.36 -335.904)"
          fill="currentColor"
        />
        <path
          id="Path_13751"
          data-name="Path 13751"
          d="M295.558,382.384a.6.6,0,0,1,0-.077c.075-.011.158-.04.179.077Z"
          transform="translate(-259.647 -335.846)"
          fill="currentColor"
        />
        <path
          id="Path_13752"
          data-name="Path 13752"
          d="M188.348,215.17a33.025,33.025,0,0,1-8.782-1.073,10.56,10.56,0,0,1-2.588-1.065,3.788,3.788,0,0,1-.947-.769,1.381,1.381,0,0,1,.01-1.966,5.46,5.46,0,0,1,2.21-1.378,22.284,22.284,0,0,1,5.6-1.268,38.3,38.3,0,0,1,7.935-.084,21.535,21.535,0,0,1,7.077,1.635,5.131,5.131,0,0,1,1.261.8,1.519,1.519,0,0,1-.011,2.578,6.609,6.609,0,0,1-2.1,1.151,24.574,24.574,0,0,1-6.245,1.278c-1.177.111-2.358.138-3.424.163"
          transform="translate(-154.286 -182.201)"
          fill="currentColor"
        />
        <path
          id="Path_13753"
          data-name="Path 13753"
          d="M200.762,275.371c0,.853.023,1.683-.008,2.511a1.717,1.717,0,0,1-.762,1.239,8.353,8.353,0,0,1-3.007,1.427,35.7,35.7,0,0,1-17.634,0,8.952,8.952,0,0,1-2.905-1.333,1.95,1.95,0,0,1-.892-1.775c.041-.677.008-1.359.008-2.067,4,1.972,8.28,2.4,12.6,2.4s8.6-.415,12.6-2.4"
          transform="translate(-154.218 -241.913)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
